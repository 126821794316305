import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

class PureMember extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  renderStatus = () => {
    if (this.props.history.Action === 'Subscribe'){
      if (this.props.history.Status === "pending")
        return ('En attente')
      return ('Réussi')
    }
    if (this.props.history.Action === "Validation Subscribe"){
      if (this.props.history.Status === "subscribe")
        return ('Réussi')
      return ('Raté')
    }
    if (this.props.history.Action === "UnSubscribe"){
    return ('Réussi')
    }
    if (this.props.history.Action === "FacturationButton"){
      if (this.props.history.Status === "false")
        return ("Facture Upload")
      return ("Facture envoyé")
    }
    if (this.props.history.Action === "Payment"){
      if (this.props.history.Status === "true")
        return ("Réussi")
      if (this.props.history.Status === "Open Stripe")
        return ("Ouverture du formulaire Stripe")
      return ("Rater/Annulé")
    }
    return ('')
  }

  UrlPayment = () => {
    if (this.props.history.UrlPayment){
      return (
        <a  style={{fontSize: '0.85em'}}  href={this.props.history.UrlPayment} >
        {this.props.history.UrlPayment}
        </a>
      )
    }
    return ('')
  }

  renderFile = () => {
    if (this.props.history.File){
      return (
        <Button onClick={() => this.props.refParent._DownloadFileInvoice(this.props.history.File, "Event")} style={{fontSize: '0.85em'}} variant='text'>
          Télécharger
        </Button>
      )
    }
    return ('')
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Date}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.NameEvent}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Action}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.renderStatus()}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Source ? this.props.history.Source : this.props.history.From}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.UrlPayment ? this.UrlPayment() : ""}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.File ? this.renderFile() : ""}</TableCell>
      </TableRow>)
  }

}

export default PureMember;
