import React from 'react';
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import CurrencyTextField from '../../CurrencyTextField/index'
import Switch from "react-switch";
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class OwnerSection extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    componentDidMount(){

    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

 render() {
   return (
     <Container ref={this.props.refParent.FacturationSection} className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 10, padding: 50, margin: 10, marginTop: 25}}>
       <Row>
         <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations de facturation</h6>
         </Col>
       </Row>
             <Row>
               <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
                 <p style={{textAlign: 'left', marginTop: 10}}>Type de contact<span style={{color: 'red'}}> *</span></p>
                 <FormControlLabel control={<OrangeCheckbox onChange={() => this.props.refParent.setState({TypeContact: "society"})} checked={this.props.refParent.state.TypeContact === "society"} name="society"/>} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Société</Typography>} />
                 <FormControlLabel control={<OrangeCheckbox onChange={() => this.props.refParent.setState({TypeContact: "private"})} checked={this.props.refParent.state.TypeContact === "private"} name="private" />} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Privé</Typography>} />
               </Form.Group>
             </Row>
             <Row style={{marginTop: 15}}>
             {
               this.props.refParent.state.TypeContact === "society" ?
               <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
                 <p style={{textAlign: 'left', marginTop: 10}}>Tva<span style={{color: 'red'}}> *</span></p>
                 <Form.Control
                 required
                 type="text"
                 placeholder={"Entrez la Tva"}
                 value={this.props.refParent.state.TvaFacturation}
                 onChange={(event) => this.props.refParent.setState({TvaFacturation: event.target.value})}/>
                 {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                    <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.tva}</p>
                    :
                    <></>
                 }
               </Form.Group>
               :
               <>
               </>
             }

             <Form.Group as={Col} xl={4} lg={5} sm={12} md={12}>
               <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
               <Form.Control
               required
               type="text"
               placeholder={"Entrez le nom de la société/personne"}
               value={this.props.refParent.state.SocietyNameFacturation}
               onChange={(event) => this.props.refParent.setState({SocietyNameFacturation: event.target.value})}/>
               {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                  <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.society}</p>
                  :
                  <></>
               }
             </Form.Group>
             </Row>
              <Row>
                <Form.Group as={Col} xl={2} lg={2} sm={2} md={2}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Numéro<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={"Numéro, boîte"}
                  value={this.props.refParent.state.NumberFacturation}
                  onChange={(event) => this.props.refParent.setState({NumberFacturation: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationNumber}</p>
                     :
                     <></>
                  }
                </Form.Group>
                <Form.Group as={Col} xl={9} lg={9} sm={9} md={9}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Adresse<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={"Rue, Avenue"}
                  value={this.props.refParent.state.StreetCityFacturation}
                  onChange={(event) => this.props.refParent.setState({StreetCityFacturation: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationStreet}</p>
                     :
                     <></>
                  }
                </Form.Group>
                </Row>
              <Row>
                <Form.Group as={Col} xl={4} lg={3} sm={12} md={12}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Code Postal<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="number"
                  placeholder={"Entrez le code postal"}
                  value={this.props.refParent.state.PostalCodeFacturation}
                  onChange={(event) => this.props.refParent.setState({PostalCodeFacturation: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationZipCode}</p>
                     :
                     <></>
                  }
                </Form.Group>
                <Form.Group as={Col} xl={7} lg={8} sm={12} md={12}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Ville<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={"Entrez la ville"}
                  value={this.props.refParent.state.CityFacturation}
                  onChange={(event) => this.props.refParent.setState({CityFacturation: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationCity}</p>
                     :
                     <></>
                  }
                </Form.Group>

                </Row>
                <Row>
                  <Form.Group as={Col} xl={4} lg={3} sm={12} md={12}>
                    <p style={{textAlign: 'left', marginTop: 10}}>Email de facturation<span style={{color: 'red'}}> *</span></p>
                    <Form.Control
                    required
                    type="text"
                    placeholder={"Entrez l'email de réception"}
                    value={this.props.refParent.state.EmailFacturation}
                    onChange={(event) => this.props.refParent.setState({EmailFacturation: event.target.value})}/>
                    {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                       <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationEmail}</p>
                       :
                       <></>
                    }
                  </Form.Group>
                  <Form.Group as={Col} xl={7} lg={8} sm={12} md={12}>
                    <p style={{textAlign: 'left', marginTop: 10}}>Numéro de Po</p>
                    <Form.Control
                    required
                    type="text"
                    placeholder={"Entrez le numéro de PO"}
                    value={this.props.refParent.state.Po}
                    onChange={(event) => this.props.refParent.setState({Po: event.target.value})}/>
                  </Form.Group>

                  </Row>
                <Row style={{marginTop: 10}}>
                  <Col xl={3} lg={3} md={12} sm={12}>
                    <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
                    <CurrencyTextField
                       label="Montant"
                       variant="standard"
                       value={this.props.refParent.state.Price ? this.props.refParent.state.Price : '0'}
                       currencySymbol="€"
                       minimumValue="0"
                       outputFormat="string"
                       decimalCharacter="."
                       digitGroupSeparator=""
                       onChange={(event) => this.props.refParent.setState({Price: event.target.value})}
                       />
                       {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                          <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.price}</p>
                          :
                          <></>
                       }
                    </div>
                  </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                  <Col xl={3} lg={3} md={12} sm={12}>
                    <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 5}}>
                      <p style={{fontSize: '1em', color: 'black'}}>{"Prix variable ?"}</p>
                      <Switch onChange={() => this.props.refParent.setState({VariablePrice: !this.props.refParent.state.VariablePrice})} checked={this.props.refParent.state.VariablePrice}  width={42} height={22} />
                    </div>
                  </Col>
                </Row>
                <Row style={{marginTop: 10}}>
                  <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
                    <FormControlLabel control={
                      <OrangeCheckbox onChange={() => this.props.refParent.setState({SendMail: !this.props.refParent.state.SendMail})}
                      checked={this.props.refParent.state.SendMail}  />}
                      label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Envoyée la facture</Typography>} />
                  </Form.Group>
                </Row>
     </Container>
   )
 }
}


export default OwnerSection;
