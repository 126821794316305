import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import SpecificNoneMember from './SpecificNoneMember'

class ParselDetailsNoneMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={SpecificNoneMember} {...props}/>
   )
 }
}



export default ParselDetailsNoneMember;
