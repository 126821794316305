import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";

class Invoice extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object) => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }

  EmptyStreet = () => {
    if (!this.props.InvoiceInfo || this.ConstructStringWithComma([this.props.InvoiceInfo.Number, this.props.InvoiceInfo.StreetWN, this.props.InvoiceInfo.ZipCode, this.props.InvoiceInfo.City], ' ') === 'Vide')
      return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
    else
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
  }


  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={4} lg={4} md={12}>

          <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS FACTURATION</p>


            <div style={{}}>


              <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
                <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Adresse:</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={this.EmptyStreet()}>{this.props.InvoiceInfo ? this.ConstructStringWithComma([this.props.InvoiceInfo.Number, this.props.InvoiceInfo.StreetWN, this.props.InvoiceInfo.ZipCode, this.props.InvoiceInfo.City], ' ') :  "Vide"}</p>
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
                <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Email:</p>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={this.EmptyStreet()}>{this.props.InvoiceInfo ? this.props.InvoiceInfo.Email :  "Vide"}</p>
                </div>
              </div>


              {
                this.props.InvoiceInfo.TypeContactFacturation === "society" ?
                <>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                  <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
                  <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Société:</p>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <p style={this.HowStyleToApply("Society", "InvoiceInfo")}>{this.props.InvoiceInfo && this.props.InvoiceInfo.Society ? this.props.InvoiceInfo.Society : "Vide"}</p>
                  </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                  <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
                  <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Tva:</p>
                  </div>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <p style={this.HowStyleToApply("TVA", "InvoiceInfo")}>{this.props.InvoiceInfo && this.props.InvoiceInfo.TVA ? this.props.InvoiceInfo.TVA : "Vide"}</p>
                  </div>
                </div>
                </>
                :
                <>
                </>

              }

            </div>
          </Col>
    )
  }
}

export default Invoice;
