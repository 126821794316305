import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import Layout from './BodyAdmin/Layout';
import messages from './messages';
import './styles/App.scss';
import { BrowserRouter as Router } from 'react-router-dom'
import detectBrowserLanguage from 'detect-browser-language'
import './App.css'

function App() {
  const [locale, setLocale] = useState('en');


  const SetLanguage = () => {
    try {
      var language = detectBrowserLanguage();
      if (!language){
        setLocale('en')
        return ;
      }
      var formatLanguage = language.split('-')[0]
      if (formatLanguage !== 'fr' && formatLanguage !== 'nl')
        setLocale('en')
      else
        setLocale(formatLanguage)
    } catch (e) {
      setLocale('en')
    }
  }

  useEffect(() => {
    SetLanguage();
  }, [])

  return (
    <Router>
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Layout setLocale={setLocale}/>
    </IntlProvider>
    </Router>
  );
}

export default App;
