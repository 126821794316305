import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';



class PureMember extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  _setMaj = (string) => {
    try {
      if(string !== null){
        const newString = string.split("-")
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
        }
        const newStringJoin = newString.join("-")
        return (newStringJoin)
      }
      return ('')
    }catch (e){
      return (string)
    }
}

  _renderBusiness = () => {
    if (this.props.member.BusinessInfo !== true && this.props.member.BusinessInfo !== null)
    {
      return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
          {this.props.member.RegistrationType}
          <Link to={{
          pathname: `/details_member/${this.props.member.BusinessInfo.Id}`,
          state: {}
          }}
          style={{fontWeight: 'bold', color: 'black' }}>{this.props.member.BusinessInfo.FirstName + " " + this.props.member.BusinessInfo.LastName}</Link>
        </div>
      )
    }
    return (this.props.member.RegistrationType)
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.LastName)}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.FirstName)}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.Email}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.Society)}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.AccountCreatedAt}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.DeletedAt}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.RegistrationType === "Business" || this.props.member.RegistrationType === "Couple" ? this._renderBusiness() : this.props.member.RegistrationType}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.intl.formatMessage({id: this.props.member.Region})}</TableCell>
        <TableCell align="center" style={{}}>


        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown"  style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
            to={{
            pathname: `/details_deleted_member/${this.props.member.Id}`,
            state: {oldPage: "/deletedmembers/"}
            }}>
                Détails
            </Button>

            </Dropdown.Item>
            {
              this.props.member.RegistrationType === "SingleA" || this.props.member.RegistrationType === "SingleM" || this.props.member.RegistrationType === "Young" ?
            <Dropdown.Item  id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
              <Button onClick={() => {this.props.refParent._openDeleteToUser(this.props.member)}} variant="text" style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                  Faire Membre
              </Button>
            </Dropdown.Item>
            :
            <Dropdown.Item  id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            </Dropdown.Item>
            }

          </Dropdown.Menu>
        </Dropdown>



        </TableCell>
      </TableRow>)
  }

}

export default PureMember;
