import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddGallery from './AddGallery'

class ParselAddGallery extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddGallery} {...props}/>
   )
 }
}



export default ParselAddGallery;
