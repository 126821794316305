import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';


const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUpOwnerChange extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _ButtonClickNo = () => {
      this.props.refParent.setState({openModalOwner: false, idOwnerChange: null})
    }

    _ButtonClickYes = () => {
      this.props.refParent._modifyOwner()
    }


 render() {

   return (
       <Popup showPopup={this.props.refParent.state.openModalOwner} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.refParent.state.openModalOwner ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.5rem', alignItems: 'center'}}>ATTENTION</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.3rem', alignItems: 'center'}}>Êtes-vous sûr de vouloir changer le propriétaire du compte ?</p>
          </Col>
        </Row>
        <Row style={{margin: 10}}>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
          <ButtonTestCss  disabled={this.props.refParent.state.confirmOwnerChanger} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>
          {
            this.props.refParent.state.confirmOwnerChanger ?
            <CircularProgress
              size={"0.8rem"}
              thickness={5}
              color={"inherit"}
            />
            :
            <p style={{margin: 0, padding: 0}}>{this.props.refParent.state.changeOwnerResult != null ? "Ré-essayer" : "Oui"}</p>
          }
          </ButtonTestCss>
            <ButtonTestCss  disabled={this.props.refParent.state.confirmOwnerChanger} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
          </Col>
        </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUpOwnerChange;
