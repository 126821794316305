import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead'
import PureLocation from './PureLocation'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TableAdmin extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderLocation = () => {
      return (
        this.props.location.map((location, index) => (
        <PureLocation intl={this.props.intl} refParent={this.props.refParent} deleting={this.props.deleting} location={location} key={location.Id} index={index}/>
      ))
      )
    }

 render() {
   return (
     <Table>
      <TableHead refParent={this.props.refParent}/>
      {
        this.props.location.length === 0  && this.props.loadingLocation === false?
        <TableBody>
          <TableRow>
            <TableCell  colSpan={"7"}>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3}}>
                    <p style={{color: 'black', fontSize: '1.2rem', fontWeight: 'bold'}}>Pas de résultat</p>
                  </Col>
                </Row>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
        :
        <TableBody>
          {this.renderLocation()}
        </TableBody>
      }

    </Table>
   )
 }
}


export default TableAdmin;
