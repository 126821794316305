import React from 'react';

class Name extends React.Component {

  _setMaj = (string) => {
    try {
      if(string !== null){
        const newString = string.split("-")
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
        }
        const newStringJoin = newString.join("-")
        return (newStringJoin)
      }
      return ('')
    }catch (e){
      return (string)
    }
}

  render (){
    return (
      <div style={{display: 'flex', width: '100%'}}>
        <p style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>{this.props.FirstName && this.props.LastName ? this._setMaj(this.props.FirstName) + " " + this._setMaj(this.props.LastName) : ""}</p>
      </div>
    )
  }
}

export default Name;
