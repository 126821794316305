import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class PureMember extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  _renderBusiness = () => {
    if (this.props.member.BusinessInfo !== true && this.props.member.BusinessInfo !== null)
    {
      return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
          {this.props.member.RegistrationType}
          <Link to={{
          pathname: `/details_member/${this.props.member.BusinessInfo.Id}`,
          state: {}
          }}
          style={{fontWeight: 'bold', color: 'black' }}>{this.props.member.BusinessInfo.FirstName + " " + this.props.member.BusinessInfo.LastName}</Link>
        </div>
      )
    }
    return (this.props.member.RegistrationType)
  }

  _setMaj = (string) => {
    try {
      if(string !== null){
        const newString = string.split("-")
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
        }
        const newStringJoin = newString.join("-")
        return (newStringJoin)
      }
      return ('')
    }catch (e){
      return (string)
    }
}

_viewDetails = () =>  {
  this.props.refParent.GetSpecificMemberDetails(this.props.member)
}

  render() {
    return (
      <TableRow   style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none", cursor: 'pointer'}}>

        <TableCell onClick={() =>  this._viewDetails()} align="left">
        {
          this.props.member.Image ?
            <img  alt={"member ressource"} key={this.props.member.Id} src={this.props.member.Image} style={{width: 35, borderRadius: 360, height: 35}}/>
          :
            <div style={{width: 35, borderRadius: 360, height: 35, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.LastName)}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.FirstName)}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.member.Email}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this._setMaj(this.props.member.Society)}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.member.SubscriptionBegin}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.member.SubscriptionEnd}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{color: this.props.member.Cotisation ? 'green' : 'red', fontWeight: this.props.member.Cotisation ? '' : 'bold', fontSize: '0.8rem'}}>{this.props.member.Cotisation ? "Oui" : "Non"}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.member.RegistrationType === "Business" || this.props.member.RegistrationType === "Couple" ? this._renderBusiness() : this.props.member.RegistrationType}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.intl.formatMessage({id: this.props.member.Region})}</TableCell>
        <TableCell onClick={() =>  this._viewDetails()} align="left" style={{fontSize: '0.8rem'}}>{this.props.member.DateInvoice}</TableCell>

        <TableCell align="center">


        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
            to={{
            pathname: `/details_member/${this.props.member.Id}`,
            state: {oldPage: "/end_member/"}
            }}>
                DÉTAILS
            </Button>

            </Dropdown.Item>
            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button onClick={() => {this.props.refParent._DeleteUser(this.props.member)}} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              SUPPRIMER
            </Button>

            </Dropdown.Item>

            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button disabled={this.props.member.RegistrationType === "Young" ? true: false} onClick={() => {this.props.refParent._AcceptRefuseGestion(this.props.member, true)}} variant="text" style={{color: 'green', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              VALIDER
            </Button>

            </Dropdown.Item>
            {
              this.props.member.RegistrationType === 'Young' ?
              <>
              </>
              :
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button onClick={() => {this.props.refParent.UploadInvoice(this.props.member)}} variant="text" style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                facture
              </Button>

              </Dropdown.Item>
            }
            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button disabled={this.props.member.RegistrationType === "Young" ? true: false} onClick={() => {this.props.refParent._BlockedGestion(this.props.member)}} variant="text" style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              {this.props.member.Payment === "true" ? "BLOQUER" : "DÉBLOQUER"}
            </Button>


            </Dropdown.Item>

          </Dropdown.Menu>
        </Dropdown>

        </TableCell>
      </TableRow>)
  }

}

export default PureMember;
