import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";

class Complement extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object) => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }


  render (){
    return (
      <Col sm={12} xl={12} lg={12} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS SUR LES DATES</p>


        <div style={{}}>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date de création:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("CreatedAt", "Date")}>{this.props.Date && this.props.Date.CreatedAt ? this.props.Date.CreatedAt : "Vide"}</p>
          </div>
        </div>


        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Dernière date de modification:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("ModifyAt", "Date")}>{this.props.Date && this.props.Date.ModifyAt ? this.props.Date.ModifyAt : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date de l'évènement:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("DateEvent", "Date")}>{this.props.Date && this.props.Date.DateEvent ? this.props.Date.DateEvent : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Heure de début:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("BeginHours", "Date")}>{this.props.Date && this.props.Date.BeginHours ? this.props.Date.BeginHours : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Heure de fin:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("EndHours", "Date")}>{this.props.Date && this.props.Date.EndHours ? this.props.Date.EndHours : "Vide"}</p>
          </div>
        </div>


        </div>
      </Col>
    )
  }
}

export default Complement;
