import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Photo from './ProfilImage'
import Name from './Name'
import SocietyInfo from './SocietyInfo'
import ActionMember from './ActionMember'
import NavBarMember from './NavMember'

class Header extends React.Component {


  render (){
    return (


      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20, paddingBottom: 0,  whidth: '100%', marginBottom: 10}}>
        <Row>
          <Col sm={12} md={12} lg={4} xl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Photo Image={this.props.Image} />
          </Col>
          <Col  lg={5} xl={5} style={{padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column'}}>
            <Name FirstName={this.props.FirstName} LastName={this.props.LastName}/>
            <SocietyInfo SocietyInfo={this.props.SocietyInfo}/>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 0}}>
            <NavBarMember NotAcces={this.props.NotAcces} refParent={this.props.refParent}/>
          </Col>
        </Row>
      </Container>

    )
  }
}

export default Header;
