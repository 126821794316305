import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Row';


class Header extends React.Component {

 render() {

   return (
     <div ref={this.props.refParent.Header}>
         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
           <Col xl={5} lg={5} md={5} sm={5} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3, flexDirection: 'column'}}>

               <h2 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{this.props.intl.formatMessage({ id: 'AddBusinessCutomize' })}</h2>
               <div style={{height: 2, width: "50%", backgroundColor: 'orange'}} />
           </Col>
         </Row>
     </div>
   )
 }
}


export default Header;
