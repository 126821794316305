import React from 'react';

import AlertUser from './Alert'


class Header extends React.Component {

  _renderAlert = () => {
    var NumberAlert = []


    if (this.props.refParent.state.hasOwnProperty('Cotisation') && this.props.refParent.state.Cotisation === false)
      NumberAlert.push({color: "white", message: "NotOrderCotisation", background: "#ff7878"})
    if (this.props.refParent.state.hasOwnProperty('PaymentStatus') && this.props.refParent.state.PaymentStatus !== null && this.props.refParent.state.PaymentStatus !== 'true'){
      if (this.props.refParent.state.PaymentStatus === "pending")
        NumberAlert.push({color: "black", message: "PendingStatusMember", background: "#ffdd70"})
      else
        NumberAlert.push({color: "white", message: "BlockStatusMember", background: "#ff7878"})
    }
    if (this.props.refParent.state.Complement && (this.props.refParent.state.Complement.RegistrationType === "Business" || this.props.refParent.state.Complement.RegistrationType === "Couple") && this.props.refParent.state.Complement.BusinessInfo && this.props.refParent.state.Complement.BusinessInfo !== true){
      if (this.props.refParent.state.Complement.BusinessInfo.PayementStatus === "pending")
        NumberAlert.push({color: "black", message: "PendingStatusMember", background: "#ffdd70"})
      else if (this.props.refParent.state.Complement.BusinessInfo.PaymentStatus === "false")
        NumberAlert.push({color: "white", message: "BlockStatusMember", background: "#ff7878"})
    }
    if (NumberAlert.length === 0)
      return (<></>)

    return NumberAlert.map((info, index) => {
      return <AlertUser intl={this.props.intl} key={index} background={info.background} color={info.color} message={info.message} />
    })

  }

  render (){
    return (

      <>
      {this._renderAlert()}
      </>
    )
  }
}

export default Header;
