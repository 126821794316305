import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CssTextField} from './Style'


class Name extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
     <>
       <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.FirstName ? this.props.FirstName : ''} label="Prénom" variant="outlined" onChange={(event) => this.props.refParent.setState({FirstName: event.target.value})}/>
            <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.FirstName ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer le prénom</p>
          </div>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.LastName ? this.props.LastName : ''} label="Nom" variant="outlined" onChange={(event) => this.props.refParent.setState({LastName: event.target.value})}/>
            <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.LastName ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer le nom</p>

          </div>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.Function ? this.props.Function : ''} label="Fonction" variant="outlined" onChange={(event) => this.props.refParent.setState({Function: event.target.value})}/>
            <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Function ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer la fonction</p>
          </div>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.Linkedin ? this.props.Linkedin : ''} label="Linkedin" variant="outlined" onChange={(event) => this.props.refParent.setState({Linkedin: event.target.value})}/>
          </div>
        </Col>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.Society ? this.props.Society : ''} label="Société" variant="outlined" onChange={(event) => this.props.refParent.setState({Society: event.target.value})}/>
            <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Society ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer la Société</p>
          </div>
        </Col>
       </Row>
     </>
   )
 }
}


export default Name;
