import React from 'react';
import Col from 'react-bootstrap/Col';
import {CssTextField} from './Style'


class Name extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <CssTextField value={this.props.Name ? this.props.Name : ''} label="Nom" variant="outlined" onChange={(event) => this.props.refParent.setState({Name: event.target.value})}/>
            <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Name ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer le nom</p>
          </div>
        </Col>

   )
 }
}


export default Name;
