import React from 'react';
import { IoIosAlert } from "react-icons/io";
import {Row, Container, Col} from 'react-bootstrap';
import FadeInOut from "./FadeInOut";


class AlertSuccess extends React.Component {

 render() {
     return (


         <FadeInOut show={this.props.refParent.state.openModal} duration={500}>
           <div className="shadow" style={{backgroundColor: this.props.refParent.state.colorAlert, borderRadius: 10, padding: 15, margin: 10, marginTop: 25, position: 'absolute', zIndex: 10000, bottom: 0, right: 0}}>
            <Container>
             <Row style={{}}>
               <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', height: '100%', flexDirection: 'row', padding: 10, alignItems: 'center'}}>
                   <div style={{justifyContent: 'flex-start', alignItems: 'center', marginRight: 10}}>
                     <IoIosAlert size={20} color={"black"}/>
                   </div>
                   <div style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                     <p style={{color: "black", fontSize: 12, padding: 0, margin: 0, fontWeight: 'bold'}}>{this.props.refParent.state.messageAlert}</p>
                   </div>
               </Col>
             </Row>
             </Container>

             </div>
           </FadeInOut>


     )

 }
}

export default AlertSuccess;
