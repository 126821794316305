import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({info}) => {

  return (

    <Row style={{height: '325px'}}>
      <Bar data={info}
      height='100%'
      width='100%'
      options={{ maintainAspectRatio: false }}
      />
    </Row>
  )
}

export default (BarChart)
