import React from 'react';
import {Requestdynamic} from '../Network/Fetch';
import Cookies from 'universal-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CreateChart from './CreateChart/CreateChart';
import './DashBoard.css'
import {FormatStatisticDate, GetCurrentDateEuropeParis, FormatNumberMonth} from '../Moment/Moment'


class DashBoard extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        selectB19Club: "",
        loading: false
      }
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

   _ratio = (StatBefore, StatNow) => {
     if (this.state.hasOwnProperty(this.state.selectB19Club)){
       if (StatBefore === 0)
        return (
          <div class="number" style={{color: 'green', fontWeight: 'bold', fontSize: "1rem", fontFamily: "poppins, sans-serif"}}>
            <p>{"+ "}{StatNow}{" %"}</p>
          </div>
        )

        var result = -(100 - ((StatNow / StatBefore)* 100)).toFixed(2)
          return (
            <div class="number" style={{color: result > 0 ? 'green' : 'red', fontWeight: 'bold', fontSize: "1rem", fontFamily: "poppins, sans-serif"}}>
              <p>{result > 0 ? "+ " : "- "}{result > 0 ? result : -result}{" %"}</p>
            </div>
        )
     }
     else {
       return (
         <div class="number">
           <p>0{" %"}</p>
         </div>
       )
     }
   }

    _getSubscriptionStat = async (TargetMonth = null, TargetYears = null) => {
      this.setState({loading: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Info = cookies.get('infoAdmin');
        var Params = {
            Jwt: Jwt,
            Previous: 'true',
            NumberOfPrevious: 12,
        }
        if (TargetMonth != null)
          Params.TargetMonth = TargetMonth
        if (TargetYears != null)
          Params.TargetYears = TargetYears
        var promise = await Requestdynamic('_getSubscriptionStat', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var Keys = Object.keys(promise.data.data);
          var paramsSetState = {loading: false}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = promise.data.data[Keys[i]]
          }
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true){
            this.setState({loading: false})
            return ;
          }
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
        }
      })

    }

    handleChange = (event) => {
      try {
        if (event.target.value){
          var TargetMonth = event.target.value.split('-')[1];
          var TargetYears = event.target.value.split('-')[0];
          this.setState({DateTarget: FormatStatisticDate(event.target.value, 'YYYY-MM-DD')})
          this._getSubscriptionStat(parseInt(TargetMonth, 10), parseInt(TargetYears, 10))
        }
      } catch (e) {
        console.log(e)
        return ;
      }

  }
    componentDidMount(){
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      if (Info && Info.Region)
        this.setState({selectB19Club: Info.Region[0], DateTarget: FormatStatisticDate(GetCurrentDateEuropeParis('YYYY-MM-DD'), 'YYYY-MM-DD')}, () => {this._getSubscriptionStat()})

    }

    Total = () => {
      try {
        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - 2]
        var SumBusinessPackCustomize = this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty('BusinessPackCustomize') ? this.state[this.state.selectB19Club][TargetMonth].BusinessPackCustomize : 0

        return (SumBusinessPackCustomize + this.state[this.state.selectB19Club][TargetMonth].SingleA + this.state[this.state.selectB19Club][TargetMonth].Business + this.state[this.state.selectB19Club][TargetMonth].Young)
      } catch (e) {
        return "";
      }

    }



    TotalSales = () => {
      try {
        var SingleAPrice = 600;
        var BusinessPrice = 2000;

        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - 2]

        var SingleASales = SingleAPrice * this.state[this.state.selectB19Club][TargetMonth].SingleA;
        var BusinessSales = BusinessPrice * this.state[this.state.selectB19Club][TargetMonth].Business;
        var BusinessPackCustomizeSales = this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty("BusinessPackCustomizeSales") ? this.state[this.state.selectB19Club][TargetMonth].BusinessPackCustomizeSales : 0;

        return (SingleASales + BusinessSales + BusinessPackCustomizeSales)
      } catch (e) {
        return "";
      }

    }



    _getNumberOfaPackageByTargetMonth = (NamePackage) => {
      try {
        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - 2]
        if(!this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty(NamePackage))
          return (0)
        return (this.state[this.state.selectB19Club][TargetMonth][NamePackage])
      } catch (e) {
        return "";
      }
    }

    _createFilter = () => {
      try {
        var cookies = new Cookies()
        var Info = cookies.get('infoAdmin')
        var NewRegion = [];
        Info.Region.forEach(res => {
          if (res === 'anv' || res === 'fl' || res === 'bxl')
            NewRegion.push('bxl')
          else
            NewRegion.push(res)
        })
        NewRegion = Array.from(new Set(NewRegion))
        return NewRegion.map((region, index) => {
          return (
            <a  onClick={() => this.setState({selectB19Club: region})} style={{fontFamily: "poppins, sans-serif"}}>
              <div class={"hover-underline-animation " + (this.state.selectB19Club === region ? 'activeFiltre' : '')}>
                <h3 style={{fontSize: '0.9rem'}}>{region === 'bxl' ? "Bruxelles" : this.props.intl.formatMessage({ id: region })}</h3>
              </div>
            </a>
          )
        })
      } catch (e){
        return <></>
      }
    }

    _GetTotalSalesOfMonth = (Number) => {
      try {
        var SingleAPrice = 600;
        var BusinessPrice = 2000;

        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - (Number + 2)]

        var SingleASales = SingleAPrice * this.state[this.state.selectB19Club][TargetMonth].SingleA;
        var BusinessSales = BusinessPrice * this.state[this.state.selectB19Club][TargetMonth].Business;
        var BusinessPackCustomizeSales = this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty("BusinessPackCustomizeSales") ? this.state[this.state.selectB19Club][TargetMonth].BusinessPackCustomizeSales : 0;

        return (SingleASales + BusinessSales + BusinessPackCustomizeSales)
      } catch (e) {
        return 0;
      }
    }

    _GetTotalMemberOfMonth = (Number) => {
      try {
        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - (Number + 2)]
        var SumBusinessPackCustomize = this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty('BusinessPackCustomize') ? this.state[this.state.selectB19Club][TargetMonth].BusinessPackCustomize : 0
        return (SumBusinessPackCustomize + this.state[this.state.selectB19Club][TargetMonth].SingleA + this.state[this.state.selectB19Club][TargetMonth].Business + this.state[this.state.selectB19Club][TargetMonth].Young)
      } catch (e) {
        return 0;
      }
    }

    _GetTotalPackageOfMonth = (Number, NamePackage) => {
      try {
        var Keys = Object.keys(this.state[this.state.selectB19Club])
        var TargetMonth = Keys[Keys.length - (Number + 2)]
        if (!this.state[this.state.selectB19Club][TargetMonth].hasOwnProperty(NamePackage))
        return (0)
        return (this.state[this.state.selectB19Club][TargetMonth][NamePackage])
      } catch (e) {
        return 0;
      }
    }

    _h1 = (Text) => (
      <h1 style={{fontWeight: 800, fontSize: "1.8rem", fontFamily: "poppins, sans-serif"}}>{Text}</h1>
    )

    _h3 = (Text) => (
      <h3 style={{fontSize: "0.87rem", fontFamily: "poppins, sans-serif"}}>{Text}</h3>
    )

    _h5 = (Text) => (
      <h5 style={{fontSize: "0.77rem", fontFamily: "poppins, sans-serif"}}>{Text}</h5>
    )

    _createMethodPayment = () => {
      try {
        var ObjectReturn = {}
        ObjectReturn.Title = 'Méthodes de payements ' + this.state.Years
        ObjectReturn.Size = '4'
        ObjectReturn.ChartName = 'Doughnut'
        ObjectReturn.labels = ['Stripe', 'Facture']
        ObjectReturn.datasets = []
        var data = {
          data: [this.state[this.state.selectB19Club]["Total"]["Stripe"], this.state[this.state.selectB19Club]["Total"]["Billing"]],
          backgroundColor: [
            'rgba(255, 99, 132, 0.8)',
            'rgba(54, 162, 235, 0.8)',
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
          ],
          borderWidth: 2,
        }
        ObjectReturn.datasets.push(data)
        return ObjectReturn
      } catch (e){
        var ObjectReturn = {}
        ObjectReturn.Title = 'Méthodes de payements ' + this.state.Years
        ObjectReturn.Size = '4'
        ObjectReturn.labels = ['Stripe', 'Facture']
        ObjectReturn.datasets = []
        return ObjectReturn
      }
    }

    _createLabelMonth = (selectB19Club) => {
      try {
        var LabelArray = []


        selectB19Club.forEach((item, i) => {
          LabelArray.push(FormatNumberMonth(item))
        });
        return LabelArray
      } catch (e) {
        return []
      }

    }

    _createDatasetsBar = (selectB19Club) => {
      try {
        var DataSets = []
        var Pack = [{name: 'SingleA', color: 'rgba(255, 99, 132, 0.8)'}, {name: 'Young', color: 'rgba(53, 162, 235, 0.8)'}, {name: 'Business', color: 'rgba(255, 159, 64, 0.8)'}, {name: 'BusinessPackCustomize', color: 'rgba(255, 3, 76, 0.8)'}];
        Pack.forEach((item, i) => {
          var Item = {}
          Item.label = item.name === "SingleA" ? "Single" : item.name;
          Item.backgroundColor = item.color;
          Item.data = []
          selectB19Club.forEach((month, i) => {
            Item.data.push(this.state[this.state.selectB19Club][month][item.name])
          });

          DataSets.push(Item)

        });

        return DataSets
      } catch (e) {
        return []
      }

    }

    _createDataBarChart = () => {
      try {
        var Keys = Object.keys(this.state[this.state.selectB19Club])
        Keys.pop()
        var ObjectReturn = {}
        ObjectReturn.Title = 'Tendance nouveaux membres ' + this.state.Years
        ObjectReturn.Size = '7'
        ObjectReturn.ChartName = 'Bar'
        ObjectReturn.labels = this._createLabelMonth(Keys)
        ObjectReturn.datasets = this._createDatasetsBar(Keys)
        return ObjectReturn
      } catch (e){
        var ObjectReturn = {}
        ObjectReturn.Title = 'Tendance nouveaux membres ' + this.state.Years
        ObjectReturn.Size = '4'
        ObjectReturn.labels = ['Stripe', 'Facture']
        ObjectReturn.datasets = []
        return ObjectReturn
      }
    }

 render() {
   return (
     <main>
        {this._h1("Dashboard")}

        <div class="date">
          <input type="date"  onChange={this.handleChange}/>
        </div>

        <filtre>
         {this._createFilter()}
       </filtre>

        <div class="insights">

          <div class="sales">

            <span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Ventes")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this.TotalSales() + "€ HTVA" : 0 + "€ HTVA")}
              </div>
              <div class="progress">
                {this._ratio(this._GetTotalSalesOfMonth(1), this._GetTotalSalesOfMonth(0))}
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>


          </div>

          <div class="expenses">
            {/* <MdBarChart/> */}<span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Nouveaux Membres")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this.Total() : 0)}
              </div>
              <div class="progress">
                {this._ratio(this._GetTotalMemberOfMonth(1), this._GetTotalMemberOfMonth(0))}
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>
          </div>

          <div class="income">
            {/* <MdOutlineStackedLineChart/> */}<span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Single Pack")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this._getNumberOfaPackageByTargetMonth("SingleA") : 0)}
              </div>
              <div class="progress">
                <div class="number">
                  {this._ratio(this._GetTotalPackageOfMonth(1, 'SingleA'), this._GetTotalPackageOfMonth(0, "SingleA"))}
                </div>
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>
          </div>

          <div class="expenses">
            {/* <MdBarChart/> */}<span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Business Pack")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this._getNumberOfaPackageByTargetMonth("Business") : 0)}
              </div>
              <div class="progress">
                <div class="number">
                  {this._ratio(this._GetTotalPackageOfMonth(1, 'Business'), this._GetTotalPackageOfMonth(0, "Business"))}
                </div>
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>
          </div>
          <div class="expenses">
            {/* <MdBarChart/> */}<span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Business Pack Customize")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this._getNumberOfaPackageByTargetMonth("BusinessPackCustomize") : 0)}
              </div>
              <div class="progress">
                <div class="number">
                  {this._ratio(this._GetTotalPackageOfMonth(1, 'BusinessPackCustomize'), this._GetTotalPackageOfMonth(0, "BusinessPackCustomize"))}
                </div>
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>
          </div>

          <div class="expenses">
            {/* <MdBarChart/> */}<span></span>
            <div class="middle">
              <div class="left">
                {this._h3("Total Young Pack")}
                {this._h5(this.state.DateTarget ? this.state.DateTarget.charAt(0).toUpperCase() + this.state.DateTarget.slice(1) : "")}
                {this._h1(this.state.hasOwnProperty(this.state.selectB19Club) ? this._getNumberOfaPackageByTargetMonth("Young") : 0)}
              </div>
              <div class="progress">
                <div class="number">
                  {this._ratio(this._GetTotalPackageOfMonth(1, 'Young'), this._GetTotalPackageOfMonth(0, "Young"))}
                </div>
              </div>
            </div>
            <small class="text-muted">Last 24 Hours</small>
          </div>

        </div>
        {
          this.state.hasOwnProperty(this.state.selectB19Club)
          ?
          <CreateChart info={{
            chart: [
              [
                this._createMethodPayment(),
                this._createDataBarChart()
              ]
            ]
          }}/>
          :
          <>
          </>
        }


      </main>
   )
 }
}


export default DashBoard;
