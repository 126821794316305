import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FileUpload from "../../FileUpload/FileUpload";
import FileUploadReset from "../../FileUpload/FileUploadReset";
import SeparateLine from "./SeparateLine"
import {GetCurrentDateEuropeParis} from '../../Moment/Moment'

class Photo extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }



     updateUploadedFiles = async (files) => {
      var file = files[0];
      var base64 = await this._convertBase64(file);
      var Picture = {Picture: base64, Date: GetCurrentDateEuropeParis()}

      this.props.refParent.setState({Picture: Picture})
     }

    _convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  _getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
  }

  _getImage = () => {

    if (this.props.OldPhoto){
      return (<img alt='ambassador' style={{width: 50, height: 50, borderRadius: 360}} src={this.props.OldPhoto}/>)
    }
    else{

      return (<div style={{width: 50, height: 50, borderRadius: 360, backgroundColor: this._getRandomColor()}}></div>)
    }
  }

 render() {

   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row'}}>
             <div style={{marginRight: 15}}>
              {this._getImage()}
             </div>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{this.props.intl.formatMessage({ id: 'Photo' })}</h3>
              </div>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Picture ? 'block' : 'none', marginTop: 5}}>Veuillez upload une photo</p>

           </Col>
         </Row>
         <Row>
           <Col xl={12} lg={12} md={12} sm={12} style={{color: this.props.success ? 'orange' : '', textAlign: 'center'}}>
           {
             this.props.success ?
             <FileUploadReset
             />
             :
             <FileUpload
             label={this.props.intl.formatMessage({ id: 'AmbassadeurImage' })}
             updateFilesCb={this.updateUploadedFiles}
             />
           }

           </Col>
         </Row>
         <SeparateLine width={"50%"} height={1} color="greyTrans" />
     </>
   )
 }
}


export default Photo;
