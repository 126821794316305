import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AllGallery from './AllGallery'

class ParselAllGallery extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AllGallery} {...props}/>
   )
 }
}



export default ParselAllGallery;
