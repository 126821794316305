import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead'
import PureMember from './PureMember'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TableMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderMember = () => {
      return (
        this.props.memberDisplay.map((member, index) => (
        <PureMember refresh={this.props.refresh} refParent={this.props.refParent} deleting={this.props.deleting} member={member} key={member.Id} intl={this.props.intl} index={index}/>
      ))
      )
    }

 render() {
   return (
     <Table>
      <TableHead refParent={this.props.refParent}/>
      {
        this.props.memberDisplay.length === 0 && this.props.loadingMore === false  ?
        <TableBody>
          <TableRow>
            <TableCell  colSpan={"9"}>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3}}>
                    <p style={{color: 'black', fontSize: '1.2rem', fontWeight: 'bold'}}>Pas de résultats</p>
                  </Col>
                </Row>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
        :
        <TableBody>
          {this.renderMember()}
        </TableBody>
      }

    </Table>
   )
 }
}


export default TableMember;
