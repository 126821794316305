import React from 'react';
import Col from 'react-bootstrap/Col';


class Colleague extends React.Component {



  _renderSponsor = () => {
    if (!this.props.Sponsor || this.props.Sponsor.length === 0)
      return (<></>)
    else{
      try {
        return this.props.Sponsor.map((sponsor, index) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row', whidth: '100%', alignItems: 'center', margin: 10}} key={index}>
            <img alt={'sponsor ressource'} src={sponsor.Logo} style={{objectFit: 'contain', width: 90, height: 90}}/>
            <p style={{fontSize: '1em', fontWeight: 'bold', color: 'black', marginLeft: 25}}>{sponsor.Name}</p>
            <p><a style={{fontSize: '1em', marginLeft: 50, color: '#082430'}} href={sponsor.Link}>{sponsor.Link}</a></p>
            </div>
          )
        })
      } catch (e) {
        return (<></>)
      }
    }
  }



  render (){
    return (
      <Col sm={12} xl={12} lg={12} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
        <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS SUR LES SPONSORS</p>


          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexDirection: 'column', display: 'flex'}}>
              {this._renderSponsor()}
              </div>

            </div>
        </div>
      </Col>
    )
  }
}

export default Colleague;
