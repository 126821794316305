import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/nl';


const CheckFormatDate = (Date, Format) => {
  try {
    if (moment(Date, Format).format(Format) === Date)
      return (1);
    return (0);
  } catch (e) {
    return (0);
  }
}

const CheckIfDateIsAfter = (FirstDate, SecondDate, Format = "YYYY/MM/DD HH:mm:ss") => {
  try {
    if (moment(FirstDate, Format).isAfter(moment(SecondDate, Format)))
      return (0);
    return (1);
  } catch (e) {
    return (0)
  }
}

const FormatFullDate = (Date, OldFormat, NewFomart) => {
  return (moment(Date, OldFormat).format(NewFomart));
}

const FormatStatisticDate = (Date, Format) => {
  return (moment(Date, Format).locale('fr').format("MMMM YYYY"));
}

const GetCurrentDateEuropeParis = (Format = 'YYYY/MM/DD HH:mm:ss') => {
  return (moment().format(Format));
}

const DateDisplayEvent = (date, Format, Language) => {
  return (moment(date, Format).locale('fr').format("dddd - DD MMMM YYYY"))
}

const DateDisplayEventApp = (date, Language, Format) => {

  if (!Language)
    Language = "en-gb"
  var New_format = moment(date, Format).locale(Language).format('LL')
  return(New_format)

}

const TransformDateToUnixTime = (Date = "Not defined", Format) => {
  if (Date === "Not defined")
    return ;

  var UnixTime = moment(Date, Format).valueOf()
  return (UnixTime)

}

const FormatNumberMonth = (MonthNumber) => {
  return (moment(MonthNumber, 'M').locale('fr').format("MMMM"));
}

const AddTimeFormat = (n = 0, Format = "YYYY/MM/DD", CurrentDate, targetTime = "years") => {
  var Date = (moment(CurrentDate, Format).add(n, targetTime).format(Format))
  return (Date)
}


export {
CheckFormatDate,
TransformDateToUnixTime,
GetCurrentDateEuropeParis,
CheckIfDateIsAfter,
DateDisplayEvent,
DateDisplayEventApp,
FormatFullDate,
FormatStatisticDate,
FormatNumberMonth,
AddTimeFormat
}
