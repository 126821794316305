import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import Modify from './ModifyMember'
import HeaderGoBack from './HeaderGoBack'
import PopUp from './PopUp'
import LoadingInfo from './LoadingInfo'
import UpdateModal from './ModalUpdateProfil'

class AddNoneMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: false,
        modify: false,
        Error: null,
        updateProfil: false,
        StatusUpdate: null,
        Complement: {
          Region: null,
          Source: "Admin"
        },
        Private: {
          EmailPrivate: null,
          Tel: null
        },
        Society: {
          Name: null,
          Status: null
        },
        FirstName: null,
        LastName: null
      }
    }


    componentDidMount(){
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      var paramsNavigate = {pathname: `/all_none_member/`}
      this.props.navigation.history.push(paramsNavigate);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    _CheckPrivateInfo = (Error) => {
      try {
        var error = false;
        var ErrorPrivate = {}

        if (!this.state.FirstName || this.state.FirstName.length < 2)
        {
          error = true
          ErrorPrivate.FirstName = true
        }
        if (!this.state.LastName || this.state.LastName.length < 2)
        {
          error = true
          ErrorPrivate.LastName = true
        }
        if (!this.state.Private.EmailPrivate || this.state.Private.EmailPrivate.length === 0)
        {
          error = true
          ErrorPrivate.Email = true
        }
        if (!this.state.Private.Tel || this.state.Private.Tel.length === 0)
        {
          error = true
          ErrorPrivate.Tel = true
        }
        if (error === true)
          Error.Private = ErrorPrivate
      } catch (e) {
        ErrorPrivate.FirstName = true
        ErrorPrivate.LastName = true
        ErrorPrivate.Email = true
        Error.Private = ErrorPrivate
      }

    }


    _CheckSubscribeInfo = (Error) => {
      try {
        var error = false;
        var ErrorSubscribe = {}

        if (!this.state.Complement.Region || this.state.Complement.Region.length === 0)
        {
          error = true
          ErrorSubscribe.Region = true
        }
        if (!this.state.Complement.Source || this.state.Complement.Source.length === 0)
        {
          error = true
          ErrorSubscribe.Source = true
        }
        if (error === true)
          Error.Subscribe = ErrorSubscribe
      } catch (e) {
        ErrorSubscribe.Region = true
        Error.Subscribe = ErrorSubscribe
      }

    }


_fillParams = (ObjectName, target) => {
  if (!this.state[ObjectName] || !this.state[ObjectName][target])
    return null
  return (this.state[ObjectName][target])
}


  _addNoneMember = async () => {
    this.setState({Error: null}, async () => {
      var Error = {}

      this._CheckPrivateInfo(Error)
      this._CheckSubscribeInfo(Error)

      var KeysObjectError = Object.keys(Error).length;
      if (KeysObjectError !== 0)
      {
        this.setState({Error: Error})
        return ;
      }
      this.setState({updateProfil: true, StatusUpdate: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          Email: this._fillParams("Private", "EmailPrivate"),
          Region: this._fillParams("Complement", "Region"),
          Source: this._fillParams("Complement", "Source"),
          Society: this._fillParams("Society", "Name"),
          Status: this._fillParams("Society", "Status"),
          Tel: this._fillParams("Private", "Tel"),
        }
        var promise = await Requestdynamic('_addNoneMember', Params);
        if (promise.error === false && promise.data.error === false)
        {
          this.setState({StatusUpdate: "Success", message: "Le membre à bien été ajouté"})
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else{
            if (promise.data && promise.data.message.email === "E-mail déjà existant")
            {
              this.setState({StatusUpdate: "Error", message: "L'email mentionné existe déjà"})
              return ;
            }
            this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})

          }
          return ;
        }
      })
    })
  }

 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <UpdateModal refParent={this} openModal={this.state.updateProfil} statusRequest={this.state.StatusUpdate} message={this.state.message}/>
      <PopUp redirect={this.state.redirect} refParent={this} openModal={this.state.openModal} message={this.state.message} title={this.state.title} />
      <LoadingInfo openModal={this.state.loading} />
      <HeaderGoBack refParent={this}/>
      <Modify Error={this.state.Error} refParent={this} intl={this.props.intl}/>
    </div>
   )
 }
}


export default AddNoneMember;
