import React from 'react';

class Header extends React.Component {
  render (){
    return (
      <div style={{ paddingTop: '10px'}}>
          <h1>Promotion <span style={{color: "#FC8016"}}>Inscription</span> </h1>
          <p style={{color: '#bfbfbf'}}>Outils / <span style={{fontWeight: 'bold'}}>Promotion B19</span></p>
      </div>
    )
  }
}

export default Header;
