import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CssTextField} from './Style'
import TitleContact from './TitleContact'
import SeparateLine from './SeparateLine'

class Contact extends React.Component {
  constructor(props){
      super(props);
      this.state = {

        }
    }


 render() {

   return (
        <>
            <TitleContact />
            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
              <Col sm={12} md={12} xl={4} lg={4}>
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
                  <CssTextField value={this.props.FirstName ? this.props.FirstName : ""} label="Prénom" variant="outlined" onChange={(event) => this.props.refParent.setState({FirstName: event.target.value})}/>
                </div>
              </Col>
              <Col sm={12} md={12} xl={4} lg={4}>
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
                  <CssTextField value={this.props.LastName ? this.props.LastName : ""} label="Nom" variant="outlined" onChange={(event) => this.props.refParent.setState({LastName: event.target.value})}/>
                </div>
              </Col>
              <Col sm={12} md={12} xl={4} lg={4}>
                <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
                  <CssTextField value={this.props.Email ? this.props.Email : ""} label="Email" variant="outlined" onChange={(event) => this.props.refParent.setState({Email: event.target.value})}/>
                </div>
              </Col>
            </Row>
            <Row style={{marginTop: 10}}>
              <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{fontWeight: 'bold', color: 'black', fontSize: '1rem', fontStyle: 'italic'}}>Attention: l'e-mail ne pourra être modifier à l'avenir. Il servira d'identifiant unique</p>
              </Col>
            </Row>
            {
              this.props.Error && this.props.Error.Contact ?
              <Row style={{marginTop: 5}}>
                <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <p style={{fontSize: "0.9rem", color: "red", marginleft: 5}}>Veuillez renseigner tout les champs</p>
                </Col>
              </Row>
              :
              <>
              </>

            }
            <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

          </>
   )
 }
}


export default Contact;
