import React, { useState, useEffect, useContext } from 'react';
import {Button, Dropdown, DropdownButton, Row, Col, Alert } from 'react-bootstrap';
import Buttonne from '@material-ui/core/Button';
import { AiOutlineFieldNumber, AiOutlineFilePdf, AiOutlineSearch, AiOutlineExport } from "react-icons/ai";
import {Requestdynamic} from '../../Network/Fetch';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Cookies from 'universal-cookie'
import { BsDownload } from "react-icons/bs";
import { BiExport, BiRefresh } from "react-icons/bi";
import ReactExport from "react-export-excel";
import CircularProgress from '@material-ui/core/CircularProgress';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Badge from 'react-bootstrap/Badge';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Table from 'react-bootstrap/Table';
import Empty from '../../Image/empty.jpeg';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function NewMember() {

    const [member, setMember] = useState([])
    const [isSet, setIsSet] = useState(false)
    const [key, setKey] = useState("all")
    const [search, setSearch] = useState(null)
    const [searchText, setSearchText] = useState(null)
    const [tab30, setTab30] = useState([])
    const [tab15, setTab15] = useState([])
    const [tab0, setTab0] = useState([])
    const [tabError, setTabError] = useState([])

    const [loadingExport, setLoadingExport] = useState(false)
    const [loadingRefresh, setLoadingRefresh] = useState(false)
    const [loadingRefreshStatus, setLoadingRefreshStatus] = useState(false)
    const [loadingMember, setLoadingMember] = useState(true)


    const [loadingBilling, setLoadingBilling] = useState(false)

    const [error, setError]  = useState(true)

    const [loadingIndices, setLoadingIndices] = useState(new Set());
    const [loadingIndicesCreditNote, setLoadingIndicesCreditNote] = useState(new Set());

    const [show, setShow] = useState(false);
    const [showAlreadyPay, setShowAlreadyPay] = useState(false);
    const [showSuccesSendBiling, setShowSuccesSendBiling] = useState(false);
    const [showSuccesDelete, setShowSuccesDelete] = useState(false);
    const [showSuccesApprove, setShowSuccesApprove] = useState(false);

    const handleClose = () => setShow(false);
    const handleClosePay = () => setShowAlreadyPay(false);
    const handleCloseSuccesSendBilling = () => setShowSuccesSendBiling(false);
    const handleCloseSuccesDelete = () => setShowSuccesDelete(false);
    const handleCloseSuccesApprove = () => setShowSuccesApprove(false);


    const _renderErrorAlert = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#F8D7D9'}} show={show} onHide={handleClose} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#831F29'}}>Une erreur est survenue !</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#831F29'}}>
                Veuillez vérifier votre connexion internet ou réessayer plus tard.
                <br/>
                Si le problème persiste, veuillez contacter le service technique.
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _renderErrorAlreadyPay = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#F8D7D9'}} show={showAlreadyPay} onHide={handleClosePay} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#831F29'}}>Oups ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#831F29'}}>
                Il semblerait que ce membre a déja payé sa facture.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _renderSuccesSendBilling = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={showSuccesSendBiling} onHide={handleCloseSuccesSendBilling} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#0F5132'}}>
                La facture s'est bien envoyée.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _renderSuccesDelete = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={showSuccesDelete} onHide={handleCloseSuccesDelete} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#0F5132'}}>
                Ce membre à bien été supprimé.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _renderSuccesApprove = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={showSuccesApprove} onHide={handleCloseSuccesApprove} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#0F5132'}}>
                Ce membre à bien été accepté.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }


    useEffect(() => {
    if (!isSet)
        _getNewMember()
  }, [isSet])

  const _loadingExcel = () => {
      setLoadingExport(true)
      setTimeout(() => {
        setLoadingExport(false);
      }, "3000")
  }

    const _export = () => {
        return(
            <div style={{position: 'relative'}}>
            <ExcelFile element={
            <Button onClick={() => _loadingExcel()} variant='text' style={{color: 'white', fontWeight: 'bold', backgroundColor: '#082430', fontSize: '1rem'}}>
                <span style={{color: loadingExport === true ? "transparent" : "white"}}>EXPORTER</span>
                <span style={{marginLeft: '10px'}}>
                    <AiOutlineExport size={23} style={{color: loadingExport === true ? "transparent" : "white"}} />
                </span>
                <div style={{position: 'absolute', left: '0', right: '0', bottom: '0'}}>
                            {
                            loadingExport === true ?
                                <CircularProgress
                                size={25}
                                thickness={4}
                                color={"white"}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
            </Button>}>
            <ExcelSheet data={member}  name='Member'>
                <ExcelColumn label="Nom" value="LastName"/>
                <ExcelColumn label="Prénom" value="FirstName"/>
                <ExcelColumn label="Email" value="Email"/>
                <ExcelColumn label="Date Inscription" value="Subscription"/>
                <ExcelColumn label="Type d'inscription" value="RegistrationType"/>
                <ExcelColumn label="Région" value="Region"/>
            </ExcelSheet>
        </ExcelFile>
        </div>
        )

    }

    const _verifyPayment = async () => {
        setLoadingRefreshStatus(true)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
       }
        var promise = await Requestdynamic('_verifyPayment', Params);
        if (promise.error === false && promise.data.error === false && (promise.data.data.Success.length != 0 || promise.data.data.Error.length != 0)){
            setLoadingRefreshStatus(false)

        }
        _getNewMember()
        setLoadingRefreshStatus(false)
        console.log(promise)
    }

    const _createTab = (info) => {
        var temp = info.filter(item => item.FacturationDelcaSubscription?.error === true)
        setTabError(temp)
        temp = info.filter(item => item.FacturationSince === 30)
        console.log("30", temp)
        setTab30(temp)
        temp = info.filter(item => item.FacturationSince === 15)
        console.log("15", temp)

        setTab15(temp)
        temp = info.filter(item => item.FacturationSince === 0)
        console.log("0", temp)

        setTab0(temp)
    }

    const _search = (info) => {
        setSearchText(info.toLowerCase())
        if(info.length === 0)
            setSearch(null)
        var find = member.filter(item => item.UserInformation.Name.First.includes(info) || item.UserInformation.Name.Last.includes(info) || item.UserInformation.Email.Privates.includes(info))
        console.log("FIND", find)
        setSearch(find)
        setKey("all")
    }

    const _delete = async (info) => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: info
       }
        var promise = await Requestdynamic('_refuseNewMember', Params);
        console.log("promise", promise)
        if (promise.error === false && promise.data.error === false){
            setShowSuccesDelete(true)
            _getNewMember()
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }

    const _approve = async (info) => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: info
       }
        var promise = await Requestdynamic('_acceptNewMember', Params);
        if (promise.error === false && promise.data.error === false){
            setShowSuccesApprove(true)
            _getNewMember()
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }

  /*  const _sendBilling = async (info) => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: info
       }
        var promise = await Requestdynamic('_sendMailRemindNewMember', Params);
        console.log("promise", promise)
        if (promise.error === false && promise.data.error === false){
            if(promise.data.InvoiceIsPaid === true)
                setShowAlreadyPay(true)
            else{
                setShowSuccesSendBiling(true)
            }
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }*/

    const _checkMail = (info) => {
        try {
        if (info && info.FacturationDelcaSubscription && info.FacturationDelcaSubscription.Mail)
            return(info.FacturationDelcaSubscription.Mail[info.FacturationDelcaSubscription.Mail.length - 1].split(" ")[0] + " (" +(info.FacturationDelcaSubscription.Mail.length) + ")")
        }catch{
            return(" ")
        }
    }

    const _refreshNewMember = async () => {
      setLoadingRefresh(true)
      await _getNewMember()
      setLoadingRefresh(false)
    }

    const _getNewMember = async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Delca: true,
       }
        var promise = await Requestdynamic('_getNewMemberDelca', Params);
        if (promise.error === false && promise.data.error === false){
            setIsSet(true)
            _createTab(promise.data.data)
            setMember(promise.data.data)
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
        setLoadingMember(false)
    }

    const _createCreditNote = async (item, i) => {
        item.facture = true
        setLoadingIndicesCreditNote((prev) => new Set([...prev, i]));
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          IdFacturation: item.FacturationDelcaSubscription?.CreditNote?.Id,
          Id: item.Id,
          Jwt: Jwt,
          Target: "credit_notes"
       }
        var promise = await Requestdynamic('_getFacturationPdfDelca', Params);
        if (promise.error === false && promise.data.error === false){
            const linkSource = `data:application/pdf;base64,${promise.data.data}`;
            const link = document.createElement('a');
            link.href = linkSource;
            link.setAttribute('download', Params.IdFacturation + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
        setLoadingIndicesCreditNote((prev) => {
            const updated = new Set(prev);
            updated.delete(i);
            return updated;
          });

    }

    const _createBilling = async (item, i) => {
        item.facture = true
        setLoadingIndices((prev) => new Set([...prev, i]));
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          IdFacturation: item.FacturationDelcaSubscription.Id,
          Id: item.Id,
          Jwt: Jwt,
       }
        var promise = await Requestdynamic('_getFacturationPdfDelca', Params);
        if (promise.error === false && promise.data.error === false){
            const linkSource = `data:application/pdf;base64,${promise.data.data}`;
            const link = document.createElement('a');
            link.href = linkSource;
            link.setAttribute('download', Params.IdFacturation + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
        setLoadingIndices((prev) => {
            const updated = new Set(prev);
            updated.delete(i);
            return updated;
          });

    }

    const _setMaj = (string) => {
        try{
          const newString = string.split("-")
          for (var i = 0; i < newString.length; i++) {
            newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
          }
          const newStringJoin = newString.join("-")
          return (newStringJoin)
        }catch(e){
          return(" ")
        }
      }

    const GetColorFacturationIsPaid = (item) => {
      if (!item.FacturationDelcaSubscription || !item.FacturationDelcaSubscription.Id)
        return ('')
      if (item.FacturationDelcaSubscription.IsPaid)
        return ({color: 'white', backgroundColor: 'green', borderRadius: 2, boxShadow: "6px 4px 19px -3px #000000"})
      else if (item.FacturationDelcaSubscription.IsPaid === false)
        return ('')
      else
      return ({color: 'white', backgroundColor: 'orange', borderRadius: 2, boxShadow: "6px 4px 19px -3px #000000"})
    }

    const _renderAll = () => {

        if (member.length != 0){
            var display = [...member]
            if(search){
                display = [...search]
            }
          return(
            display.map((item, i) => {
                item.facture = false
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{_checkMail(item)} </td>
                    <td class="align-middle">{item.RegistrationType}</td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.Id ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createBilling(item, i)} size={25} style={
                          Object.assign({marginLeft: '15px', cursor: 'pointer', display: loadingIndices.has(i) ? 'none' : ''}, GetColorFacturationIsPaid(item))
                        }/>
                                <div style={{marginLeft: '15px', display: loadingIndices.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.CreditNote ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createCreditNote(item, i)} size={25} style={{marginLeft: '15px', cursor: 'pointer', display: loadingIndicesCreditNote.has(i) ? 'none' : ''}}/>
                                <div style={{marginLeft: '15px', display: loadingIndicesCreditNote.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/newMemberDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            ACCEPTER
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _delete(item.Id)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                            SUPPRIMER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _render30 = () => {
        if (tab30.length != 0){
          return(
            tab30.map((item, i) => {
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{_checkMail(item)} </td>
                    <td class="align-middle">{item.RegistrationType}</td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription.Id ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createBilling(item, i)} size={25} style={
                          Object.assign({marginLeft: '15px', cursor: 'pointer', display: loadingIndices.has(i) ? 'none' : ''}, GetColorFacturationIsPaid(item))
                        }/>
                                <div style={{marginLeft: '15px', display: loadingIndices.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/newMemberDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            ACCEPTER
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _delete(item.Id)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                            SUPPRIMER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _render15 = () => {
        if (tab15.length != 0){
          return(
            tab15.map((item, i) => {
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{_checkMail(item)} </td>
                    <td class="align-middle">{item.RegistrationType}</td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.Id ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createBilling(item, i)} size={25} style={
                          Object.assign({marginLeft: '15px', cursor: 'pointer', display: loadingIndices.has(i) ? 'none' : ''}, GetColorFacturationIsPaid(item))
                        }/>
                                <div style={{marginLeft: '15px', display: loadingIndices.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/newMemberDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            ACCEPTER
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _delete(item.Id)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                            SUPPRIMER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _render0 = () => {
        if (tab0.length != 0){
          return(
            tab0.map((item, i) => {
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{_checkMail(item)} </td>
                    <td class="align-middle">{item.RegistrationType}</td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.Id ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createBilling(item, i)} size={25} style={
                          Object.assign({marginLeft: '15px', cursor: 'pointer', display: loadingIndices.has(i) ? 'none' : ''}, GetColorFacturationIsPaid(item))
                        }/>
                                <div style={{marginLeft: '15px', display: loadingIndices.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.CreditNote ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createCreditNote(item, i)} size={25} style={{marginLeft: '15px', cursor: 'pointer', display: loadingIndicesCreditNote.has(i) ? 'none' : ''}}/>
                                <div style={{marginLeft: '15px', display: loadingIndicesCreditNote.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/newMemberDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            ACCEPTER
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _delete(item.Id)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                            SUPPRIMER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _renderError = () => {
        if (tabError.length != 0){
          return(
            tabError.map((item, i) => {
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{_checkMail(item)} </td>
                    <td class="align-middle">{item.RegistrationType}</td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.Id ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createBilling(item, i)} size={25} style={{marginLeft: '15px', cursor: 'pointer', display: loadingIndices.has(i) ? 'none' : ''}}/>
                                <div style={{marginLeft: '15px', display: loadingIndices.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td class="align-middle">
                        {
                        item.FacturationDelcaSubscription?.CreditNote ?
                        <>

                        <AiOutlineFilePdf onClick={() => _createCreditNote(item, i)} size={25} style={{marginLeft: '15px', cursor: 'pointer', display: loadingIndicesCreditNote.has(i) ? 'none' : ''}}/>
                                <div style={{marginLeft: '15px', display: loadingIndicesCreditNote.has(i) ? '' : 'none'}}>
                                <CircularProgress
                                    size={25}
                                    thickness={4}
                                    color={"red"}
                                    />
                                </div>
                              </>
                        :
                        <>
                        </>
                        }
                    </td>
                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/newMemberDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            ACCEPTER
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _delete(item.Id)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                            SUPPRIMER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _renderTitle = () => {
          return (
              <>
              <th>#</th>
            <th>NOM</th>
            <th>PRENOM</th>
            <th>EMAIL</th>
            <th>INSCRIPTION</th>
            <th>ENVOIE</th>
            <th>ABONNEMENT</th>
            <th>FACTURE</th>
            {
                key === 'all' || key === "render0" || key === "renderError" ?
                <th>NC</th>
                :
                <></>
            }
            <th>ACTION</th>
              </>
          )
      }

      const _textAll = () => {
        return(
          <p style={{color: 'black'}}>
          ALL <span style={{fontWeight: 'bold'}}>({member.length})</span>
        </p>
        )
    }

    const _text30 = () => {
        return(
          <p style={{color: 'black'}}>
          30 JOURS <span style={{fontWeight: 'bold'}}>({tab30.length})</span>
        </p>
        )
    }

    const _text15 = () => {
        return(
          <p style={{color: 'black'}}>
          15 JOURS <span style={{fontWeight: 'bold'}}>({tab15.length})</span>
        </p>
        )
    }

    const _text0 = () => {
        return(
          <p style={{color: 'black'}}>
          0 JOURS <span style={{fontWeight: 'bold'}}>({tab0.length})</span>
        </p>
        )
    }

      const _textError = () => {
          return(
            <p style={{color: 'black'}}>
            ERREUR <span style={{color: 'red', fontWeight: 'bold'}}>({tabError.length})</span>
          </p>
          )
      }


  return (
    <div  style={{ overflowX: 'scroll'}}>

    {
        error === true ?
        <>
        {_renderErrorAlert()}

        </>
        :
        <>
        </>
  }

  {
      showAlreadyPay === true ?
      <>
      {_renderErrorAlreadyPay()}
      </>
      :
      <>
      </>
  }

  {
      showSuccesSendBiling === true ?
      <>
      {_renderSuccesSendBilling()}
      </>
      :
      <>
      </>
  }

  {
      showSuccesDelete === true ?
      <>
      {_renderSuccesDelete()}
      </>
      :
      <>
      </>
  }

  {
      showSuccesApprove === true ?
      <>
      {_renderSuccesApprove()}
      </>
      :
      <>
      </>
  }

   <div style={{ paddingTop: '10px'}}>
       <h1>Nouveaux <span style={{color: "#FC8016"}}>Membres</span> </h1>
       <p style={{color: '#bfbfbf'}}>Delca / <span style={{fontWeight: 'bold'}}>Nouveaux Membres</span></p>
   </div>

    <Form style={{marginTop: '25px', marginBottom: '25px'}}>
        <Row>
            <Col style={{}} xl={4} lg={4} sm={12} md={12}>
            <Form.Group  md="4">
                <InputGroup hasValidation>
                <InputGroup.Text><AiOutlineSearch/></InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder="Rechercher"
                    value={searchText}
                    onChange={(event) => _search(event.target.value)}
                />
                </InputGroup>
            </Form.Group>
            </Col>
            <Col xl={8} lg={8} sm={12} md={12}>
            <div style={{justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex'}}>
                <div style={{paddingRight: 5, position: 'relative'}}>
                <Button variant='text' style={{backgroundColor: '#FC8016', color: 'white'}} onClick={() => _refreshNewMember()}>
                    <span style={{fontWeight: 'bold', color: loadingRefresh === true ? "transparent" : "white" }}>
                        ACTUALISER
                    </span>
                    <div style={{position: 'absolute', left: '0', right: '0', bottom: '0'}}>
                        {
                        loadingRefresh === true ?
                            <CircularProgress
                            size={25}
                            thickness={4}
                            color={"red"}
                            />
                            :
                            <>
                            </>
                        }
                    </div>
                    <BiRefresh size= {'24'} style={{marginLeft: '10px', color: loadingRefresh === true ? "transparent" : "white" }} />

                </Button>
                </div>
                <div style={{paddingRight: 5, position: 'relative'}}>
                    <Button variant='text' style={{backgroundColor: '#FC8016', color: 'white'}} onClick={() => _verifyPayment()}>
                        <span style={{fontWeight: 'bold', color: loadingRefreshStatus === true ? "transparent" : "white" }}>
                            VERIFIER STATUS
                        </span>
                        <div style={{position: 'absolute', left: '0', right: '0', bottom: '0'}}>
                            {
                            loadingRefreshStatus === true ?
                                <CircularProgress
                                size={25}
                                thickness={4}
                                color={"red"}
                                />
                                :
                                <>
                                </>
                            }
                        </div>
                        <BiRefresh size= {'24'} style={{marginLeft: '10px', color: loadingRefreshStatus === true ? "transparent" : "white" }} />

                    </Button>
                </div>
            {_export()}
            </div>
            </Col>
        </Row>
    </Form>

    <Tabs
      defaultActiveKey="all"
      activeKey={key}
      id="fill-tab-example"
      className="mb-3"
      fill
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="all" title={_textAll()}>
        <Table striped hover>
            <thead>
                <tr>
                {_renderTitle()}
                </tr>
            </thead>
            <tbody>
                {_renderAll()}
            </tbody>
        </Table>
        </Tab>
        <Tab eventKey="render30" title={_text30()}>
            <Table striped hover>
                <thead>
                    <tr>
                    {_renderTitle()}
                    </tr>
                </thead>
                <tbody>
                    {_render30()}
                </tbody>
            </Table>
        </Tab>
        <Tab eventKey="render15" title={_text15()}>
        <Table striped hover>
            <thead>
                <tr>
                {_renderTitle()}
                </tr>
            </thead>
            <tbody>
                {_render15()}
            </tbody>
        </Table>
        </Tab>
        <Tab eventKey="render0" title={_text0()}>
            <Table striped hover>
                <thead>
                    <tr>
                    {_renderTitle()}
                    </tr>
                </thead>
                <tbody>
                    {_render0()}
                </tbody>
            </Table>
        </Tab>
        <Tab eventKey="renderError" title={_textError()}>
            <Table striped hover>
                <thead>
                    <tr>
                    {_renderTitle()}
                    </tr>
                </thead>
                <tbody>
                    {_renderError()}
                </tbody>
            </Table>
        </Tab>
    </Tabs>
    {
      loadingMember ?
      <div style={{width: '100%', textAlign: 'center', margin: 25, color: "orange"}}>
        <h3 style={{color: "black"}}>Récupération des données chez Delca</h3>
        <CircularProgress
        size={40}
        thickness={6}
        color={"inherit"}
        />
      </div>
      :
      <></>
    }

    </div>
  )
}
