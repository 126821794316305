import React from 'react';
import Col from 'react-bootstrap/Col';
import {ButtonCssChecked} from './Style'


class Filter extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        checked: false
      }
    }

    _Click = () => {
      this.setState({checked: !this.state.checked}, () => {
        this.props.refParent._ConstructFilterArray(this.props.region)
      })
    }

  render (){
    return (
      <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column', padding: 5, margin: 5}}>
        <ButtonCssChecked onClick={(event) => this._Click(event)} checked={this.state.checked} style={{fontSize: '0.75rem', width: "100%", display: 'flex', borderRadius: 5, padding: 5, color: 'white', backgroundColor: this.state.checked ? "#0069d9" : "#082430"}}>
          {this.props.intl.formatMessage({id: this.props.region}).toUpperCase()}
        </ButtonCssChecked>
      </Col>
    )
  }
}

export default Filter;
