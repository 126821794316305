import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import PopUpDelete from './PopUpDelete'
import Cookies from 'universal-cookie'
import Header from './Header'


class AllAmbassador extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        ambassador: [],
        ambassadorDisplay: [],
        searchAmbassador: [],
        search: null,
        Filter: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        openModalDelete: false,
        deleting: null,
        AmbassadorDelete: null,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: 'up',
          Society: "up",
          AccountCreatedAt: "up",
          SubscriptionBegin: "up",
          SubscriptionEnd: "up",
          Cotisation: "up",
          RegistrationType: "up",
          Region: "up",
        }
      }
    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.location];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
          a[Name] = ""
        if (!b[Name])
          b[Name] = ""

        var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
         if (nameA < nameB) //sort string ascending
          return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({location: SortedResult, sortFilter: sortOrder})
    }

    _getAllNextAmbassador = async () => {

      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            Size: "Medium",
          },
        }
        if (this.state.Filter.length !== 0)
          Params.Body.Region = this.state.Filter
        var promise = await Requestdynamic('_getAllAmbassador', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var AmbassadorCopy = [...this.state.ambassador];
          var newListAmbassador = AmbassadorCopy.concat(promise.data.data)
          newListAmbassador = [...new Set(newListAmbassador)]
          AmbassadorCopy = newListAmbassador.slice(0, 20)
          var paramsSetState = {ambassadorDisplay: AmbassadorCopy, ambassador: newListAmbassador}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
        }
        this.setState({loadingMore: false})
      })

    }

    _addAllNextMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy = []
        if (this.state.search != null)
           Copy = [...this.state.searchAmbassador]
        else
           Copy = [...this.state.ambassador]

        var CopyDisplay = [...this.state.ambassadorDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, ambassadorDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
      this._getAllNextAmbassador()
    }

    search = (value) => {
      var Copy = []
      var Display = []
      if (value.length === 0)
      {
        Copy = [...this.state.ambassador];
        Display = Copy.slice(0, 20)
        this.setState({search: null, ambassadorDisplay: Display, searchAmbassador: []})
      }
      Copy = [...this.state.ambassador]
      var FilterResult = Copy.filter(res => res.LastName.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()))
      Display = FilterResult.slice(0, 20)
      this.setState({search: value, ambassadorDisplay: Display, searchAmbassador: FilterResult})
    }

    handleScroll = (e) => {
      var verify = ''
      if (this.state.search === null)
        verify = 'ambassador'
      else
        verify = 'searchAmbassador'

      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom && this.state.ambassadorDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
        this._getAllNextAmbassador()
      }
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    _deleteAmbassador = () => {
      this.setState({deleting: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
            IdAmbassador: this.state.AmbassadorDelete.Id,
        }
        var promise = await Requestdynamic('_deleteAmbassador', Params)
        if (promise.error === false && promise.data.error === false){
          this.setState({deleting: "Success"}, async () => {
            var Copy = [...this.state.ambassador]
            var IndexCopy = Copy.findIndex(res => res.Id === this.state.AmbassadorDelete.Id)
            Copy.splice(IndexCopy, 1)
            var CopyDisplay = [...this.state.ambassadorDisplay]
            var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.AmbassadorDelete.Id)
            CopyDisplay.splice(IndexCopyDisplay, 1)
            await this.sleep(500)
            this.setState({deleting: null, ambassador: Copy, ambassadorDisplay: CopyDisplay, AmbassadorDelete: null, openModalDelete: false})
          })
          return ;
        }
        else {
          if (promise.error === true)
           this.setState({deleting: "Error"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({deleting: "Error"})
        }
      })

    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <PopUpDelete AmbassadorDelete={this.state.AmbassadorDelete} deleting={this.state.deleting} openModalDelete={this.state.openModalDelete} refParent={this}/>
     <Header Filter={this.state.Filter} intl={this.props.intl} refParent={this}/>
     <Table intl={this.props.intl} loadingMore={this.state.loadingMore} refParent={this} deleting={this.state.deleting} ambassador={this.state.ambassador} ambassadorDisplay={this.state.ambassadorDisplay}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllAmbassador;
