import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CssTextField} from './Style'

class ActionHeader extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _ChangeDisplayMember = (event) => {
      this.props.refParent.setState({TypeMember: event.target.value, member: [], memberDisplay: [], search: null, searchResult: [], loadingMore: true}, async () => {
        this.props.refParent._getAllNotParticipant(event.target.value)
      })
    }

 render() {

   return (
     <>
     <Row style={{}}>
       <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
          <div style={{borderBottom: '2px solid orange'}}>
           <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Type Membre</h3>
          </div>
       </Col>
     </Row>
     <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
       <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <div style={{display: 'flex', width: "60%", justifyContent: 'center'}}>
           <CssTextField onChange={(event) => this._ChangeDisplayMember(event)} value={this.props.TypeMember === null ? "PlaceHolder" : this.props.TypeMember} select label="Type Membre" style={{width: '100%'}} variant="outlined" SelectProps={{native: true}}>
              <option key={10} value={"PlaceHolder"} disabled>
                {"Veuillez choisir le type de membre"}
              </option>
               <option key={11} value={"NonMember"}>
                 {"Non membre"}
               </option>
               <option key={12} value={"Member"}>
                 {"Membre"}
               </option>
             </CssTextField>
         </div>
       </Col>
     </Row>
     </>
   )
 }
}


export default ActionHeader;
