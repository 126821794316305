import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ButtonTestCss} from "../../ButtonStyle";


class ActionHeader extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

 render() {

   return (
     <Row style={{marginTop: 30, display: "flex", justifyContent: 'flex-start'}}>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss disabled={this.props.action} onClick={() => this.props.refParent.setState({addFiles: true})} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
           Ajouter des photos
         </ButtonTestCss>
       </Col>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss disabled={this.props.action} onClick={() => this.props.refParent.RedirectModify()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
           Modifier les informations
         </ButtonTestCss>

       </Col>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss disabled={this.props.action} onClick={() => this.props.refParent.DeleteGallery()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
           Supprimer l'album
         </ButtonTestCss>
       </Col>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss disabled={this.props.loading || this.props.refParent.state.images.length === 0 || (this.props.action && !this.props.edit)} onClick={() => this.props.refParent.Edit()} size={"0.8rem"} color={this.props.edit ? 'rgb(8, 36, 48)' : 'orange'} radius={"10px"} width={"100%"}>
           {this.props.edit ? "Sauver les modifications" : "Modifier les photos"}
         </ButtonTestCss>
       </Col>
     </Row>

   )
 }
}


export default ActionHeader;
