import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import PopUpDelete from './PopUpDelete'
import Cookies from 'universal-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class AllSponsor extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        sponsor: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        openModalDelete: false,
        deleting: null,
        SponsorDelete: null,
        sortFilter: {
          Name: "up",
        }
      }
    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.sponsor];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
          a[Name] = ""
        if (!b[Name])
          b[Name] = ""

        var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
         if (nameA < nameB) //sort string ascending
          return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({sponsor: SortedResult, sortFilter: sortOrder})
    }

    _getAllNextSponsor = async (LastKey) => {

      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            Size: "Small",
          },
        }
        var promise = await Requestdynamic('_getAllSponsor', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var SponsorCopy = [...this.state.sponsor];
          var newListSponsor = SponsorCopy.concat(promise.data.data)
          newListSponsor = [...new Set(newListSponsor)]
          var paramsSetState = {sponsor: newListSponsor}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
        }
        this.setState({loadingMore: false})
      })

    }

    componentDidMount(){
      this._getAllNextSponsor()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    _deleteSponsor = () => {
      this.setState({deleting: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
            IdSponsor: this.state.SponsorDelete.Id,
        }
        await this.sleep(2000)
        var promise = await Requestdynamic('_deleteSponsor', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({deleting: 'Success'}, async () => {
            await this.sleep(1500)
            var Copy = [...this.state.sponsor];
            var index = Copy.findIndex(res => res.Id === this.state.SponsorDelete.Id)
            if (index === -1){
              this.setState({deleting: null, SponsorDelete: null, openModalDelete: null})
              return ;
            }
            Copy.splice(index, 1)
            this.setState({sponsor: Copy})
            await this.sleep(650)
            this.setState({deleting: null, SponsorDelete: null, openModalDelete: null})
          })
        }
        else {
            if (promise.error === true)
            this.setState({deleting: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({deleting: "Error"})
        }
      })

    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <PopUpDelete SponsorDelete={this.state.SponsorDelete} deleting={this.state.deleting} openModalDelete={this.state.openModalDelete} refParent={this}/>
     <Container fluid>
       <Row>
         <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
           <h2 style={{textAlign: 'left', marginBottom: 25}}>Sponsor</h2>
         </Col>
       </Row>
     </Container>
     <Table intl={this.props.intl} refParent={this} deleting={this.state.deleting} loadingMore={this.state.loading} sponsor={this.state.sponsor}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllSponsor;
