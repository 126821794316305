import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import ModalDeleteEvent from './ModalDeleteEvent'
import ProfilMember from './ProfilMember'

class SpecificMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        IdEvent: null,
        modify: false,
        blocked: false,
        statusBlocked: null,
        statusDelete: null,
        delete: false,
        accept_refuse: false,
        statusAccept_Refuse: null,
        actionAccept_Refuse: null,
      }
    }

    _DeleteEvent = async () => {
      if (this.state.delete === true)
      {
        this.setState({statusDelete: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, IdEvent: this.state.IdEvent}
          var promise = await Requestdynamic('_deleteEvent', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusDelete: "Success"}, async () => {
              await this.sleep(500)
              this.goBack()
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusDelete: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusDelete: "Error"})
          }
        })
      }
      else {
        this.setState({delete: true, statusDelete: "Ask"})
        return ;
      }

    }

    GetSpecificEvent = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        SizeImage: 'Medium',
        IdEvent: this.state.IdEvent
      }
      var promise = await Requestdynamic('_getSpecificEvent', Params);
      if (promise.error === false && promise.data.error === false)
      {
        var Data = promise.data.data
        try {
          var Keys = Object.keys(Data);
          var paramsSetState = {loading: false}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = Data[Keys[i]]
          }
          this.setState(paramsSetState)
        } catch (e) {
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
        }
      }
      else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.eventid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              IdEvent: this.props.navigation.match.params.eventid,
            }, async () => this.GetSpecificEvent())
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage){
        this.props.navigation.history.push(this.props.navigation.location.state.oldPage);
        return ;
      }
      this.props.navigation.history.push(`/all_event/`);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }


    _ModifyActionClick = () => {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      let found = false;
      var index = 0;
      for (var i = 0; i < this.state.Region.length; i++){
        let region = this.state.Region && this.state.Region[i] ? this.state.Region[i] : ''
        index = Info.Region.find(res => res === region)
        if (index !== -1){
          found = true;
          break ;
        }
    }

    var paramsNavigate = {pathname: `/event_edit/${this.state.IdEvent}`, state: {Acces: found}}
    if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
      paramsNavigate.state.oldPage = this.props.navigation.location.state.oldPage
    this.props.navigation.history.push(paramsNavigate);
    }

    _GoParticipantClick = () => {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      let found = false;
      var index = 0;
      for (var i = 0; i < this.state.Region.length; i++){
        let region = this.state.Region && this.state.Region[i] ? this.state.Region[i] : ''

        index = Info.Region.find(res => res === region)
        if (index !== -1){
          found = true;
          break ;
        }
    }
      var paramsNavigate = {pathname: `/subscribe_member_event/${this.state.IdEvent}`, state: {Acces: found, Name: this.state.Name, Date: this.state.EventDate.Date, isFree: this.state.Free.isFree, Compta: this.state.Compta}}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state.oldPage = this.props.navigation.location.state.oldPage
      this.props.navigation.history.push(paramsNavigate);
    }

 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <ModalDeleteEvent refParent={this} openModal={this.state.delete} statusDelete={this.state.statusDelete}/>

      <ProfilMember refParent={this} intl={this.props.intl} />
    </div>
   )
 }
}


export default SpecificMember;
