import React from 'react';
import ImageProfil from '../../Image/placeholder_man.png'
class ProfilImage extends React.Component {


  render (){
    return (
      <div style={{display: 'flex', minWidth: '50%', justifyContent: 'center', alignItems: 'center'}}>

          <img  alt={"Profil"} src={ImageProfil} style={{width: '10.5em', borderRadius: 360, height: '10.5em'}}/>

      </div>
    )
  }
}

export default ProfilImage;
