import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Logo from './Logo'
import Header from './Header'
import PopUp from './PopUp'
import Info from './Info'
import {ButtonTestCss} from "../../ButtonStyle";
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'
import RegionB19 from './RegionB19'

class AddAmbassadord extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        success: false,
        title: null,
        message: null,
        openModal: false,
        updateSponsor: false,
        Name: null,
        Link: null,
        Category: "PlaceHolder",
        Error: null,
        Logo: null,
        OldLogo: null,
        IdSponsor: null,
        RegionB19: [],
      }

    }

    _VerifySource = async () => {
      this.setState({verifyArgument: true}, async () => {
            if (!this.props.navigation.location || !this.props.navigation.location.state || !this.props.navigation.match.params.sponsorid){
              this.setState({verifyArgument: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              verifyArgument: false,
              OldLogo: this.props.navigation.location.state.Logo,
              Name: this.props.navigation.location.state.Name,
              Category: this.props.navigation.location.state.Category,
              Link: this.props.navigation.location.state.Link,
              IdSponsor: this.props.navigation.match.params.sponsorid,
              RegionB19: this.props.navigation.location.state.RegionB19 ? this.props.navigation.location.state.RegionB19 : [],
            })
        })
    }


    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);

    }

    goBack(){
        this.props.navigation.history.push(`/all_sponsor/`);
    }

    _CheckName = (Error) => {
      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = true

    }

    _CheckLink = (Error) => {
      if (!this.state.Link || this.state.Link.length === 0)
        Error.Link = true

    }

    _CheckCategory = (Error) => {
      if (this.state.Category  === "PlaceHolder")
        Error.Category = true
    }

    _CheckRegionB19 = (Error) => {
      if (!this.state.RegionB19 || this.state.RegionB19.length === 0)
        Error.RegionB19 = true
    }


    _updateSponsor = async () => {
    this.setState({updateSponsor: true, Error: null}, async() => {
    var Error = {};

    this._CheckName(Error)
    this._CheckCategory(Error)
    this._CheckLink(Error)
    this._CheckRegionB19(Error)

    var Logo = this.state.Logo

    var KeysErrorLength = Object.keys(Error).length
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, updateSponsor: false})
      return ;
    }
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var Params = {

      Name: this.state.Name,
      Link: this.state.Link,
      RegionSponsor: this.state.RegionB19,
      Category: this.state.Category,
      IdSponsor: this.state.IdSponsor,
      Jwt: Jwt,
      Logo: Logo,
    }
    var promise = await Requestdynamic('_updateSponsor', Params)
    if(promise.error === false && promise.data.error === false)
    {
      this.setState({updateSponsor: false, openModal: true, title: 'Success', message: "Le partenaire à bien été mis à jour", redirect: true})
    }
    else
    {
      if (promise.error === true)
        this.setState({updateSponsor: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
      {
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else
        this.setState({updateSponsor: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      return ;
    }
  })

}

 render() {

   return (
     <>
      <div style={{marginLeft: 10, overflowY: "auto"}}>
       <Container fluid>
         <PopUp redirect={this.state.redirect} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
        <Header refParent={this} intl={this.props.intl}/>
        <Logo OldLogo={this.state.OldLogo} Error={this.state.Error} success={this.state.success} intl={this.props.intl} refParent={this}/>
        <Info
        Name={this.state.Name}
        Link={this.state.Link}
        Category={this.state.Category}
        refParent={this}
        Error={this.state.Error}/>
        <RegionB19 Error={this.state.Error} intl={this.props.intl} refParent={this} RegionB19={this.state.RegionB19}/>


        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ButtonTestCss disabled={this.state.addSponsor} color={"orange"} style={{margin: 10}} onClick={() => this._updateSponsor()}>
              {
                this.state.addSponsor ?
                <CircularProgress
                  size={"1.5rem"}
                  thickness={5}
                  color={"inherit"}
                />
                :
                <p style={{margin: 0, padding: 0}}>Sauvegarder</p>
              }
            </ButtonTestCss>

          </Col>
        </Row>

       </Container>
     </div>

     </>
   )
 }
}


export default AddAmbassadord;
