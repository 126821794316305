import React from 'react';
import Container from 'react-bootstrap/Container';

import Header from './Header'
import Loading from './Loading'
import PopUp from './PopUp'
import Cookies from 'universal-cookie'
import InfoMember from './InfoMember'
import HeaderGoBack from './HeaderGoBack'
import AlertUser from './Alert'

class ProfilEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _renderAlert = () => {
      var NumberAlert = []

      try {
        var cookies = new Cookies()
        var Info = cookies.get('infoAdmin')
        let found = false;
        var index = 0;
        for (var i = 0; i < this.props.refParent.state.Region.length; i++){
          let region = this.state.Region && this.state.Region[i] ? this.state.Region[i] : ''
          index = Info.Region.find(res => res === region)
          if (index !== -1){
            found = true;
            break ;
          }
      }
      if (found === false)
        NumberAlert.push({color: "white", message: "NotAuthorizedToUpdate", background: "#ff7878"})
      if (NumberAlert.length === 0)
        return (<></>)
      return NumberAlert.map((info, index) => {
        return <AlertUser intl={this.props.intl} key={index} background={info.background} color={info.color} message={info.message} />
      })
      } catch (e) {
        return <></>
      }
    }

 render() {
   return (
     <Container fluid>
      <PopUp redirect={this.props.refParent.state.redirect} refParent={this.props.refParent} openModal={this.props.refParent.state.openModal} message={this.props.refParent.state.message} title={this.props.refParent.state.title} />
      <HeaderGoBack refParent={this.props.refParent}/>
      {this._renderAlert()}
      <Header Image={this.props.refParent.state.Image} Location={this.props.refParent.state.Location} Name={this.props.refParent.state.Name} intl={this.props.intl} refParent={this.props.refParent}/>
      <InfoMember refParent={this.props.refParent} intl={this.props.intl}/>
      <Loading loadingMore={this.props.refParent.state.loading} size={50} />
     </Container>
   )
 }
}


export default ProfilEvent;
