import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SeparateLine from './SeparateLine'
import {CssTextField} from './Style'

class Name extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'column', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Adresse</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 3, display: 'flex', justifyContent: 'flex-start'}}>
          <Col xl={3} lg={3} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField value={this.props.City ? this.props.City : ''} label="Ville" variant="outlined" onChange={(event) => this.props.refParent.setState({City: event.target.value})}/>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.City ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer la ville</p>
            </div>
          </Col>
          <Col xl={2} lg={2} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField InputProps={{ inputProps: { min: 0 } }} type={'number'} value={this.props.PostalCode ? this.props.PostalCode : ''} label="Code postal" variant="outlined" onChange={(event) => this.props.refParent.setState({PostalCode: event.target.value})}/>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.PostalCode ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer le code postal</p>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField  value={this.props.Street ? this.props.Street : ''} label="Rue" variant="outlined" onChange={(event) => this.props.refParent.setState({Street: event.target.value})}/>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Street ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer la rue</p>
            </div>
          </Col>
          <Col xl={1} lg={1} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField InputProps={{ inputProps: { min: 0 } }} type={'number'} value={this.props.Number ? this.props.Number : ''} label="Numéro" variant="outlined" onChange={(event) => this.props.refParent.setState({Number: event.target.value})}/>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Number ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer le numéro</p>
            </div>
          </Col>
         </Row>
         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

     </>
   )
 }
}


export default Name;
