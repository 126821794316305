import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AnimationRotate, AnimationDownUp} from './Style'
import { IoIosArrowForward } from "react-icons/io";
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import SeparateLine from './SeparateLine'
import TableSponsor from './TableSponsor'

function combineStyles(...styles) {
  return function CombineStyles(theme) {
    const outStyles = styles.map((arg) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === 'function') {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}


class LimitPlace extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        rotate: false
      }
    }


 render() {
   const { classes } = this.props
   return (
     <>
        <SeparateLine color={"greyTrans"} width={"100%"} height={1} marginTop={10}/>
         <Row style={{}} onClick={() => this.setState({rotate: !this.state.rotate})}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Sponsor</h3>
              </div>
              <div  style={{marginRight: 10}}>
                <Button onClick={() => this.setState({rotate: !this.state.rotate})} variant='text' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                  <IoIosArrowForward className={this.state.rotate ? classes.spin : classes.refresh} color={'black'} size={20} />
                </Button>
              </div>
           </Col>
         </Row>


           <Row   className={this.state.rotate ? classes.show : classes.hidden}>
              {
                this.state.rotate ?
                <TableSponsor refParent={this.props.refParent} sponsor={this.props.Sponsor} SponsorSelect={this.props.SponsorSelect}/>
                :
                <>
                </>
              }


           </Row>






     </>
   )
 }
}

const combinedStyles = combineStyles(AnimationRotate, AnimationDownUp);

export default withStyles(combinedStyles)(LimitPlace);
