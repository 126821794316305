import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap'

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutComponent = ({info}) => {

  return (

    <Row style={{height: '325px'}}>
      <Doughnut data={info}
      height='100%'
      width='100%'
      options={{ maintainAspectRatio: false }}
      />
    </Row>
  )
}

export default (DoughnutComponent)
