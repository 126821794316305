import React, { useState } from 'react';

import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import {GetCurrentDateEuropeParis} from '../../Moment/Moment'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import Cookies from 'universal-cookie'


import UpdateImage from '../../FileUpload/FileUpload'


const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Modify(props) {
  const [validated, setValidated] = useState(false);

const handleSubmit = (event) => {
  event.preventDefault();
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.stopPropagation();
  }

  setValidated(true);
  props.refParent._updateMember()
};

const RegionB19 = () => {
  try {
    var cookies = new Cookies();
    var InfoAdmin = cookies.get('infoAdmin');
    if (!InfoAdmin || InfoAdmin === 'undefined' || !InfoAdmin.Region)
      return ;
    return (InfoAdmin.Region.map((region, index) => {
      return (
      <option key={index} value={region}>
         {props.intl.formatMessage({id: region})}
      </option>)
    }))
  } catch (e) {
    return ;
  }

}

const setObject = (ObjectName, target ,value) => {
  var CopyObject = Object.assign({}, props.refParent.state[ObjectName]);
  CopyObject[target] = value
  var setParams = {}
  setParams[ObjectName] = CopyObject
  props.refParent.setState(setParams)
}

const _convertBase64 = (file) => {
return new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);

  fileReader.onload = () => {
    resolve(fileReader.result);
  };

  fileReader.onerror = (error) => {
    reject(error);
  };
});
};

const setDate = (ObjectName, target, index ,value) => {
  try {
    var DateSplit;
    var Date = props.refParent.state[ObjectName][target]
    if (!Date || Date === "null/null/null"){
       DateSplit = new Array(3)
      for (var i = 0; i < 3; i++){
        DateSplit[i] = ""
      }
    }
    else {
      Date = Date.split(' ')[0]
      DateSplit = Date.split('/');
    }
    DateSplit[index] = value;
    var j = 0
    for (var i = 0; i < DateSplit.length; i++){
      if (DateSplit[i].length === 0)
        j++
    }
    if (j === 3)
    {
      setObject(ObjectName, target, null)
      return ;
    }
    var DateFormed  = DateSplit.map(res => res).join('/');
    setObject(ObjectName, target, DateFormed)
  } catch (e) {
    return ;
  }
}

const splitDate = (Date, index) => {
  try {
    Date = Date.split(' ')[0]
    if (Date === "null/null/null")
      return ('')
    return Date.split('/')[index]
  } catch (e) {
    return ('')
  }
}

const renderInfoSubscribe = () => {
  if (props.refParent.state.Complement && (props.refParent.state.Complement.RegistrationType === "Business" || props.refParent.state.Complement.RegistrationType === "Couple") && props.refParent.state.Complement.BusinessInfo !== true)
    return (<></>)
  else {
    return  (
      <>
      <Row>
        <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations d'inscription</h6>
        </Col>
      </Row>
      <Row>
      <Form.Group as={Col} controlId="B19Clubs">
        <p style={{textAlign: 'left', marginTop: 10}}>Clubs B19*</p>
         <Form.Control
         onChange={(e) => setObject("Complement", "Region", e.target.value)}
         value={props.refParent.state.Complement && props.refParent.state.Complement.Region ? props.refParent.state.Complement.Region :  ""} required as="select">
           <option hidden value="">
             Veuillez indiquer votre région parmis nos clubs
           </option>
           {RegionB19()}
     </Form.Control>
     <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
        {"Veuillez sélectionner un club"}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
        {props.Error && props.Error.Subscribe && props.Error.Subscribe.Region ? "Veuillez sélectionner un club" : ""}
      </Form.Control.Feedback>
   </Form.Group>

      </Row>
      {
        props.refParent.state.hasOwnProperty('Accept') && props.refParent.state.Accept === true ?
        <Row>
        <Form.Group as={Col} controlId="formGridDay">
          <p style={{textAlign: 'left', marginTop: 10}}>Date de fin d'adhésion*</p>
          <Form.Control
          required
          type="text"
          placeholder="jour"
          value={props.refParent.state.Complement && props.refParent.state.Complement.SubscriptionEnd ? splitDate(props.refParent.state.Complement.SubscriptionEnd, 0) :  ""}
          onChange={(e) => setDate("Complement", "SubscriptionEnd", 0, e.target.value)}
          />
         <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
            {"Veuillez saisir une date valide"}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {props.Error && props.Error.Subscribe && props.Error.Subscribe.SubscriptionEnd ? "Veuillez saisir une date valide" : ""}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridMounth">
          <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
          <Form.Control
          required
          type="text"
          placeholder="mois"
          value={props.refParent.state.Complement && props.refParent.state.Complement.SubscriptionEnd ? splitDate(props.refParent.state.Complement.SubscriptionEnd, 1) :  ""}
          onChange={(e) => setDate("Complement", "SubscriptionEnd", 1, e.target.value)}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridYear">
          <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
          <Form.Control
          required
          type="text"
          placeholder="année"
          value={props.refParent.state.Complement && props.refParent.state.Complement.SubscriptionEnd ? splitDate(props.refParent.state.Complement.SubscriptionEnd, 2) :  ""}
          onChange={(e) => setDate("Complement", "SubscriptionEnd", 2, e.target.value)}
          />
        </Form.Group>
        </Row>
        :
        <>
        </>
      }

      </>
    )
  }
}

const updateUploadedFiles = async (files) => {
 var file = files[0];
 var base64 = await _convertBase64(file);
 props.refParent.setState({Picture: {Image: base64,  Date: GetCurrentDateEuropeParis()}})
}


  return (
    <div>
      <div style={{backgroundColor: "#F6F7FB"}}>
      <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
      <h2 style={{textAlign: 'left'}}>Modifier le profil</h2>

      <UpdateImage
        updateFilesCb={updateUploadedFiles}/>

      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
      <Row>
        <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h6 style={{textAlign: 'left', color:'#FC8016'}}>Informations Personnelles</h6>

        </Col>
      </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridlastName">
            <p style={{textAlign: 'left', marginTop: 10}}>Nom</p>
            <Form.Control
            required
            type="text"
            value={props.refParent.state.LastName ? props.refParent.state.LastName : ""}
            onChange={(e) => props.refParent.setState({LastName: e.target.value})}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {"Veuillez rentrer un nom avec au moin deux character"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.LastName ? "Veuillez rentrer un nom avec au moin deux character" : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridfirstName">
            <p style={{textAlign: 'left', marginTop: 10}}>Prénom</p>
            <Form.Control
            required
            type="text"
            value={props.refParent.state.FirstName ? props.refParent.state.FirstName : ""}
            onChange={(e) => props.refParent.setState({FirstName: e.target.value})}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer un prénom avec au moin deux character"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.FirstName ? "Veuillez rentrer un prénom avec au moin deux character" : ""}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridTel">
          <p style={{textAlign: 'left', marginTop: 10}}>Téléphone</p>
          <Form.Control
          type="text"
          value={props.refParent.state.Private && props.refParent.state.Private.TelPrivate ? props.refParent.state.Private.TelPrivate : ""}
          onChange={(e) => setObject("Private", "TelPrivate", e.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {"Veuillez rentrer un numéro de téléphone"}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {props.Error && props.Error.Private && props.Error.Private.Tel ? "Veuillez rentrer un numéro de téléphone" : ""}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridZip">
            <p style={{textAlign: 'left', marginTop: 10}}>Code postal</p>
            <Form.Control
            required
            type="number"
            value={props.refParent.state.Private && props.refParent.state.Private.ZipCodePrivate ? props.refParent.state.Private.ZipCodePrivate : ""}
            onChange={(e) => setObject("Private", "ZipCodePrivate", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer un code postale"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.ZipCode ? "Veuillez rentrer un code postale" : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <p style={{textAlign: 'left', marginTop: 10}}>Ville</p>
            <Form.Control
            required
            type="text"
            value={props.refParent.state.Private && props.refParent.state.Private.CityPrivate ? props.refParent.state.Private.CityPrivate : ""}
            onChange={(e) => setObject("Private", "CityPrivate", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer une ville"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.City ? "Veuillez rentrer une ville" : ""}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridAddress">
          <p style={{textAlign: 'left', marginTop: 10}}>Adresse postale</p>
          <Form.Control
          required
          type="text"
          value={props.refParent.state.Private && props.refParent.state.Private.StreetPrivate ? props.refParent.state.Private.StreetPrivate : ""}
          onChange={(e) => setObject("Private", "StreetPrivate", e.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {"Veuillez rentrer une adresse"}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {props.Error && props.Error.Private && props.Error.Private.Street ? "Veuillez rentrer une adresse" : ""}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Form.Group as={Col} controlId="formGridDay">
            <p style={{textAlign: 'left', marginTop: 10}}>Date de naissance*</p>
            <Form.Control
            required
            type="text"
            placeholder="jour"
            value={props.refParent.state.Private && props.refParent.state.Private.Date ? splitDate(props.refParent.state.Private.Date, 0) : ""}
            onChange={(e) => setDate("Private", "Date", 0, e.target.value)}
            />
           <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {"Veuillez rentrer une date valide"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.Date ? "Veuillez rentrer une date valide" : ""}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridMounth">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
            <Form.Control
            required
            type="text"
            placeholder="mois"
            value={props.refParent.state.Private && props.refParent.state.Private.Date ? splitDate(props.refParent.state.Private.Date, 1) : ""}
            onChange={(e) => setDate("Private", "Date", 1, e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridYear">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
            <Form.Control
            required
            type="text"
            placeholder="année"
            value={props.refParent.state.Private && props.refParent.state.Private.Date ? splitDate(props.refParent.state.Private.Date, 2) : ""}
            onChange={(e) => setDate("Private", "Date", 2, e.target.value)}
            />
          </Form.Group>
        </Row>

        <Form.Group  controlId="formGridSociety">
          <p style={{textAlign: 'left', marginTop: 10}}>Commentaire éventuel ?</p>
          <Form.Control
          type="text"
          value={props.refParent.state.Private && props.refParent.state.Private.Commentary ? props.refParent.state.Private.Commentary : ""}
          onChange={(e) => setObject("Private", "Commentary", e.target.value)}
          />
        </Form.Group>
        <Row>
          <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations Professionnelles</h6>


          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>Société</p>
            <Form.Control
            required={props.refParent.state.Complement && props.refParent.state.Complement.RegistrationType && props.refParent.state.Complement.RegistrationType !== 'Young' ? true : false}
            type="text"
            value={props.refParent.state.Society && props.refParent.state.Society.Name ? props.refParent.state.Society.Name : ""}
            onChange={(e) => setObject("Society", "Name", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer le nom de la société"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Pro && props.Error.Pro.Society ? "Veuillez rentrer le nom de la société" : ""}

            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridTva">
            <p style={{textAlign: 'left', marginTop: 10}}>Nº TVA</p>
            <Form.Control
            required={props.refParent.state.Complement && props.refParent.state.Complement.RegistrationType && props.refParent.state.Complement.RegistrationType !== 'Young' ? true : false}
            type="text"
            value={props.refParent.state.Society && props.refParent.state.Society.Tva ? props.refParent.state.Society.Tva : ""}
            onChange={(e) => setObject("Society", "Tva", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer un numéro de TVA"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Pro && props.Error.Pro.Tva ? "Veuillez rentrer un numéro de TVA" : ""}

            </Form.Control.Feedback>
          </Form.Group>

        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridStatus">
          <p style={{textAlign: 'left', marginTop: 10}}>Poste</p>
          <Form.Control
          type="text"
          value={props.refParent.state.Society && props.refParent.state.Society.Status ? props.refParent.state.Society.Status : ""}
          onChange={(e) => setObject("Society", "Status", e.target.value)}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridSector">
          <p style={{textAlign: 'left', marginTop: 10}}>Secteur</p>
           <Form.Control
           onChange={(e) => setObject("Society", "Sector", e.target.value)}
           value={props.refParent.state.Society && props.refParent.state.Society.Sector ? props.refParent.state.Society.Sector : ""}
            as="select">
             <option hidden value="">
               Veuillez indiquer le secteur d'activité
             </option>
             <option value="1">
               Immobilier
             </option>
             <option value="2">
               Banques, assurances, finances
             </option>
             <option value="3">
               Industriel
             </option>
             <option value="4">
               IT
             </option>
             <option value="5">
               Biens et services divers aux entreprises(BtoB)
             </option>
             <option value="6">
               Commerces (BtoC)
             </option>
             <option value="7">
               Avocats et autres professions libérales
             </option>
             <option value="8">
               Consultance/formation
             </option>
             <option value="9">
               Autres
             </option>
        </Form.Control>
        </Form.Group>


        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridCityPro">
            <p style={{textAlign: 'left', marginTop: 10}}>Ville</p>
            <Form.Control
            type="text"
            value={props.refParent.state.Pro && props.refParent.state.Pro.CityPro ? props.refParent.state.Pro.CityPro : ""}
            onChange={(e) => setObject("Pro", "CityPro", e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridZipCodePro">
            <p style={{textAlign: 'left', marginTop: 10}}>Code postal</p>
            <Form.Control
            type="text"
            value={props.refParent.state.Pro && props.refParent.state.Pro.ZipCodePro ? props.refParent.state.Pro.ZipCodePro : ""}
            onChange={(e) => setObject("Pro", "ZipCodePro", e.target.value)}
            />
          </Form.Group>
        </Row>

        <Form.Group controlId="formGridStreetPro">
          <p style={{textAlign: 'left', marginTop: 10}}>Adresse</p>
          <Form.Control
          value={props.refParent.state.Pro && props.refParent.state.Pro.StreetPro ? props.refParent.state.Pro.StreetPro : ""}
          type="text"
          onChange={(e) => setObject("Pro", "StreetPro", e.target.value)}
          />
        </Form.Group>

          <Row>
            <Form.Group as={Col} controlId="formGridTelPro">
              <p style={{textAlign: 'left', marginTop: 10}}>Tel</p>
              <Form.Control
              type="text"
              value={props.refParent.state.Pro && props.refParent.state.Pro.TelPro ? props.refParent.state.Pro.TelPro : ""}
              onChange={(e) => setObject("Pro", "TelPro", e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridEmailPro">
              <p style={{textAlign: 'left', marginTop: 10}}>E-mail</p>
              <Form.Control
              type="text"
              value={props.refParent.state.Pro && props.refParent.state.Pro.EmailPro ? props.refParent.state.Pro.EmailPro : ""}
              onChange={(e) => setObject("Pro", "EmailPro", e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations Complémentaire</h6>
            </Col>
          </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridDayPro">
            <p style={{textAlign: 'left', marginTop: 10}}>Date de création de l'entreprise</p>
            <Form.Control
            type="text"
            placeholder="jour"
            value={props.refParent.state.Society && props.refParent.state.Society.Date ? splitDate(props.refParent.state.Society.Date, 0) : ""}
            onChange={(e) => setDate("Society", "Date", 0, e.target.value)}
            />
           <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
              {"Veuillez saisir un date valide"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Complement && props.Error.Complement.Date ? "Veuillez saisir un date valide" : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridMounthPro">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
            <Form.Control
            type="text"
            placeholder="mois"
            value={props.refParent.state.Society && props.refParent.state.Society.Date ? splitDate(props.refParent.state.Society.Date, 1) : ""}
            onChange={(e) => setDate("Society", "Date", 1, e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridYearPro">
            <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
            <Form.Control
            type="text"
            placeholder="année"
            value={props.refParent.state.Society && props.refParent.state.Society.Date ? splitDate(props.refParent.state.Society.Date, 2) : ""}
            onChange={(e) => setDate("Society", "Date", 2, e.target.value)}
            />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} controlId="formGridLocalisation">
            <p style={{textAlign: 'left', marginTop: 10}}>Localisation de l'entreprise</p>
            <Form.Control
            type="text"
            placeholder="Belgique"
            value={props.refParent.state.Society && props.refParent.state.Society.Localisation ? props.refParent.state.Society.Localisation : ""}
            onChange={(e) => setObject("Society", "Localisation", e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridSizeOfSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>Taille de l'entreprise</p>
             <Form.Control
             onChange={(e) => setObject("Society", "SizeOfSociety", e.target.value)}
             value={props.refParent.state.Society && props.refParent.state.Society.SizeOfSociety ? props.refParent.state.Society.SizeOfSociety : ""}
              as="select">
               <option hidden value="">
                 Veuillez indiquer la taille de l'entreprise
               </option>
               <option value="-10">
                 Moins de 10 travailleurs
               </option>
               <option value="10/50">
                Entre 10 et 50 travailleurs
               </option>
               <option value="50/200">
                 De 50 à 200 travailleurs
               </option>
               <option value="200">
                 Plus de 200 travailleurs
               </option>
          </Form.Control>
          </Form.Group>
          <Form.Group as={Col} controlId="formGridFacturationSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>Parrain</p>
            <Form.Control
            type="text"
            placeholder="Entrez le parrain"
            value={props.refParent.state.Complement && props.refParent.state.Complement.Sponsor ? props.refParent.state.Complement.Sponsor : ""}
            onChange={(e) => setObject("Complement", "Sponsor", e.target.value)}
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridCommentaryAdmin">
            <p style={{textAlign: 'left', marginTop: 10}}>Commentaire Administrateur</p>
            <Form.Control
            //type="text"
            as="textarea"
            rows="3"
            value={props.refParent.state.Complement && props.refParent.state.Complement.CommentaryAdmin ? props.refParent.state.Complement.CommentaryAdmin : ""}
            onChange={(e) => setObject("Complement", "CommentaryAdmin", e.target.value)}
            />
          </Form.Group>
        </Row>



        <Row>
          <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 30}}>Coordonnées de facturation</h6>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
            <p style={{textAlign: 'left', marginTop: 10}}>Type de contact<span style={{color: 'red'}}> *</span></p>
            <FormControlLabel control={<OrangeCheckbox onChange={() => props.refParent.setState({TypeContactFacturation: "society"})} checked={props.refParent.state.TypeContactFacturation === "society"} name="society"/>} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Société</Typography>} />
            <FormControlLabel control={<OrangeCheckbox onChange={() => props.refParent.setState({TypeContactFacturation: "private"})} checked={props.refParent.state.TypeContactFacturation === "private"} name="private" />} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Privé</Typography>} />
          </Form.Group>
        </Row>
        {
          props.refParent.state.TypeContactFacturation != "private" ?
          <Row>

                  <Form.Group as={Col} controlId="formGridFacturationSociety">
                    <p style={{textAlign: 'left', marginTop: 10}}>Societé</p>
                    <Form.Control
                    type="text"
                    placeholder="Entrez-votre société/forme juridique"
                    value={props.refParent.state.Facturation && props.refParent.state.Facturation.Society ? props.refParent.state.Facturation.Society : ""}
                    onChange={(e) => setObject("Facturation", "Society", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridfirstName">
                    <p style={{textAlign: 'left', marginTop: 10}}>TVA</p>
                    <Form.Control
                    type="text"
                    placeholder="Entrez-votre numéro de TVA"
                    value={props.refParent.state.Facturation && props.refParent.state.Facturation.TVA ? props.refParent.state.Facturation.TVA : ""}
                    onChange={(e) => setObject("Facturation", "TVA", e.target.value)}
                    />
                  </Form.Group>
                </Row>
          :
          <>
          </>
        }


              <Row>
                <Form.Group as={Col} controlId="formGridFacturationZipCode">
                  <p style={{textAlign: 'left', marginTop: 10}}>Code postal*</p>
                  <Form.Control
                  type="text"
                  placeholder="Entrez-votre code postal"
                  value={props.refParent.state.Facturation && props.refParent.state.Facturation.ZipCode ? props.refParent.state.Facturation.ZipCode : ""}
                  onChange={(e) => setObject("Facturation", "ZipCode", e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridFacturationCity">
                  <p style={{textAlign: 'left', marginTop: 10}}>Ville*</p>
                  <Form.Control
                  type="text"
                  placeholder="Entrez-votre ville"
                  value={props.refParent.state.Facturation && props.refParent.state.Facturation.City ? props.refParent.state.Facturation.City : ""}
                  onChange={(e) => setObject("Facturation", "City", e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
              <Form.Group as={Col} controlId="formGridFacturationNumber">
                <p style={{textAlign: 'left', marginTop: 10}}>Number*</p>
                <Form.Control
                type="text"
                placeholder="Numéro"
                value={props.refParent.state.Facturation && props.refParent.state.Facturation.Number ? props.refParent.state.Facturation.Number : ""}
                onChange={(e) => setObject("Facturation", "Number", e.target.value)}
                />
              </Form.Group>
                <Form.Group as={Col} controlId="formGridFacturationStreet">
                  <p style={{textAlign: 'left', marginTop: 10}}>Adresse de facturation*</p>
                  <Form.Control
                  type="text"
                  placeholder="Entrez-votre adresse de facturation"
                  value={props.refParent.state.Facturation && props.refParent.state.Facturation.StreetWN ? props.refParent.state.Facturation.StreetWN : ""}
                  onChange={(e) => setObject("Facturation", "StreetWN", e.target.value)}
                  />
                </Form.Group>
              </Row>
              <Row>
              <Form.Group as={Col} controlId="formGridFacturationEmail">
                <p style={{textAlign: 'left', marginTop: 10}}>Email de facturation*</p>
                <Form.Control
                type="text"
                placeholder="Numéro"
                value={props.refParent.state.Facturation && props.refParent.state.Facturation.Email ? props.refParent.state.Facturation.Email : ""}
                onChange={(e) => setObject("Facturation", "Email", e.target.value)}
                />
              </Form.Group>
                <Form.Group as={Col} controlId="formGridFacturationPo">
                  <p style={{textAlign: 'left', marginTop: 10}}>Numéro de Po</p>
                  <Form.Control
                  type="text"
                  placeholder="Numéro de Po"
                  value={props.refParent.state.Facturation && props.refParent.state.Facturation.MoreInfoFacturation &&  props.refParent.state.Facturation.MoreInfoFacturation.Po ? props.refParent.state.Facturation.MoreInfoFacturation.Po : ""}
                  onChange={(e) => {
                    var Po = e.target.value
                    let MoreInfoFacturation = {Po: Po}
                    setObject("Facturation", "MoreInfoFacturation", MoreInfoFacturation)
                  }}
                  />
                </Form.Group>
              </Row>


              {renderInfoSubscribe()}


        <div className="centerButton" style={{marginBottom: 15}}>
          <Button
          style={{marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
          type="submit">
            ENREGISTRER
          </Button>
        </div>


      </Form>

    </Container>

    <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
      </div>
    </div>
  );
}

export default Modify;
