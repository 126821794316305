import React from 'react';
import IsConnectedAndAccess from '../../IsConnected/IsConnectedAndAccess'
import RenewBusinessPackCustom from './renewBusinessPackCustom'

class ParselRenewBusinessPackCustom extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={true} Access={"delca"} Component={RenewBusinessPackCustom} {...props}/>
   )
 }
}



export default ParselRenewBusinessPackCustom;
