import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import EditAdmin from './EditAdmin'

class ParselAddAdmin extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={EditAdmin} {...props}/>
   )
 }
}



export default ParselAddAdmin;
