import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Cookies from 'universal-cookie'

class PureEvent extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  _GoParticipantClick = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    let found = false;
    var index = 0;
    for (var i = 0; i < this.props.event.Region.length; i++){
      let region = this.props.event.Region && this.props.event.Region[i] ? this.props.event.Region[i] : ''

      index = Info.Region.find(res => res === region)
      if (index !== -1){
        found = true;
        break ;
      }
  }
    var paramsNavigate = {pathname: `/subscribe_member_event/${this.props.event.Id}`, state: {Acces: found, Name: this.props.event.Name, Date: this.props.event.DateEvent, isFree: this.props.event.Free, Compta: this.props.event.Compta}}
    paramsNavigate.state.returnTo = "/all_event/"
    paramsNavigate.state.oldPage = "/all_event/"
    this.props.refParent.props.navigation.history.push(paramsNavigate);
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left">
        {
          this.props.event.Image ?
            <img  alt={"ressource event"} key={this.props.event.Id} src={this.props.event.Image} style={{objectFit: 'contain', width: 50, borderRadius: 360, height: 35}}/>
          :
            <div style={{width: 35, borderRadius: 360, height: 35, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell align="left" style={{}}>
          <Button variant="text" style={{textTransform: 'none', fontSize: '0.8rem', margin: 0, padding: 0}} component={Link}
          to={{
          pathname: `/details_event/${this.props.event.Id}`,
          state: {oldPage: "/all_event/"}
          }}>
              {this.props.event.Name}
          </Button>

        </TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.DateEvent}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.BeginHours}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.EndHours}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.NumberSubscribe}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.Location}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.HeaderLiner}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.PublicUrlSubscribe}</TableCell>
        <TableCell align="center" style={{}}>


        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown1" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
              to={{
              pathname: `/details_event/${this.props.event.Id}`,
              state: {oldPage: "/all_event/"}
              }}>
                  Détails
              </Button>

            </Dropdown.Item>
            <Dropdown.Item id="nav-dropdown2" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button variant="text" onClick={() => this._GoParticipantClick()} style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                  Participant
              </Button>

            </Dropdown.Item>
            <Dropdown.Item id="nav-dropdown3" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, EventDelete: this.props.event})}} disabled={this.props.deleting} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              Supprimer
            </Button>

            </Dropdown.Item>


          </Dropdown.Menu>
        </Dropdown>




        </TableCell>
      </TableRow>)
  }

}

export default PureEvent;
