import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'universal-cookie'


class IsConnected extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        IsConnected: false,
        check: true
      }
    }

    _CheckConnexion =  () => {
      var cookies = new Cookies();
      var acces = cookies.get('acces')
      if (acces !== 'undefined' && acces)
      {

        this.setState({IsConnected: true, check: false})
        return ;
      }
      this.setState({check: false})
    }

    componentDidMount(){
      this._CheckConnexion()
    }



 redirectLogin = () => {
   var cookies = new Cookies();
   cookies.remove('acces', { path: '/' })
   cookies.remove('infoAdmin', { path: '/' })
   var url = '/login'
   const link = document.createElement('a');
   link.href = url;
   document.body.appendChild(link);
   link.click();
   link.parentNode.removeChild(link);
   return (<></>)
 }

 RenderComponent = () => {
   const {Component, ...props} = this.props;
   if (!Component)
    return (this.redirectLogin())
   const RenderComponent = Component;
   return (<RenderComponent {...props}/>)
 }


 render() {

   if (this.state.check === true)
    return (
      <div style={{color: 'orange', width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <CircularProgress color={'inherit'} size={40} thickness={5}/>
      </div>
    )
  else if (this.state.check === false && this.state.IsConnected === true)
    return (
      <>
      {this.RenderComponent()}
      </>
    )
  else
    <>
    {this.redirectLogin()}
    </>
 }
}


export default IsConnected;
