import React from 'react';

class Name extends React.Component {


  render (){
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{fontSize: '1.5em', fontWeight: 'bold', color: 'black'}}>{this.props.Location && this.props.Location.Name  ? this.props.Location.Name : ""}</p>
      </div>
    )
  }
}

export default Name;
