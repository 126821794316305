import React from 'react';
import styled from "styled-components";
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";
import {CssTextField} from './Style'
import { Fade } from "react-awesome-reveal";


const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    _renderModal = () => {
        return (
          <Fade>


            <Form noValidate validated={this.props.refParent.state.EmailFormValidated} onSubmit={(event) => this.props.refParent._checkEmailColleague(event)} style={{marginTop: 25}}>
                  <Row>
                    <Col>
                    <p style={{textAlign: 'left', marginTop: 10}}>Email<span style={{color: 'red'}}> *</span></p>
                    </Col>
                  </Row>
                   <Row>
                     <Col xl={5} lg={5} sm={6} md={4} style={{}}>
                       <Form.Control
                       type="text"
                       required
                       placeholder={"test@gmail.com"}
                       value={this.props.refParent.state.NewEmail}
                       onChange={(event) => this.props.refParent.setState({NewEmail: event.target.value})}/>
                       {this.props.refParent.state.messageEmailOwner ?
                          <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.messageEmailOwner}</p>
                          :
                          <></>
                        }
                     </Col>
                     <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
                     <Button
                     type="submit"
                     style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
                     >
                     {
                       this.props.refParent.state.verifyEmailLoading === true ?
                       <div style={{position: 'relative'}}>
                         <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                           <CircularProgress color="inherit" size={20}/>
                         </div>
                         <div>
                           <p style={{margin: 0, color: 'transparent'}}>Vérifier</p>
                         </div>
                       </div>
                       :
                         <p style={{margin: 0}}>Vérifier</p>
                     }
                     </Button>
                     </Col>
                   </Row>
               </Form>
             {this.props.refParent.state.EmailValidated === true ?
               <Fade>
               <Row>
              <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
                <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
                <Form.Control
                required
                type="text"
                placeholder={"Entrez le nom"}
                value={this.props.refParent.state.NewLast}
                onChange={(event) => this.props.refParent.setState({NewLast: event.target.value})}/>
                {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("NewLast") ?
                   <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>Veuillez indiquer le nom</p>
                   :
                   <></>
                 }
              </Form.Group>
              <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
                <p style={{textAlign: 'left', marginTop: 10}}>Prénom<span style={{color: 'red'}}> *</span></p>
                <Form.Control
                required
                type="text"
                placeholder={"Entrez le prénom"}
                value={this.props.refParent.state.NewFirst}
                onChange={(event) => this.props.refParent.setState({NewFirst: event.target.value})}/>
                {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("NewFirst") ?
                   <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>Veuillez indiquer le prénom</p>
                   :
                   <></>
                 }
              </Form.Group>
               </Row>
              </Fade>
              :
              <div/>
             }
          </Fade>
        )
    }


    _renderStatus = () => {
      if (!this.props.statusDelete || this.props.statusDelete === "Ask")
        return (<></>)
      if (this.props.statusDelete === "Waiting"){
        return (
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={35}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>)
      }
      if (this.props.statusDelete === "Success"){
        return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
        )
      }
      return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            </Col>
          </Row>
      )

    }

    _ButtonClickNo = () => {
      this.props.refParent.setState({addResult: null, add: false, NewFirst: null, NewLast: null, messageEmailOwner: false, EmailValidated: false, IdOwner: false, NewEmail: ""})
    }

    _ButtonClickYes = () => {
      this.props.refParent._AddColleague()
    }


 render() {

   return (
       <Popup showPopup={this.props.add} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.add ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
       {this._renderModal()}
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
         <ButtonTestCss  disabled={this.props.adding} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>
         {
           this.props.adding ?
           <CircularProgress
             size={"0.8rem"}
             thickness={5}
             color={"inherit"}
           />
           :
           <p style={{margin: 0, padding: 0}}>{this.props.addResult != null ? "Ré-essayer" : "Ajouter"}</p>
         }
         </ButtonTestCss>
           <ButtonTestCss  disabled={this.props.adding} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Annuler</ButtonTestCss>
         </Col>
       </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUp;
