import React from 'react';
import {ButtonCss} from './Style'

class ActionEvent extends React.Component {



  render (){
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 20, marginBottom: 20, flexWrap: 'wrap'}}>
        <div>
        <ButtonCss style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyActionClick()}>MODIFIER</ButtonCss>
        </div>
        <div>

        <ButtonCss style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._GoParticipantClick()}>PARTICIPANT</ButtonCss>
        </div>

        <div>

        <ButtonCss style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._DeleteEvent()}>SUPPRIMER</ButtonCss>
        </div>

      </div>
    )
  }
}

export default ActionEvent;
