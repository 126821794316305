import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FormatFullDate} from '../../Moment/Moment'

class Name extends React.Component {
  render (){
    return (
        <Row style={{marginTop: 15}}>
          <Col sm={12} lg={12} xl={12} md={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

            <h2 style={{color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>{this.props.Name ? this.props.Name : ""}</h2>
            <p style={{color: 'black', fontSize: '1rem', fontWeight: 'bold'}}>{this.props.Date ? FormatFullDate(this.props.Date, 'YYYY/MM/DD', 'DD/MM/YYYY') : ""}</p>

          </Col>
        </Row>
    )
  }
}

export default Name;
