import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddEvent from './AddEvent'

class ParselAddEvent extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddEvent} {...props}/>
   )
 }
}



export default ParselAddEvent;
