const Config = require('./Constants')
const fetch = require('node-fetch');
const RequestModel = require('./Model/export')

export const ContructUrlParams = (Params) => {
  var esc = encodeURIComponent;
  return Object.values(Params).map(res => esc(res)).join('&')
}


/**
* Request call the AuthApi to decode the JWt
* @module Request
* @async
* @param {Object} Params
* @return {<Promise>Object} contains all information about the request
*/
 export const Requestdynamic = async (Model, Params) => {
  var controller = new AbortController();
  if (Params === null)
    return ({error: true, data: null, message: "missing argument(s)"});
  try {
    var params = RequestModel[Model](Params);
  } catch (e) {
    return {error: true, message: "Model not match", data: null}
  }
  if (!params.hasOwnProperty("method") || !params.hasOwnProperty("Route"))
    return ({error: true, data: null, message: "missing Method or Route"})
  var FetchRequest = {method: params.method}
  FetchRequest.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  if (params.method === "POST" || params.method === "PUT")
  {
    if (!params.hasOwnProperty("body"))
      return ({error: true, data: null, message: "Body missing"});
    FetchRequest.body = JSON.stringify(params.body);
  }
  else if (params.method === "GET")
  {
    if (params.hasOwnProperty("body"))
      params.Route = params.Route + ContructUrlParams(params.body);

  }
  FetchRequest.signal = controller.signal
  var time = setTimeout(() => {controller.abort()}, 20000);
  try{
    var Ip = Params.OAuth ? Config.IP_BACKEND_DNS_AUTH : Config.IP_BACKEND_DNS
    var promise = await fetch(Ip + params.Route, FetchRequest).then(res => {return {error: false, data: res}}).catch(e => {return {error: true, data: e}});
    clearTimeout(time)
    if (promise.error === true)
      return ({error: true, data: null});
    var result = await promise.data.json();
    return ({error: false, data: result});
  }catch(e){
    return ({error: true, data: null});
  }

}

export const RequestFileUpload = async (Model, Params) => {
  var controller = new AbortController();
  if (Params === null)
    return ({error: true, data: null, message: "missing argument(s)"});
  try {
    var params = RequestModel[Model](Params);
  } catch (e) {
    return {error: true, message: "Model not match", data: null}
  }
  var FetchRequest = {method: params.method}
  FetchRequest.headers = {

  }
  let formData = new FormData();
  var KeyBody = Object.keys(params.body);
  for (var i = 0; i < KeyBody.length; i++){
    formData.append(KeyBody[i], params.body[KeyBody[i]])
  }
  FetchRequest.body = formData
  FetchRequest.signal = controller.signal
  var time = setTimeout(() => {controller.abort()}, 20000);
  try{
    var Ip = Params.OAuth ? Config.IP_BACKEND_DNS_AUTH : Config.IP_BACKEND_DNS
    var promise = await fetch(Ip + params.Route, FetchRequest).then(res => {return {error: false, data: res}}).catch(e => {return {error: true, data: e}});
    clearTimeout(time)
    if (promise.error === true)
      return ({error: true, data: null});
    var result = await promise.data.json();
    return ({error: false, data: result});
  }catch(e){
    return ({error: true, data: null});
  }
}
