import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from '@material-ui/core/Button';
import Loading from './Loading';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Private from '../SpecificMember/PrivateInfo'
import Pro from '../SpecificMember//ProInfo'
import Invoice from '../SpecificMember/InvoiceInfo'
import Complement from '../SpecificMember/ComplementInfo'
import Colleague from '../SpecificMember/Colleague'
import Subscribe from '../SpecificMember/SubscribeInfo'

class ModalDetails extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  _setMaj = (string) => {
    try {
      if(string !== null){
        const newString = string.split("-")
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
        }
        const newStringJoin = newString.join("-")
        return (newStringJoin)
      }
      return ('')
    }catch (e){
      return (string)
    }
}

  renderAfterLoading = () => {
    try {
      console.log("Hey", this.props.detailsMemberShowBackend.Facturation)
      if (this.props.errorDetailsMemberShow === true)
        return (<h3>Une erreur est survenue lors de l'affichage</h3>)
      return (
        <Container   style={{backgroundColor: 'white', padding: 15, borderRadius: 5, marginTop: 30, marginBottom: 10}}>
          <Row>
            <Private Private={this.props.detailsMemberShowBackend.Private}/>
            <Pro SocietyInfo={this.props.detailsMemberShowBackend.Society} Pro={this.props.detailsMemberShowBackend.Pro} />
            <Invoice  InvoiceInfo={this.props.detailsMemberShowBackend.Facturation}/>
            <Complement  Complement={this.props.detailsMemberShowBackend.Complement} SocietyInfo={this.props.detailsMemberShowBackend.Society}/>
            <Subscribe  Private={this.props.detailsMemberShowBackend.Private} intl={this.props.intl} Complement={this.props.detailsMemberShowBackend.Complement}/>
            {
              this.props.detailsMemberShowBackend.Complement && (this.props.detailsMemberShowBackend.Complement.RegistrationType === "Business" || this.props.detailsMemberShowBackend.Complement.RegistrationType === "Couple" || this.props.detailsMemberShowBackend.Complement.RegistrationType === "BusinessPackCustomize") && this.props.detailsMemberShowBackend.Complement.BusinessInfo === true ?
              <Colleague  Accept={this.props.detailsMemberShowBackend.Accept} intl={this.props.intl} Complement={this.props.detailsMemberShowBackend.Complement}/>
              :
              <>
              </>
            }
          </Row>
        </Container>
      )
    }catch (e){
      console.log(e)
      return (<h3>Une erreur est survenue lors de l'affichage</h3>)
    }
}

  renderLoadingComponent = () => {
    try {
      if (this.props.loadingDetailsMemberShow === true)
        return (<Loading loadingMore={this.props.loadingDetailsMemberShow}/>)
      return (this.renderAfterLoading())
    }catch (e){
      return (<h3>Une erreur est survenue lors du chargement des données</h3>)
    }
}

 render() {
   if (!this.props.detailsMember)
    return (<></>)

   return (
     <Modal
       {...this.props}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
       <Modal.Title id="contained-modal-title-vcenter">
         {this._setMaj(this.props.detailsMember.LastName) + " " + this._setMaj(this.props.detailsMember.FirstName)}
       </Modal.Title>
       </Modal.Header>
       <Modal.Body>
        {this.renderLoadingComponent()}
       </Modal.Body>
       <Modal.Footer>
         <Button onClick={this.props.onHide}>Fermer</Button>
       </Modal.Footer>
     </Modal>
   )
 }
}


export default ModalDetails;
