import React, { useState, useEffect } from 'react';
import Aside from './Aside';
import Main from './Main';
import { Redirect } from 'react-router-dom';
import { useIntl  } from 'react-intl';

function Layout({setLocale}) {
  const [toggled, setToggled] = useState(false);
  const [infoAdmin, setInfoAdmin] = useState(null);
  const intl = useIntl();



  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    if (infoAdmin){
      return <Redirect push to={"/allmembers"}/>
    }


  }, [infoAdmin])

  const refreshLayout = (infoAdmin) => {
    var Name = infoAdmin.name;
    var Region = infoAdmin.Region;
    setInfoAdmin({Name: Name, Region: Region})
  }

  return (
    <div className={`app ${toggled ? 'toggled' : ''}`}>
      <Aside
        setLocale={setLocale}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
      <Main
        intl={intl}
        refreshLayout={refreshLayout}
        handleToggleSidebar={handleToggleSidebar}
        toggled={toggled}

      />
    </div>
  );
}

export default Layout;
