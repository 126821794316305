import React, {useEffect, useState} from 'react';
import {Row, Col, Container} from 'react-bootstrap'

import RouteChart from './RouteChart'
import FilterChart from './FilterChart'

const CreateChart = ({info}) => {
  return (
    <div style={{marginTop: '1.5rem'}}>
    {
      info.chart.lenght == 0 ? <div></div> :
      info.chart.map((info_row) => {
        return (
          <Row style={{display: 'flex',
          justifyContent: 'space-between',}}>
            {
              info_row.map((info_chart) => {
                return(
                  <Col xl={info_chart.Size} lg={info_chart.Size} md={12} sm={12} style={{
                    background: "#fff",
                    padding: "1.8rem",
                    borderRadius: "2rem",
                    margin: "1rem",

                    boxShadow: "0 2rem 3rem rgba(132, 139, 200, 0.18)",
                    transition: "all 300ms ease",
                  }}>
                    <Container fluid={true} style={{}}>
                      <Row style={{borderBottomStyle: 'solid', borderBottomWidth: '0.5px', borderBottomColor: 'grey', display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', paddingBottom: '5px'}}>
                        <Col lg='auto' sm='auto' md='auto' style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                          <h5>{info_chart.Title}</h5>
                        </Col>
                        {
                          info_chart.filter ?
                          <Col lg='auto' md='auto' sm='auto'>
                            <Row style={{
                            overflowX: 'auto'}}>
                              <FilterChart filter={info_chart.filter} name={info_chart.Title}/>
                            </Row>
                          </Col>
                          : <>
                          </>
                        }

                      </Row>
                      <RouteChart info={info_chart} />
                      </Container>
                  </Col>
                )
              })
            }
          </Row>
        )
      })
    }
    </div>

  )
}

//

export default (CreateChart)
