import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ItemGallery from './ItemGallery'
import {  LazyLoadComponent } from 'react-lazy-load-image-component';

class ListOfGallery extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderImages = () => {
      return (


          this.props.gallery.map((image, index) => (
          <LazyLoadComponent effect='blur'>
            <ItemGallery refParent={this.props.refParent} image={image} key={index} index={index}/>
          </LazyLoadComponent>

        ))

      )
    }

 render() {
   return (

      <Container fluid>
        <Row>
            {this.props.gallery.length === 0 ?
              <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '90vh'}}>
                <div>
                  <p style={{fontSize: '1.2rem', fontWeight: 'bold'}}>Pas de résultats</p>
                </div>
              </Col>
              :
              this.renderImages()}
        </Row>
      </Container>
   )
 }
}


export default ListOfGallery;
