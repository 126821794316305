import React from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';

class Alert extends React.Component {

  ErrorAlert = () => {
    return (
      <Offcanvas style={{backgroundColor: '#F8D7D9'}} show={this.props.refParent.state.show} onHide={() => this.props.refParent.setState({show: false})} placement={'top'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{color: '#831F29'}}>Une erreur est survenue !</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{color: '#831F29'}}>
          Veuillez vérifier votre connexion internet ou réessayer plus tard.
          <br/>
          Si le problème persiste, veuillez contacter le service technique.
        </Offcanvas.Body>
      </Offcanvas>
    )
  }

  SuccessAlertAdd = () => {
    return (
      <>
      <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={this.props.refParent.state.show} onHide={() => {this.props.refParent.setState({show: false}); this.props.refParent.resetInformationAdd(); this.props.refParent._getAllPromotion()}} placement={'top'}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{color: '#0F5132'}}>
            La promotion à bien été ajoutée.
            <br/>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }

  SuccessAlertDelete = () => {
    return (
      <>
      <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={this.props.refParent.state.show} onHide={() => this.props.refParent.setState({show: false})} placement={'top'}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{color: '#0F5132'}}>
            La promotion à bien été supprimée.
            <br/>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }

  SuccessAlertModify = () => {
    return (
      <>
      <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={this.props.refParent.state.show} onHide={() => {this.props.refParent.setState({show: false}); this.props.refParent.resetInformationAdd(); this.props.refParent._getAllPromotion()}} placement={'top'}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{color: '#0F5132'}}>
            La promotion à bien été modifiée.
            <br/>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    )
  }

  render (){
    if (this.props.refParent.state.Alert === "SuccessAdd")
      return this.SuccessAlertAdd()
    else if (this.props.refParent.state.Alert === "SuccessDelete")
      return this.SuccessAlertDelete()
    else if (this.props.refParent.state.Alert === "SuccessModify")
      return this.SuccessAlertModify()
    else if (this.props.refParent.state.Alert === "Error")
      return this.ErrorAlert()
    else
      return <></>
  }
}

export default Alert;
