import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class SeparateLine extends React.Component {
  constructor(props){
      super(props);
      this.state = {

      }
    }



 render() {
   const Color = {
     greyTrans: 'rgb(8, 36, 48, 0.2)'
   }
   return (
     <>
         <Row style={{marginTop: this.props.marginTop ? this.props.marginTop : 0}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', alignItems: "center", justifyContent: 'center'}}>
            <div style={{width: this.props.width, height: this.props.height, backgroundColor: Color[this.props.color]}}>
            </div>
          </Col>
         </Row>
    </>
   )
 }
}


export default SeparateLine;
