import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container  from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import CircularProgress from '@material-ui/core/CircularProgress';

class ValidateToken extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (

       <Container className="shadow" style={{width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50, marginBottom: 50}}>
       <Row >
        <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <h2>Vérification du compte</h2>
        </Col>
       </Row>
       {
         this.props.Error && this.props.Error.Connexion ?
         <Row >
          <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{color:'red', fontWeight:'bold', marginTop: 25}}>{this.props.Error.Connexion}</p>
          </Col>
         </Row>
         : <>
         </>
       }

       <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
         <Form.Group as={Col} sm={10} md={10} xl={8} lg={8} controlId="formGridEmail" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
           <Form.Control
           type="email"
           placeholder="Login (adresse email)"
           value={this.props.Email ? this.props.Email : ""}
           onChange={(event) => this.props.refParent.setState({Email: event.target.value})}

           />
         </Form.Group>
         {
           this.props.Error && this.props.Error.Email ?
           <Col sm={10} md={10} xl={8} lg={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
             <p style={{color:'red', fontWeight:'bold'}}>Veuillez saire une adresse email</p>
           </Col>
           :
           <>
           </>
         }
       </Row>
       <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <Form.Group as={Col} sm={10} md={10} xl={8} lg={8} controlId="formGridEmail" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
         <Form.Control
         type="password"
         placeholder="Clef d'accés"
         value={this.props.Token ? this.props.Token : ""}
         onChange={(event) => this.props.refParent.setState({Token: event.target.value})}
         />
         </Form.Group>
         {
           this.props.Error && this.props.Error.Token ?
           <Col sm={10} md={10} xl={8} lg={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
             <p style={{color:'red', fontWeight:'bold'}}>Veuillez saisir la clef d'accés</p>
           </Col>
           :
           <>
           </>
         }
       </Row>
       <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} sm={10} md={10} xl={8} lg={8}>
          <Button
          disabled={this.props.checkOut}
          onClick={() => this.props.refParent._checkOut()}
          style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
         >
         {
           this.props.checkOut ?
           <div style={{display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
           <div style={{width: '100%'}}>
            <p style={{color: 'transparent', margin: 0, padding: 0}}>Vérifier</p>
           </div>
           <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <CircularProgress
               size={"1.5rem"}
               thickness={5}
               color={"inherit"}
             />
           </div>
           </div>
           :
          <p style={{margin: 0, padding: 0}}>Vérifier</p>
         }

          </Button>
        </Col>
       </Row>

       </Container>

   )
 }
}


export default ValidateToken;
