import React from 'react';
import Container from 'react-bootstrap/Container';

import Header from './Header'
import Loading from './Loading'
import PopUp from './PopUp'
import InfoMember from './InfoMember'
import HeaderGoBack from './HeaderGoBack'
import HistoryMember from './HistoryMember'

class ProfilMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
     <Container fluid>
      <PopUp redirect={this.props.refParent.state.redirect} refParent={this.props.refParent} openModal={this.props.refParent.state.openModal} message={this.props.refParent.state.message} title={this.props.refParent.state.title} />
      <HeaderGoBack refParent={this.props.refParent}/>
       <Header NotAcces={this.props.refParent.state.NotAcces} SocietyInfo={this.props.refParent.state.Society} Image={this.props.refParent.state.Image} intl={this.props.intl} LastName={this.props.refParent.state.LastName} FirstName={this.props.refParent.state.FirstName} refParent={this.props.refParent}/>
       {
         this.props.currentPage === 'info' ?
         <InfoMember Accept={this.props.refParent.state.Accept} intl={this.props.intl} Complement={this.props.refParent.state.Complement} InvoiceInfo={this.props.refParent.state.Facturation} SocietyInfo={this.props.refParent.state.Society} loading={this.props.refParent.state.loading} Pro={this.props.refParent.state.Pro} Private={this.props.refParent.state.Private}/>
          :
          <HistoryMember refParent={this.props.refParent} currentHistory={this.props.currentHistory}/>
       }
       <Loading loadingMore={this.props.refParent.state.loading} size={50} />
     </Container>
   )
 }
}


export default ProfilMember;
