import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Cookies from 'universal-cookie'

import {ButtonTestCss} from "../../ButtonStyle";

import Header from './Header'
import CircularProgress from '@material-ui/core/CircularProgress';
import {Requestdynamic} from '../../Network/Fetch';
import Name from './Name'
import Adress from './Address'
import PopUp from './PopUp'
import RegionB19 from './RegionB19'

class AddAdress extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          addAddress: false,
          Error: null,
          City: null,
          Street: null,
          PostalCode: null,
          Number: null,
          Name: null,
          success: false,
          title: null,
          message: null,
          RegionB19: null,
          openModal: false
        }
    }

    Check_PostalCode = (Error) => {
      try {
        if (!this.state.PostalCode || this.state.PostalCode.length === 0)
          Error.PostalCode = true
        if (isNaN(this.state.PostalCode))
          Error.PostalCode = true
        if (parseInt(this.state.PostalCode, 10) < 0)
          Error.PostalCode = true
      } catch (e){
        Error.PostalCode = true

      }
    }

    Check_City = (Error) => {
      try {
        if (!this.state.City || this.state.City.length === 0)
          Error.City = true
      } catch (e){
        Error.City = true
      }
    }

    Check_Street = (Error) => {
      try {
        if (!this.state.Street || this.state.Street.length === 0)
          Error.Street = true
      } catch (e){
        Error.Street = true
      }
    }

    Check_Number = (Error) => {
      try {
        if (!this.state.Number || this.state.Number.length === 0)
          Error.Number = true
      } catch (e){
        Error.Number = true

      }
    }

    Check_Name = (Error) => {
      try {
        if (!this.state.Name || this.state.Name.length === 0)
          Error.Name = true
      } catch (e) {
        Error.Name = true
      }

    }

    Check_RegionB19 = (Error) => {
      try {
        if (!this.state.RegionB19 || this.state.RegionB19.length === 0)
          Error.RegionB19 = true
      } catch (e) {
        Error.RegionB19 = true
      }

    }


    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
    }

    _addPlace = async () => {
      this.setState({addAddress: true, Error: null}, async () => {
        var Error = {};

        this.Check_Name(Error)
        this.Check_City(Error)
        this.Check_PostalCode(Error)
        this.Check_Street(Error)
        this.Check_Number(Error)
        this.Check_RegionB19(Error)

        var KeysErrorLength = Object.keys(Error).length;
        if (KeysErrorLength !== 0){
          this.setState({Error: Error, addAddress: false})
          return ;
        }
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Name: this.state.Name,
          Jwt: Jwt,
          RegionLocation: this.state.RegionB19,
          Street: this.state.Street,
          City: this.state.City,
          Number: this.state.Number,
          PostalCode: this.state.PostalCode,
        }
        var promise = await Requestdynamic('_addLocation', Params);
        if (promise.error === false && promise.data.error === false){
          this.setState({success: true, addAddress: false, openModal: true, title: 'Success', message: "L'adresse à bien été ajouté"})
        }
        else {
          if (promise.error === true)
            this.setState({addAddress: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
          if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({addAddress: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
          return ;
        }
      })
    }

 render() {

   return (

       <div style={{marginLeft: 10, overflowY: "auto"}}>
        <Container fluid>
          <PopUp refParent={this} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} />
          <Header refParent={this}/>
          <Name refParent={this} Error={this.state.Error} Name={this.state.Name} />
          <Adress refParent={this} Error={this.state.Error} City={this.state.City} PostalCode={this.state.PostalCode} Street={this.state.Street} Number={this.state.Number}/>
          <RegionB19 RegionB19={this.state.RegionB19} refParent={this} Error={this.state.Error} intl={this.props.intl} />
          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
            <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ButtonTestCss disabled={this.state.addAddress} color={"orange"} style={{margin: 10}} onClick={() => this._addPlace()}>
                {
                  this.state.addAddress ?
                  <CircularProgress
                    size={"1.5rem"}
                    thickness={5}
                    color={"inherit"}
                  />
                  :
                  <p style={{margin: 0, padding: 0}}>Ajouter</p>
                }
              </ButtonTestCss>

            </Col>
          </Row>


        </Container>
      </div>

   )
 }
}


export default AddAdress;
