import React from 'react';
import styled from "styled-components";
import FileUpload from './FileUpload/FileUpload'

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    updateFilesCb = (Files) => {
      this.props.refParent.setState({Files: Files})
    }

    _ButtonClick = () => {
      if (this.props.redirect === true)
      {
        this.props.refParent.goBack()
        return ;
      }
      var Params = {openModal: !this.props.openModal, title: null, message: null}

      this.props.refParent.setState(Params)
    }

 render() {

   return (
       <Popup showPopup={this.props.addFiles} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.addFiles ? 10000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{width: "60%", borderRadius: 10, maxHeight: '60%',backgroundColor: 'white', overflowY: 'scroll'}}>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: '1.2rem', fontFamily:'Mitr, sans-sérif', }}>Ajouter des photos</h3>
              </div>
            </div>
            <div style={{width: '100%', padding: 20}}>
              <FileUpload Reset={this.props.refParent._ResetGallery} IdGallery={this.props.IdGallery} closeModalCallBack={() => this.props.refParent.setState({addFiles: false})} multiple={true} updateFilesCb={this.updateFilesCb}/>
            </div>

          </div>
       </Popup>
   )
 }
}

/*
<div style={{width: '100%', padding: 20, flexDirection: "row", display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>


  <ButtonTestCss  onClick={() => this.props.refParent._uploadFiles()} color={'rgb(8, 36, 48)'} disabled={this.props.Files.length === 0 ? true : false}>
    Upload
  </ButtonTestCss>



  <ButtonTestCss onClick={() => this.props.refParent.setState({addFiles: false})} color={'orange'}>
    Annuler
  </ButtonTestCss>




</div>*/
export default PopUp;
