import React from 'react';
import Col from 'react-bootstrap/Col';


class Colleague extends React.Component {

  _linkColleagueIfAccept = (colleague) => {
    if (this.props.Accept === true){
      return( <p><a style={{fontSize: '0.8em', color: 'black'}} href={`/details_member/${colleague.Id}`}>{colleague.email}</a></p>)
    }
    return(<p style={{fontSize: '0.8em', color: 'black'}}>{colleague.email}</p>)

  }

  _renderColleague = () => {
    if (!this.props.Complement || !this.props.Complement.Colleague || this.props.Complement.Colleague.length === 0)
      return (<></>)
    else{
      try {
        return this.props.Complement.Colleague.map((colleague, index) => {
          return (
            <div style={{display: 'flex', flexDirection: 'column'}} key={index}>
            <p style={{fontSize: '0.9em', fontWeight: 'bold', color: 'black'}}>{colleague.firstName} {colleague.lastName}</p>
            {this._linkColleagueIfAccept(colleague)}
            </div>
          )
        })
      } catch (e) {
        return (<></>)
      }
    }
  }



  render (){
    return (
      <Col sm={12} xl={4} lg={4} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
        <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS SUR LES COLLEGUES</p>


          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flexDirection: 'column', display: 'flex'}}>
              {this._renderColleague()}
              </div>

            </div>
        </div>
      </Col>
    )
  }
}

export default Colleague;
