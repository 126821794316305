import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ButtonCss, CssTextField, ButtonCssChecked} from './Style'
import Typography from '@material-ui/core/Typography';
import Cookies from 'universal-cookie'
import Filter from './Filter';

class Header extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  _createFilter = () => {
    try {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      Info = {}
      Info.Region = ["bxl", 'anv', 'lux', 'fl', "luik"]
      return Info.Region.map((region, index) => {
        return (
          <Filter intl={this.props.intl} region={region} key={index} refParent={this}/>
        )
      })
    } catch (e){
      return <></>
    }
  }

  _ConstructFilterArray = (Region) => {
    try {
      var CurrentRegionArray = [...this.props.Filter]
      var index = CurrentRegionArray.findIndex(res => res === Region)
      if (index === -1)
        CurrentRegionArray.push(Region)
      else
        CurrentRegionArray.splice(index, 1)
      this.props.refParent.setState({Filter: CurrentRegionArray}, () => {
        this.props.refParent.MemberDisplayRegistration()
      })
    } catch (e){
      return ;
    }
  }


  renderTotal = () => {
    if (this.props.searchRegion === true)
      return (this.props.searchMemberRegion.length)
    if (this.props.Member)
      return (this.props.Member.length)
    return ("0")
  }

  render (){
    return (
      <>
      <Container fluid>
        <Row style={{display: 'flex', alignItems: 'center', marginBottom: "25px"}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h2 style={{textAlign: 'left', margin: 0}}>Non Membres</h2>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundColor: 'rgba(0, 0, 0, 0.1)',  padding: 20}}>
        <Row style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
          {this._createFilter()}
        </Row>
        </Container>
        <Container fluid>
        <Row style={{marginTop: 20, display: 'flex', justifyContent: 'flex-start'}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h4 style={{textAlign: 'left', marginBottom: 10}}>Rechercher </h4>
          </Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <div style={{width: "100%", display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              <CssTextField value={this.props.search ? this.props.search : null} label="Rechercher" variant="outlined" onChange={(event) => this.props.refParent.search(event.target.value)} style={{marginRight: 5}}/>
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: 20, display: 'flex', justifyContent: 'flex-start'}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h4 style={{textAlign: 'left', marginBottom: 10}}>Nombre de non membres</h4>
          </Col>
        </Row>
        <Row>
        <Col sm={3} md={3} lg={3} xl={3} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10}}>
          <div style={{width: "100%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
              <Typography style={{fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.98rem', color: '#082430', marginBottom: 50, marginTop: 10}}>{this.renderTotal()}</Typography>
          </div>
        </Col>
        </Row>

        </Container>
        </>
    )
  }
}

export default Header;
