import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'
import Header from './Header'
import OwnerSection from './OwnerSection'
import SocietySection from './SocietySection'
import FacturationSection from './FacturationSection'
import ColleagueSection from './ColleagueSection'
import { Button, Container, Row, Col} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import AlertSuccess from './AlertSuccess'
import {GetCurrentDateEuropeParis, AddTimeFormat} from '../../Moment/Moment'


class BusinessCustomize extends React.Component {
  constructor(props){
      super(props);
      this.ownerSection = React.createRef();
      this.colleagueSection = React.createRef();
      this.FacturationSection = React.createRef();
      this.SocietySection = React.createRef();
      this.Header = React.createRef();
      this.state = {
        SendMail: true,
        NumberFacturation: "",
        EmailFacturation: "",
        Po: "",
        ModifyEndDate: false,
        SocietyInfoSection: true,
        TypeContact: "society",
        loadingBusinessPack: false,
        EmailOwnerFormValidated: false,
        EmailOwnerValidated: false,
        loadingEmailOwner: false,
        EmailOwner: "",
        FirstName: "",
        LastName: "",
        Tel: "",
        openModal: false,
        messageEmailOwner: null,
        SocietyName: "",
        Tva: "",
        StreetCity: "",
        PostalCode: "",
        City: "",
        SocietyNameFacturation: "",
        TvaFacturation: "",
        StreetCityFacturation: "",
        PostalCodeFacturation: "",
        CityFacturation: "",
        TvaSearch: "",
        CountryTva: "",
        messageTvaSearch: "",
        loadingTvaSearch: false,
        TvaSearchFormValidated: false,
        messageStreet: "",
        StreetNumberValidation: false,
        Colleague: [],
        Price: 0,
        Region: null,
        IdOwnerBusinessPack: null,
        ErrorBackend: null,
        Error: null,
        messageAlert: null,
        colorAlert: null,
        VariablePrice: false,
        Month: "",
        Years: "",
        Days: "",
      }
    }

    _excuteScroll = (target) => {
      this[target].current.scrollIntoView({behavior: "smooth"})
    }

    refreshState = () => {
      this.setState({
        SendMail: true,
        Month: "",
        Years: "",
        Days: "",
        Error: null,
        Po: "",
        EmailFacturation: "",
        NumberFacturation: "",
        SocietyInfoSection: true,
        TypeContact: "society",
        VariablePrice: false,
        ErrorBackend: null,
        loadingBusinessPack: false,
        EmailOwnerFormValidated: false,
        EmailOwnerValidated: false,
        loadingEmailOwner: false,
        EmailOwner: "",
        FirstName: "",
        LastName: "",
        Tel: "",
        messageEmailOwner: null,
        SocietyName: "",
        Tva: "",
        StreetCity: "",
        PostalCode: "",
        City: "",
        SocietyNameFacturation: "",
        TvaFacturation: "",
        StreetCityFacturation: "",
        PostalCodeFacturation: "",
        CityFacturation: "",
        TvaSearch: "",
        CountryTva: "",
        messageTvaSearch: "",
        loadingTvaSearch: false,
        TvaSearchFormValidated: false,
        messageStreet: "",
        StreetNumberValidation: false,
        Colleague: [],
        Price: 0,
        Region: null,
        IdOwnerBusinessPack: null,
      })
    }

    CheckEmailColleague = async (event, Colleague) => {
      event.preventDefault()
      event.stopPropagation()
      var indexColleague = this.state.Colleague.findIndex(res => res.target === Colleague.target)
      var ColleagueCopy = [...this.state.Colleague]
      if (indexColleague === -1) {
        let messageEmailOwner = "Une erreur est survenue, veuillez remplir les informations"
        ColleagueCopy[ColleagueCopy.length - 1].messageEmailOwner = messageEmailOwner
        ColleagueCopy[ColleagueCopy.length - 1].EmailFormValidated = true
        this.setState({Colleague: ColleagueCopy})
        return;
      }
      if (Colleague.email.length === 0){
        ColleagueCopy[indexColleague].EmailFormValidated = true
        this.setState({Colleague: ColleagueCopy})
        return;
      }
      ColleagueCopy[indexColleague].loadingEmail = true;
      ColleagueCopy[indexColleague].EmailFormValidated = false;
      ColleagueCopy[indexColleague].messageEmailOwner = null;
      this.setState({Colleague: ColleagueCopy}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Email: Colleague.email
        }
        var promise = await Requestdynamic('_searchMemberAttach', Params);
        if (promise.error === false && promise.data.error === false){
          if (promise.data.data != null){
            var InfoOwner = promise.data.data;
            if (InfoOwner.RegistrationType != 'SingleA' && InfoOwner.RegistrationType != 'SingleM'){
              let RegistrationType = InfoOwner.RegistrationType != null ? InfoOwner.RegistrationType : InfoOwner.MemberStatus
              let messageEmailOwner = "Cette adresse mail est déjà associé à un compte de type "+ RegistrationType +""
              ColleagueCopy[indexColleague].loadingEmail = false;
              ColleagueCopy[indexColleague].EmailFormValidated = true;
              ColleagueCopy[indexColleague].EmailValidated = true;
              ColleagueCopy[indexColleague].messageEmailOwner = messageEmailOwner;
              ColleagueCopy[indexColleague].Id = InfoOwner.Id;
              this.setState({Colleague: ColleagueCopy})
              return ;
            }
            var Info = cookies.get('infoAdmin')
            if (Info && Info.Region){
              var indexRegion = Info.Region.findIndex(res => res === InfoOwner.Region)
              if (indexRegion === -1)
              {
                let messageEmailOwner = "Cette adresse mail est associé à une Région B19 où vous n'avez pas d'accès"
                ColleagueCopy[indexColleague].messageEmailOwner = messageEmailOwner;
                ColleagueCopy[indexColleague].EmailValidated = true;
                ColleagueCopy[indexColleague].EmailFormValidated = true;
                ColleagueCopy[indexColleague].loadingEmail = false;
                ColleagueCopy[indexColleague].Id = InfoOwner.Id;
                this.setState({Colleague: ColleagueCopy})
                return ;
              }
            }

            ColleagueCopy[indexColleague].firstName = InfoOwner.UserInformation.Name.First;
            ColleagueCopy[indexColleague].lastName = InfoOwner.UserInformation.Name.Last;
            ColleagueCopy[indexColleague].loadingEmail = false;
            ColleagueCopy[indexColleague].EmailValidated = true;
            ColleagueCopy[indexColleague].Id = InfoOwner.Id;

            this.setState({Colleague: ColleagueCopy})
            return ;
          }
        }
        ColleagueCopy[indexColleague].loadingEmail = false;
        ColleagueCopy[indexColleague].EmailValidated = true;
        ColleagueCopy[indexColleague].firstName = "";
        ColleagueCopy[indexColleague].lastName = "";
        ColleagueCopy[indexColleague].Id = null;
        this.setState({Colleague: ColleagueCopy})
      })
    }

    ChangeInfoColleague = (value, target, targetValue) => {
      var Colleague = [...this.state.Colleague]
      var index = Colleague.findIndex(res => res.target === target)
      if (index === -1)
        return ;
      Colleague[index][targetValue] = value
      this.setState({Colleague: Colleague})
    }

    RemoveColleague = (target) => {
      var Colleague = [...this.state.Colleague]
      var index = Colleague.findIndex(res => res.target === target)
      if (index === -1)
        return ;
      Colleague.splice(index, 1)
      this.setState({Colleague: Colleague})
    }

    AddColleague = () => {
      var Colleague = [...this.state.Colleague]
      var Target = Math.random().toString(36).slice(-8)
      Colleague.push({email: "", firstName: "", lastName: "", target: Target, EmailValidated: false, loadingEmail: false, EmailFormValidated: false, messageEmailOwner: null})
      this.setState({Colleague: Colleague})
    }

    SearchTva = async (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.setState({loadingTvaSearch: true, TvaSearchFormValidated: false}, async () => {
        var Params = {
          Vat: this.state.TvaSearch,
          VatCountry: this.state.CountryTva
        }
        var promise = await Requestdynamic('_searchTva', Params);
        if (promise.error === false && promise.data.error === false){
          var StreetNumber = ""
          if (promise.data.data.address && promise.data.data.address.streetNumber !== undefined){
            StreetNumber = promise.data.data.address.streetNumber
          }
          var paramsSetState = {
            Tva: this.state.CountryTva + this.state.TvaSearch,
            StreetCity: StreetNumber + ", " + promise.data.data.address.street,
            PostalCode: promise.data.data.address.postalCode,
            City: promise.data.data.address.city,
            SocietyName: promise.data.data.name,
            TvaFacturation: this.state.CountryTva + this.state.TvaSearch,
            StreetCityFacturation: promise.data.data.address.street,
            NumberFacturation: StreetNumber,
            PostalCodeFacturation: promise.data.data.address.postalCode,
            CityFacturation: promise.data.data.address.city,
            SocietyNameFacturation: promise.data.data.name,
            loadingTvaSearch: false,
          }
          if (StreetNumber.length === 0){
            paramsSetState.messageStreet = "Attention ! vérifier l'adresse. Il semblerait qu'elle soit incompléte"
            paramsSetState.StreetNumberValidation = true
          }
          this.setState(paramsSetState)
          return;
        } else {
          this.setState({loadingTvaSearch: false, TvaSearchFormValidated: true, messageTvaSearch: "Une erreur est survenue, veuillez verifier les informations. Il se peut que le service soit momentanément inaccessible"})
        }
        this.setState({loadingTvaSearch: false, TvaSearchFormValidated: true})
      })
    }

    CreateColleagueArrayForRequest = () => {
      var ColleagueClean = []
      var Copy = [...this.state.Colleague]
      Copy.forEach((item, i) => {
        let push = {
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName
        }
        if (item.hasOwnProperty("Id") && item.Id && item.Id.length != 0 && item.Id != null)
          push.Id = item.Id
        ColleagueClean.push(push)
      });
      return ColleagueClean
    }

    findScrollTarget = (data) => {
      var scrollTarget = "";
      if (data.hasOwnProperty('Colleague'))
        scrollTarget = "colleagueSection"
      if (data.hasOwnProperty('Facturation'))
        scrollTarget = "FacturationSection"
      if (data.hasOwnProperty('Society'))
        scrollTarget = "SocietySection"
      if (data.hasOwnProperty('Private'))
        scrollTarget = "ownerSection"
      return (scrollTarget)
    }

    GestionError = async (promise) => {
      try {
        if (promise.message === "CheckArg"){
          var scrollTarget = this.findScrollTarget(promise.data)
          this.setState({Error: promise.data, loadingBusinessPack: false})
          this._excuteScroll(scrollTarget)
          return ;
        }
        else {
          this.executeErrorAlert()
          return ;
        }
      } catch (e) {
        this.executeErrorAlert()
        return ;
      }
    }

    _ConstructEndDate = () => {
     return ([this.state.Years, this.state.Month, this.state.Days].join('/'))
    }

    CreateBusinessPack = async () => {
      this.setState({loadingBusinessPack: true, Error: null, ErrorBackend: null}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var MoreInfoFacturation = {}
        if (this.state.Po.length != 0)
          MoreInfoFacturation.Po = this.state.Po
        var Params = {
          Jwt: Jwt,
          NumberFacturation: this.state.NumberFacturation,
          FacturationEmail: this.state.EmailFacturation,
          MoreInfoFacturation: MoreInfoFacturation,
          SocietyInfoSection: this.state.SocietyInfoSection,
          TypeContact: this.state.TypeContact,
          Price: this.state.Price,
          firstName: this.state.FirstName,
          lastName: this.state.LastName,
          email: this.state.EmailOwner,
          tel: this.state.Tel,
          society: this.state.SocietyName,
          tva: this.state.CountryTva + this.state.TvaSearch,
          zipCode: this.state.PostalCode,
          city: this.state.City,
          street: this.state.StreetCity,
          region: this.state.Region,
          facturationSociety: this.state.SocietyNameFacturation,
          facturationStreet: this.state.StreetCityFacturation,
          facturationZipCode: this.state.PostalCodeFacturation,
          facturationCity: this.state.CityFacturation,
          facturationTva: this.state.TvaFacturation,
          IdOwner: this.state.IdOwnerBusinessPack,
          colleague: this.CreateColleagueArrayForRequest(),
          VariablePrice: this.state.VariablePrice,
          language: "fr",
          EndDate: this._ConstructEndDate(),
          SendMail: this.state.SendMail
        }
        var promise = await Requestdynamic('_createBusinessPackCustomize', Params);
        //var promise = {error: true, data: {error: false, data: null}}
        if (promise.error === false && promise.data.error === false){
            this.executeSuccesAlert()
            return ;
        } else {
          if (promise.error === false){
            await this.GestionError(promise.data)
            return ;
          }
          this.executeErrorAlert()
          return ;
        }
        this.setState({loadingBusinessPack: false})
      })
    }

    _splitTva = (Tva) => {
      if (!Tva)
        return ({CountryTva: "", TvaSearch: ""})
      var TvaClean = Tva.trim().replaceAll(' ', '')
      var CountryTva = TvaClean.substring(0, 2)

      var TvaSearch = TvaClean.slice(2)
      return ({CountryTva: CountryTva, TvaSearch: TvaSearch})
    }

    _splitEndDate = (EndDate) => {
      if (!EndDate){
        var CurrentDate = GetCurrentDateEuropeParis('YYYY/MM/DD')
        var DateMoreOneYears = AddTimeFormat(1, 'YYYY/MM/DD', CurrentDate, "years")
        var SplitDate = DateMoreOneYears.split('/')
        return ({Month: SplitDate[1],
        Days: SplitDate[2],
        Years: SplitDate[0]})
      }
      try {
        var SplitDate = EndDate.split('/')
        return ({Days: SplitDate[2], Month: SplitDate[1], Years: SplitDate[0]})
      } catch (e) {
        var CurrentDate = GetCurrentDateEuropeParis('YYYY/MM/DD')
        var DateMoreOneYears = AddTimeFormat(1, 'YYYY/MM/DD', CurrentDate, "years")
        var SplitDate = DateMoreOneYears.split('/')
        return ({Month: SplitDate[1],
        Days: SplitDate[2],
        Years: SplitDate[0]})
      }
    }

    CheckEmailOwner = async (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (this.state.EmailOwner.length === 0){
        this.setState({EmailOwnerFormValidated: true})
        return;
      }
      this.setState({loadingEmailOwner: true, EmailOwnerFormValidated: false, messageEmailOwner: null}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Email: this.state.EmailOwner
        }
        var promise = await Requestdynamic('_searchMemberAttach', Params);

        if (promise.error === false && promise.data.error === false){
          if (promise.data.data != null){
            var InfoOwner = promise.data.data;
            if (InfoOwner.RegistrationType != 'SingleA' && InfoOwner.RegistrationType != 'SingleM'){
              let RegistrationType = InfoOwner.RegistrationType != null ? InfoOwner.RegistrationType : InfoOwner.MemberStatus
              let messageEmailOwner = "Cette adresse mail est déjà associé à un compte de type "+ RegistrationType +""
              this.setState({IdOwnerBusinessPack: InfoOwner.Id,EmailOwnerValidated: true, EmailOwnerFormValidated: true, loadingEmailOwner: false, messageEmailOwner: messageEmailOwner})
              return ;
            }
            var Info = cookies.get('infoAdmin')
            if (Info && Info.Region){
              var indexRegion = Info.Region.findIndex(res => res === InfoOwner.Region)
              if (indexRegion === -1)
              {
                let messageEmailOwner = "Cette adresse mail est associé à une Région B19 où vous n'avez pas d'accès"
                this.setState({IdOwnerBusinessPack: InfoOwner.Id, EmailOwnerValidated: true, EmailOwnerFormValidated: true, loadingEmailOwner: false, messageEmailOwner: messageEmailOwner})
                return ;
              }
            }
            var {TvaSearch, CountryTva} = this._splitTva(InfoOwner.UserInformation.Society.Tva ? InfoOwner.UserInformation.Society.Tva : InfoOwner.UserInformation.Facturation.TVA ? InfoOwner.UserInformation.Facturation.TVA : "")
            var {Month, Years, Days} = this._splitEndDate(InfoOwner.UserInformation.Subscription.End)
            var paramsSetState = {
              Month: Month,
              Years: Years,
              Days: Days,
              CountryTva: CountryTva,
              TvaSearch: TvaSearch.replaceAll('.', '').replaceAll(' ', ''),
              FirstName: InfoOwner.UserInformation.Name.First,
              LastName: InfoOwner.UserInformation.Name.Last,
              Tel: InfoOwner.UserInformation.Tel.Private,
              Tva: InfoOwner.UserInformation.Society.Tva,
              SocietyName: InfoOwner.UserInformation.Society.Name,
              StreetCity: InfoOwner.UserInformation.Adress.Pro.Street,
              PostalCode: InfoOwner.UserInformation.Adress.Pro.ZipCode,
              City: InfoOwner.UserInformation.Adress.Pro.City,
              TvaFacturation: InfoOwner.UserInformation.Facturation.TVA ? InfoOwner.UserInformation.Facturation.TVA : InfoOwner.UserInformation.Society.Tva,
              SocietyNameFacturation: InfoOwner.UserInformation.Facturation.Society ? InfoOwner.UserInformation.Facturation.Society : InfoOwner.UserInformation.Society.Name,
              StreetCityFacturation: InfoOwner.UserInformation.Facturation.StreetWN ? InfoOwner.UserInformation.Facturation.StreetWN : InfoOwner.UserInformation.Adress.Pro.Street,
              PostalCodeFacturation: InfoOwner.UserInformation.Facturation.ZipCode ? InfoOwner.UserInformation.Facturation.ZipCode : InfoOwner.UserInformation.Adress.Pro.ZipCode,
              CityFacturation: InfoOwner.UserInformation.Facturation.City ? InfoOwner.UserInformation.Facturation.City : InfoOwner.UserInformation.Adress.Pro.City,
              NumberFacturation: InfoOwner.UserInformation.Facturation.Number ? InfoOwner.UserInformation.Facturation.Number : "",
              Region: InfoOwner.Region,
              IdOwnerBusinessPack: InfoOwner.Id,
              loadingEmailOwner: false,
              EmailOwnerValidated: true,
              EmailFacturation: InfoOwner.UserInformation.Facturation.Email ? InfoOwner.UserInformation.Facturation.Email : this.state.EmailOwner,
            }
            this.setState(paramsSetState)
            return ;
          }
          var CurrentDate = GetCurrentDateEuropeParis('YYYY/MM/DD')
          var DateMoreOneYears = AddTimeFormat(1, 'YYYY/MM/DD', CurrentDate, "years")
          var SplitDate = DateMoreOneYears.split('/')
          this.setState({
            Month: SplitDate[1],
            Days: SplitDate[2],
            Years: SplitDate[0],
          })
        }
        this.setState({loadingEmailOwner: false, EmailOwnerValidated: true, EmailOwnerFormValidated: true, IdOwnerBusinessPack: null})
      })
    }

    componentDidMount(){

    }

    componentWillUnmount(){
      clearTimeout(this.refTimeOut)
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    executeSuccesAlert = (ms) => {
      this.refreshState()
      this.setState({openModal: true, messageAlert: "Le BusinessPack à bien été créé.", colorAlert: '#C6DCA0'})
      this._excuteScroll("Header")
      this.refTimeOut = setTimeout(() => {
        this.setState({openModal: false})
      }, 4000)
   }

   executeErrorAlert = (ms) => {
     this.setState({loadingBusinessPack: false, openModal: true, messageAlert: "Une erreur est survenue, veuillez contacter le staff", colorAlert: '#ff7878'})
     this._excuteScroll("Header")
     this.refTimeOut = setTimeout(() => {
       this.setState({openModal: false})
     }, 4000)
  }


 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto", overflowX: "hidden"}}>
      <Header refParent={this} intl={this.props.intl} />
      <AlertSuccess refParent={this} intl={this.props.intl}/>
      <OwnerSection refParent={this} intl={this.props.intl}/>

      <SocietySection refParent={this} intl={this.props.intl}/>
      <FacturationSection refParent={this} intl={this.props.intl}/>
      <ColleagueSection refParent={this} intl={this.props.intl}/>
      <Container>
        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20}}>

        <Button
        as={Col}
        xl={5}
        lg={5}
        sm={5}
        md={5}
        onClick={() => this.CreateBusinessPack()}
        style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', marginTop: 10, adding: 8,  border: "none"}}
        >
        {
          this.state.loadingBusinessPack === true ?
          <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
              <CircularProgress color="inherit" size={20}/>
            </div>
            <div>
              <p style={{margin: 0, color: 'transparent'}}>Créer le Business Pack</p>
            </div>
          </div>
          :
          <p style={{margin: 0}}>Créer le Business Pack</p>
        }
        </Button>

        </Row>
      </Container>

    </div>
   )
 }
}


export default BusinessCustomize;
