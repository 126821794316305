import React from 'react';
import IsConnectedAndAccess from '../../IsConnected/IsConnectedAndAccess'
import NewMember from './newMember'

class ParselNewMemberLuik extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={true} Access={"luikMember"} Component={NewMember} {...props}/>
   )
 }
}



export default ParselNewMemberLuik;
