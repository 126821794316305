import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SeparateLine from './SeparateLine'
import {CssTextField} from './Style'



class Date extends React.Component {


     createHours = (start, Hours) => {
       var CreateOptions = [];

       for(var i = start; i <= Hours; i++){
         CreateOptions.push((i).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}))
       }
       return CreateOptions.map(e => (
         <option key={e} value={e}>{e}</option>
       ))
     }

     createMinutes = (start, end) => {
       var CreateOptions = [];

       for(var i = start; i <= end; i += 10){

         CreateOptions.push((i).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}))
       }
       return CreateOptions.map(e => (
         <option key={e} value={e}>{e}</option>
       ))
     }

 render() {

   return (
     <>
       <Row style={{}}>
         <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Date</h3>
            </div>
         </Col>
       </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <p style={{fontSize: 15, color: this.props.Error  && this.props.Error.Date ? "red" : '#808080'}}>Date (jj/mm/aaaa)</p>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <CssTextField value={this.props.Day ? this.props.Day : ''} label="jj" variant="outlined" onChange={(event) => this.props.refParent.setState({Day: event.target.value})} style={{marginRight: 5}}/>
                <CssTextField value={this.props.Month ? this.props.Month : ''} label="mm" variant="outlined" onChange={(event) => this.props.refParent.setState({Month: event.target.value})} style={{marginRight: 5}}/>
                <CssTextField value={this.props.Year ? this.props.Year : ''} label="aaa" variant="outlined" onChange={(event) => this.props.refParent.setState({Year: event.target.value})}/>
              </div>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Date ? 'block' : 'none', marginTop: 5}}>Vérifier le format de la date, la date ne peut être antérieur à aujourd'hui</p>
           </div>
         </Col>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <p style={{fontSize: 15, color: this.props.Error  && this.props.Error.Hours ? "red" : '#808080'}}>Heure de début</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <CssTextField value={this.props.BeginHours} select onChange={(event) => this.props.refParent.setState({BeginHours: event.target.value})} label="Heure" variant="outlined" SelectProps={{native: true}}>
                <option key={0} value={"PlaceHolder"} disabled>{"Heure"}</option>
                {this.createHours(6, 22)}
              </CssTextField>
              <p style={{margin: 2}}>:</p>
              <CssTextField value={this.props.BeginMinutes}  select label="Minute" variant="outlined" onChange={(event) => this.props.refParent.setState({BeginMinutes: event.target.value})} SelectProps={{native: true}}>
                <option key={0} value={"PlaceHolder"} disabled>{"Minute"}</option>
                {this.createMinutes(0, 50)}
              </CssTextField>
              </div>
           </div>
         </Col>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <p style={{fontSize: 15, color: this.props.Error  && this.props.Error.Hours ? "red" : '#808080'}}>Heure de fin</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
              <CssTextField value={this.props.EndHours} select label="Heure" variant="outlined" SelectProps={{native: true}} onChange={(event) => this.props.refParent.setState({EndHours: event.target.value})}>
                <option key={0} value={"PlaceHolder"} disabled>{"Heure"}</option>
                {this.createHours(6, 22)}
              </CssTextField>
              <p style={{margin: 2}}>:</p>
              <CssTextField value={this.props.EndMinutes} select label="Minute" variant="outlined" SelectProps={{native: true}} onChange={(event) => this.props.refParent.setState({EndMinutes: event.target.value})}>
                <option key={0} value={"PlaceHolder"} disabled>{"Minute"}</option>
                {this.createMinutes(0, 50)}
              </CssTextField>
              </div>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Hours ? 'block' : 'none', marginTop: 5}}>L'heure de départ ne correspond avec l'heure de fin</p>
           </div>
         </Col>
         </Row>

         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />


     </>
   )
 }
}


export default Date;
