import React from 'react';
import { RiMapPin2Fill } from "react-icons/ri";
import { ImPhone } from "react-icons/im";
import { IoMail, IoCalendar } from "react-icons/io5";
import { BiCommentDetail } from "react-icons/bi";
import Col from 'react-bootstrap/Col';

const ICONSIZE = 20;
const TEXTSIZE = "0.85em";


class Private extends React.Component {

  ConstructStringWithComma = (ArrayString) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(', '))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value) => {
    if (this.props.Private && this.props.Private[value] && this.props.Private[value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE

    })
  }

  EmptyStreet = () => {
    if (this.props.Private && this.props.Private.StreetPrivate && this.props.Private.CityPrivate && this.props.Private.ZipCodePrivate
    && this.props.Private.StreetPrivate.length !== 0 && this.props.Private.CityPrivate.length && this.props.Private.ZipCodePrivate.length)
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
    else
      return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE
    })

  }

  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={4} lg={4} md={12}>

          <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS PERSONNELLES</p>

          <div style={{}}>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <RiMapPin2Fill size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.EmptyStreet()}>{this.props.Private ? this.ConstructStringWithComma([this.props.Private.StreetPrivate, this.props.Private.ZipCodePrivate, this.props.Private.CityPrivate]) :  ""}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <ImPhone size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("TelPrivate")}>{this.props.Private && this.props.Private.TelPrivate ? this.props.Private.TelPrivate : "Vide"}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <IoMail size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("EmailPrivate")}>{this.props.Private && this.props.Private.EmailPrivate ? this.props.Private.EmailPrivate : "Vide"}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <IoCalendar size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Date")}>{this.props.Private && this.props.Private.Date  ? this.props.Private.Date : "Vide"}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <BiCommentDetail size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Commentary")}>{this.props.Private && this.props.Private.Commentary  ? this.props.Private.Commentary : "Vide"}</p>

            </div>
          </div>

          </div>
          </Col>
    )
  }
}

export default Private;
