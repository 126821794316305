import React, {useEffect, useState} from 'react';
import {Doughnut, Bar} from './ChartJs/export'

const RouteChart = ({info}) => {
  const integrateChart = [{Name: 'Doughnut', Component: Doughnut}, {Name: 'Bar', Component: Bar}]
  const index = integrateChart.findIndex(res => res.Name == info.ChartName)
  let Component = null
  if (index != -1)
     Component = integrateChart[index].Component
  return (
    <div>
      {
        index == -1 ? <div></div> : <Component info={info}/>
      }
    </div>
  )
}

{/*Le component devrais recevoir les noms des different event associer pour pouvoir mettre a jour correctement ainsi que les different filtre activer
  il faut encore recevoir les data pour deja prevoir la page de creation de graphique. le but etant de pouvoir dire aue si data est null alors on doit avoir recour aux socket pour pouvoir recuperer et peut etre instaurer du real time a l'inverse
  si data n'est pas nul nous devons donc pas mettre a jour ne devons juste aller chercher les data en question avec les filtre en question il faut bien reflechir*/}

export default RouteChart
