import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import Modify from './ModifyMember'
import HeaderGoBack from './HeaderGoBack'
import PopUp from './PopUp'
import Header from './Header'
import LoadingInfo from './LoadingInfo'
import {CheckFormatDate, CheckIfDateIsAfter, GetCurrentDateEuropeParis} from '../../Moment/Moment'
import UpdateModal from './ModalUpdateProfil'

class AllMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        IdMember: null,
        modify: false,
        Error: null,
        updateProfil: false,
        StatusUpdate: null,
        Picture: null
      }
    }

    GetSpecificMember = async () => {
      await this.sleep(500)

      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        Id: this.state.IdMember,
        SizeImage: "Small"
      }
      var promise = await Requestdynamic('_getSpecificMember', Params);
      if (promise.error === false && promise.data.error === false)
      {
        var Data = promise.data.data
        if (!Data.MemberStatus || Data.MemberStatus !== 'Member'){
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Information incorrect', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
          return ;
        }
        try {
          var Keys = Object.keys(Data);
          var paramsSetState = {loading: false}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = Data[Keys[i]]
          }
          this.setState(paramsSetState)
        } catch (e) {
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
        }
      }
      else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              IdMember: this.props.navigation.match.params.memberid,
            }, async () => this.GetSpecificMember())
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      var paramsNavigate = {pathname: `/details_member/${this.state.IdMember}`}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
        this.props.navigation.history.push(paramsNavigate);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    _CheckPrivateInfo = (Error) => {
      try {
        var error = false;
        var ErrorPrivate = {}

        if (!this.state.FirstName || this.state.FirstName.length < 2)
        {
          error = true
          ErrorPrivate.FirstName = true
        }
        if (!this.state.LastName || this.state.LastName.length < 2)
        {
          error = true
          ErrorPrivate.LastName = true
        }
        /*if (!this.state.Private.TelPrivate || this.state.Private.TelPrivate.length === 0){
          error = true
          ErrorPrivate.Tel = true
        }*/
        if (!this.state.Private.ZipCodePrivate || this.state.Private.ZipCodePrivate.length === 0){
          error = true
          ErrorPrivate.ZipCode = true
        }
        if (!this.state.Private.CityPrivate || this.state.Private.CityPrivate.length === 0){
          error = true
          ErrorPrivate.City = true
        }
        if (!this.state.Private.StreetPrivate || this.state.Private.StreetPrivate.length === 0){
          error = true
          ErrorPrivate.Street = true
        }
        if (this.state.Complement && this.state.Complement.RegistrationType && this.state.Complement.RegistrationType === 'Young'){
          if (!this.state.Private.Date || this.state.Private.Date.length === 0 || !CheckFormatDate(this.state.Private.Date, "DD/MM/YYYY")){
            error = true
            ErrorPrivate.Date = true
          }
          var Now = GetCurrentDateEuropeParis("DD/MM/YYYY")
          if (!CheckIfDateIsAfter(this.state.Private.Date, Now, "DD/MM/YYYY")){
            ErrorPrivate.Date = true;
            error = true
          }
        }
        if (error === true)
          Error.Private = ErrorPrivate
      } catch (e) {
        ErrorPrivate.FirstName = true
        ErrorPrivate.LastName = true
        ErrorPrivate.Tel = true
        ErrorPrivate.ZipCode = true
        ErrorPrivate.City = true
        ErrorPrivate.Street = true
        ErrorPrivate.Date = true
        Error.Private = ErrorPrivate
      }

    }

    _CheckProInfo = (Error) => {
      try {
        var error = false;
        var ErrorPro = {}

        if (this.state.Complement && this.state.Complement.RegistrationType && this.state.Complement.RegistrationType !== 'Young'){
          if (!this.state.Society.Name || this.state.Society.Name === 0)
          {
            error = true
            ErrorPro.Society = true
          }
          if (!this.state.Society.Tva || this.state.Society.Tva.length === 0){
            error = true
            ErrorPro.Tva = true
          }
          if (error === true)
            Error.Pro = ErrorPro
        }
      } catch (e) {
        ErrorPro.Society = true
        ErrorPro.Tva = true
        Error.Pro = ErrorPro
      }

    }

    _CheckSubscribeInfo = (Error) => {
      try {
        var error = false;
        var ErrorSubscribe = {}

        if (!this.state.Complement.Region || this.state.Complement.Region.length === 0)
        {
          error = true
          ErrorSubscribe.Region = true
        }
        if (this.state.hasOwnProperty('Accept') && this.state.Accept === true && this.state.FreeStatus === false){
          if (!this.state.Complement.SubscriptionEnd || this.state.Complement.SubscriptionEnd.length === 0){
            error = true
            ErrorSubscribe.SubscriptionEnd = true
          }
          if (this.state.Complement.SubscriptionEnd && this.state.Complement.SubscriptionEnd.length !== 0){
            var DateSplit = this.state.Complement.SubscriptionEnd.split(' ')[0];
            if (!CheckFormatDate(DateSplit, 'DD/MM/YYYY'))
            {
              error = true
              ErrorSubscribe.SubscriptionEnd = true
            }
          }
        }
        if (error === true)
          Error.Subscribe = ErrorSubscribe
      } catch (e) {
        ErrorSubscribe.Region = true
        ErrorSubscribe.SubscriptionEnd = true
        Error.Subscribe = ErrorSubscribe
      }

    }

    _CheckComplementInfo = (Error) => {
      try {
        var error = false;
        var ErrorComplement = {}
        if (this.state.Society.Date && this.state.Society.Date.length !== 0 && this.state.Society.Date !== 'null/null/null' && !CheckFormatDate(this.state.Society.Date, 'DD/MM/YYYY')){
          error = true
          ErrorComplement.Date = true
        }
        if (error === true)
          Error.Complement = ErrorComplement
      } catch (e) {
        return ;
      }

    }

_fillParams = (ObjectName, target) => {
  if (!this.state[ObjectName] || !this.state[ObjectName][target])
    return null
  return (this.state[ObjectName][target])
}

_fillParamsDate = (ObjectName, target, index) => {
  try {
    if (!this.state[ObjectName] || !this.state[ObjectName][target])
      return null
    var DateSplit = this.state[ObjectName][target].split(' ')[0]
    if (DateSplit ===  "null/null/null")
      return null;
    return DateSplit.split('/')[index]
  } catch (e) {
    return (null)
  }
}

  _updateMember = async () => {
    this.setState({Error: null}, async () => {
      var Error = {}

      this._CheckPrivateInfo(Error)
      this._CheckProInfo(Error)
      this._CheckComplementInfo(Error)
      this._CheckSubscribeInfo(Error)

      var KeysObjectError = Object.keys(Error).length;
      if (KeysObjectError !== 0)
      {
        this.setState({Error: Error})
        return ;
      }
      this.setState({updateProfil: true, StatusUpdate: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: this.state.IdMember,
          RegistrationType: this._fillParams("Complement", "RegistrationType"),
          Image: this.state.Picture,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          FreeStatus: this.state.FreeStatus === false ? "false": "true",
          ZipCodePrivate: this._fillParams("Private", "ZipCodePrivate"),
          CityPrivate: this._fillParams("Private", "CityPrivate"),
          StreetPrivate: this._fillParams("Private", "StreetPrivate"),
          Tel: this._fillParams("Private", "TelPrivate"),
          Day: this._fillParamsDate('Private', "Date", 0),
          Mounth: this._fillParamsDate('Private', "Date", 1),
          Year: this._fillParamsDate('Private', "Date", 2),
          Commentary: this._fillParams("Private", "Commentary"),
          DayEnd: this._fillParamsDate('Complement', "SubscriptionEnd", 0),
          MounthEnd: this._fillParamsDate('Complement', "SubscriptionEnd", 1),
          YearEnd: this._fillParamsDate('Complement', "SubscriptionEnd", 2),
          Region: this._fillParams("Complement", "Region"),
          Society: this._fillParams("Society", "Name"),
          Tva: this._fillParams("Society", "Tva"),
          Status: this._fillParams("Society", "Status"),
          Sector: this._fillParams("Society", "Sector"),
          ZipCodePro: this._fillParams("Pro", "ZipCodePro"),
          CityPro: this._fillParams("Pro", "CityPro"),
          StreetPro: this._fillParams("Pro", "StreetPro"),
          TelPro: this._fillParams("Pro", "TelPro"),
          EmailPro: this._fillParams("Pro", "EmailPro"),
          DayPro: this._fillParamsDate('Society', "Date", 0),
          MounthPro: this._fillParamsDate('Society', "Date", 1),
          YearPro: this._fillParamsDate('Society', "Date", 2),
          Localisation: this._fillParams("Society", "Localisation"),
          SizeOfSociety: this._fillParams("Society", "SizeOfSociety"),
          Accept: this.state.Accept,
          CommentaryAdmin: this._fillParams("Complement", "CommentaryAdmin"),
          FacturationSociety: this._fillParams("Facturation", "Society"),
          FacturationStreet: this._fillParams("Facturation", "StreetWN"),
          FacturationZipCode: this._fillParams("Facturation", "ZipCode"),
          FacturationCity: this._fillParams("Facturation", "City"),
          FacturationTva: this._fillParams("Facturation", "TVA"),
          FacturationNumber: this._fillParams("Facturation", "Number"),
          FacturationMoreInfo: this._fillParams("Facturation", "MoreInfoFacturation"),
          TypeContactFacturation: this.state.TypeContactFacturation,
          FacturationEmail: this._fillParams("Facturation", "Email"),
          Sponsor: this._fillParams("Complement", "Sponsor"),
        }
        var promise = await Requestdynamic('_updateProfilMember', Params);
        console.log(promise)
        if (promise.error === false && promise.data.error === false)
        {
          this.setState({StatusUpdate: "Success", message: "Les informations ont bien été mis à jour"})
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})
          return ;
        }
      })
    })
  }

 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <UpdateModal refParent={this} openModal={this.state.updateProfil} statusRequest={this.state.StatusUpdate} message={this.state.message}/>
      <PopUp redirect={this.state.redirect} refParent={this} openModal={this.state.openModal} message={this.state.message} title={this.state.title} />
      <LoadingInfo openModal={this.state.loading} />
      <HeaderGoBack refParent={this}/>
      <Header refParent={this} intl={this.props.intl}/>
      <Modify Error={this.state.Error} refParent={this} intl={this.props.intl}/>
    </div>
   )
 }
}


export default AllMember;
