import React from 'react';
import Button from '@material-ui/core/Button';
import Col from 'react-bootstrap/Col';
import { MdDeleteForever } from "react-icons/md";
import { LazyLoadImage } from 'react-lazy-load-image-component';



class ItemPhoto extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        delete: false,
      }
    }



  render() {
    return (
      <Col sm={6} lg={2} xl={2} md={4}  id={this.props.image.Key} draggable={this.props.edit}  onDragOver={(ev) => ev.preventDefault()} onDragStart={(ev) => this.props.handleDrag(ev)} onDrop={(ev) => this.props.handleDrop(ev)}>

        <div draggable={this.props.edit} style={{width: '100%', height: 'auto',margin: 10, padding: 5, position: 'relative'}}>
          <div draggable={this.props.edit} style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, padding: 5, display: this.props.edit ? "block" : 'none'}}>
            <div draggable={this.props.edit} style={{display: 'flex', width: "100%", justifyContent: 'center', alignItems: 'flex-end', height: '100%'}}>
              <div draggable={this.props.edit} style={{height: this.state.delete ? '100%' : '', display: 'flex', width: '100%', backgroundColor: 'rgb(8, 36, 48, 0.8)', justifyContent: 'center', borderBottomLeftRadius: 5, borderBottomRightRadius: 5, alignItems: 'center'}}>
                {
                  this.state.delete ?
                  <>
                  <Button style={{textAlign: 'center'}} onClick={() => this.setState({delete: false})}>
                    <p style={{color: 'red', fontSize: '0.7rem', fontWeight: 'bold'}}>Annuler</p>
                  </Button>
                  <Button onClick={() => {this.props.refParent.DeleteImage(this.props.image); this.setState({delete: false})}}>
                    <p style={{color: 'green', fontSize: '0.7rem', fontWeight: 'bold'}}>Confirmer</p>
                  </Button>
                  </>
                  :
                  <Button onClick={() => this.setState({delete: true})}>
                    <MdDeleteForever color={"white"} size={"1.5rem"}/>
                  </Button>
                }
              </div>

            </div>
          </div>
          <LazyLoadImage
          draggable={this.props.edit}
          width={'100%'}
          style={{borderRadius: 5}}
          src={this.props.image.Url} effect="blur" />

        </div>

      </Col>
    )
  }

}


export default ItemPhoto;
