import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic, RequestFileUpload} from '../../Network/Fetch';
import PopUp from './PopUp'
import Cookies from 'universal-cookie'
import Header from './Header'
import ModalInvoiceUser from './ModalInvoice'
import ExcelFile from './ExportExcelFile'

class NewMemberStripe extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        member: [],
        memberDisplay: [],
        searchMember: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        search: null,
        Filter: [],
        MemberInvoice: null,
        send: false,
        finaliser: false,
        Error: null,
        Invoice: null,
        statusInvoice: null,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: "up",
          AccountCreatedAt: "up",
          Society: "up",
          BeginDate: "up",
          EndDate: "up",
          RegistrationType: "up",
          Region: "up",
        }
      }
    }

    UploadInvoice = async (member) => {
      if (this.state.finaliser === true)
      {
        this.setState({statusInvoice: "Waiting", Error: null}, async () => {
          var Error = {}
          if (!this.state.Invoice || this.state.Invoice.type !== "application/pdf")
          {
            Error.File = true
            this.setState({Error: Error, statusInvoice: "Error"})
            return ;
          }
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {EndAdhesionUpload: "false", Jwt: Jwt, Id: this.state.MemberInvoice.Id, Send: this.state.send, file: this.state.Invoice, PaymentLink: false}
          var promise = await RequestFileUpload('_UploadInvoiceNewStripeMember', Params)
          if (promise.error === false && promise.data.error === false){
              var Copy = [...this.state.member]
              var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              Copy.splice(IndexCopy, 1)
              var CopyDisplay = [...this.state.memberDisplay]
              var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              CopyDisplay.splice(IndexCopyDisplay, 1)
              await this.sleep(500)
              this.setState({statusInvoice: "Success", member: Copy, memberDisplay: CopyDisplay})
          }
          else {
            if (promise.error === true)
              this.setState({statusInvoice: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusInvoice: "Error"})
          }
        })
      }
      else {
        this.setState({finaliser: true, statusInvoice: "Ask", MemberInvoice: member})
        return ;
      }

    }

    _sortByAlphabet = (Name) => {
      var Copy = [...this.state.member];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
           a[Name] = ""
        if (!b[Name])
          b[Name] = ""
         var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({memberDisplay: Display, member: SortedResult, sortFilter: sortOrder})
    }

    _getAllNextNewMemberStripe = async () => {
      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
        }
        if (this.state.Filter.length !== 0)
          Params.RegionFrontend = this.state.Filter
        var promise = await Requestdynamic('_getAllNextNewMemberStripe', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var MemberCopy = [...this.state.member];
          var newListMember = MemberCopy.concat(promise.data.data)
          newListMember = [...new Set(newListMember)]
          var MemberDisplay = newListMember.slice(0, 20)
          var paramsSetState = {memberDisplay: MemberDisplay, member: newListMember, MemberDelete: null, statusDelete: null, delete: false}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
        }
        this.setState({loadingMore: false})
      })

    }

    _addAllNextNewStripeMemberMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy;
        if (this.state.search != null)
           Copy = [...this.state.searchMember]
        else
           Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    search = (value) => {
      var Copy, Display
      if (value.length === 0)
      {
         Copy = [...this.state.member];
         Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
      }
       Copy = [...this.state.member]
       var FilterResult = Copy.filter(res => {
         if (res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
           return true
         else if (res.Society && res.Society.toLowerCase().includes(value.toLowerCase()))
           return true
         return false
       })
       Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }

    componentDidMount(){
      this._getAllNextNewMemberStripe()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleScroll = (e) => {
      var verify;
      if (this.state.search === null)
         verify = 'member'
      else
         verify = 'searchMember'

      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
        this._addAllNextNewStripeMemberMember()
      }
    }

 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <ModalInvoiceUser  Error={this.state.Error} intl={this.props.intl} refParent={this} send={this.state.send} statusInvoice={this.state.statusInvoice} openModal={this.state.finaliser} MemberInvoice={this.state.MemberInvoice}/>
     <Header Member={this.state.member} Filter={this.state.Filter} intl={this.props.intl} refParent={this}/>
     <ExcelFile data={this.state.member}/>
     <Table memberDisplay={this.state.memberDisplay} loadingMore={this.state.loadingMore} intl={this.props.intl} refParent={this} deleting={this.state.deleting} member={this.state.member}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default NewMemberStripe;
