const _updateEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateEvent",
    body : Params
  }
  return params
}

const _putParticipantEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/PutParticipantEvent",
    body : Params
  }
  return params
}


const _deleteParticipantEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteParticipantEvent",
    body : Params
  }
  return params
}

const _validateAccount_Token = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ValidateAccount_Token",
    body : Params
  }
  return params
}

const _validatePaymentEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ValidateParticipantEvent",
    body : Params
  }
  return params
}


const _deleteEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteEvent",
    body : Params
  }
  return params
}

const _deleteAmbassador = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteAmbassador",
    body : Params
  }
  return params
}

const _updateAmbassador = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateAmbassador",
    body : Params
  }
  return params
}

const _deleteSponsor = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteSponsor",
    body : Params
  }
  return params
}

const _updateSponsor = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateSponsor",
    body : Params
  }
  return params
}

const _getAllNextGallery = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetAllGallery",
    body : Params.Body
  }
  return params
}

const _createUploadIdImage = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/CreatePipeUploadImages",
    body : Params
  }
  return params
}

const _endUploadIdImage = (Params) => {

  var params;
  params = {
    method : "POST",
    Route : "/EndPipeUploadImages",
    body : Params
  }
  return params
}

const _deleteGallery = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteGallery",
    body : Params
  }
  return params
}

const _updateImagesInfo = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateImagesInfoGallery",
    body : Params
  }
  return params
}

const _editGallery = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateGalleryInfo",
    body : Params
  }
  return params
}

const _login = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/login",
    body : Params
  }
  return params
}

const _validateAccount_password = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ValidateAccount_CreatePassword",
    body : Params
  }
  return params
}


const _validateEmail_ForgetPassword = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ForgetPassword/ValidateEmail",
    body : Params
  }
  return params
}

const _recoveryToken_ForgetPassword = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ForgetPassword/ValidateRecoverToken",
    body : Params
  }
  return params
}

const _newPassword_ForgetPassword = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ForgetPassword/CreateNewPassword",
    body : Params
  }
  return params
}

const _deleteAdmin = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteAdmin",
    body : Params
  }
  return params
}

const _updateAdmin = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateAdmin",
    body : Params
  }
  return params
}


const _deleteLocation = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteLocation",
    body : Params
  }
  return params
}

const _updateLocation = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateLocation",
    body : Params
  }
  return params
}

const _getAllNextMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetMember",
    body : Params
  }
  return params
}

const _getAllNextDeletedMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetDeletedMember",
    body : Params
  }
  return params
}

const _getSpecificMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetSpecifyMember",
    body : Params
  }
  return params
}

const _getSpecificDeletedMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetSpecifyDeletedMember",
    body : Params
  }
  return params
}

const _updateProfilMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateMember",
    body : Params
  }
  return params
}

const _blockedMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateBlockMember",
    body : Params
  }
  return params
}

const _deleteMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteMember",
    body : Params
  }
  return params
}

const _getAllNextWaitingMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetPendingMember",
    body : Params
  }
  return params
}

const _acceptRefuseMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateSubscription",
    body : Params
  }
  return params
}

const _getSubscriptionStat = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetNumberOfSubscription",
    body : Params
  }
  return params
}

const _getAllNextEndMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetEndSubscription",
    body : Params
  }
  return params
}

const _getAllNextNewMemberStripe = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetFirstFacturation",
    body : Params
  }
  return params
}

const _UploadInvoiceNewStripeMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateFacturation",
    body : Params
  }
  return params
}

const _getAllNextNoneMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetNonMember",
    body : Params
  }
  return params
}


const _updateNoneMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateNonMember",
    body : Params
  }
  return params
}

const _deleteNonMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeleteNonMember",
    body : Params
  }
  return params
}

const _updateEmailMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateEmailMember",
    body : Params
  }
  return params
}


const _DownloadInvoiceFile = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetSignedUrlInvoice",
    body : Params
  }
  return params
}

const _searchPassedEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/SearchIntoAllPassedEvent",
    body : Params
  }
  return params
}

const _getAllParticipantSubscribeInvoice = (Params) => {
  var params;

  params = {
    Route: '/GetAllParticipantSubscribeInvoice',
    body : Params,
    method : "POST",
  }
  return params
}

const _sendInvoiceEvent = (Params) => {
  var params;

  params = {
    Route: '/SendInvoiceEvent',
    body : Params,
    method : "POST",
  }
  return params
}

const _removeColleague = (Params) => {
  var params;

  params = {
    Route: '/RemoveColleague',
    body : Params,
    method : "POST",
  }
  return params
}

const _addColleague = (Params) => {
  var params;

  params = {
    Route: '/AttachMemberBusiness',
    body : Params,
    method : "POST",
  }
  return params
}

const _ownerChanger = (Params) => {
  var params;

  params = {
    Route: '/ChangeOwnerBusiness',
    body : Params,
    method : "POST",
  }
  return params
}

const _transfertDeleteToMember = (Params) => {
  var params;

  params = {
    Route: '/TransfertDeleteToMember',
    body : Params,
    method : "POST",
  }
  return params
}

const _searchMemberAttach = (Params) => {
  var params;

  params = {
    Route: '/SearchMemberAttach',
    body : Params,
    method : "POST",
  }
  return params
}

const _searchTva = (Params) => {
  var params;

  params = {
    Route: '/GetVat',
    body : Params,
    method : "POST",
  }
  return params
}

const _createBusinessPackCustomize = (Params)  => {
  var params;

  params = {
    Route: '/CreateBusinessPackCustomize',
    body : Params,
    method : "POST",
  }
  return params
}

const _FreeMember = (Params) => {
  var params;

  params = {
    Route: '/UpdateFreeStatus',
    body : Params,
    method : "POST",
  }
  return params
}

const _changeTypeOfBusiness = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ChangeTypeOfBusiness",
    body : Params
  }
  return params
}

const _changePriceOfBusiness = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ChangePriceOfBusiness",
    body : Params
  }
  return params
}

const _variablePrice = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateVariablePrice",
    body : Params
  }
  return params
}

const _changeTypeContactFacturation = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/ChangeTypeContactFacturation",
    body : Params
  }
  return params
}

const _getNewMemberDelca = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetNewMemberDelca",
    body : Params
  }
  return params
}

const _getEndMemberDelca = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetEndMemberDelca",
    body : Params
  }
  return params
}

const _getEndBusinessCustomDelca = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetEndBusinessCustomDelca",
    body : Params
  }
  return params
}


const _getFacturationPdfDelca = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetFacturationPdfDelca",
    body : Params
  }
  return params
}

const _verifyPayment = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/VerifyStatusFacturation",
    body : Params
  }
  return params
}

const _verifyEndPayment = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/VerifyStatusEndFacturation",
    body : Params
  }
  return params
}



const _acceptNewMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/AcceptNewMember",
    body : Params
  }
  return params
}

const _acceptEndMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/AcceptEndMember",
    body : Params
  }
  return params
}

const _refuseNewMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/RefuseNewMember",
    body : Params
  }
  return params
}

const _sendMailRemindNewMember = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/SendMailRemindNewMember",
    body : Params
  }
  return params
}

const _getAllPromotion = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetAllPromotion",
    body : Params
  }
  return params
}

const _putNewPromotion = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/PutNewPromotion",
    body : Params
  }
  return params
}

const _updateStatusOnlinePromotion = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdateStatusOnlinePromotion",
    body : Params
  }
  return params
}

const _deletePromotion = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/DeletePromotion",
    body : Params
  }
  return params
}

const _updatePromotion = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/UpdatePromotion",
    body : Params
  }
  return params
}

const _sendMailEndBusinessCustom = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/SendMailEndSubscriptionBusinessCustom",
    body : Params
  }
  return params
}



module.exports = {
  _updateEvent,
  _putParticipantEvent,
  _deleteParticipantEvent,
  _deleteEvent,
  _deleteAmbassador,
  _updateAmbassador,
  _deleteSponsor,
  _updateSponsor,
  _getAllNextGallery,
  _endUploadIdImage,
  _createUploadIdImage,
  _deleteGallery,
  _updateImagesInfo,
  _editGallery,
  _login,
  _validateAccount_Token,
  _validateAccount_password,
  _newPassword_ForgetPassword,
  _validateEmail_ForgetPassword,
  _recoveryToken_ForgetPassword,
  _deleteAdmin,
  _updateAdmin,
  _deleteLocation,
  _updateLocation,
  _getAllNextMember,
  _getAllNextDeletedMember,
  _getSpecificMember,
  _getSpecificDeletedMember,
  _updateProfilMember,
  _blockedMember,
  _deleteMember,
  _getAllNextWaitingMember,
  _acceptRefuseMember,
  _getAllNextEndMember,
  _getAllNextNewMemberStripe,
  _UploadInvoiceNewStripeMember,
  _getAllNextNoneMember,
  _updateNoneMember,
  _deleteNonMember,
  _updateEmailMember,
  _DownloadInvoiceFile,
  _searchPassedEvent,
  _getAllParticipantSubscribeInvoice,
  _sendInvoiceEvent,
  _validatePaymentEvent,
  _removeColleague,
  _addColleague,
  _transfertDeleteToMember,
  _getSubscriptionStat,
  _searchMemberAttach,
  _searchTva,
  _createBusinessPackCustomize,
  _ownerChanger,
  _FreeMember,
  _changeTypeOfBusiness,
  _changePriceOfBusiness,
  _variablePrice,
  _changeTypeContactFacturation,
  _getNewMemberDelca,
  _getEndMemberDelca,
  _getEndBusinessCustomDelca,
  _getFacturationPdfDelca,
  _verifyPayment,
  _verifyEndPayment,
  _acceptNewMember,
  _refuseNewMember,
  _sendMailRemindNewMember,
  _getAllPromotion,
  _putNewPromotion,
  _updateStatusOnlinePromotion,
  _deletePromotion,
  _updatePromotion,
  _acceptEndMember,
  _sendMailEndBusinessCustom
}
