import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Photo from './ProfilImage'
import Place from './Name'
import Name from './SocietyInfo'
import ActionMember from './ActionMember'

class Header extends React.Component {


  render (){
    return (


      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20, paddingBottom: 0,  width: '100%', marginBottom: 10}}>
        <Row>
          <Col  lg={12} xl={12} sm={12} md={12} style={{padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <Place Location={this.props.Location}/>
            <Name Name={this.props.Name}/>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Photo Image={this.props.Image} />
          </Col>

          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ActionMember refParent={this.props.refParent}/>
          </Col>
        </Row>
      </Container>

    )
  }
}

export default Header;
