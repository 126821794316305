import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";

class Complement extends React.Component {


  HowStyleToApply = (value, object) => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }


  render (){
    return (
      <Col sm={12} xl={4} lg={4} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS SUR L'INSCRIPTION</p>


        <div style={{}}>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Type d'inscription:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={{color: 'black', fontSize: TEXTSIZE}}>Non membre</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date d'inscription:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("AccountCreatedAt", "Complement")}>{this.props.Complement && this.props.Complement.AccountCreatedAt ? this.props.Complement.AccountCreatedAt : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Club B19:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Region", "Complement")}>{this.props.Complement && this.props.Complement.Region ? this.props.intl.formatMessage({id: this.props.Complement.Region}) : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Source:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Source", "Complement")}>{this.props.Complement && this.props.Complement.Source ? this.props.Complement.Source : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Nombre de participation:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("NumberSubscribeEvent", "Complement")}>{this.props.Complement  ? this.props.Complement.NumberSubscribeEvent : 0}</p>
          </div>
        </div>

        </div>
      </Col>
    )
  }
}

export default Complement;
