import React, { useState } from "react";
import styled from "styled-components";
import { IoIosArrowForward } from "react-icons/io";
import {makeStyles} from '@material-ui/core/styles';

const AnimationRotate = makeStyles((theme) => ({
  refresh: {
    margin: "auto",
    animation: "$spinNormal 0.5s 1",

  },
  spin: {
    margin: "auto",
    animation: "$spin 0.5s 1",
    transform: "rotate(90deg)",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(90deg)",
    },
  },
  "@keyframes spinNormal": {
    "0%": {
      transform: "rotate(90deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  }
}));


const DropDownContainer = styled("div")`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column
`;

const DropDownHeader = styled("div")`
  font-weight: 500;
  font-size: 15;
  color: white;
  width: 25%;

`;

const DropDownListContainer = styled("div")`
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.90);
  width: 'auto';
  padding: 10px;
  transition: opacity 0.5s;
  transition: max-height 0.5s ease-in;
  opacity: ${({ showlist }) => (showlist ? '1' : '0')};
  max-height: ${({ showlist }) => (showlist ? '500px' : '0px')};
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  width: "100%";
  color: grey;
  font-size: 15;
  &:first-child {
    padding-top: 0.8em;
  }
`;

const ListItem = styled("li")`
  list-style: none;
  &:hover {
    color: #fd9e46;
  }
`;

const options = [{key: "en", trad: "Anglais"}, {key: "fr", trad: "Français"}, {key: "nl", trad: "Néerlandais"}];

export default function App({setLocale}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({key: "en", trad: "Anglais"});
  const classes = AnimationRotate();

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => {
    setSelectedOption(option);
    setLocale(option.key)
    setIsOpen(false);
  };

  return (

      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {selectedOption.key}
          <IoIosArrowForward className={isOpen ? classes.spin : classes.refresh} color={'white'} size={15} />

        </DropDownHeader>

          <DropDownListContainer showlist={isOpen} >
            <DropDownList>
              {options.map(option => (
                <ListItem onClick={() => onOptionClicked(option)} key={Math.random()}>
                  {option.trad}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>

      </DropDownContainer>

  );
}
