import http from "./http-common";
import Cookies from 'universal-cookie'

const upload = (file, onUploadProgress) => {
  let formData = new FormData();

  var cookies = new Cookies();
  var Jwt = cookies.get('acces');
  formData.append("image", file.file);
  formData.append("Jwt", Jwt);
  formData.append("IdGallery", file.IdGallery);
  formData.append("UploadIdPipe", file.UploadId);

  return http.post("/UploadImagesGallery", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};


const FileUploadService = {
  upload,
};

export default FileUploadService;
