import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";
import { Form} from 'react-bootstrap';

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }



    _renderStatus = () => {
      if (!this.props.refParent.state.statusChangeTypeFacturation || this.props.refParent.state.statusChangeTypeFacturation === "Ask")
        return (<></>)
      if (this.props.refParent.state.statusChangeTypeFacturation === "Waiting"){
        return (
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={35}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>)
      }
      if (this.props.refParent.state.statusChangeTypeFacturation === "Success"){
        return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
        )
      }
      return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            </Col>
          </Row>
      )

    }

    _ButtonClickNo = () => {
      this.props.refParent.setState({Error: null, FacturationCopy: this.props.refParent.state.Facturation, ChangeTypeFacturation: false, statusChangeTypeFacturation: null, statusBufferChangeType: null})
    }

    _ButtonClickYes = () => {
      this.props.refParent._TypeContactGestion()
    }

    _setStateFacturationCopy = (Name, Value) => {
      let ObjectCopy = Object.assign({}, this.props.refParent.state.FacturationCopy);
      ObjectCopy[Name] = Value
      this.props.refParent.setState({FacturationCopy: ObjectCopy})
    }

    _ValueOrNull = (Name) => {
      if (this.props.refParent.state.FacturationCopy && this.props.refParent.state.FacturationCopy[Name])
        return (this.props.refParent.state.FacturationCopy[Name])
      return ("")
    }

 render() {
   return (
       <Popup showPopup={this.props.refParent.state.ChangeTypeFacturation} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.refParent.state.ChangeTypeFacturation ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5, width: "50%"}}>
       <Row style={{marginTop: 15}}>
       {
         this.props.refParent.state.statusBufferChangeType === "society" ?
         <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
           <p style={{textAlign: 'left', marginTop: 10}}>Tva<span style={{color: 'red'}}> *</span></p>
           <Form.Control
           required
           type="text"
           placeholder={"Entrez la Tva"}
           value={this._ValueOrNull("TVA")}
           onChange={(event) => this._setStateFacturationCopy("TVA", event.target.value)}/>
           {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
              <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.tva}</p>
              :
              <></>
           }
         </Form.Group>
         :
         <>
         </>
       }

       <Form.Group as={Col} xl={4} lg={5} sm={12} md={12}>
         <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
         <Form.Control
         required
         type="text"
         placeholder={"Entrez le nom de la société/personne"}
         value={this._ValueOrNull("Society")}
         onChange={(event) => this._setStateFacturationCopy("Society", event.target.value)}/>
         {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
            <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.society}</p>
            :
            <></>
         }
       </Form.Group>
       </Row>
       <Row>
       <Form.Group as={Col} xl={2} lg={2} sm={2} md={2}>
         <p style={{textAlign: 'left', marginTop: 10}}>Numéro<span style={{color: 'red'}}> *</span></p>
         <Form.Control
         required
         type="text"
         placeholder={"Numéro, boîte"}
         value={this._ValueOrNull("Number")}
         onChange={(event) => this._setStateFacturationCopy("Number", event.target.value)}/>
         {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
            <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationNumber}</p>
            :
            <></>
         }
       </Form.Group>
         <Form.Group as={Col} xl={9} lg={9} sm={9} md={9}>
           <p style={{textAlign: 'left', marginTop: 10}}>Adresse<span style={{color: 'red'}}> *</span></p>
           <Form.Control
           required
           type="text"
           placeholder={"Rue, Avenue"}
           value={this._ValueOrNull("StreetWN")}
           onChange={(event) => this._setStateFacturationCopy("StreetWN", event.target.value)}/>
           {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
              <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationStreet}</p>
              :
              <></>
           }
         </Form.Group>
         </Row>
         <Row>
           <Form.Group as={Col} xl={4} lg={3} sm={12} md={12}>
             <p style={{textAlign: 'left', marginTop: 10}}>Code Postal<span style={{color: 'red'}}> *</span></p>
             <Form.Control
             required
             type="number"
             placeholder={"Entrez le code postal"}
             value={this._ValueOrNull("ZipCode")}
             onChange={(event) => this._setStateFacturationCopy("ZipCode", event.target.value)}/>
             {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationZipCode}</p>
                :
                <></>
             }
           </Form.Group>
           <Form.Group as={Col} xl={7} lg={8} sm={12} md={12}>
             <p style={{textAlign: 'left', marginTop: 10}}>Ville<span style={{color: 'red'}}> *</span></p>
             <Form.Control
             required
             type="text"
             placeholder={"Entrez la ville"}
             value={this._ValueOrNull("City")}
             onChange={(event) => this._setStateFacturationCopy("City", event.target.value)}/>
             {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Facturation") ?
                <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Facturation.facturationCity}</p>
                :
                <></>
             }
           </Form.Group>

           </Row>

       {this._renderStatus()}
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
           <ButtonTestCss  disabled={this.props.refParent.state.ChangeTypeFacturation && this.props.refParent.state.ChangeTypeFacturation === 'Waiting'} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{this.props.refParent.state.statusChangeTypeFacturation && this.props.refParent.state.statusChangeTypeFacturation === "Error" ? "Ré-essayer" : "Oui"}</ButtonTestCss>
           <ButtonTestCss  disabled={this.props.refParent.state.ChangeTypeFacturation && this.props.refParent.state.ChangeTypeFacturation === 'Waiting'} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
         </Col>
       </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUp;
