import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Cookies from 'universal-cookie'

class PureEvent extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }


  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left">
        {
          this.props.event.Image ?
            <img  alt={'event ressource'} key={this.props.event.Id} src={this.props.event.Image} style={{objectFit: 'cover', width: 35, borderRadius: 360, height: 35}}/>
          :
            <div style={{width: 35, borderRadius: 360, height: 35, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell align="left" style={{}}>
          <Button variant="text" style={{textTransform: 'none', fontSize: '0.8rem', margin: 0, padding: 0}} component={Link}
          to={{
          pathname: `/details_event/${this.props.event.Id}`,
          state: {oldPage: "/all_event_passed/"}
          }}>
              {this.props.event.Name}
          </Button>

        </TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.DateEvent}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.BeginHours}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.EndHours}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.NumberSubscribe}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.Location}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.event.PublicUrlSubscribe}</TableCell>
        <TableCell align="center" style={{}}>


        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
            to={{
            pathname: `/details_event/${this.props.event.Id}`,
            state: {oldPage: "/all_event_passed/"}
            }}>
                Détails
            </Button>

            </Dropdown.Item>
            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, EventDelete: this.props.event})}} disabled={this.props.deleting} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              Supprimer
            </Button>

            </Dropdown.Item>


          </Dropdown.Menu>
        </Dropdown>




        </TableCell>
      </TableRow>)
  }

}

export default PureEvent;
