import React from 'react';
import Col from 'react-bootstrap/Col';
import {ButtonDiv} from './Style'
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

class ItemGallery extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }



  render() {
    return (
      <Col sm={6} lg={3} xl={3} md={4}  style={{marginTop: 10, marginBottom: 10}} id={this.props.image.Id}>

        <ButtonDiv style={{borderRadius: 15, backgroundImage: this.props.image.Image ? `url(${this.props.image.Image})` : '', backgroundRepeat: 'no-repeat', backgroundSize: "cover"}} onClick={() => this.props.refParent.ChangeRoute(this.props.image)}>
          <div style={{position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: 15, top:0, bottom:0, right:0, left:0}} >
            <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: "column"}}>
              <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', textAlign: 'center'}}>
                <p style={{fontSize: '1.2rem', color: 'white', fontWeight: 'bold'}}>{this.props.image.Name}</p>
              </div>
              {
                this.props.image.City ?
                <div style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', textAlign: 'center'}}>
                  <p style={{fontSize: '1rem', color: 'white', fontStyle: 'italic', margin: 0}}>{this.props.image.City}</p>
                </div>
                :
                <>
                </>
              }
              <div>
                <p style={{fontSize: '1rem', color: 'white', fontStyle: "italic"}}>{this.props.image.Date}</p>
              </div>
              <div>
                {this.props.image.Public ? <MdVisibility size={20} color={'white'}/> : <MdVisibilityOff size={20} color={'white'}/>}
              </div>
            </div>
          </div>
        </ButtonDiv>
      </Col>
    )
  }

}


export default ItemGallery;
