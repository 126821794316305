import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

export const ButtonCss = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    margin: 5,
    padding: '6px 12px',
    borderRadius: 10,
    border: '2px solid #FC8017',
    lineHeight: 1.5,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: "#FC8017",
      boxShadow: 'none',
      color: 'white'
    },
    '&:active': {
      backgroundColor: "#FC8017",
      boxShadow: 'none',
      color: 'white'
    },
    '&:focus': {
    },
  },
})(Button);

export const ButtonCssNav = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold',
    margin: 0,
    padding: 15,
    paddingTop:10,
    paddingBottom: 5,
    border: '2px solid grey',
    borderBottomWidth: 0,
    borderRadius: 0,
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: "#082430",
      boxShadow: 'none',
      color: 'white'
    },
    '&:active': {
      backgroundColor: "#082430",
      boxShadow: 'none',
      color: 'white'
    },
    '&:focus': {
    },
  },
})(Button);

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "white",
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: "green",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#082430",
  },
  checked: {},
}))(Switch);

export const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#082430',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#082430',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'orange',
      },
      '&:hover fieldset': {
        borderColor: '#FF8C00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#082430',
      },
    },
  },
})(TextField);
