import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {AnimationRotate} from './Style'
import { IoIosArrowForward} from "react-icons/io";

class TableHeadEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _renderCell = (Name, Trad, classes, none) => {
      if (none)
      {
        return (
          <div style={{display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
            <p>{Trad}</p>
            <div style={{marginLeft: 5}}>
              <IoIosArrowForward  color={'rgba(0,0,0,0)'} size={15} />
            </div>
          </div>
        )
      }
      return (
        <div onClick={() => this.props.refParent._sortByAlphabet(Name)} style={{display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
          <p>{Trad}</p>
          <div style={{marginLeft: 5}}>
            <IoIosArrowForward className={this.props.refParent.state.sortFilter[Name] === 'down' ? classes.spin : classes.refresh} color={'black'} size={15} />
          </div>
        </div>
      )
    }

 render() {
   const { classes } = this.props

   return (
      <TableHead>
        <TableRow>
          <TableCell align="left">{this._renderCell("LastName", 'NOM', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("FirstName", 'PRENOM', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("Email", 'E-MAIL', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("Society", 'SOCIETE', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("AccountCreatedAt", "INSCRIPTION", classes)}</TableCell>
          <TableCell align="left">{this._renderCell("DeletedAt", 'SUPPRIMER', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("RegistrationType", 'ABONNEMENT', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("Region", 'B19', classes)}</TableCell>
          <TableCell align="center">{this._renderCell("Action", 'ACTION', classes, true)}</TableCell>
        </TableRow>
      </TableHead>

   )
 }
}


export default withStyles(AnimationRotate)(TableHeadEvent);
