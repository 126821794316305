import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";

class Complement extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object) => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }


  render (){
    return (
      <Col sm={12} xl={4} lg={4} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS SUR L'INSCRIPTION</p>


        <div style={{}}>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Type d'inscription:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("RegistrationType", "Complement")}>{this.props.Complement && this.props.Complement.RegistrationType ? this.props.Complement.RegistrationType : "Vide"}</p>
          </div>
        </div>

        {
          this.props.Complement && (this.props.Complement.RegistrationType === "Business" || this.props.Complement.RegistrationType === "Couple" || this.props.Complement.RegistrationType === "BusinessPackCustomize") && this.props.Complement.BusinessInfo !== true ?
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>{"Titulaire:"}</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={{fontSize: '0.85em'}}>
              <a href={`/details_member/${this.props.Complement.BusinessInfo.Id}`}
              style={{fontWeight: 'bold', color: 'black',  }}>{this.props.Complement.BusinessInfo.FirstName + " " + this.props.Complement.BusinessInfo.LastName}</a>
              </p>
            </div>
          </div>
          :
          <>
          </>
        }

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date d'inscription:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("AccountCreatedAt", "Complement")}>{this.props.Complement && this.props.Complement.AccountCreatedAt ? this.props.Complement.AccountCreatedAt : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date fin adhésion:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("SubscriptionEnd", "Complement")}>{this.props.Complement && this.props.Complement.SubscriptionEnd ? this.props.Complement.SubscriptionEnd : "Vide"}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date du dernier payement:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("SubscriptionBegin", "Complement")}>{this.props.Complement && this.props.Complement.SubscriptionBegin ? this.props.Complement.SubscriptionBegin : "Vide"}</p>
          </div>
        </div>



        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Club B19:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Region", "Complement")}>{this.props.Complement && this.props.Complement.Region ? this.props.intl.formatMessage({id: this.props.Complement.Region}) : "Vide"}</p>
          </div>
        </div>

        </div>
      </Col>
    )
  }
}

export default Complement;
