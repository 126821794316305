import React from 'react';
import Form from 'react-bootstrap/Form';
import {Button, Dropdown, DropdownButton, Row, Col, Alert } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {BiPlusCircle } from "react-icons/bi";
import { AiOutlineSearch} from "react-icons/ai";

class ActionHeader extends React.Component {

  render (){
    return (
      <Form style={{marginTop: '25px', marginBottom: '25px'}}>
          <Row>
              <Col style={{}} xl={4} lg={4} sm={12} md={12}>
              <Form.Group  md="4">
                  <InputGroup hasValidation>
                  <InputGroup.Text><AiOutlineSearch/></InputGroup.Text>
                  <Form.Control
                      type="text"
                      placeholder="Rechercher"
                      value={this.props.refParent.state.searchText}
                      onChange={(event) => this.props.refParent._search(event.target.value)}
                  />
                  </InputGroup>
              </Form.Group>
              </Col>
              <Col xl={8} lg={8} sm={12} md={12}>
              <div style={{justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex'}}>
                  <div style={{paddingRight: 5, position: 'relative', display: 'flex'}}>
                      <Button variant='text' style={{backgroundColor: '#FC8016', color: 'white'}} onClick={() => this.props.refParent.setState({AddModalShow: true})}>
                          <span style={{fontWeight: 'bold', color: "white"}}>
                              AJOUTER
                          </span>
                          <BiPlusCircle size= {'25'} style={{marginLeft: '10px', color: "white"}} />
                      </Button>
                  </div>
              </div>
              </Col>
          </Row>
      </Form>
    )
  }
}

export default ActionHeader;
