import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { ButtonCss, CssTextField, ButtonCssChecked} from './Style'
import Typography from '@material-ui/core/Typography';
import Cookies from 'universal-cookie'
import Filter from './Filter';

class Header extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  _ConstructFilterArray = (event) => {
    var CurrentRegionArray = [...this.props.Filter]
    var index = CurrentRegionArray.findIndex(res => res === event)
    if (index === -1)
      CurrentRegionArray.push(event)
    else
      CurrentRegionArray.splice(index, 1)
    this.props.refParent.setState({Filter: CurrentRegionArray})
  }

  _createFilter = () => {
    try {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      Info = {}
      Info.Region = ["bxl", 'anv', 'lux', 'fl', "luik"]
      return Info.Region.map((region, index) => {
        return (
          <Filter intl={this.props.intl} region={region} key={index} refParent={this}/>
        )
      })
    } catch (e){
      return <></>
    }
  }

  _ClickRegistation = (RegistrationType) => {
    try {
      var CurrentRegistrationArray = [...this.props.FilterRegistration]
      var index = CurrentRegistrationArray.findIndex(res => res === RegistrationType)
      if (index === -1)
        CurrentRegistrationArray.push(RegistrationType)
      else
        CurrentRegistrationArray.splice(index, 1)
      this.props.refParent.setState({FilterRegistration: CurrentRegistrationArray}, () => {
        this.props.refParent.MemberDisplayRegistration()
      })
    } catch (e){
      return ;
    }
  }

  verifyChecked = (RegistrationType) => {
    try {
      var CurrentRegistrationArray = [...this.props.FilterRegistration]
      var index = CurrentRegistrationArray.findIndex(res => res === RegistrationType)
      if (index === -1)
        return false
      else
        return true
    } catch (e){
      return false
    }
  }

  _createFilterRegistration = () => {
    try {


        return (
          <>
          <Col sm={12} lg={2} xl={2} md={12} style={{display: 'flex', flexDirection: 'column', padding: 5, margin: 5}}>
            <ButtonCssChecked onClick={(event) => this._ClickRegistation("SingleA")} checked={this.verifyChecked("SingleA")} style={{fontSize: '0.75rem', width: "100%", display: 'flex', borderRadius: 5, padding: 5, color: 'white', backgroundColor: this.verifyChecked("SingleA") ? "#0069d9" : "#082430"}}>
              Single Annuel
            </ButtonCssChecked>
          </Col>
          <Col sm={12} lg={2} xl={2} md={12} style={{display: 'flex', flexDirection: 'column', padding: 5, margin: 5}}>
            <ButtonCssChecked onClick={(event) => this._ClickRegistation("SingleM")} checked={this.verifyChecked("SingleM")} style={{fontSize: '0.75rem', width: "100%", display: 'flex', borderRadius: 5, padding: 5, color: 'white', backgroundColor: this.verifyChecked("SingleM") ? "#0069d9" : "#082430"}}>
              Single Mensuel
            </ButtonCssChecked>
          </Col>
          <Col sm={12} lg={2} xl={2} md={12} style={{display: 'flex', flexDirection: 'column', padding: 5, margin: 5}}>
            <ButtonCssChecked onClick={(event) => this._ClickRegistation("Young")} checked={this.verifyChecked("Young")} style={{fontSize: '0.75rem', width: "100%", display: 'flex', borderRadius: 5, padding: 5, color: 'white', backgroundColor: this.verifyChecked("Young") ? "#0069d9" : "#082430"}}>
              Young
            </ButtonCssChecked>
          </Col>
          <Col sm={12} lg={2} xl={2} md={12} style={{display: 'flex', flexDirection: 'column', padding: 5, margin: 5}}>
            <ButtonCssChecked onClick={(event) => this._ClickRegistation("Business")} checked={this.verifyChecked("Business")} style={{fontSize: '0.75rem', width: "100%", display: 'flex', borderRadius: 5, padding: 5, color: 'white', backgroundColor: this.verifyChecked("Business") ? "#0069d9" : "#082430"}}>
              Business
            </ButtonCssChecked>
          </Col>
          </>
        )

    } catch (e){
      return <></>
    }
  }

  _applyFilter = () => {
    this.props.refParent.setState({memberDisplay: [], member: [], loadingMore: true}, () => {
      this.props.refParent._getAllNextMember()
    })
  }

  renderTotal = () => {
    if (this.props.searchRegistration === true)
      return (this.props.searchMemberRegistration.length)
    if (this.props.Member)
      return (this.props.Member.length)
    return ("0")
  }

  render (){
    return (
      <>
      <Container fluid>
        <Row>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h2 style={{textAlign: 'left', marginBottom: 25}}>Membres</h2>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{backgroundColor: 'rgba(0, 0, 0, 0.1)',  padding: 20}}>
        <Row style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
          {this._createFilter()}
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} style={{marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: "25%", display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
              <ButtonCss  onClick={() => this._applyFilter()} color={'inherit'} style={{width: '100%', backgroundColor: 'transparent'}}>
                <Typography style={{fontWeight: 'bold', fontSize: '0.85rem', color: 'black'}}>APPLIQUER LE FILTRE</Typography>
              </ButtonCss>
            </div>
          </Col>
        </Row>
        </Container>
        <Container fluid>
        <Row style={{marginTop: 20, display: 'flex', justifyContent: 'flex-start'}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h4 style={{textAlign: 'left', marginBottom: 10}}>Trier par</h4>
          </Col>
        </Row>
        <Row style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start'}}>
          {this._createFilterRegistration()}
        </Row>
        <Row style={{marginTop: 20, display: 'flex', justifyContent: 'flex-start'}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h4 style={{textAlign: 'left', marginBottom: 10}}>Rechercher un membre</h4>
          </Col>
        </Row>
        <Row style={{marginTop: 10}}>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            <div style={{width: "100%", display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              <CssTextField value={this.props.search ? this.props.search : null} label="Rechercher un membre" variant="outlined" onChange={(event) => this.props.refParent.search(event.target.value)} style={{marginRight: 5}}/>
            </div>
          </Col>
        </Row>
        <Row style={{marginTop: 20, display: 'flex', justifyContent: 'flex-start'}}>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h4 style={{textAlign: 'left', marginBottom: 10}}>Nombre de membres</h4>
          </Col>
        </Row>
        <Row>
        <Col sm={3} md={3} lg={3} xl={3} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10}}>
          <div style={{width: "100%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>
              <Typography style={{fontStyle: 'italic', fontWeight: 'bold', fontSize: '0.98rem', color: '#082430', marginBottom: 50, marginTop: 10}}>{this.renderTotal()}</Typography>
          </div>
        </Col>
        </Row>

        </Container>
        </>
    )
  }
}

export default Header;
