import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IoIosArrowForward} from "react-icons/io";

class TableHeadAdmin extends React.Component {


    _renderCell = (Name, Trad) => {

        return (
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
            <p>{Trad}</p>
            <div style={{marginLeft: 5}}>
              <IoIosArrowForward  color={'rgba(0,0,0,0)'} size={15} />
            </div>
          </div>
        )

    }

 render() {

   return (

      <TableHead>
        <TableRow>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('FirstName', 'PRENOM')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('LastName', 'NOM')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('Email', 'E-MAIL')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('Region', 'B19')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('Status', 'STATUS')}</div></TableCell>
          <TableCell align="center"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell('Action', 'ACTION')}</div></TableCell>
        </TableRow>
      </TableHead>

   )
 }
}


export default TableHeadAdmin;
