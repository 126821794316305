import React from 'react';
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { Fade } from "react-awesome-reveal";
import { FaTrashAlt } from 'react-icons/fa';

class ColleagueInfo extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    componentDidMount(){

    }


 render() {
   return (
     <Fade>
       <Row>
         <Col style={{display: "flex", flexDirection: 'row', marginTop: 15, alignItems: 'center'}}>
          <FaTrashAlt style={{marginRight: 10, cursor: 'pointer'}} onClick={() => this.props.refParent.RemoveColleague(this.props.Colleague.target)} size={20}/ >
           <p style={{textAlign: 'left', margin: 0, paddind: 0, fontWeight: 'bold', fontSize: 15}}>{"Collègue " + (this.props.index + 1)}</p>
         </Col>
         <Col>
         </Col>
       </Row>


       <Form noValidate validated={this.props.Colleague.EmailFormValidated} onSubmit={(event) => this.props.refParent.CheckEmailColleague(event, this.props.Colleague)} style={{marginTop: 25}}>
             <Row>
               <Col>
               <p style={{textAlign: 'left', marginTop: 10}}>Email<span style={{color: 'red'}}> *</span></p>
               </Col>
             </Row>
              <Row>
                <Col xl={5} lg={5} sm={6} md={4} style={{}}>
                <Form.Control
                type="text"
                required
                placeholder={"test@gmail.com"}
                value={this.props.Colleague.email}
                onChange={(event) => this.props.refParent.ChangeInfoColleague(event.target.value, this.props.Colleague.target, "email")}/>
                {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Colleague") && this.props.refParent.state.Error.Colleague.hasOwnProperty((this.props.index + 1).toString()) ?
                   <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Colleague[(this.props.index + 1)].emailColleague}</p>
                   :
                   <></>
                 }
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                   {this.props.Colleague.messageEmailOwner}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {this.props.Colleague.messageEmailOwner}
                   </Form.Control.Feedback>
                </Col>
                <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
                <Button
                type="submit"
                style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
                >
                {
                  this.props.Colleague.loadingEmail === true ?
                  <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                      <CircularProgress color="inherit" size={20}/>
                    </div>
                    <div>
                      <p style={{margin: 0, color: 'transparent'}}>Vérifier</p>
                    </div>
                  </div>
                  :
                    <p style={{margin: 0}}>Vérifier</p>
                }
                </Button>
                </Col>
              </Row>
          </Form>
        {this.props.Colleague.EmailValidated === true ?
          <Fade>
          <Row>
         <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
           <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
           <Form.Control
           required
           type="text"
           placeholder={"Entrez le nom"}
           value={this.props.Colleague.lastName}
           onChange={(event) => this.props.refParent.ChangeInfoColleague(event.target.value, this.props.Colleague.target, "lastName")}/>
           {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Colleague") && this.props.refParent.state.Error.Colleague.hasOwnProperty((this.props.index + 1).toString()) ?
              <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Colleague[(this.props.index + 1)].lastNameColleague}</p>
              :
              <></>
            }
         </Form.Group>
         <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
           <p style={{textAlign: 'left', marginTop: 10}}>Prénom<span style={{color: 'red'}}> *</span></p>
           <Form.Control
           required
           type="text"
           placeholder={"Entrez le prénom"}
           value={this.props.Colleague.firstName}
           onChange={(event) => this.props.refParent.ChangeInfoColleague(event.target.value, this.props.Colleague.target, "firstName")}/>
           {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Colleague") && this.props.refParent.state.Error.Colleague.hasOwnProperty((this.props.index + 1).toString()) ?
              <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Colleague[(this.props.index + 1)].firstNameColleague}</p>
              :
              <></>
            }
         </Form.Group>
          </Row>
         </Fade>
         :
         <div/>
        }
     </Fade>
   )
 }
}


export default ColleagueInfo;
