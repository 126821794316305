import React from 'react';


import {Requestdynamic} from '../Network/Fetch';
import Cookies from 'universal-cookie'

import ValidateToken from './ValidateToken'
import RetrieveKey from './RetrieveKeys'
import ErrorNotFound from './ErrorNotFound'
import NewPassword from './NewPassword'

class CheckOut extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        Error: null,
        Email: null,
        Token: null,
        UniqueKey: null,
        successValidateAccount: false,
        successPassword: false,
        checkOut: false,
        Password: null,
        VerifyPassWord: null,
        createPassword: false
      }
    }

    componentDidMount(){
      var cookies = new Cookies();
      var acces = cookies.get('acces')
      if (acces !== 'undefined' && acces){
        this.props.navigation.history.push({
        pathname: `/login`,
        state: {}
        })
      }


    }

_CheckToken = (Error) => {
  if (!this.state.Token || this.state.Token.length === 0)
    Error.Token = true
}


_CheckEmail = (Error) => {
  if (!this.state.Email || this.state.Email.length === 0){
      Error.Email = true
  }
}

sleep = (ms) => {
 return new Promise(resolve => setTimeout(resolve, ms));
}

_checkOut = () => {
  this.setState({checkOut: true, Error: null}, async () => {

  var Error = {};
  this._CheckToken(Error);
  this._CheckEmail(Error);
  var KeysErrorLength = Object.keys(Error).length
  if (KeysErrorLength !== 0)
  {
    this.setState({Error: Error, checkOut: false})
    return ;
  }
  var Params = {
    Email: this.state.Email,
    Token: this.state.Token,
    OAuth: false,
  }
  var promise = await Requestdynamic('_validateAccount_Token', Params)
  if (promise.error === false && promise.data.error === false)
  {
    this.setState({checkOut: false, Error: null, successValidateAccount: true})
  }
  else {
    if (promise.error === true)
      this.setState({checkOut: false, Error: {Connexion: "Un problème est survenue veuillez réesayer plus tard"}})
    else
      this.setState({checkOut: false, Error: {Connexion: 'Information incorrect'}})
  }

  })


}

_next = () => {
  this.props.navigation.history.push({
  pathname: `/login`,
  state: {}
  })
}

_CheckPassword = (Error) => {
  if (!this.state.Password || this.state.Password.length === 0)
    Error.Password = true
}

_CheckVerifyPassword = (Error) => {
  if (!this.state.VerifyPassWord || this.state.Password.VerifyPassWord === 0)
    Error.VerifyPassWord = true
}

_createPassword = () => {
  this.setState({createPassword: true, Error: null}, async () => {

    var Error = {};
    this._CheckPassword(Error);
    if (Error.hasOwnProperty("Password"))
    {
      this.setState({Error: Error, createPassword: false})
      return ;
    }
    this._CheckVerifyPassword(Error);
    if (Error.hasOwnProperty("VerifyPassWord")){
      this.setState({Error: Error, createPassword: false})
      return ;
    }
    if (this.state.Password !== this.state.VerifyPassWord)
    {
      this.setState({Error: {NotMatchPassword: true}, createPassword: false})
      return ;
    }
    var Params = {
      Password: this.state.Password,
      Email: this.state.Email,
      Token: this.state.Token,
      VerifyPassword: this.state.VerifyPassWord,
    }
    var promise = await Requestdynamic('_validateAccount_password', Params)
    if (promise.error === false && promise.data.error === false)
    {
      this.setState({createPassword: false, Error: null, successPassword: true, UniqueKey: promise.data.data})
    }
    else {
        this.setState({createPassword: false, Error: {Connexion: 'Un problème est survenue veuillez réesayer plus tard'}})
    }

  })
}

_renderComponent = () => {
  if (this.state.successValidateAccount === false)
    return (<ValidateToken Token={this.state.Token} Email={this.state.Email} checkOut={this.state.checkOut} refParent={this} intl={this.props.intl} Error={this.state.Error} />)
  else if (this.state.successValidateAccount === true && this.state.successPassword === false)
    return (<NewPassword Error={this.state.Error} refParent={this} Password={this.state.Password} VerifyPassWord={this.state.VerifyPassWord} createPassword={this.state.createPassword} />)
  else if (this.state.successValidateAccount === true && this.state.successPassword === true && this.state.UniqueKey)
    return (<RetrieveKey refParent={this} UniqueKey={this.state.UniqueKey} />)
  else
    return (<ErrorNotFound next={this._next}/>)
}

 render() {
   return (
     <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',  overflowY: "auto"}}>
     {
       this._renderComponent()
     }
     </div>
   )
 }
}


export default CheckOut;
