import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AntSwitch} from './Style'
import Form from 'react-bootstrap/Form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Target extends React.Component {

 render() {

   return (
     <>

         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Target</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
              <FormControlLabel control={<OrangeCheckbox onChange={() => this.props.refParent.setState({Target: "FR"})} checked={this.props.refParent.state.Target === "FR"} name="FR"/>} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>FR</Typography>} />
              <FormControlLabel control={<OrangeCheckbox onChange={() => this.props.refParent.setState({Target: "NL"})} checked={this.props.refParent.state.Target === "NL"} name="NL" />} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>NL</Typography>} />
            </Form.Group>
           </div>
         </Col>
         </Row>

     </>
   )
 }
}


export default Target;
