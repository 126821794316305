import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import SeparateLine from './SeparateLine'
import {OrangeCheckbox} from './Style'


  class Permission extends React.Component {
  constructor(props){
      super(props);
      this.state = {

        }
    }

    _ConstructRegionArray = (event) => {
      var CurrentRegionArray = [...this.props.Region]
      var index = CurrentRegionArray.findIndex(res => res === event.target.value)
      if (index === -1)
        CurrentRegionArray.push(event.target.value)
      else
        CurrentRegionArray.splice(index, 1)
      this.props.refParent.setState({Region: CurrentRegionArray})
    }

    verifyChecked = (region) => {
      var CurrentRegionArray = [...this.props.Region]
      var index = CurrentRegionArray.findIndex(res => res === region)
      if (index === -1)
        return false
      else
        return true
    }

CreatePermissionRegion = () => {
  try {
    var cookies = new Cookies();
    var InfoAdmin = cookies.get('infoAdmin');
    var Region = InfoAdmin.Region;

    return Region.map((region) => {
    return (
      <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: "100%", display: 'flex'}}>
        <FormControlLabel
          value={region}
          control={<OrangeCheckbox  name={region} />}
          checked={this.verifyChecked(region)}

          label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>{this.props.intl.formatMessage({ id: region })}</Typography>}
          onChange={(region) => this._ConstructRegionArray(region)}
        />
        </div>
      </Col>
    )})
  } catch (e) {
    return (<></>)
  }

}

CreateStatusAdmin = () => {
  try {
    var cookies = new Cookies();
    var InfoAdmin = cookies.get('infoAdmin');
    var status = InfoAdmin.status;

    if (status === "SuperSuperAdmin"){
      return (
        <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: "100%", display: 'flex'}}>
        <FormControlLabel
          value={this.props.refParent.state.SuperAdmin === 'true' ? 'false' : 'true'}
          control={<OrangeCheckbox  name={"SuperAdmin"} />}
          checked={this.props.refParent.state.SuperAdmin === "true" ? true : false}

          label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>Super Admin</Typography>}
          onChange={(e) => this.props.refParent.setState({SuperAdmin: e.target.value})}
        />
        </div>
      </Col>)
    }
    return (<></>)
  } catch (e) {
    return (<></>)
  }
}

 render() {

   return (
     <>
     <Row style={{margin: 10, marginTop: 20}}>
       <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
         <p style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Mitr, sans-serif', color: 'black'}}>Autoriser l'administrateur à avoir accés aux clubs :</p>
       </Col>
     </Row>
      <Row style={{margin: 10, marginTop: 20}}>
      {
        this.CreatePermissionRegion()
      }
      {
        this.CreateStatusAdmin()
      }
      </Row>
      {
        this.props.Error && this.props.Error.Region ?
        <Row style={{marginTop: 5}}>
          <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <p style={{fontSize: "0.9rem", color: "red", marginleft: 5}}>Veuillez selectionner au moin un club</p>
          </Col>
        </Row>
        :
        <>
        </>

      }
      <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

      </>
   )
 }
}


export default Permission;
