import React, { useState } from 'react';

import { Button, Row, Container, Col, Form} from 'react-bootstrap';

import Cookies from 'universal-cookie'


function Modify(props) {
  const [validated, setValidated] = useState(false);

const handleSubmit = (event) => {
  event.preventDefault();
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.stopPropagation();
  }

  setValidated(true);
  props.refParent._updateNoneMember()
};

const RegionB19 = () => {
  try {
    var cookies = new Cookies();
    var InfoAdmin = cookies.get('infoAdmin');
    if (!InfoAdmin || InfoAdmin === 'undefined' || !InfoAdmin.Region)
      return ;
    return (InfoAdmin.Region.map((region, index) => {
      return (
      <option key={index} value={region}>
         {props.intl.formatMessage({id: region})}
      </option>)
    }))
  } catch (e) {
    return ;
  }

}

const Source = () => {
  try {
    var Source = ['Admin', "marketing", 'Facebook', 'Instagram', 'Other', 'Student', 'Promo', 'Linkedin']
    return (Source.map((source, index) => {
      return (
      <option key={index} value={source}>
          {source}
      </option>)
    }))
  } catch (e) {
    return ;
  }

}

const setObject = (ObjectName, target ,value) => {
  var CopyObject = Object.assign({}, props.refParent.state[ObjectName]);
  CopyObject[target] = value
  var setParams = {}
  setParams[ObjectName] = CopyObject
  props.refParent.setState(setParams)
}


  return (
    <div>
      <div style={{backgroundColor: "#F6F7FB"}}>
      <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
      <h2 style={{textAlign: 'left'}}>Modifier le Profil</h2>

      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
      <Row>
        <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <h6 style={{textAlign: 'left', color:'#FC8016'}}>Informations Personnelles</h6>

        </Col>
      </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridlastName">
            <p style={{textAlign: 'left', marginTop: 10}}>Nom</p>
            <Form.Control
            required
            type="text"
            value={props.refParent.state.LastName ? props.refParent.state.LastName : ""}
            onChange={(e) => props.refParent.setState({LastName: e.target.value})}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {"Veuillez rentrer un nom avec au moin deux character"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.LastName ? "Veuillez rentrer un nom avec au moin deux character" : ""}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridfirstName">
            <p style={{textAlign: 'left', marginTop: 10}}>Prénom</p>
            <Form.Control
            required
            type="text"
            value={props.refParent.state.FirstName ? props.refParent.state.FirstName : ""}
            onChange={(e) => props.refParent.setState({FirstName: e.target.value})}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer un prénom avec au moin deux character"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
              {props.Error && props.Error.Private && props.Error.Private.FirstName ? "Veuillez rentrer un prénom avec au moin deux character" : ""}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row>
        <Form.Group as={Col} controlId="formGridTel">
          <p style={{textAlign: 'left', marginTop: 10}}>Téléphone</p>
          <Form.Control
          required
          type="text"
          value={props.refParent.state.Private && props.refParent.state.Private.TelPrivate ? props.refParent.state.Private.TelPrivate : ""}
          onChange={(e) => setObject("Private", "TelPrivate", e.target.value)}
          />
          <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
            {"Veuillez rentrer un numéro de téléphone"}
          </Form.Control.Feedback>
          <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
            {props.Error && props.Error.Private && props.Error.Private.Tel ? "Veuillez rentrer un numéro de téléphone" : ""}
          </Form.Control.Feedback>
        </Form.Group>
        </Row>


        <Row>
          <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations Professionnelles</h6>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridSociety">
            <p style={{textAlign: 'left', marginTop: 10}}>Société</p>
            <Form.Control
            type="text"
            value={props.refParent.state.Society && props.refParent.state.Society.Name ? props.refParent.state.Society.Name : ""}
            onChange={(e) => setObject("Society", "Name", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer le nom de la société"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Pro && props.Error.Pro.Society ? "Veuillez rentrer le nom de la société" : ""}

            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridStatus">
            <p style={{textAlign: 'left', marginTop: 10}}>Fonction</p>
            <Form.Control
            type="text"
            value={props.refParent.state.Society && props.refParent.state.Society.Status ? props.refParent.state.Society.Status : ""}
            onChange={(e) => setObject("Society", "Status", e.target.value)}
            />
            <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
              {"Veuillez rentrer la fonction du membre"}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
              {props.Error && props.Error.Pro && props.Error.Pro.Status ? "Veuillez rentrer la fonction du membre" : ""}

            </Form.Control.Feedback>
          </Form.Group>

        </Row>


        <Row>
          <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations d'inscription</h6>
          </Col>
        </Row>
        <Row>
        <Form.Group as={Col} controlId="B19Clubs">
          <p style={{textAlign: 'left', marginTop: 10}}>Clubs B19*</p>
           <Form.Control
           onChange={(e) => setObject("Complement", "Region", e.target.value)}
           value={props.refParent.state.Complement && props.refParent.state.Complement.Region ? props.refParent.state.Complement.Region :  ""} required as="select">
             <option hidden value="">
               Veuillez indiquer votre région parmis nos clubs
             </option>
             {RegionB19()}
       </Form.Control>
       <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
          {"Veuillez sélectionner un club"}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
          {props.Error && props.Error.Subscribe && props.Error.Subscribe.Region ? "Veuillez sélectionner un club" : ""}
        </Form.Control.Feedback>
     </Form.Group>

        </Row>
        <Row>
        <Form.Group as={Col} controlId="Source">
          <p style={{textAlign: 'left', marginTop: 10}}>Source*</p>
           <Form.Control
           onChange={(e) => setObject("Complement", "Source", e.target.value)}
           value={props.refParent.state.Complement && props.refParent.state.Complement.Source ? props.refParent.state.Complement.Source :  ""} required as="select">
             <option hidden value="">
               Veuillez indiquer la source
             </option>
             {Source()}
       </Form.Control>
       <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
          {"Veuillez sélectionner une source"}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
          {props.Error && props.Error.Subscribe && props.Error.Subscribe.Source ? "Veuillez sélectionner une source" : ""}
        </Form.Control.Feedback>
     </Form.Group>

        </Row>


        <div className="centerButton" style={{marginBottom: 15}}>
          <Button
          style={{marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
          type="submit">
            ENREGISTRER
          </Button>
        </div>


      </Form>

    </Container>

    <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
      </div>
    </div>
  );
}

export default Modify;
