import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import DetailsEvent from './SpecificEvent'

class ParselDetailsEvent extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={DetailsEvent} {...props}/>
   )
 }
}



export default ParselDetailsEvent;
