import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import {OrangeCheckbox} from './Style'


class Region extends React.Component {


  _ConstructRegionArray = (event) => {
    this.props.refParent.setState({Compta: event.target.value})
  }

  verifyChecked = (region) => {
    if (region === this.props.Compta)
      return (true)
    return false
  }

CreateCompta = (Region) => {
  try {
    
    return Region.map((region) => {
    return (
      <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: "100%", display: 'flex'}}>
        <FormControlLabel
          value={region}
          control={<OrangeCheckbox  name={region} />}
          checked={this.verifyChecked(region)}

          label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>{this.props.intl.formatMessage({ id: region })}</Typography>}
          onChange={(region) => this._ConstructRegionArray(region)}
        />
        </div>
      </Col>
    )})
  } catch (e) {
    return (<></>)
  }
}

CheckRender = () => {
  try {
    var cookies = new Cookies();
    var InfoAdmin = cookies.get('infoAdmin');
    var Region = InfoAdmin.Region;
    return (
      <>
      <Row style={{}}>
        <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'column', marginTop: 10}}>
           <div style={{borderBottom: '2px solid orange'}}>
            <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Comptabilité</h3>
           </div>
           <p style={{fontSize: '0.8rem', fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>Choissisez une des options suivante<br /> * Si l'évènement n'est pas gratuit, les paiements seront rediriger sur le compte de la comptabilité sélectionnée </p>
        </Col>
      </Row>
      <Row style={{margin: 10, marginTop: 20}}>
      {
        this.CreateCompta(Region)
      }
      </Row>
      {
        this.props.Error && this.props.Error.Region ?
        <Row style={{marginTop: 5}}>
          <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <p style={{fontSize: "0.9rem", color: "red", marginleft: 5}}>Veuillez selectionner une option</p>
          </Col>
        </Row>
        :
        <>
        </>

      }
      </>
    )
  } catch (e) {
    return (<></>)
  }
}


 render() {

   return (
     <>
     {this.CheckRender()}
      </>
   )
 }
}


export default Region;
