import { Button, Row, Container, Col, Image} from 'react-bootstrap';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import { IoIosArrowBack } from "react-icons/io";
import Fade from 'react-awesome-reveal'
import styled from "styled-components";
import {CheckFormatDate, DateDisplayEvent, DateDisplayEventApp} from '../../Moment/Moment'
import LogoB19 from '../../assets/ThumbLogo.png'
import ShareBtn from '../../assets/ic_share_white.png'
import ic_location from '../../assets/ic_location.png'
import ic_arrow_down_white from '../../assets/ic_arrow_down_white.png'
import ic_b19_grey from '../../assets/ic_b19_grey.png'
import ReactDevicePreview from './Device'
import './device.min.css'
const HtmlToReactParser = require('html-to-react').Parser;
const draftToHtml = require('draftjs-to-html')
const DiVV = styled.div`
  transform: scale(${({ scale }) => (scale ? scale : 0.9)});
  height: calc(100% * 0.9);
  -webkit-transform-origin: center;
  padding: 0,
`;

export default function PreviewEvent(props){

  const renderDate = () => {
      try {
        if (!CheckFormatDate(props.Date, 'YYYY/MM/DD'))
          return ('')
        var Display = DateDisplayEvent(props.Date, 'YYYY/MM/DD')
        if (Display === 'null/null/null')
          return ('')
        return (Display.charAt(0).toUpperCase() + Display.slice(1))
      } catch (e) {
        return (props.Date)
      }
  }

  const renderDateApp = (index) => {
      try {
        if (!CheckFormatDate(props.Date, 'YYYY/MM/DD'))
          return ('')
        var Display = DateDisplayEventApp(props.Date, 'en-gb', 'YYYY/MM/DD')
        if (Display === 'null/null/null')
          return ('')
        var Split = Display.split(' ')[index]
        return Split
      } catch (e) {
        return (props.Date)
      }
  }

const _renderDescription = () => {
  let htmlInput = draftToHtml(props.Description);
  let htmlToReactParser = new HtmlToReactParser();
  let reactElement = htmlToReactParser.parse(htmlInput);
  return(reactElement)
}

const renderStreet = (Name, Value) => {
  if (props[Name] && props[Name][Value])
    return (props[Name][Value])
  return ('')
}

  return (
    <>
    {
      props.preview === 'DeviceApp' ?
      <div className="test" style={{width: '100%', overflowX: 'scroll'}}>
        <div className="scale-preview">
          <Fade>
            <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{margin: 10}}>
            <ReactDevicePreview
              device="iphonex"
              scale="1"
              >
            <Container style={{padding: 0, margin: 0, marginTop: '30px'}}>
                <p style={{fontSize: 19, fontWeight: 'bold', color:"#292E45", textAlign: 'left', marginLeft: "15px",}}>Agenda</p>
                <div style={{backgroundColor: '#E8E9EB', height: 1}}></div>
                <p style={{fontSize: 15, fontWeight: 'bold', color:"#292E45", textAlign: 'left', marginTop: 5, marginLeft: "15px",}}>Afficher les évènements de</p>


                <div  style={{backgroundColor: 'rgb(239,106,31)', display: 'flex', borderRadius: 5, marginLeft: "15px", marginRight: '15px', padding: 10}}>
                  <div style={{flex: 1/8, alignItems: 'flex-start', justifyContent: 'center', marginLeft: 10}}>
                    <img style={{width: 18, height: 18, marginBottom: 3}} src={ic_location}/>
                  </div>
                  <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start', alignItems:'center'}}>

                      <p style={{color:'#F8b083', fontSize: 15, backgroundColor: 'transparent', padding: 0, margin: 0 }}>Sélectionnez une adresse B19</p>
                      </div>

                  <div style={{flex: 1/6, alignItems: 'center', justifyContent: 'center'}}>
                    <img src={ic_arrow_down_white} style={{width: 19, height: 13}}/>
                  </div>
                </div>


                <p style={{fontSize: 15, fontWeight: 'bold', color:"#292E45", textAlign: 'left', marginTop: 10, marginLeft: "15px"}}>Prochainement</p>

                <div style={{backgroundColor: '#E8E9EB', height: 1}}></div>
                  <div style={{display: 'flex', width: '100%', alignItems: 'center', marginTop: 10, paddingLeft: "15px", paddingRight: '15px'}}>


                    <div className="shadowww" style={{display: 'flex', width: '100%', justifyContent: 'center', borderRadius: 10}}>
                        <div style={{display: "flex", width: '100%', flexDirection: 'row', margin: 0, padding: 0}}>
                          <div style={{width: '30%'}}>
                          <img alt={"preview App"} src={props.FileAppUrl} style={{width: "100%", objectFit: 'cover', height:'100%', borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}/>

                          </div>
                          <div style={{display: 'flex', width: '65%',  margin: 0, marginTop: 3, marginLeft: 12, flexDirection: 'column'}}>
                            <div style={{display: "flex", flexDirection: "row", alignItems: 'flex-start', justifyContent: "flex-start", margin: 0, marginTop: 5, width: '100%'}}>
                              <img style={{height: 10, width: 10, marginTop: 3}} src={ic_b19_grey} />
                              <p className="textLineLocationName" style={{fontSize: 12, color: "#FF8500", padding: 0, margin: 0, marginLeft: 5, overflowWrap: 'anywhere'}}>{renderStreet('Location', 'Name')}</p>
                            </div>
                            <div style={{ textAlign: 'left', margin: 0, padding: 0, width: '100%', marginTop: 10, paddingRight: 10}}>
                              <p className='textLine' style={{fontSize: '0.8rem', fontWeight: 'bold', padding: 0, margin: 0, overflowWrap: 'break-word'}}>{props.Name}</p>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "flex-start",  margin: 0, width: '100%', marginTop: 5, marginBottom: 5}}>
                              <p style={{fontSize: 17, fontWeight: 'bold', padding: 0, margin: 0}}>{renderDateApp(0)} </p>
                              <p style={{fontSize: 12, color: "#AFBDC2",  margin: 0, marginLeft: 10, padding: 0,}}>{renderDateApp(1) + " " + renderDateApp(2)} </p>
                            </div>
                          </div>
                      </div>
                    </div>

                  </div>
              </Container>
            </ReactDevicePreview>
            </div>
              <div style={{margin: 10}}>
                <ReactDevicePreview
                  device="iphonex"
                  scale="1"
                  >
                  <Container style={{padding: 0, margin: 0, marginTop: '30px'}}>

                <div style={{textAlign: 'left'}}>
                <p style={{fontSize: 30, fontWeight: 'bold', color:"#292E45", marginLeft: 10}}> <IoIosArrowBack /> <span style={{fontSize: 19, fontWeight: 'bold', color:"#292E45", marginLeft: 10}}> Évènements </span> </p>
                </div>
                <div style={{textAlign: 'left', }}>
                <img alt={"preview App"} src={props.FileUrl} style={{width: "100%",}}/>
                </div>
                <div style={{marginLeft: "15px", marginRight: '15px'}}>
                <div style={{textAlign: 'left', marginTop: 20, display: 'flex', alignItems: 'flex-start'}}>
                <Image src={LogoB19} style={{width: "30px"}}/>
                <p style={{marginLeft: 10, color: "#FC8016"}}>{renderStreet('Location', 'Name')}</p>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{display: "flex", flex: 1}}>
                    <p style={{fontSize: 35, fontWeight: 'bold'}}>{renderDateApp(0)} <span style={{fontSize: 12, color: "#AFBDC2"}}> {renderDateApp(1) + " " + renderDateApp(2)} </span></p>
                  </div>
                  <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                    <Button style={{ backgroundColor: '#FC8016', fontSize: 14, color: 'white', borderWidth: 0}}> S'inscrire </Button>
                  </div>
                </div>
                <div style={{textAlign: 'left'}}>
                <p style={{fontWeight: 'bold'}}>
                {props.Name}
                </p>
                </div>
                <div style={{display: "flex"}}>
                  <div style={{display: "flex", flex: 1}}>
                  <Button style={{ backgroundColor: '#FC8016', fontSize: 14, color: 'white', borderWidth: 0, width:"95%"}}> + Ajouter au calendrier </Button>

                  </div>
                  <div style={{flex: 1/6, display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                    <Button style={{ backgroundColor: '#FC8016', fontSize: 14, color: 'white', borderWidth: 0, width: '80%'}}><img alt={"preview ShareBtn"} src={ShareBtn} style={{width: "15px", margin: 0, padding: 0}}/> </Button>
                  </div>
                </div>
                {/*ENLEVER LA WIDTH 20%*/}
                <div style={{height: 1, backgroundColor: "#E8E9EB", marginTop: 5, textAlign: 'left', width: "20%"}}/>
                <div style={{textAlign: 'left', marginTop: 20}}>
                <p>{_renderDescription()}</p>
                <p style={{fontWeight: 'bold'}}>Adresse:</p>
                <p>{renderStreet('Location', 'Street')}, {renderStreet('Location', 'Number')}, {renderStreet('Location', 'PostalCode')}, {renderStreet('Location', 'City')}</p>
                </div>
                </div>
              </Container>
            </ReactDevicePreview>
          </div>
        </div>
      </Fade>
      </div>
      </div>
      :
      <Fade>
      <DiVV className="shadow " style={{width: '100%'}}>
        <Container >


        <Row>
          <Col   style={{backgroundColor: '#FFFFFF', padding: 15, borderRadius: 5, margin: 10}}>

          <div style={{textAlign: 'center'}}>
          </div>
          <div style={{display:'flex', flexDirection: 'row', marginTop: 40,  justifyContent: 'center' }}>
            <Image style={{width: '5vh'}} src={LogoB19}  />
            <div style={{display:'flex', alignItems: 'flex-end', marginLeft: 10}}>
            </div>
          </div>

          <div style={{marginTop: 20, textAlign: 'center'}}>
            <h3>{renderStreet('Location', 'Name')}</h3>
          </div>
          <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>

            <div style={{display:'flex', alignItems: 'center', marginLeft: 10, marginTop: 12}}>
            <p style={{fontSize: "1.7vh"}}>{renderDate()}</p>
          </div>
        </div>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 20}} >
            <div style={{ width: '60%', textAlign: 'center'}}>
            <Image thumbnail style={{padding: 5, borderRadius: 10}}  src={props.FileUrl} />
            <div style={{textAlign: 'center', marginBottom: 30, marginTop: 30}}>
            <Button style={{fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0, marginTop: 10, fontSize: 18}}>S'INSCRIRE</Button>
            </div>

            <div style={{marginBottom: 30, textAlign: 'center'}}>
            <h5>{props.Name}</h5>
            </div>

            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 40, minWidth: '100%'}}>
            <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
            </div>

            <div style={{textAlign: 'left', marginTop: 50}}>
            <h4>Description</h4>
            </div>
              <div style={{textAlign: 'justify', marginTop: 20}}>{_renderDescription()}</div>


            <p style={{textAlign: 'left', fontWeight: 'bold'}}>Adresse:</p>
            <p style={{textAlign: 'left'}}>{renderStreet('Location', 'Street')}, {renderStreet('Location', 'Number')}, {renderStreet('Location', 'PostalCode')}, {renderStreet('Location', 'City')}</p>
            <div style={{marginTop: 50,  textAlign: 'center'}}>
            <FacebookShareButton disabled style={{margin: 5}}>
            <FacebookIcon size={32} round/>
            </FacebookShareButton>
            <FacebookMessengerShareButton disabled style={{margin: 5}}>
            <FacebookMessengerIcon size={32} round />
            </FacebookMessengerShareButton>
            <TwitterShareButton disabled style={{margin: 5}}>
            <TwitterIcon  size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton disabled style={{margin: 5}}>
            <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton  disabled style={{margin: 5}}>
            <EmailIcon size={32} round />
            </EmailShareButton>
            </div>
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 50}}>
                <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%'}}/>
              </div>
            </div>
          </div>

          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center'}}>
          {
            props.Sponsor && props.Sponsor.length != 0 ?
            <h3 style={{marginTop: 25, marginBottom: 50}}>Sponsorisé par</h3>
            :
            <>
            </>
          }
          </Col>
        </Row>
        <Row style={{display: 'flex', justifyContent:'center', paddingBottom: 50}}>

            {props.Sponsor && props.Sponsor.map((item) => {
              return(
                <Col xl={2} lg={2} md={2} sm={3}>
                  <a href={item.Link}>
                    <Image style={{margin: 10,objectFit: 'contain', width: "80%"}} src={item.LogoLarge ? item.LogoLarge : item.Logo}  />
                  </a>
                </Col>
              )
            })}

          </Row>
        </Container>
        </DiVV>
        </Fade>



    }
    </>


  )
}
