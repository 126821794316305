import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class PureAdmin extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

    ConstructArray = (Array) => {
    try {
      return Array.map(res => this.props.intl.formatMessage({ id: res })).join(', ')
    } catch (e) {
      return ("")
    }
  }

   ConstructRegion = (Permission) => {
    try {

        return (<p style={{color: 'black', fontSize: '0.8rem'}}>{this.ConstructArray(Permission)}</p>)
    } catch (e) {
      return (<></>)
    }
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.admin.FirstName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.admin.LastName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.admin.Email}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.ConstructRegion(this.props.admin.Permission)}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.admin.Status}</TableCell>
        <TableCell align="center" style={{}}>


        <Dropdown style={{textAlign: 'left'}}>
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary"/>
            <Dropdown.Menu variant="primary">
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                  <Button disabled={this.props.deleting} variant="text" style={{color: 'orange', fontWeight: 'bold',  fontSize: "0.65em", width: '100%'}} component={Link} to=
                  {{pathname: `/admin_edit/${this.props.admin.Id}`,
                    state: {Status: this.props.admin.Status, FirstName: this.props.admin.FirstName, LastName: this.props.admin.LastName, Email: this.props.admin.Email, Permission: this.props.admin.Permission}
                }}>
                    Editer
                  </Button>
              </Dropdown.Item>
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, AdminDelete: this.props.admin})}} disabled={this.props.deleting} variant="text" style={{color: 'red', fontWeight: 'bold',  fontSize: "0.65em", width: '100%'}}>
                  Supprimer
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>


        </TableCell>


      </TableRow>)
  }

}

export default PureAdmin;
