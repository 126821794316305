
import styled from "styled-components";


export const ButtonDiv = styled.div`
  width: '100%';
  height: 0;
  padding-bottom: 80%;
  border-radius: 15;
  position: relative;
  background-color: 'white';


  &:hover {
      opacity: 0.2;
  }

`;
