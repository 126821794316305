import React from 'react';
import ImageProfil from '../../Image/placeholder_man.png'
class ProfilImage extends React.Component {


  render (){
    return (
      <div style={{display: 'flex', minWidth: '50%', justifyContent: 'center', alignItems: 'center'}}>
        {
          this.props.Image ?
          <img  alt={"member"} src={this.props.Image} style={{width: '10.5em', borderRadius: 360, height: '10.5em', objectFit: 'cover'}}/>
            :
          <img  alt={"no ressource"} src={ImageProfil} style={{width: '10.5em', borderRadius: 360, height: '10.5em'}}/>

        }


      </div>
    )
  }
}

export default ProfilImage;
