import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';
import styled from "styled-components";
import Button from '@material-ui/core/Button';

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#082430',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#082430',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'orange',
        borderRadius: 10,
      },
      '&:hover fieldset': {
        borderColor: '#FF8C00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#082430',
      },
    },
  },
})(TextField);


export const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


export const ButtonCss = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: "#082430",
    borderColor: "#082430",
    '&:hover': {
      backgroundColor: '#0069d9',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
    },
    '&:focus': {
    },
  },
})(Button);


export const ButtonCssChecked = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    borderColor: "#082430",
    '&:hover': {
      backgroundColor: '#0069d9',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#0062cc',
    },
    '&:focus': {
    },
  },
})(Button);

export const AnimationRotate = (theme) => ({
      refresh: {
        margin: "auto",
        animation: "$spinNormal 0.5s 1",
        transform: "rotate(270deg)",

      },
      spin: {
        margin: "auto",
        animation: "$spin 0.5s 1",
        transform: "rotate(90deg)",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(90)",
        },
      },
      "@keyframes spinNormal": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(270deg)",
        },
      }
    })

export const SimpleButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    backgroundColor: '#082430',
    padding: '6px 12px',
    lineHeight: 1.5,
    '&:hover': {
    },
    '&:active': {
    },
    '&:focus': {
    },
  },
})(Button);

export const DivButton = styled("div")`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: ${({ checked }) => (checked ? "#0069d9" : "#082430")};

&:hover {
  background-color: #0069d9;
}

`;
