import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import ProfilMember from './ProfilMember'
import ModalBlockedUser from './ModalBlockedMember'
import ModalFreeUser from './ModalFreeMember'
import ModalDeleteUser from './ModalDeleteMember'
import ModalAcceptRefuseUser from './ModalAcceptRefuseMember'
import ModalUpdateContactFacturation from './ModalUpdateContactFacturation'

class SpecificMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        IdMember: null,
        modify: false,
        blocked: false,
        free: false,
        statusFree: null,
        statusBlocked: null,
        statusDelete: null,
        delete: false,
        accept_refuse: false,
        statusAccept_Refuse: null,
        actionAccept_Refuse: null,
        currentPage: 'info',
        NotAcces: true,
        verifyAccess: false,
        currentHistory: 'subscribe',
        ChangeTypeFacturation: false,
        statusChangeTypeFacturation: null,
        statusBufferChangeType: null,
        Error: null,
        ErrorBackend: null,


      }
    }

    _DownloadFileInvoice = async (keyFile, type) => {
      try {
        var url;
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var promise = await Requestdynamic('_DownloadInvoiceFile', {Jwt: Jwt, IdMember: this.state.IdMember, KeyFile: keyFile, TypeInvoice: type})
        if (promise.error === false && promise.data.error === false){
          url = promise.data.data
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        else {
          if (promise.error === true)
            return ;
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            return ;
        }
      } catch(e)
      {
        return ;
      }
    }

    GestionError = async (promise) => {
      try {

        if (promise.message === "CheckArg"){
          this.setState({Error: promise.data, statusChangeTypeFacturation: "Error"})
          return ;
        }
        else {
          this.setState({statusChangeTypeFacturation: "Error"})
          return ;
        }
      } catch (e) {
        this.setState({statusChangeTypeFacturation: "Error"})
        return ;
      }
    }

    _TypeContactGestion = async (Status) => {
      if (this.state.ChangeTypeFacturation === true){
        this.setState({statusChangeTypeFacturation: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember, Status: this.state.statusBufferChangeType}
          Params.Facturation = Object.assign({}, this.state.FacturationCopy)
          var promise = await Requestdynamic('_changeTypeContactFacturation', Params);
          console.log(promise)
          if (promise.error === false && promise.data.error === false){
              this.setState({statusChangeTypeFacturation: "Success"})
              await this.sleep(500)
              this.setState({Error: false, ChangeTypeFacturation: false, Facturation: this.state.FacturationCopy, TypeContactFacturation: this.state.statusBufferChangeType, statusBufferChangeType: null})
              return ;
          } else {
            if (promise.error === false){
              await this.GestionError(promise.data)
              return ;
            }
            this.setState({statusChangeTypeFacturation: "Error"})
            return ;
          }
        })
      } else {
        this.setState({ChangeTypeFacturation: true, statusChangeTypeFacturation: "Ask", statusBufferChangeType: Status})
        return ;
      }
    }

    _FreeGestion = async () => {
      if (this.state.free === true)
      {
        var Status;
        this.setState({statusFree: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember}
          Params.Status = this.state.FreeStatus === true ? "false" : "true"
          var promise = await Requestdynamic('_FreeMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusFree: "Success"}, async () => {
              await this.sleep(500)
              this.setState({loading: true}, () => {
                this.GetSpecificMember()
              })
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusFree: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusFree: "Error"})
          }
        })
      }
      else {
        this.setState({free: true, statusFree: "Ask"})
        return ;
      }

    }

    _BlockedGestion = async () => {
      if (this.state.blocked === true)
      {
        var Status;
        this.setState({statusBlocked: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember}
          Params.Status = this.state.Blocked === "true" ? "true" : "false"
          var promise = await Requestdynamic('_blockedMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusBlocked: "Success"}, async () => {
              await this.sleep(500)
              this.setState({loading: true}, () => {
                this.GetSpecificMember()
              })
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusBlocked: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusBlocked: "Error"})
          }
        })
      }
      else {
        this.setState({blocked: true, statusBlocked: "Ask"})
        return ;
      }

    }

    _ModifyEmail = () => {
      var paramsNavigate = {pathname: `/edit_email/${this.state.IdMember}`, state: {back: this.props.navigation.match.url}}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state.oldPage = this.props.navigation.location.state.oldPage
      this.props.navigation.history.push(paramsNavigate);
    }

    _ChangeInformationColleague = () => {
      var paramsNavigate = {pathname: `/edit_colleague_info_business_pack/${this.state.IdMember}`, state: {back: this.props.navigation.match.url, Colleague: this.state.Complement.Colleague, RegistrationType: this.state.Complement.RegistrationType, Price: this.state.Price, firstNameOwner: this.state.FirstName, lastNameOwner: this.state.LastName, emailOwner: this.state.Private.EmailPrivate, VariablePrice: this.state.VariablePrice}}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state.oldPage = this.props.navigation.location.state.oldPage
      this.props.navigation.history.push(paramsNavigate);
    }

    _DeleteUser = async () => {
      if (this.state.delete === true)
      {
        this.setState({statusDelete: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember}
          if (this.state.hasOwnProperty('Complement') && (this.state.Complement.RegistrationType === 'Business' || this.state.Complement.RegistrationType === 'Couple' || this.state.Complement.RegistrationType === 'BusinessPackCustomize') && this.state.Complement.BusinessInfo !== true){
            var promise = await Requestdynamic('_removeColleague', Params)
          }
          else
            var promise = await Requestdynamic('_deleteMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusDelete: "Success"}, async () => {
              await this.sleep(500)
              this.goBack()
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusDelete: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusDelete: "Error"})
          }
        })
      }
      else {
        this.setState({delete: true, statusDelete: "Ask"})
        return ;
      }

    }

    GetSpecificMember = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        Id: this.state.IdMember,
        SizeImage: "Large"
      }
      var promise = await Requestdynamic('_getSpecificMember', Params);
      if (promise.error === false && promise.data.error === false)
      {
        var Data = promise.data.data
        if (!Data.MemberStatus || Data.MemberStatus !== 'Member'){
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Information incorrect', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null, free: false, statusFree: null})
          return ;
        }
        try {
          var NotAcces = false
          if (this.state.verifyAccess === true)
          {
            var InfoMember = cookies.get('infoAdmin')
            var Region = InfoMember.Region;
            var RegionUsers = Data.Complement.Region;
            var index = Region.findIndex(res => res === RegionUsers)
            if (index === -1)
              NotAcces = true
          }
          var Keys = Object.keys(Data);
          var paramsSetState = {NotAcces: NotAcces, loading: false, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null, free: false, statusFree: null}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = Data[Keys[i]]
          }
          paramsSetState.FacturationCopy = Object.assign({}, paramsSetState.Facturation)
          this.setState(paramsSetState)
        } catch (e) {
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null, free: false, statusFree: null})
        }
      }
      else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null, free: false, statusFree: null})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null, free: false, statusFree: null})
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            var verifyAccess = false;
            if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.verify === true)
              verifyAccess = true
            this.setState({
              verifyAccess: verifyAccess,
              IdMember: this.props.navigation.match.params.memberid,
            }, async () => this.GetSpecificMember())
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage){

        var paramsNavigate = {pathname: this.props.navigation.location.state.oldPage}
        delete this.props.navigation.location.state.oldPage
        paramsNavigate.state = this.props.navigation.location.state
        this.props.navigation.history.push(paramsNavigate);
        return ;
      }
      if (this.state.Accept)
        this.props.navigation.history.push(`/allmembers/`);
      else
        this.props.navigation.history.push(`/waiting_member/`);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }


    _ModifyActionClick = () => {
      var paramsNavigate = {pathname: `/edit_member/${this.state.IdMember}`}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
      this.props.navigation.history.push(paramsNavigate);
    }

    _AcceptRefuseGestion = async (action) => {
      if (this.state.accept_refuse === true)
      {
        this.setState({statusAccept_Refuse: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember, Accept: this.state.actionAccept_Refuse}
          var promise = await Requestdynamic('_acceptRefuseMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusAccept_Refuse: "Success"}, async () => {
              await this.sleep(500)
              if (this.state.actionAccept_Refuse === false){
                this.goBack()
                return ;
              }
              this.setState({loading: true}, () => {
                this.GetSpecificMember()
              })
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusAccept_Refuse: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusAccept_Refuse: "Error"})
          }
        })
      }
      else {
        this.setState({accept_refuse: true, statusAccept_Refuse: "Ask", actionAccept_Refuse: action})
        return ;
      }

    }



 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>

     <ModalUpdateContactFacturation refParent={this} />
      <ModalAcceptRefuseUser refParent={this} openModal={this.state.accept_refuse} statusAccept_Refuse={this.state.statusAccept_Refuse} actionAccept_Refuse={this.state.actionAccept_Refuse} />
      <ModalBlockedUser refParent={this} openModal={this.state.blocked} statusBlocked={this.state.statusBlocked}/>
      <ModalFreeUser refParent={this} openModal={this.state.free} statusFree={this.state.statusFree}/>
      <ModalDeleteUser refParent={this} openModal={this.state.delete} statusDelete={this.state.statusDelete}/>
      <ProfilMember currentHistory={this.state.currentHistory} currentPage={this.state.currentPage} refParent={this} intl={this.props.intl} />
    </div>
   )
 }
}


export default SpecificMember;
