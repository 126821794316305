import React from 'react';
import IsConnectedAndAccess from '../../IsConnected/IsConnectedAndAccess'
import RenewMember from './renewMember'

class ParselRenewMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={true} Access={"delca"} Component={RenewMember} {...props}/>
   )
 }
}



export default ParselRenewMember;
