import React from 'react';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import {Button, Dropdown, DropdownButton } from 'react-bootstrap';

import Loading from './Loading'

class TableComponent extends React.Component {

   _renderTitle = () => {
      return (
          <>
        <th>#</th>
        <th>NOM</th>
        <th>MOIS</th>
        <th>DATE</th>
        <th>ONLINE</th>
        <th>ACTION</th>
          </>
      )
  }

   renderAll = () => {
     var display = []
      if (this.props.promotion.length != 0){
          display = [...this.props.promotion]
          if(this.props.search){
              display = [...this.props.search]
          }
        return(
          display.map((item, i) => {
            return(
              <tr style={{}}>
                  <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                  <td class="align-middle">{item.Name.toUpperCase()} </td>
                  <td class="align-middle">{item.Month}</td>
                  <td class="align-middle">{item.CreatedAt.split(' ')[0]}</td>
                  <td class="align-middle">
                    <Switch uncheckedIcon={false} checkedIcon={false} checked={item.Online} onChange={() => this.props.refParent._changeStatus(item)} width={42} height={22} />
                  </td>
                  <td>
                  <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                  <Dropdown.Item>
                      <Button onClick={() => this.props.refParent.setState({Name: item.Name, Month: item.Month, IdPromotionModify: item.Id, ModifyModalShow: true})} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                          MODIFIER
                      </Button>
                  </Dropdown.Item>
                  <Dropdown.Item>
                      <Button onClick={() => this.props.refParent._deletePromotion(item)} style={{backgroundColor: 'transparent', color: 'red', border:'none', width: '100%', textAlign: 'left'}}>
                          SUPPRIMER
                      </Button>
                  </Dropdown.Item>
                  </DropdownButton>
                  </td>
              </tr>
            )
          })
        )
      }
    }

  render (){
    return (
      <Table striped hover>
          <thead>
              <tr>
              {this._renderTitle()}
              </tr>
          </thead>
          <tbody>
            {
              this.props.loadingPromotion ?
              <td class="align-middle" colSpan={"6"} style={{fontWeight: 'bold'}}>
                <Loading loadingMore={this.props.loadingPromotion}/>
              </td>
              :
              this.renderAll()

            }
          </tbody>
      </Table>
    )
  }
}

export default TableComponent;
