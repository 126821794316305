import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddNoneMember from './AddNoneMember'

class ParselAddNoneMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddNoneMember} {...props}/>
   )
 }
}



export default ParselAddNoneMember;
