import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AllAmbassador from './AllAmbassador'

class ParselAllAmbassador extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AllAmbassador} {...props}/>
   )
 }
}



export default ParselAllAmbassador;
