import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class TableHeadHistory extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

 render() {

   return (

      <TableHead style={{backgroundColor: '#DAEFFF'}}>
        <TableRow >
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Date</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Nom de l'évènement</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Action</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Status</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Source</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Url de payement</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>Fichier</div></TableCell>
        </TableRow>
      </TableHead>

   )
 }
}


export default TableHeadHistory;
