import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import PopUpDelete from './PopUpDelete'
import Header from './Header'
import Cookies from 'universal-cookie'
import ModalPdfPrint from './ModalPrintPdf'
import Button from '@material-ui/core/Button';

class AllEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        event: [],
        eventDisplay: [],
        searchEvent: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        search: null,
        openModalDelete: false,
        deleting: null,
        EventDelete: null,
        PdfPrint: false,
        Filter: [],
        searchEventRegion: false,
        FilterRegion: [],
        sortFilter: {
          Name: "up",
          DateEvent: "up",
          EndHours: 'up',
          BeginHours: "up",
          NumberSubscribe: "up",
          Location: "up",
          PublicUrlSubscribe: "up",
          Location: 'up',
          HeaderLiner: 'up'
        }
      }
    }

    EventDisplayRegion = () => {
      var Copy, Display
      if (this.state.Filter.length === 0)
      {
         Copy = [...this.state.event];
         Display = Copy.slice(0, 20)
        this.setState({eventDisplay: Display, FilterRegion: [], searchEventRegion: false})
        return ;
      }
       Copy = [...this.state.event]
      var FilterResult = Copy.filter(res => this.state.Filter.find(region => region === res.RegionPlace))
       Display = FilterResult.slice(0, 20)
      this.setState({searchEventRegion: true, eventDisplay: Display, FilterRegion: FilterResult})
    }

    _sortByAlphabet = (Name) => {

      var Copy = [];
      if (this.state.searchEvent.length != 0)
        Copy = [...this.state.searchEvent]
      else
        Copy = [...this.state.event];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
          a[Name] = ""
        if (!b[Name])
          b[Name] = ""
          var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      var  paramsSetState = {eventDisplay: Display, sortFilter: sortOrder}
      paramsSetState[this.state.searchEvent.length === 0 ? "event" : "searchEvent"] = SortedResult
      this.setState(paramsSetState)
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _getAllNextEvent = async () => {

      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            SizeImage: "Medium",
          },
        }
        if (this.state.Filter.length !== 0)
          Params.Body.RegionFrontend = this.state.Filter
        var promise = await Requestdynamic('_getAllNextEvent', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var EventCopy = [...this.state.event];
          var newListEvent = EventCopy.concat(promise.data.data)
          newListEvent = [...new Set(newListEvent)]
          var EventDisplay = newListEvent.slice(0, 20)
          var paramsSetState = {eventDisplay: EventDisplay, event: newListEvent, EventDelete: null, statusDelete: null, delete: false}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
        }
        this.setState({loadingMore: false})
      })

    }

    _addAllNextEvent = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy = []
        if (this.state.search != null)
           Copy = [...this.state.searchEvent]
       else if (this.state.searchEventRegion === true)
         Copy = [...this.state.FilterRegion]
        else
           Copy = [...this.state.event]

        var CopyDisplay = [...this.state.eventDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, eventDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
      this._getAllNextEvent()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    search = (value) => {
      var Copy = []
      var Display =[]
      var Verify;
      if (this.state.searchEventRegion === true)
        Verify = "FilterRegion"
      else
        Verify = "event"
      if (value.length === 0)
      {
         Copy = [...this.state[Verify]];
         Display = Copy.slice(0, 20)
        this.setState({search: null, eventDisplay: Display, searchEvent: []})
        return ;
      }
      Copy = [...this.state[Verify]]
      var FilterResult = Copy.filter(res => res.Name.toLowerCase().includes(value.toLowerCase()))
      Display = FilterResult.slice(0, 20)
      this.setState({search: value, eventDisplay: Display, searchEvent: FilterResult})
    }


    handleScroll = (e) => {
      var verify = ""
      if (this.state.search === null && this.state.searchEventRegion === false)
         verify = 'event'
      else if (this.state.search != null)
        verify = 'searchEvent'
      else
        verify = 'FilterRegion'

        const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
        if (bottom && this.state.eventDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
          this._addAllNextEvent()
        }
    }

    _deleteEvent = () => {
      this.setState({deleting: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
            IdEvent: this.state.EventDelete.Id,
        }
        await this.sleep(2000)
        var promise = await Requestdynamic('_deleteEvent', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({deleting: 'Success'}, async () => {
            var Copy = [...this.state.event]
            var IndexCopy = Copy.findIndex(res => res.Id === this.state.EventDelete.Id)
            Copy.splice(IndexCopy, 1)
            var CopyDisplay = [...this.state.eventDisplay]
            var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.EventDelete.Id)
            CopyDisplay.splice(IndexCopyDisplay, 1)
            await this.sleep(500)
            this.setState({openModalDelete: false, event: Copy, eventDisplay: CopyDisplay, deleting: null, EventDelete: null})
          })
        }
        else {
          if (promise.error === true)
            this.setState({deleting: "Error"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else{
            if (promise.data && promise.data.NotAcces === true){
              this.setState({deleting: "NotAcces"})
              return ;
            }
            this.setState({deleting: "Error"})
          }
        }
      })

    }

    _printPdfFile = async () => {
     /* var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
          Jwt: Jwt,
          IdEvent: this.props.navigation.match.params.eventid,
      }
     var url = `${IP_BACKEND_DNS}/PrintParticipantEvent/`
     url = url + ContructUrlParams(Params)
     const link = document.createElement('a');
     link.href = url;
     link.target = '_blank'
     document.body.appendChild(link);
     link.click();
     link.parentNode.removeChild(link);*/
     this.setState({PdfPrint: true})
    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <PopUpDelete EventDelete={this.state.EventDelete} deleting={this.state.deleting} openModalDelete={this.state.openModalDelete} refParent={this}/>
     <ModalPdfPrint event={this.state.searchEventRegion === true ? this.state.FilterRegion : this.state.event} openModal={this.state.PdfPrint} refParent={this}/>
     <Header Event={this.state.event} Filter={this.state.Filter} intl={this.props.intl} refParent={this}/>
     <Button variant="text" style={{color: 'white', fontWeight: 'bold', fontSize: "1em", backgroundColor: '#082430'}} onClick={() => this._printPdfFile()}>Imprimer les évènements</Button>
     <Table loadingMore={this.state.loadingMore} refParent={this} deleting={this.state.deleting} eventDisplay={this.state.eventDisplay}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllEvent;
