import React, {useEffect, useState} from 'react';
import { ButtonGroup } from '@material-ui/core';
import {  ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {

    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const FilterChart = ({filter, classes, name}) => {

  const [formats, setFormats] = React.useState(() => []);
  //const styles = styles_import()

  const handleFormat = (event, newFormats) => {
    console.log(event)
    console.log(newFormats)
    console.log(name)
    setFormats(newFormats);
  };



  return (

    <StyledToggleButtonGroup
         size="small"
         value={formats}
         onChange={handleFormat}
         arial-label="text formatting"
       >
          {

              filter.map(filtre => {
                return (

                    <ToggleButton  value={filtre} arial-label={filtre}>{filtre}</ToggleButton>

                )
              })

          }
    </StyledToggleButtonGroup>


  )
}

export default FilterChart
