import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ItemPhoto from './ItemPhoto'
import Loading from './Loading'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { MdSettingsBackupRestore } from "react-icons/md";
import Button from '@material-ui/core/Button';


class ListPhoto extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderImages = () => {
      return (


          this.props.images.filter(res => res.delete === false).sort((a, b) => a.order - b.order).map((image, index) => (
          <LazyLoadComponent effect='blur'>
            <ItemPhoto handleDrop={this.props.handleDrop} handleDrag={this.props.handleDrag} refParent={this.props.refParent} edit={this.props.edit} image={image} key={index} index={index}/>
          </LazyLoadComponent>

        ))

      )
    }

 render() {
   return (

      <Container fluid >
        <Row>
          <Col sm={12} lg={12} md={12} xl={12}>
            <div style={{display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
              <Button variant='text' onClick={() => this.props.refParent._ResetGallery(350)} style={{margin: 10}}>
                <MdSettingsBackupRestore color={"orange"} size={30} />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>

            {this.renderImages()}

        </Row>
        <div style={{backgroundColor: "rgba(255, 255, 255, 0.8)", display: this.props.loading ? 'block' : 'none', position: 'absolute', top: 0, bottom:0, left: 0, right: 0, zIndex: 1000}}>
          <Loading loadingMore={this.props.loading} size={60}/>
        </div>

      </Container>
   )
 }
}


export default ListPhoto;
