import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Header from './Header'
import Table from './Table'
import ActionHeader from './ActionHeader'
import Alert from './Alert'
import AddModal from "./AddModal"
import ModifyModal from './ModifyModal'

class AllPromotion extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        promotion: [],
        loadingPromotion: true,
        Alert: null,
        searchText: "",
        search: null,
        show: false,
        AddModalShow: false,
        ModifyModalShow: false,
        modify: false,
        add: false,
        ErrorForm: null,
        Name: "",
        Month: 0,
        IdPromotionModify: null
      }
    }

    resetInformationAdd = () => {
      this.setState({ModifyModalShow: false, AddModalShow: false, Month: 0, Name: "", ErrorForm: null, IdPromotionModify: null})
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

   ModifyPromotion = async () => {
     var Error = {}

      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = 'Le nom de la promotion est obligatoire'
      if (isNaN(this.state.Month) || parseInt(this.state.Month, 10) < 0)
        Error.Month = 'Le nombre de mois est requis et doit être positif'
      var Key = Object.keys(Error)
      if (Key.length != 0){
        this.setState({ErrorForm: Error})
        return ;
      }
      this.setState({ErrorForm: null, modify: true}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');

        var Params = {
            Jwt: Jwt,
            Name:  this.state.Name,
            Month: this.state.Month,
            IdPromotion: this.state.IdPromotionModify
        }
        var promise = await Requestdynamic('_updatePromotion', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({ModifyModalShow: false, show: true, Alert: "SuccessModify"})
          return ;
        }
        else {
          if (promise.error === true){
            this.setState({modify: false, ErrorForm: {Request: "Veuillez vérifier votre connexion internet ou réessayer plus tard. Si le problème persiste, veuillez contacter le service technique."}})
            return ;
          }
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
        else {
          this.setState({modify: false, ErrorForm: {Request: "Veuillez vérifier votre connexion internet ou réessayer plus tard. Si le problème persiste, veuillez contacter le service technique."}})
          return ;
          }
        }
      })
   }

   AddPromotion = async () => {
     var Error = {}

      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = 'Le nom de la promotion est obligatoire'
      if (isNaN(this.state.Month) || parseInt(this.state.Month, 10) < 0)
        Error.Month = 'Le nombre de mois est requis et doit être positif'
      var Key = Object.keys(Error)
      if (Key.length != 0){
        this.setState({ErrorForm: Error})
        return ;
      }
      this.setState({ErrorForm: null, add: true}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');

        var Params = {
            Jwt: Jwt,
            Name:  this.state.Name,
            Month: this.state.Month
        }
        var promise = await Requestdynamic('_putNewPromotion', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({AddModalShow: false, show: true, Alert: "SuccessAdd"})
          return ;
        }
        else {
          if (promise.error === true){
            this.setState({add: false, ErrorForm: {Request: "Veuillez vérifier votre connexion internet ou réessayer plus tard. Si le problème persiste, veuillez contacter le service technique."}})
            return ;
          }
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
        else {
          this.setState({add: false, ErrorForm: {Request: "Veuillez vérifier votre connexion internet ou réessayer plus tard. Si le problème persiste, veuillez contacter le service technique."}})
          return ;
          }
        }
      })
   }

   _search = (info) => {
     info = info.toUpperCase()
     this.setState({searchText: info})
     if (info.length === 0)
      this.setState({search: null})
    var find = this.state.promotion.filter(item => item.Name.includes(info))
    this.setState({search: find})
   }

   _deletePromotion = async (item) => {
     var cookies = new Cookies();
     var Jwt = cookies.get('acces');

     var Params = {
         Jwt: Jwt,
         IdPromotion: item.Id,
     }
     var promise = await Requestdynamic('_deletePromotion', Params)
     if(promise.error === false && promise.data.error === false)
     {
       var Copy = [...this.state.promotion];
       var findIndex = Copy.findIndex(res => res.Id === item.Id)
       if (findIndex === -1){
         this._getAllPromotion()
         return ;
       }
      Copy.splice(findIndex, 1)
      this.setState({promotion: Copy, show: true, Alert: "SuccessDelete"})
     }
     else {
       if (promise.error === true){
         this.setState({show: true, Alert: "Error"})
         return ;
       }
       else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
         cookies.remove('acces', { path: '/' })
         cookies.remove('infoAdmin', { path: '/' })
         await this.sleep(900)
         var url = '/login'
         const link = document.createElement('a');
         link.href = url;
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);
         return ;
       }
     else {
       this.setState({show: true, Alert: "Error"})
       return ;
     }
     }

   }

   _changeStatus = async (item) => {
     var cookies = new Cookies();
     var Jwt = cookies.get('acces');

     var Params = {
         Jwt: Jwt,
         IdPromotion: item.Id,
         Status: item.Online ? "false" : 'true'
     }
     var promise = await Requestdynamic('_updateStatusOnlinePromotion', Params)
     if(promise.error === false && promise.data.error === false)
     {
       var Copy = [...this.state.promotion];
       var findIndex = Copy.findIndex(res => res.Id === item.Id)
       if (findIndex === -1){
         this._getAllPromotion()
        return ;
       }
      Copy[findIndex].Online = !item.Online
      this.setState({promotion: Copy})
     }
     else {
       if (promise.error === true){
         this.setState({show: true, Alert: "Error"})
         return ;
       }
       else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
         cookies.remove('acces', { path: '/' })
         cookies.remove('infoAdmin', { path: '/' })
         await this.sleep(900)
         var url = '/login'
         const link = document.createElement('a');
         link.href = url;
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);
         return ;
       }
     else {
       this.setState({show: true, Alert: "Error"})
       return ;
     }
     }

   }

    _getAllPromotion = async () => {
      this.setState({loadingPromotion: true}, async() => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');

        console.log(Jwt)
        var Params = {
            Jwt: Jwt,
        }
        var promise = await Requestdynamic('_getAllPromotion', Params)
        console.log(promise)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({loadingPromotion: false, promotion: promise.data.data})
        }
        else {
          if (promise.error === true){
            this.setState({loadingPromotion: false, show: true, Alert: "Error"})
            return ;
          }
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
        else {
          this.setState({loadingPromotion: false, show: true, Alert: "Error"})
          return ;
        }
        }
      })

    }

    componentDidMount(){
     this._getAllPromotion()
    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <Alert refParent={this}/>
      <AddModal refParent={this} />
      <ModifyModal refParent={this} />
      <Header />
      <ActionHeader refParent={this} />
      <Table refParent={this} search={this.state.search} loadingPromotion={this.state.loadingPromotion} promotion={this.state.promotion}/>
    </div>
   )
 }
}


export default AllPromotion;
