import React from 'react';
import {  IoIosAlert } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class Alert extends React.Component {


  render (){
    return (

      <Container className="shadow" style={{backgroundColor: this.props.background, borderRadius: 10, paddingBottom: 0,  whidth: '100%', marginBottom: 10}}>
        <Row style={{}}>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', height: '100%', flexDirection: 'row'}}>
              <div style={{width: '10%', display: 'flex', padding: 10, justifyContent: 'flex-start', alignItems: 'center'}}>
                <IoIosAlert size={30} color={this.props.color}/>
              </div>
              <div style={{width: '90%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <p style={{color: this.props.color}}>{this.props.intl.formatMessage({id: this.props.message})}</p>
              </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Alert;
