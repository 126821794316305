import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead'
import PureHistory from './PureHistory'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TableSubscribeHistory extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderHistory = () => {
      return (
        this.props.History.reverse().map((history, index) => (
        <PureHistory refParent={this.props.refParent} history={history} key={index} index={index}/>
      ))
      )
    }

 render() {
   return (
     <Table>
      <TableHead />
      {
        this.props.History.length === 0 ?
        <TableBody>
          <TableRow>
            <TableCell  colSpan={"6"}>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3}}>
                    <p style={{color: 'black', fontSize: '1.2rem', fontWeight: 'bold'}}>Pas de résultats</p>
                  </Col>
                </Row>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
        :
        <TableBody>
          {this.renderHistory()}
        </TableBody>
      }

    </Table>
   )
 }
}


export default TableSubscribeHistory;
