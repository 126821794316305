import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Header from './Header'
import Name from './Name'
import City from './City'
import Target from './Target'
import Date from './Date'
import Description from './Description'
import PopUp from './PopUp'
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import {CheckFormatDate} from '../../Moment/Moment'
import {Requestdynamic} from '../../Network/Fetch';
import { EditorState, convertToRaw } from "draft-js";
import Cookies from 'universal-cookie'

class AddGallery extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        City: "",
        Target: "FR",
        addGallery: false,
        Year: null,
        Day: null,
        Month: null,
        Error: null,
        Name: null,
        Editor: EditorState.createEmpty(),
        openModal: false,
        title: null,
        message: null,
        success: false,
      }
    }


    componentDidMount(){
    }

    _ConvertToRaw = (Error) => {
      try {
        if (!this.state.Editor)
          return (null)
        var RawEditor = convertToRaw(this.state.Editor.getCurrentContent())
        return (RawEditor)
      } catch (e) {
        return ("erreur")
      }

    }

    _CheckDate = (Error) => {
      if (!this.state.Year || !this.state.Month || !this.state.Day)
      {
        Error.Date = true
        return ;
      }
      var Date = this.state.Year + '/' + this.state.Month + '/' + this.state.Day
      if (!CheckFormatDate(Date, "YYYY/MM/DD"))
        Error.Date = true
      return ;
    }


    _CheckName = (Error) => {
      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = true
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _addGallery = async () => {
    this.setState({addGallery: true, Error: null}, async() => {
    var Error = {};

    this._CheckDate(Error)
    this._CheckName(Error)

    var Description = this._ConvertToRaw()

    var KeysErrorLength = Object.keys(Error).length
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, addGallery: false})
      return ;
    }
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var City = this.state.City.length === 0 ? null : this.state.City
    var Params = {
      Target: this.state.Target,
      City: City,
      Name: this.state.Name,
      Date: this.state.Year + '/' + this.state.Month + '/' + this.state.Day,
      Jwt: Jwt,
      Description: Description,
    }
    var promise = await Requestdynamic('_addGallery', Params)
    if(promise.error === false && promise.data.error === false)
    {
      this.setState({success: true, addGallery: false, openModal: true, title: 'Success', message: "La gallerie à bien été crée"})
    }
    else
    {
      if (promise.error === true)
        this.setState({addGallery: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
      {
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else
        this.setState({addGallery: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      return ;
    }
  })

}


 render() {
   return (
      <div style={{marginLeft: 10, overflowY: "auto"}}>
       <Container fluid>
          <PopUp Editor={this.state.Editor} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
         <Header intl={this.props.intl} />

         <Name Error={this.state.Error} intl={this.props.intl} Name={this.state.Name} refParent={this}/>
         <City Error={this.state.Error} intl={this.props.intl} City={this.state.City} refParent={this}/>
         <Target Error={this.state.Error} intl={this.props.intl} Target={this.state.Target} refParent={this}/>
         <Date
        Error={this.state.Error}
        intl={this.props.intl}
        Year={this.state.Year}
        Day={this.state.Day}
        Month={this.state.Month}
        refParent={this}/>
         <Description intl={this.props.intl} Editor={this.state.Editor} refParent={this}/>


         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <ButtonTestCss disabled={this.state.addGallery} color={"orange"} style={{margin: 10}} onClick={() => this._addGallery()}>
               {
                 this.state.addGallery ?
                 <CircularProgress
                   size={"1.5rem"}
                   thickness={5}
                   color={"inherit"}
                 />
                 :
                 <p style={{margin: 0, padding: 0}}>Ajouter</p>
               }
             </ButtonTestCss>

           </Col>
         </Row>

       </Container>
     </div>
   )
 }
}


export default AddGallery;
