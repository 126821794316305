import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddSponsor from './AddSponsor'

class ParselAddSponsor extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddSponsor} {...props}/>
   )
 }
}



export default ParselAddSponsor;
