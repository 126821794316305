import React  from 'react';
import { FaBars } from 'react-icons/fa';
import Footer from './Footer'
import { Route, Switch, Redirect } from 'react-router-dom'
import Cookies from 'universal-cookie'

import NotFound from './NotFound'

import DashBoard from '../DashBoard/Parsel'
import AddAdmin from '../Admin/AddAdmin/Parsel'
import EditAdmin from '../Admin/EditAdmin/Parsel'
import AllAdmin from '../Admin/AllAdmin/Parsel'

import AddAmbassador from '../Ambassador/AddAmbassador/Parsel'
import AllAmbassador from '../Ambassador/AllAmbassador/Parsel'
import AmbassadorEdit from '../Ambassador/EditAmbassador/Parsel'

import AllSponsor from '../Sponsor/AllSponsor/Parsel'
import AddSponsor from '../Sponsor/AddSponsor/Parsel'
import EditSponsor from '../Sponsor/EditSponsor/Parsel'

import AllEvent from '../Event/AllEvent/Parsel'
import AllPassedEvent from '../Event/AllPassedEvent/Parsel'
import EditEvent from '../Event/EditEvent/Parsel'
import SpecificEvent from '../Event/SpecificEvent/Parsel'
import AddEvent from '../Event/AddEvent/Parsel'
import SubscribeMemberEvent from '../Event/SubscribeMemberEvent/Parsel'
import AddParticipantEvent from '../Event/AddParticipantEvent/Parsel'
import InvoiceEvent from '../Event/InvoiceEvent/Parsel'

import AddGallery  from '../Gallery/AddGallery/Parsel'
import InfoGallery from '../Gallery/InfoGallery/Parsel'
import AllGallery from '../Gallery/AllGallery/Parsel'
import EditGallery from '../Gallery/EditGallery/Parsel'

import Login from '../Login/Login'
import CheckOut from '../CheckOut/CheckOut'
import ForgetPassword from '../ForgetPassword/ForgetPassword'

import AddAdress from '../Address/AddAdress/Parsel'
import AllAddress from '../Address/AllAddress/Parsel'
import EditAddress from '../Address/EditAdress/Parsel'

import AllPromotion from '../Promotion/AllPromotion/Parsel'

import AllMembers from '../Member/AllMember/Parsel'
import DeletedMember from '../Member/DeletedMember/Parsel'
import DetailsMember from '../Member/SpecificMember/Parsel'
import DetailsDeletedMember from '../Member/SpecificDeletedMember/Parsel'
import EditMember from '../Member/EditMember/Parsel'
import WaitingMember from '../Member/WaitingMember/Parsel'
import EndMember from '../Member/EndMember/Parsel'
import NewMemberStripe  from '../Member/NewMemberStripe/Parsel'
import EditColleague  from '../Member/EditColleague/Parsel'
import BusinessCustomize  from '../Member/BusinessCustomize/Parsel'

import AllNoneMember from '../NoneMember/AllNoneMember/Parsel'
import AddNoneMember from '../NoneMember/AddNoneMember/Parsel'
import DetailsNonMember from '../NoneMember/SpecificNoneMember/Parsel'
import EditNoneMember from '../NoneMember/EditNoneMember/Parsel'

import NewMemberDelca from '../Delca/NewMember/parsel'
import RenewMemberDelca from '../Delca/RenewMember/parsel'
import RenewBusinessPackCustomDelca from '../Delca/RenewBusinessPackCustom/parsel'

import NewMemberLuik from '../LuikMembers/NewMember/parsel'



import EditEmail from '../UpdateEmail/Parsel'

class Main extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        }
    }

    shouldComponentUpdate(nextProps, nextState){
      if (this.props.toggled !== nextProps.toggled)
        return false
      return true
    }

   checkCookies = () => {
    const cookies = new Cookies();
    var acces = cookies.get('acces')
    if (!acces || acces === "undefined")
      return (<></>)
    else
      return (<div className="btn-toggle" onClick={() => this.props.handleToggleSidebar(true)}>
        <FaBars />
      </div>
)
  }

render () {
  return (

    <main style={{display: 'flex', overflow: "hidden", backgroundColor: "#F8F8FF"}}>
        {
          this.checkCookies()
        }


          <Switch>
            {/*Gallery*/}
            <Route path="/info_gallery/:galleryid" component={(props) => <InfoGallery intl={this.props.intl} navigation={props}/>} />
            <Route path="/add_gallery" component={(props) => <AddGallery intl={this.props.intl}/>} />
            <Route path="/all_gallery" component={(props) => <AllGallery intl={this.props.intl}  navigation={props}/>} />
            <Route path="/edit_gallery/:idgallery" component={(props) => <EditGallery intl={this.props.intl}  navigation={props}/>} />
            {/*Sponsor*/}
            <Route path="/add_sponsor/" component={(props) => <AddSponsor intl={this.props.intl} navigation={props}/>} />
            <Route path="/all_sponsor/" component={(props) => <AllSponsor intl={this.props.intl} navigation={props}/>} />
            <Route path="/sponsor_edit/:sponsorid" component={(props) => <EditSponsor intl={this.props.intl} navigation={props}/>} />
            {/*Admin*/}
            <Route path="/all_admin/" component={(props) => <AllAdmin intl={this.props.intl} navigation={props}/>} />
            <Route path="/admin_edit/:adminid" component={(props) => <EditAdmin intl={this.props.intl} navigation={props}/>} />
            <Route path="/add_admin/" component={(props) => <AddAdmin intl={this.props.intl} navigation={props}/>} />
            {/*Event*/}
            <Route path="/add_event/" component={(props) => <AddEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/all_event/" component={(props) => <AllEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/details_event/:eventid" component={(props) => <SpecificEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/all_event_passed/" component={(props) => <AllPassedEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/add_member_event/:eventid" component={(props) => <AddParticipantEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/subscribe_member_event/:eventid" component={(props) => <SubscribeMemberEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/event_edit/:eventid" component={(props) => <EditEvent intl={this.props.intl} navigation={props}/>} />
            <Route path="/invoice_event/:eventid" component={(props) => <InvoiceEvent intl={this.props.intl} navigation={props}/>} />
            {/*Ambassador*/}
            <Route path="/ambassador_edit/:memberid" component={(props) => <AmbassadorEdit intl={this.props.intl} navigation={props}/>} />
            <Route path="/add_ambassador/" component={(props) => <AddAmbassador intl={this.props.intl} navigation={props}/>} />
            <Route path="/all_ambassador/" component={(props) => <AllAmbassador intl={this.props.intl} navigation={props}/>} />
            {/*Account*/}
            <Route path="/login" component={(props) => <Login intl={this.props.intl} refreshLayout={this.props.refreshLayout} navigation={props}/>} />
            <Route exact path="/" component={(props) => <Redirect to="/login" />} />
            <Route path="/validate_account" component={(props) => <CheckOut intl={this.props.intl} navigation={props}/>} />
            <Route path="/forget_password" component={(props) => <ForgetPassword intl={this.props.intl} navigation={props}/>} />
            {/*Address*/}
            <Route path="/add_place/" component={(props) => <AddAdress intl={this.props.intl} navigation={props}/>} />
            <Route path="/all_place/" component={(props) => <AllAddress intl={this.props.intl} navigation={props}/>} />
            <Route path="/edit_place/:placeid" component={(props) => <EditAddress intl={this.props.intl} navigation={props}/>} />
            {/*Promotion*/}
            <Route path="/all_promotion/" component={(props) => <AllPromotion intl={this.props.intl} navigation={props}/>} />

            {/*Member*/}
            <Route path="/allmembers" component={(props) => <AllMembers intl={this.props.intl} navigation={props}/>} />
            <Route path="/deletedmembers" component={(props) => <DeletedMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/details_member/:memberid" component={(props) => <DetailsMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/details_deleted_member/:memberid" component={(props) => <DetailsDeletedMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/edit_member/:memberid" component={(props) => <EditMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/waiting_member/" component={(props) => <WaitingMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/end_member/" component={(props) => <EndMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/new_member_invoice/" component={(props) => <NewMemberStripe intl={this.props.intl} navigation={props}/>} />
            <Route path="/edit_colleague_info_business_pack/:memberid" component={(props) => <EditColleague intl={this.props.intl} navigation={props}/>} />
            <Route path="/addbusinesscustom/" component={(props) => <BusinessCustomize intl={this.props.intl} navigation={props}/>} />
            {/*NoneMember*/}
            <Route path="/all_none_member/" component={(props) => <AllNoneMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/add_none_member/" component={(props) => <AddNoneMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/details_none_member/:memberid" component={(props) => <DetailsNonMember intl={this.props.intl} navigation={props}/>} />
            <Route path="/edit_none_member/:memberid" component={(props) => <EditNoneMember intl={this.props.intl} navigation={props}/>} />
             {/*Delca*/}
             <Route path="/newMemberDelca" component={(props) => <NewMemberDelca intl={this.props.intl} navigation={props}/>} />
             <Route path="/renewMemberDelca" component={(props) => <RenewMemberDelca intl={this.props.intl} navigation={props}/>} />
            <Route path="/renewBusinessPackCustomDelca" component={(props) => <RenewBusinessPackCustomDelca intl={this.props.intl} navigation={props}/>} />


            {/*LuikMembers*/}
            <Route path="/newMemberLuik" component={(props) => <NewMemberLuik intl={this.props.intl} navigation={props}/>} />




            <Route path="/edit_email/:memberid" component={(props) => <EditEmail intl={this.props.intl} navigation={props}/>} />
            <Route path="/dashboard/" component={(props) => <DashBoard intl={this.props.intl} navigation={props}/>} />

            <Route path='*' component={NotFound} />



          </Switch>
          <Footer />
    </main>

  );
}

};

export default Main;
