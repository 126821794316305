import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import PreviewEvent from '../PreviewEvent/PreviewEvent'
import '../PreviewEvent/device.min.css'

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
  visibility: ${({ showPopup }) => (showPopup ? 'visible' : 'hidden')};
`;

function PopUp(props) {
    const [preview, setPreview] = useState('Website')

    const _ButtonClick = () => {
      props.refParent.setState({PreviewEvent: false})
    }

    const publicEvent = () => {
      props.refParent.setState({PreviewEvent: false})
      props.refParent._updateEvent()
    }


   return (
       <Popup showPopup={props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', borderRadius: 5, overflowX: 'scroll', maxHeight: '90%', padding: 50}}>
       <Container>
       <Row>
        <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <p style={{color: '#082430', fontSize: '1.8rem', fontWeight: 'bold'}}>Visualisation de l'évènement</p>
        </Col>
       </Row>
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
           <ButtonTestCss onClick={() => setPreview('WebSite')} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>WebSite</ButtonTestCss>
           <ButtonTestCss onClick={() => setPreview('DeviceApp')} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>App</ButtonTestCss>
         </Col>
       </Row>
        <PreviewEvent
          preview={preview}
          FileAppUrl={props.refParent.state.FileAppUrl}
          Sponsor={props.refParent.state.SponsorSelect}
          Name={props.refParent.state.Name}
          Date={props.refParent.state.Year + '/' + props.refParent.state.Month + '/' + props.refParent.state.Day}
          Description={props.refParent._ConvertToRaw()}
          FileUrl={props.refParent.state.FileUrl}
          Location={props.refParent.state.LocationSelect}
        />
        <Row style={{margin: 10}}>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
            <ButtonTestCss onClick={() => publicEvent()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Publier</ButtonTestCss>
            <ButtonTestCss onClick={() => _ButtonClick()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Fermer</ButtonTestCss>
          </Col>
        </Row>
        </Container>
       </div>
       </Popup>
   )
}


export default PopUp;
