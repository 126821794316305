export const AnimationRotate = (theme) => ({
      refresh: {
        margin: "auto",
        animation: "$spinNormal 0.5s 1",
        transform: "rotate(270deg)",

      },
      spin: {
        margin: "auto",
        animation: "$spin 0.5s 1",
        transform: "rotate(90deg)",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(90)",
        },
      },
      "@keyframes spinNormal": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(270deg)",
        },
      }
    })
