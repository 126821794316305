import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import ProfilMember from './ProfilMember'
import ModalDeleteUser from './ModalDeleteMember'

class SpecificMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        IdMember: null,
        modify: false,
        statusDelete: null,
        delete: false,
        currentPage: 'info',
        currentHistory: 'event',
        NotAcces: true,
        verifyAccess: false,
      }
    }

    _DownloadFileInvoice = async (keyFile, type) => {
      try {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var url = '';
        var promise = await Requestdynamic('_DownloadInvoiceFile', {Jwt: Jwt, IdMember: this.state.IdMember, KeyFile: keyFile, TypeInvoice: type})
        if (promise.error === false && promise.data.error === false){
          url = promise.data.data
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        else {
          if (promise.error === true)
            return ;
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            return ;
        }
      } catch(e)
      {
        return ;
      }
    }

    _DeleteUser = async () => {
      if (this.state.delete === true)
      {
        this.setState({statusDelete: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember}
          var promise = await Requestdynamic('_deleteNonMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusDelete: "Success"}, async () => {
              await this.sleep(500)
              this.goBack()
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusDelete: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusDelete: "Error"})
          }
        })
      }
      else {
        this.setState({delete: true, statusDelete: "Ask"})
        return ;
      }

    }

    GetSpecificMember = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        Id: this.state.IdMember,
        SizeImage: "Small"
      }
      var promise = await Requestdynamic('_getSpecificMember', Params);

      if (promise.error === false && promise.data.error === false)
      {
        var Data = promise.data.data
        if (!Data.MemberStatus || Data.MemberStatus !== 'NonMember'){
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Information incorrect', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null})
          return ;
        }
        try {
          var NotAcces = false
          if (this.state.verifyAccess === true)
          {
            var InfoMember = cookies.get('infoAdmin')
            var Region = InfoMember.Region;
            var RegionUsers = Data.Complement.Region;
            var index = Region.findIndex(res => res === RegionUsers)
            if (index === -1)
              NotAcces = true
          }
          var Keys = Object.keys(Data);
          var paramsSetState = {NotAcces: NotAcces, loading: false, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = Data[Keys[i]]
          }
          this.setState(paramsSetState)
        } catch (e) {
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null})
        }
      }
      else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null})
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            var verifyAccess = false;
            if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.verify === true)
              verifyAccess = true
            this.setState({
              verifyAccess: verifyAccess,
              IdMember: this.props.navigation.match.params.memberid,
            }, async () => this.GetSpecificMember())
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage){

        var paramsNavigate = {pathname: this.props.navigation.location.state.oldPage}
        delete this.props.navigation.location.state.oldPage
        paramsNavigate.state = this.props.navigation.location.state
        this.props.navigation.history.push(paramsNavigate);
        return ;
      }
      this.props.navigation.history.push('/allmembers/');
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }


    _ModifyActionClick = () => {
      var paramsNavigate = {pathname: `/edit_none_member/${this.state.IdMember}`}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
      this.props.navigation.history.push(paramsNavigate);
    }

    _ModifyEmail = () => {

      var paramsNavigate = {pathname: `/edit_email/${this.state.IdMember}`, state: {back: this.props.navigation.match.url}}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state.oldPage = this.props.navigation.location.state.oldPage
      this.props.navigation.history.push(paramsNavigate);
    }



 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <ModalDeleteUser refParent={this} openModal={this.state.delete} statusDelete={this.state.statusDelete}/>
      <ProfilMember currentHistory={this.state.currentHistory} currentPage={this.state.currentPage} refParent={this} intl={this.props.intl} />
    </div>
   )
 }
}


export default SpecificMember;
