import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import { EditorState, convertToRaw } from "draft-js";
import Photo from './Photo'
import Header from './Header'
import PopUp from './PopUp'
import Info from './Info'
import Description from './Description'
import {ButtonTestCss} from "../../ButtonStyle";
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'


class AddAmbassadord extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        success: false,
        title: null,
        message: null,
        openModal: false,
        Editor: EditorState.createEmpty(),
        addAmbassador: false,
        Region: [],
        Linkedin: null,
        FirstName: null,
        LastName: null,
        Error: null,
        Function: null,
        Society: null,
        Picture: null
      }

    }

    componentDidMount(){
    }

    _CheckName = (Error) => {
      if (!this.state.FirstName || this.state.FirstName.length === 0)
        Error.FirstName = true
      if (!this.state.LastName || this.state.LastName.length === 0)
        Error.LastName = true

    }

    _CheckRegion = (Error) => {
      if (this.state.Region.length === 0)
        Error.Region = true
    }

    _ConvertToRaw = (Error) => {
      try {
        if (!this.state.Editor)
          return (null)
        var RawEditor = convertToRaw(this.state.Editor.getCurrentContent())
        return (RawEditor)
      } catch (e) {
        return ("erreur")
      }

    }

    _CheckPicture = (Error) => {
      if (!this.state.Picture)
        Error.Picture = true
    }

    _CheckFunction = (Error) => {
      if (!this.state.Function || this.state.Function.length === 0)
        Error.Function = true
    }

    _CheckSociety = (Error) => {
      if (!this.state.Society || this.state.Function.Society === 0)
        Error.Society = true
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _addAmbassador = async () => {
    this.setState({addAmbassador: true, Error: null}, async() => {
    var Error = {};

    this._CheckName(Error)
    this._CheckRegion(Error)
    this._CheckFunction(Error)
    this._CheckPicture(Error)
    this._CheckSociety(Error)

    var Description = this._ConvertToRaw()
    var Picture = this.state.Picture

    var KeysErrorLength = Object.keys(Error).length
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, addAmbassador: false})
      return ;
    }
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var Region = [...this.state.Region]
    var Params = {
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Fonction: this.state.Function,
      Linkedin: this.state.Linkedin,
      Society: this.state.Society,
      Region: Region,
      Jwt: Jwt,
      Picture: Picture,
      Description: Description
    }
    var promise = await Requestdynamic('_addAmbassador', Params)
    if(promise.error === false && promise.data.error === false)
    {
      this.setState({success: true, addAmbassador: false, openModal: true, title: 'Success', message: "L'ambassadeur à bien été ajouté"})
    }
    else
    {
      if (promise.error === true)
        this.setState({addAmbassador: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
      {
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else
      this.setState({addAmbassador: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
    }
  })

}

 render() {

   return (
     <>
      <div style={{marginLeft: 10, overflowY: "auto"}}>
       <Container fluid>
         <PopUp Editor={this.state.Editor} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
        <Header intl={this.props.intl}/>
        <Photo Error={this.state.Error} success={this.state.success} intl={this.props.intl} refParent={this}/>
        <Info
        FirstName={this.state.FirstName}
        refParent={this}
        LastName={this.state.LastName}
        Linkedin={this.state.Linkedin}
        Function={this.state.Function}
        Region={this.state.Region}
        Error={this.state.Error}
        Society={this.state.Society}
        intl={this.props.intl}/>
        <Description intl={this.props.intl} Editor={this.state.Editor} refParent={this}/>


        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ButtonTestCss disabled={this.state.addAmbassador} color={"orange"} style={{margin: 10}} onClick={() => this._addAmbassador()}>
              {
                this.state.addAmbassador ?
                <CircularProgress
                  size={"1.5rem"}
                  thickness={5}
                  color={"inherit"}
                />
                :
                <p style={{margin: 0, padding: 0}}>Ajouter</p>
              }
            </ButtonTestCss>

          </Col>
        </Row>

       </Container>
     </div>

     </>
   )
 }
}


export default AddAmbassadord;
