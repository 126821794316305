import React from 'react';
import TableEvent from './HistoryTable/Event/Table'
import TableStripe from './HistoryTable/Stripe/Table'

class SubscribeHistory extends React.Component {


  GetHistoryEvent = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = History.Event
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  GetHistoryStripe = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = History.Stripe
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  _render = () => {
    if (this.props.History === "event")
      return (<TableEvent refParent={this.props.refParent} History={this.GetHistoryEvent()} />)
  /*  else
      return (<TableStripe refParent={this.props.refParent} History={this.GetHistoryStripe()} />)*/
  }

  render (){
    return (
      <>
      {
        this._render()
      }
      </>
    )
  }
}

export default SubscribeHistory;
