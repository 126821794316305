import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FileUpload from "../../FileUpload/FileUpload";
import SeparateLine from "./SeparateLine"
import {GetCurrentDateEuropeParis} from '../../Moment/Moment'

const Photo = (props) => {

     const updateUploadedFiles = async (files) => {
       console.log(files)
      var file = files[0];
      console.log(file)
      var base64 = await _convertBase64(file);
      console.log(base64)

      props.refParent.setState({Picture: {Picture: base64,  Date: GetCurrentDateEuropeParis()}, FileUrl: URL.createObjectURL(file)})
     }

     const updateUploadedAppFiles = async (files) => {
      var file = files[0];
      var base64 = await _convertBase64(file);
      props.refParent.setState({PictureApp: {Picture: base64,  Date: GetCurrentDateEuropeParis()}, FileAppUrl: URL.createObjectURL(file)})
     }

    const _convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      try {
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          console.log(error)
          reject(error);
        };
      } catch (e) {
        return (null)
      }

    });
  };



   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row'}}>

              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{props.intl.formatMessage({ id: 'Photo' })}</h3>
              </div>
           </Col>
         </Row>
         <Row style={{display: 'flex', justifyContent: 'space-between'}}>
           <Col xl={6} lg={6} md={12} sm={12} >
             <FileUpload
             label={"Image site Web"}
             updateFilesCb={updateUploadedFiles}
             toUpload={false}
             photo={props.refParent.state.Picture ? props.refParent.state.Picture.Picture : props.OldPhoto}
             />
           </Col>
           <Col xl={6} lg={6} md={12} sm={12}>
             <FileUpload
             label={"Image application"}
             updateFilesCb={updateUploadedAppFiles}
             toUpload={false}
             photo={props.refParent.state.PictureApp ? props.refParent.state.PictureApp.Picture : props.OldPhotoApp}


             />
           </Col>
         </Row>
         <SeparateLine width={"50%"} height={1} color="greyTrans" />
     </>
   )
}


export default Photo;
