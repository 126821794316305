import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Title extends React.Component {
  constructor(props){
      super(props);
      this.state = {

        }
    }


 render() {
   return (
          <Row>
            <Col sm={12} lg={12} xl={12} md={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3, flexDirection: 'column'}}>
                <p style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Mitr, sans-serif', color: 'black', textAlign: 'center', width: '100%'}}>Modifier un administrateur</p>
                <div style={{height: 2, width: "25%", backgroundColor: 'orange'}} />

                <p style={{marginTop: 10, fontSize: 16, fontWeight: 'bold', fontFamily: 'Mitr, sans-serif', color: 'black', textAlign: 'center', width: '100%'}}>({this.props.Email})</p>

            </Col>
          </Row>
   )
 }
}


export default Title;
