import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Cookies from 'universal-cookie'

import {ButtonTestCss} from "../../ButtonStyle";

import Header from './Header'
import Contact from './Contact'
import Permission from './Permission'
import CircularProgress from '@material-ui/core/CircularProgress';
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'

class AddAdmin extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          Region: [],
          editAdmin: false,
          Error: null,
          Email: null,
          LastName: null,
          FirstName: null,
          success: false,
          title: null,
          message: null,
          openModal: false,
          verifyArgument: false,
          redirect: false,
          IdAdmin: null,
          SuperAdmin: "false"
        }
    }

    Check_Name = (Error) => {
      try {
        if (!this.state.FirstName || !this.state.LastName)
          Error.Contact = true
        if (this.state.FirstName.length === 0 || this.state.LastName.length === 0)
          Error.Contact = true
      } catch (e){
        Error.Contact = true

      }
    }


    _VerifySource = async () => {
      this.setState({verifyArgument: true}, async () => {

            if (!this.props.navigation.location || !this.props.navigation.location.state || !this.props.navigation.match.params.adminid){
              this.setState({verifyArgument: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              verifyArgument: false,
              SuperAdmin: this.props.navigation.location.state.Status === "Admin" ? "false" : "true",
              FirstName: this.props.navigation.location.state.FirstName,
              LastName: this.props.navigation.location.state.LastName,
              Region: this.props.navigation.location.state.Permission,
              IdAdmin: this.props.navigation.match.params.adminid,
              Email: this.props.navigation.location.state.Email
            })
        })
    }


    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);

    }

    goBack(){
        this.props.navigation.history.push(`/all_admin/`);
    }

    Check_Region = (Error) => {
      try {
        if (this.state.Region.length === 0)
          Error.Region = true
      } catch (e) {
        Error.Region = true

      }

    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
    }

    _editAdmin = async () => {
      this.setState({editAdmin: true, Error: null}, async () => {
        var Error = {};

        this.Check_Name(Error)
        this.Check_Region(Error)

        var KeysErrorLength = Object.keys(Error).length;
        if (KeysErrorLength !== 0){
          this.setState({Error: Error, editAdmin: false})
          return ;
        }
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Status = "";
        if (this.state.SuperAdmin === 'false')
          Status = "Admin"
        else
          Status = "SuperAdmin"
        var Params = {
          Jwt: Jwt,
          StatusAdmin: Status,
          Permission: this.state.Region,
          LastName: this.state.LastName,
          FirstName: this.state.FirstName,
          IdAdmin: this.state.IdAdmin
        }
        var promise = await Requestdynamic('_updateAdmin', Params);
        if (promise.error === false && promise.data.error === false){
          this.setState({editAdmin: false, openModal: true, title: 'Success', message: "L'administrateur à bien été mis à jour", redirect: true})
        }
        else {
          if (promise.error === true){
            this.setState({editAdmin: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
            return ;
          }
          if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else {
            this.setState({editAdmin: false, openModal: true, title: 'Error', message: promise.data.NotAccess ? "Vous n'avez pas les droits suffisant" : "Un problème est survenue veuillez réesayer plus tard"})
            return ;
          }

        }
      })
    }

 render() {

   return (

       <div style={{marginLeft: 10, overflowY: "auto"}}>
        <Container fluid>
          <PopUp redirect={this.state.redirect} refParent={this} openModal={this.state.openModal} message={this.state.message} title={this.state.title} />
          <Header Email={this.state.Email} refParent={this}/>
          <Contact LastName={this.state.LastName} FirstName={this.state.FirstName} Email={this.state.Email} Error={this.state.Error} refParent={this}/>
          <Permission Error={this.state.Error} intl={this.props.intl} Region={this.state.Region} refParent={this}/>

          <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
            <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ButtonTestCss disabled={this.state.editAdmin} color={"orange"} style={{margin: 10}} onClick={() => this._editAdmin()}>
                {
                  this.state.editAdmin ?
                  <CircularProgress
                    size={"1.5rem"}
                    thickness={5}
                    color={"inherit"}
                  />
                  :
                  <p style={{margin: 0, padding: 0}}>Modifier</p>
                }
              </ButtonTestCss>

            </Col>
          </Row>


        </Container>
      </div>

   )
 }
}


export default AddAdmin;
