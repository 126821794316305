import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Form} from 'react-bootstrap';

class AddModal extends React.Component {

  render (){
    return (
      <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.props.refParent.state.AddModalShow}
      onHide={() => this.props.refParent.resetInformationAdd()}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une promotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{marginTop: 15}}>
          <Form.Group as={Col} xl={8} lg={8} sm={12} md={12}>
            <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="text"
            placeholder={"Nom de la promotion"}
            value={this.props.refParent.state.Name}
            onChange={(event) => this.props.refParent.setState({Name: event.target.value})}/>
              {this.props.refParent.state.ErrorForm && this.props.refParent.state.ErrorForm.hasOwnProperty("Name") ?
                 <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.ErrorForm.Name}</p>
                 :
                 <></>
              }
          </Form.Group>
          <Form.Group as={Col} xl={4} lg={4} sm={12} md={12}>
            <p style={{textAlign: 'left', marginTop: 10}}>Mois<span style={{color: 'red'}}> *</span></p>
            <Form.Control
            required
            type="number"
            placeholder={"Nombre de mois de la promotion"}
            value={this.props.refParent.state.Month}
            onChange={(event) => this.props.refParent.setState({Month: event.target.value})}/>
            {this.props.refParent.state.ErrorForm && this.props.refParent.state.ErrorForm.hasOwnProperty("Month") ?
               <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.ErrorForm.Month}</p>
               :
               <></>
            }
          </Form.Group>
          </Row>
          <Row>
          {this.props.refParent.state.ErrorForm && this.props.refParent.state.ErrorForm.hasOwnProperty("Request") ?
             <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.ErrorForm.Request}</p>
             :
             <></>
          }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='text' style={{backgroundColor: '#FC8016', color: 'white'}} onClick={() => this.props.refParent.AddPromotion()}>
              <span style={{fontWeight: 'bold', color: "white"}}>
                  AJOUTER
              </span>
          </Button>
          <Button variant='text' style={{backgroundColor: '#082430', color: 'white'}} onClick={() => this.props.refParent.resetInformationAdd()}>
              <span style={{fontWeight: 'bold', color: "white"}}>
                  ANNULER
              </span>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddModal;
