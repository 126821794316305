import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import EditEvent from './EditEvent'

class ParselEditEvent extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={EditEvent} {...props}/>
   )
 }
}



export default ParselEditEvent;
