import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class PureLocation extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  renderLocationTrad = (Region) => {
    try {
      var trad = this.props.intl.formatMessage({id: Region})
      return (trad)
    } catch (e) {
      if (Region)
        return (Region)
      return ("")
    }
  }



  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.location.Name}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.location.City}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.location.PostalCode}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.location.Street}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.location.Number}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.renderLocationTrad(this.props.location.RegionB19)}</TableCell>
        <TableCell align="center" style={{}}>




        <Dropdown style={{textAlign: 'left'}}>
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary"/>
            <Dropdown.Menu variant="primary">
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                  <Button disabled={this.props.deleting} variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link} to=
                  {{pathname: `/edit_place/${this.props.location.Id}`,
                    state: {
                      Name: this.props.location.Name,
                      City: this.props.location.City,
                      Street: this.props.location.Street,
                      RegionB19: this.props.location.RegionB19,
                      Number: this.props.location.Number,
                      PostalCode: this.props.location.PostalCode}
                }}>
                  Editer
                </Button>
              </Dropdown.Item>
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, LocationDelete: this.props.location})}} disabled={this.props.deleting} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                  Supprimer
                </Button>

              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </TableCell>
      </TableRow>)
  }

}

export default PureLocation;
