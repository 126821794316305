import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import {CssTextField} from './Style'



class Place extends React.Component {


    _createLocation = () => {
      return this.props.Location.map((location, key) => {
        return (
          <option key={location.Id} value={location.Id}>{location.Name}</option>

        )
      })
    }

    _ChangeLocation = (event) => {
      var index = this.props.Location.findIndex(res => res.Id === event.target.value)
      if (index === -1)
        return ;
      this.props.refParent.setState({LocationId: event.target.value, LocationSelect: this.props.Location[index]})
    }

 render() {
   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Lieu</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col xl={6} lg={6} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
             <CssTextField  onChange={(event) => this._ChangeLocation(event)} value={this.props.LocationId} select label="Place" variant="outlined" SelectProps={{native: true}}>
               <option key={"PlaceHolder"} value={"PlaceHolder"} disabled>{"Veuillez indiquer ou se déroulera cette conférence"}</option>
               {this._createLocation()}
             </CssTextField>
             <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Location ? 'block' : 'none', marginTop: 5}}>Veuillez sélectionner un lieux</p>

           </div>
         </Col>
         </Row>

     </>
   )
 }
}


export default Place;
