import React from 'react';
import Button from '@material-ui/core/Button';
import { IoIosCheckmark } from "react-icons/io";
import { MdSettingsBackupRestore } from "react-icons/md";
import Loading from './Loading'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

class PureParticipant extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {

        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

backgroundColorGet = () => {
  if (this.props.index % 2 === 0)
    return ('')
  return ('#ECECEC')
}

  _getStyleCotisation = (Cotisation) => {
    if (Cotisation === "false")
      return ({color: 'red', fontWeight: 'bold'})
    else if (Cotisation === 'true')
      return ({color: 'green'})
    else
      return ({color: 'orange', fontWeight: 'italic'})

  }

  IsAddedParticipant = () => {
    var index = this.props.AddParticipant.findIndex(res => res.Id === this.props.member.Id)
    if (index === -1)
      return (false)
    return (true)
  }

  renderStatus = () => {
    var index = this.props.AddParticipant.findIndex(res => res.Id === this.props.member.Id)
    if (index === -1)
      return (
        <Button variant='text' onClick={() => this.props.refParent.AddParticipantEvent(this.props.member.Id)} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <MdSettingsBackupRestore color={"red"} size={20} />
        </Button>
    )
    if (this.props.AddParticipant[index].status === 'Waiting')
      return (<Loading size={20} loadingMore={true}/>)
    else if (this.props.AddParticipant[index].status === 'Error'){
      return (
        <Button variant='text' onClick={() => this.props.refParent.AddParticipantEvent(this.props.member.Id)} style={{margin: 10}}>
          <MdSettingsBackupRestore color={"red"} size={20} />
        </Button>
      )
    }
    else
      return (<IoIosCheckmark color={"green"} size={20} />)
  }

  render() {
    return (
      <TableRow key={this.props.member.Id} style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>



        <TableCell align="left">
        {
          this.props.member.Image ?
            <img alt='participant' src={this.props.member.Image} style={{width: 35, borderRadius: 360, height: 35}}/>
          :
            <div style={{width: 35, borderRadius: 360, height: 35, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell align="left">{this.props.member.LastName}</TableCell>
        <TableCell align="left">{this.props.member.FirstName}</TableCell>
        <TableCell align="left">{this.props.member.Email}</TableCell>
        <TableCell align="left">{this.props.member.Society}</TableCell>
        <TableCell align="left">{this.props.member.Function}</TableCell>
        <TableCell align="left">{this.props.member.RegistrationType}</TableCell>
        <TableCell align="center">{this.props.member.NumberSubscribeEvent}</TableCell>
        <TableCell align="left">{this.props.intl.formatMessage({id: this.props.member.Region})}</TableCell>
        <TableCell align="center" style={{}}>
        {
          this.IsAddedParticipant() ?
          this.renderStatus()
          :
          <Button onClick={() => this.props.refParent.AddParticipantEvent(this.props.member.Id)} variant="text" style={{color: 'orange', margin: 10, fontWeight: 'bold', fontSize: 12}}>
            Ajouter
          </Button>
        }
        </TableCell>


      </TableRow>)
  }

}


export default PureParticipant;
