import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container  from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

function EmailModify(props){
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    props.refParent._ChangeEmail()
  };

   return (

     <div>
       <div style={{backgroundColor: "#F6F7FB"}}>
       <Container className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50}}>
       <h2 style={{textAlign: 'left'}}>Modifier l'email</h2>

       <Form noValidate validated={validated} onSubmit={handleSubmit} style={{marginTop: 25}}>
         <Row>
           <Form.Group as={Col} controlId="formGridEmail">
             <p style={{textAlign: 'left', marginTop: 10}}>Email</p>
             <Form.Control
             required
             type="email"
             value={props.refParent.state.Email ? props.refParent.state.Email : ""}
             onChange={(e) => props.refParent.setState({Email: e.target.value})}
             />
             <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
             {"Veuillez saisir un e-mail"}
             </Form.Control.Feedback>
             <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
               {props.Error && props.Error.Email ? "Veuillez saisir un e-mail" : ""}
             </Form.Control.Feedback>
           </Form.Group>
           </Row>
           <Row>
           <Form.Group as={Col} controlId="formGridfirstName">
             <p style={{textAlign: 'left', marginTop: 10}}>Confirmation de l'e-mail</p>
             <Form.Control
             required
             type="email"
             value={props.refParent.state.VerifyEmail ? props.refParent.state.VerifyEmail : ""}
             onChange={(e) => props.refParent.setState({VerifyEmail: e.target.value})}
             />
             <Form.Control.Feedback type="invalid" style={{textAlign: 'left'}}>
               {"Veuillez confirmer l'e-mail"}
             </Form.Control.Feedback>
             <Form.Control.Feedback type="valid" style={{textAlign: 'left', color:'red'}}>
               {props.Error && props.Error.VerifyEmail ? "Veuillez confirmer l'e-mail" : ""}
             </Form.Control.Feedback>
           </Form.Group>
         </Row>

         <div className="centerButton" style={{marginBottom: 15}}>
           <Button
           style={{marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
           type="submit">
             ENREGISTRER
           </Button>
         </div>


       </Form>

     </Container>

     <Container style={{backgroundColor: '#ff5f00', height: 2, width: '20%', marginTop: 80}}/>
       </div>
     </div>

   )
}


export default EmailModify;
