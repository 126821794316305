import React from "react";
import ReactExport from "react-export-excel";
import {SimpleButton} from './Style'
import Typography from '@material-ui/core/Typography';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Download extends React.Component {
    render() {
        return (
            <ExcelFile element={<SimpleButton><Typography style={{fontWeight: 'bold', fontSize: '15px', color: 'white'}}>Exporter les non membres</Typography></SimpleButton>}>
                <ExcelSheet data={this.props.data}  name='Member'>
                    <ExcelColumn label="Nom" value="LastName"/>
                    <ExcelColumn label="Prénom" value="FirstName"/>
                    <ExcelColumn label="Email" value="Email"/>
                    <ExcelColumn label="Société" value="Society"/>
                    <ExcelColumn label="Inscription" value="AccountCreatedAt"/>
                    <ExcelColumn label="Nombre de participation" value="NumberParticipation"/>
                    <ExcelColumn label="Téléphone" value="Tel"/>
                    <ExcelColumn label="B19" value="Region"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}


export default Download
