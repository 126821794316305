import React from "react";
import ReactExport from "react-export-excel";
import Button from '@material-ui/core/Button';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Download extends React.Component {
    render() {
        return (
            <ExcelFile element={<Button variant="text" style={{color: 'white', fontWeight: 'bold', fontSize: "1em", backgroundColor: '#082430'}}>Exporter les membres</Button>}>
                <ExcelSheet data={this.props.data}  name='Member'>
                    <ExcelColumn label="Nom" value="LastName"/>
                    <ExcelColumn label="Prénom" value="FirstName"/>
                    <ExcelColumn label="Email" value="Email"/>
                    <ExcelColumn label="Anniversaire" value="Date"/>
                    <ExcelColumn label="Société" value="Society"/>
                    <ExcelColumn label="Inscription" value="AccountCreatedAt"/>
                    <ExcelColumn label="Fin adhésion" value="EndDate"/>
                    <ExcelColumn label="Nombre de participation" value="NumberSubscribeEvent"/>
                    <ExcelColumn label="Ville" value="CityPrivate"/>
                    <ExcelColumn label="Rue" value="StreetPrivate"/>
                    <ExcelColumn label="Code postal" value="ZipCodePrivate"/>
                    <ExcelColumn label="Téléphone" value="TelPrivate"/>
                    <ExcelColumn label="Type d'inscription" value="RegistrationType"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default Download
