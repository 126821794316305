import React from 'react';
import IsConnected from '../IsConnected/IsConnected'
import UpdateEmail from './UpdateEmail'

class ParselUpdateEmail extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={UpdateEmail} {...props}/>
   )
 }
}



export default ParselUpdateEmail;
