import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SeparateLine from './SeparateLine'

class Description extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    onEditorStateChange = (editorState) => {
        this.props.refParent.setState({Editor: editorState})
    };

 render() {

   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{this.props.intl.formatMessage({ id: 'DescriptionGallery' })}</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col sm={12} lg={12} xl={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
           <Editor
           editorState={this.props.Editor}
           wrapperClassName="demo-wrapper"
           editorClassName="demo-editor"
           onEditorStateChange={this.onEditorStateChange}
           toolbar={{
             colorPicker: {
             colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
               'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
               'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
               'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
               'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
               'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)', 'rgba(0,0,0,0)'],
           },
           options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'link', 'fontFamily', 'colorPicker'],
           inline: { inDropdown: false },
           list: { inDropdown: true },
           textAlign: { inDropdown: true },
           link: { inDropdown: true },
           history: { inDropdown: false },
            }}
          />
         </Col>
         </Row>
         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />
     </>
   )
 }
}


export default Description;
