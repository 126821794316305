import React from 'react';
import Header from './Header'
import {Requestdynamic, RequestFileUpload} from '../../Network/Fetch'
import PopUp from './PopUp'
import Loading from './Loading'
import Cookies from 'universal-cookie'
import Table from './Table'
import ModalInvoiceUser from './ModalInvoice'
import {GetCurrentDateEuropeParis, FormatFullDate} from '../../Moment/Moment'

class InvoiceEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        Name: null,
        Date: null,
        redirect: false,
        member: [],
        memberDisplay: [],
        MemberInvoice: null,
        Invoice: null,
        searchMember: [],
        statusInvoice: null,
        Error: null,
        search: null,
        IdEvent: null,
        finaliser: false,
        refresh: false,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: 'up',
          Society: "up",
          DateFacturation: "up",
        }
      }


    }

    UploadInvoice = async (member) => {
      if (this.state.finaliser === true)
      {
        this.setState({statusInvoice: "Waiting", Error: null}, async () => {
          var Error = {}
          if (!this.state.Invoice || this.state.Invoice.type !== "application/pdf")
          {
            Error.File = true
            this.setState({Error: Error, statusInvoice: "Error"})
            return ;
          }
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.MemberInvoice.Id, IdEvent: this.state.IdEvent, Send: this.state.send, file: this.state.Invoice}
          var promise = await RequestFileUpload('_sendInvoiceEvent', Params)
          if (promise.error === false && promise.data.error === false){
              var Date = GetCurrentDateEuropeParis()
              var Copy = [...this.state.member]
              var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              Copy[IndexCopy].DateFacturation = Date
              Copy[IndexCopy].File = promise.data.data
              var CopyDisplay = [...this.state.memberDisplay]
              var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              CopyDisplay[IndexCopyDisplay].DateFacturation = FormatFullDate(Date, 'YYYY/MM/DD HH:mm:ss','DD/MM/YYYY HH:mm')
              CopyDisplay[IndexCopyDisplay].File = promise.data.data
              this.setState({statusInvoice: "Success", member: Copy, memberDisplay: CopyDisplay, refresh: !this.state.refresh})
          }
          else {
            if (promise.error === true)
              this.setState({statusInvoice: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusInvoice: "Error"})
          }
        })
      }
      else {
        this.setState({finaliser: true, statusInvoice: "Ask", MemberInvoice: member})
        return ;
      }

    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.member];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
          var nameA, nameB;
          if (!a[Name])
            a[Name] = ""
          if (!b[Name])
            b[Name] = ""
          nameA = a[Name].toLowerCase()
          nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({memberDisplay: Display, member: SortedResult, sortFilter: sortOrder})
    }

    GetInvoiceEvent = async () => {
      this.setState({loadingMore: true}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {

            Jwt: Jwt,
            IdEvent: this.state.IdEvent,

        }
        var promise = await Requestdynamic('_getAllParticipantSubscribeInvoice', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var MemberCopy = [...this.state.member];
          var newListMember = MemberCopy.concat(promise.data.data)
          var MemberDisplay = newListMember.slice(0, 20)
          var paramsSetState = {memberDisplay: MemberDisplay, member: newListMember, loadingMore: false}
          this.setState(paramsSetState)
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
        }
      })
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.eventid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            let Name = this.props.navigation.location.state && this.props.navigation.location.state.Name ?  this.props.navigation.location.state.Name : null;
            let Date = this.props.navigation.location.state && this.props.navigation.location.state.Date ?  this.props.navigation.location.state.Date : null;
            if (!Name || !Date){
              this.setState({openModal: true, title: 'Error', message: "Une erreur est survenue", redirect: true})
              return ;
            }
            this.setState({
              IdEvent: this.props.navigation.match.params.eventid,
              Name: Name,
              Date: Date,
            }, async () => this.GetInvoiceEvent())
        })
    }

    _addAllNextMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy;
        if (this.state.search != null)
           Copy = [...this.state.searchMember]
        else
           Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
        this._VerifySource()
        this.goBack = this.goBack.bind(this);
    }

    search = (value) => {
      var Copy, Display
      if (value.length === 0)
      {
         Copy = [...this.state.member];
         Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
        return ;
      }
       Copy = [...this.state.member]
      var FilterResult = Copy.filter(res => res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
       Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }


      goBack(){

        var paramsNavigate = {pathname: `/subscribe_member_event/${this.state.IdEvent}`, state: this.props.navigation.location.state}
        this.props.navigation.history.push(paramsNavigate);

      }


    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

   handleScroll = (e) => {
     var verify;
     if (this.state.search === null)
        verify = 'member'
     else
        verify = 'searchMember'

     const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
     if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
       this._addAllNextMember()
     }
   }

   _DownloadFileInvoice = async (keyFile, IdMember) => {
     try {
       var cookies = new Cookies();
       var Jwt = cookies.get('acces');
       var url = '';
       var promise = await Requestdynamic('_DownloadInvoiceFile', {Jwt: Jwt, IdMember: IdMember,KeyFile: keyFile, TypeInvoice: 'Event'})
       if (promise.error === false && promise.data.error === false){
        url = promise.data.data
         const link = document.createElement('a');
         link.href = url;
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);
       }
       else {
         if (promise.error === true)
           return ;
         else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
         {
           cookies = new Cookies();
           cookies.remove('acces', { path: '/' })
           cookies.remove('infoAdmin', { path: '/' })
           await this.sleep(900)
           url = '/login'
           const link = document.createElement('a');
           link.href = url;
           document.body.appendChild(link);
           link.click();
           link.parentNode.removeChild(link);
           return ;
         }
         else
           return ;
       }
     } catch(e)
     {
       return ;
     }
   }

 render() {
   return (

      <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
        <PopUp redirect={this.state.redirect} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
        <ModalInvoiceUser Error={this.state.Error} intl={this.props.intl} refParent={this} send={this.state.send} statusInvoice={this.state.statusInvoice} openModal={this.state.finaliser} MemberInvoice={this.state.MemberInvoice}/>
       <Header refParent={this} search={this.props.search} Name={this.state.Name} Date={this.state.Date} goBack={this.goBack} routeChange={this.routeChange}/>
       <Table refresh={this.state.refresh} IdEvent={this.state.IdEvent} refParent={this} loadingMore={this.state.loadingMore} memberDisplay={this.state.memberDisplay} intl={this.props.intl}/>
       <Loading loadingMore={this.state.loadingMore}/>
      </div>

   )
 }
}


export default InvoiceEvent;
