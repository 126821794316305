import React from 'react';
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { Fade } from "react-awesome-reveal";
import Cookies from 'universal-cookie'
import { IoIosAlert } from "react-icons/io";
import Switch from "react-switch";
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';


const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class OwnerSection extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    componentDidMount(){

    }


    _createRegionFilter = () => {
      try {
        var cookies = new Cookies()
        var Info = cookies.get('infoAdmin')
        if (Info && Info.Region){
          var indexSplice = Info.Region.findIndex(res => res === "anv")
          if (indexSplice != -1)
            Info.Region.splice(indexSplice, 1)
          return Info.Region.map((region, index) => {
            return (
              <option value={region}>
               {this.props.intl.formatMessage({ id: region })}
              </option>
            )
          })
        }
        return (<></>)
      } catch (e){
        return <></>
      }
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

 render() {
   return (
     <Container ref={this.props.refParent.ownerSection} className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 10, padding: 50, margin: 10, marginTop: 25}}>
       <Row>
         <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations du titulaire</h6>
         </Col>
       </Row>
      <Form noValidate validated={this.props.refParent.state.EmailOwnerFormValidated} onSubmit={this.props.refParent.CheckEmailOwner} style={{marginTop: 25}}>
            <Row>
              <Col>
              <p style={{textAlign: 'left', marginTop: 10}}>Email<span style={{color: 'red'}}> *</span></p>
              </Col>
            </Row>
             <Row>
               <Col xl={5} lg={5} sm={6} md={4} style={{}}>
                 <Form.Control
                 required
                 value={this.props.refParent.state.EmailOwner}
                 onChange={(event) => this.props.refParent.setState({EmailOwner: event.target.value})}
                 placeholder="test@gmail.com"
                 />
                 {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Private") ?
                    <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Private.email}</p>
                    :
                    <></>
                  }
                 <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                  {this.props.refParent.state.messageEmailOwner}
                 </Form.Control.Feedback>
                 <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                  {this.props.refParent.state.messageEmailOwner}
                  </Form.Control.Feedback>

               </Col>
               <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
               <Button
               type="submit"
               style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
               >
               {
                 this.props.refParent.state.loadingEmailOwner === true ?
                 <div style={{position: 'relative'}}>
                   <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                     <CircularProgress color="inherit" size={20}/>
                   </div>
                   <div>
                     <p style={{margin: 0, color: 'transparent'}}>Vérifier</p>
                   </div>
                 </div>
                 :
                 <p style={{margin: 0}}>Vérifier</p>
               }
               </Button>
               </Col>
             </Row>
         </Form>
         {this.props.refParent.state.EmailOwnerValidated === true ?
            <Fade>
              <Row>
                <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={"Entrez le nom"}
                  value={this.props.refParent.state.LastName}
                  onChange={(event) => this.props.refParent.setState({LastName: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Private") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Private.lastName}</p>
                     :
                     <></>
                   }
                </Form.Group>
                <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Prénom<span style={{color: 'red'}}> *</span></p>
                  <Form.Control
                  required
                  type="text"
                  placeholder={"Entrez le prénom"}
                  value={this.props.refParent.state.FirstName}
                  onChange={(event) => this.props.refParent.setState({FirstName: event.target.value})}/>
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Private") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Private.firstName}</p>
                     :
                     <></>
                  }
                </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col} xl={5} lg={5} sm={12} md={12}>
                  <p style={{textAlign: 'left', marginTop: 10}}>Téléphone</p>
                  <Form.Control
                  type="text"
                  placeholder={"Entrez le téléphone"}
                  value={this.props.refParent.state.Tel}
                  onChange={(event) => this.props.refParent.setState({Tel: event.target.value})}/>
                </Form.Group>
                <Form.Group as={Col} xl={5} lg={5} sm={12} md={12} controlId="formGridCity">
                <p style={{textAlign: 'left', marginTop: 10}}>Région B19<span style={{color: 'red'}}> *</span></p>
                 <Form.Control value={this.props.refParent.state.Region ? this.props.refParent.state.Region : ""} required as="select" onChange={(event) => this.props.refParent.setState({Region: event.target.value})}>
                   <option hidden value="">
                    {"Choisissez une région"}
                   </option>
                   {this._createRegionFilter()}
             </Form.Control>
               {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Private") ?
                  <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Private.region}</p>
                  :
                  <></>
               }
            </Form.Group>
                </Row>
                <Row>
                <Form.Group as={Col}>
                  <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: "rows", }}>
                    <p style={{textAlign: 'left', marginTop: 10}}>Date de fin d'adhésion</p>
                    <span style={{marginLeft: 10}}>
                    <Switch onChange={() => this.props.refParent.setState({ModifyEndDate: !this.props.refParent.state.ModifyEndDate})} checked={this.props.refParent.state.ModifyEndDate}  width={42} height={22} />
                    </span>
                  </div>
                  <Form.Control
                  required
                  disabled={!this.props.refParent.state.ModifyEndDate}
                  type="number"
                  placeholder="jour"
                  value={this.props.refParent.state.Days}
                  onChange={(e) => this.props.refParent.setState({Days: e.target.value})}
                  />
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Private") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Private.EndDate}</p>
                     :
                     <></>
                  }
                </Form.Group>
                <Form.Group as={Col}>
                  <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
                  <Form.Control
                  required
                  disabled={!this.props.refParent.state.ModifyEndDate}
                  type="number"
                  placeholder="mois"
                  value={this.props.refParent.state.Month}
                  onChange={(e) => this.props.refParent.setState({Month: e.target.value})}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridYear">
                  <p style={{textAlign: 'left', marginTop: 10, color: 'transparent'}}>-</p>
                  <Form.Control
                  required
                  disabled={!this.props.refParent.state.ModifyEndDate}
                  type="number"
                  placeholder="année"
                  value={this.props.refParent.state.Years}
                  onChange={(e) => this.props.refParent.setState({Years: e.target.value})}
                  />
                </Form.Group>
                </Row>
            </Fade>
          :
          <>
          </>
        }
     </Container>
   )
 }
}


export default OwnerSection;
