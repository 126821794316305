import React from 'react';
import Button from '@material-ui/core/Button';
import { IoIosArrowBack } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Title from './Title'

class Header extends React.Component {
  render (){
    return (
      <>
        <Row>
          <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Button onClick={() => this.props.refParent.goBack()} variant='text' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <IoIosArrowBack  color={"orange"} size={20} />
              <p style={{color: 'black', margin: 10}}>Retour</p>
            </Button>
          </Col>

        </Row>
        <Title/>
      </>
    )
  }
}

export default Header;
