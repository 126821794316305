import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic, RequestFileUpload} from '../../Network/Fetch';
import PopUp from './PopUp'
import Cookies from 'universal-cookie'
import Header from './Header'
import ModalAcceptRefuseUser from './ModalAcceptRefuseMember'
import ModalInvoiceUser from './ModalInvoice'
import {GetCurrentDateEuropeParis} from '../../Moment/Moment'
import ExcelFile from './ExportExcelFile'
import ModalDetails from './ModalDetails'

class AllMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        member: [],
        memberDisplay: [],
        searchMember: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        search: null,
        Filter: [],
        MemberAcceptRefuse: null,
        accept_refuse: false,
        statusAccept_Refuse: null,
        actionAccept_Refuse: null,
        send: false,
        finaliser: false,
        Invoice: null,
        statusInvoice: null,
        refresh: false,
        Error: null,
        modalShow: false,
        detailsMemberShow: null,
        loadingDetailsMemberShow: false,
        errorDetailsMemberShow: false,
        detailsMemberShowBackend: null,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: "up",
          Society: "up",
          AccountCreatedAt: "up",
          RegistrationType: "up",
          Region: "up",
          DateInvoice: 'up'
        }
      }
    }

    UploadInvoice = async (member) => {
      if (this.state.finaliser === true)
      {
        this.setState({statusInvoice: "Waiting", Error: null}, async () => {
          var Error = {}
          if (!this.state.Invoice || this.state.Invoice.type !== "application/pdf")
          {
            Error.File = true
            this.setState({Error: Error, statusInvoice: "Error"})
            return ;
          }
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {EndAdhesionUpload: "false", Jwt: Jwt, Id: this.state.MemberInvoice.Id, Send: this.state.send, file: this.state.Invoice, PaymentLink: false}
          var promise = await RequestFileUpload('_UploadInvoiceNewStripeMember', Params)
          if (promise.error === false && promise.data.error === false){
              var Date = GetCurrentDateEuropeParis()
              var Copy = [...this.state.member]
              var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              Copy[IndexCopy].DateInvoice = Date
              var CopyDisplay = [...this.state.memberDisplay]
              var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberInvoice.Id)
              CopyDisplay[IndexCopyDisplay].DateInvoice = Date
              this.setState({statusInvoice: "Success", member: Copy, memberDisplay: CopyDisplay, refresh: !this.state.refresh})
          }
          else {
            if (promise.error === true)
              this.setState({statusInvoice: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusInvoice: "Error"})
          }
        })
      }
      else {
        this.setState({finaliser: true, statusInvoice: "Ask", MemberInvoice: member})
        return ;
      }

    }


    _sortByAlphabet = (Name) => {
      var Copy = [];
      if (this.state.searchMember.length != 0)
        Copy = [...this.state.searchMember]
      else
        Copy = [...this.state.member];


      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
          a[Name] = ""
        if (!b[Name])
          b[Name] = ""
         var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      var  paramsSetState = {memberDisplay: Display, sortFilter: sortOrder}
      paramsSetState[this.state.searchMember.length === 0 ? "member" : "searchMember"] = SortedResult
      this.setState(paramsSetState)
    }

    _getAllNextWaitingMember = async () => {
      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
        }
        if (this.state.Filter.length !== 0)
          Params.RegionFrontend = this.state.Filter
        var promise = await Requestdynamic('_getAllNextWaitingMember', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var MemberCopy = [...this.state.member];
          var newListMember = MemberCopy.concat(promise.data.data)
          newListMember = [...new Set(newListMember)]
          var MemberDisplay = newListMember.slice(0, 20)
          var paramsSetState = {memberDisplay: MemberDisplay, member: newListMember, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, MemberAcceptRefuse: null}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, MemberAcceptRefuse: null})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, MemberAcceptRefuse: null})
        }
        this.setState({loadingMore: false})
      })

    }

    _addAllNextWaitingMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy;
        if (this.state.search != null)
          Copy = [...this.state.searchMember]
        else
          Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
      this._getAllNextWaitingMember()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    search = (value) => {
      var Copy, Display;
      if (value.length === 0)
      {
        Copy = [...this.state.member];
        Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
      }
      Copy = [...this.state.member]
      var FilterResult = Copy.filter(res => {
        if (res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
          return true
        else if (res.Society && res.Society.toLowerCase().includes(value.toLowerCase()))
          return true
        return false
      })
      Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }

    handleScroll = (e) => {
      var verify;
      if (this.state.search === null)
        verify = 'member'
      else
        verify = 'searchMember'

      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
        this._addAllNextWaitingMember()
      }
    }

    GetSpecificMemberDetails = async (Member) => {
      this.setState({loadingDetailsMemberShow: true, modalShow: true, detailsMemberShow: Member}, async() => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: Member.Id,
          SizeImage: "Large"
        }
        var promise = await Requestdynamic('_getSpecificMember', Params);
        if (promise.error === false && promise.data.error === false)
        {
          var Data = promise.data.data
          this.setState({loadingDetailsMemberShow: false, detailsMemberShowBackend: Data})
        }
        else {
          if (promise.error === true)
            this.setState({loadingDetailsMemberShow: false, errorDetailsMemberShow: true})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({loadingDetailsMemberShow: false, errorDetailsMemberShow: true})
        }
      })
    }

    _AcceptRefuseGestion = async (member, action) => {
      if (this.state.accept_refuse === true)
      {
        this.setState({statusAccept_Refuse: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.MemberAcceptRefuse.Id, Accept: this.state.actionAccept_Refuse}
          var promise = await Requestdynamic('_acceptRefuseMember', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusAccept_Refuse: "Success"}, async () => {
              var Copy = [...this.state.member]
              var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberAcceptRefuse.Id)
              Copy.splice(IndexCopy, 1)
              var CopyDisplay = [...this.state.memberDisplay]
              var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberAcceptRefuse.Id)
              CopyDisplay.splice(IndexCopyDisplay, 1)
              await this.sleep(500)
              this.setState({statusAccept_Refuse: null, member: Copy, memberDisplay: CopyDisplay, accept_refuse: false, MemberAcceptRefuse: null})
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusAccept_Refuse: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusAccept_Refuse: "Error"})
          }
        })
      }
      else {
        this.setState({accept_refuse: true, statusAccept_Refuse: "Ask", actionAccept_Refuse: action, MemberAcceptRefuse: member})
        return ;
      }

    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <ModalDetails
            show={this.state.modalShow}
            loadingDetailsMemberShow={this.state.loadingDetailsMemberShow}
            detailsMemberShowBackend={this.state.detailsMemberShowBackend}
            detailsMember={this.state.detailsMemberShow}
            errorDetailsMemberShow={this.state.errorDetailsMemberShow}
            onHide={() => this.setState({modalShow: false, detailsMemberShow: null})}
            intl={this.props.intl}
          />
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <ModalInvoiceUser Error={this.state.Error} intl={this.props.intl} refParent={this} send={this.state.send} statusInvoice={this.state.statusInvoice} openModal={this.state.finaliser} MemberInvoice={this.state.MemberInvoice}/>
     <ModalAcceptRefuseUser MemberAcceptRefuse={this.state.MemberAcceptRefuse} refParent={this} openModal={this.state.accept_refuse} statusAccept_Refuse={this.state.statusAccept_Refuse} actionAccept_Refuse={this.state.actionAccept_Refuse} />
     <Header Member={this.state.member} Filter={this.state.Filter} intl={this.props.intl} refParent={this}/>
     <ExcelFile data={this.state.member}/>
     <Table refresh={this.state.refresh} loadingMore={this.state.loadingMore} intl={this.props.intl} refParent={this} deleting={this.state.deleting} memberDisplay={this.state.memberDisplay} member={this.state.member}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllMember;
