import React from 'react';
import { BsFillBriefcaseFill } from "react-icons/bs";
import Col from 'react-bootstrap/Col';

const ICONSIZE = 20;
const TEXTSIZE = "0.85em";


class Pro extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object = "Pro") => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE

    })
  }

  EmptyStreet = () => {
    if (!this.props.Pro || this.ConstructStringWithComma([this.props.Pro.StreetPro, this.props.Pro.ZipCodePro, this.props.Pro.CityPro], ',') === 'Vide')
      return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE
    })
    else
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
  }

  EmptyStatus = () => {
    if (!this.props.SocietyInfo || this.ConstructStringWithComma([this.props.SocietyInfo.Name, this.props.SocietyInfo.Status], '-') === 'Vide')
        return ({
        color: 'red',
        fontWeight: 'bold',
        fontSize: TEXTSIZE
      })
    else
      return ({color: 'black', fontSize: TEXTSIZE})
  }


  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={4} lg={4} md={12}>

            <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS PROFESSIONNELLES</p>
          <div style={{}}>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
                <BsFillBriefcaseFill size={ICONSIZE}/>
              </div>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <p style={this.EmptyStatus()}>{this.props.SocietyInfo ? this.ConstructStringWithComma([this.props.SocietyInfo.Name, this.props.SocietyInfo.Status], ' -') :  "Vide"}</p>
              </div>
            </div>



          </div>
          </Col>
    )
  }
}

export default Pro;
