import React, { useState, useEffect, useContext } from 'react';
import {Button, Dropdown, DropdownButton, Row, Col, Alert, Container } from 'react-bootstrap';
import Buttonne from '@material-ui/core/Button';
import { AiOutlineFieldNumber, AiOutlineFilePdf, AiOutlineSearch, AiOutlineExport } from "react-icons/ai";
import {Requestdynamic} from '../../Network/Fetch';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Cookies from 'universal-cookie'
import { BsDownload } from "react-icons/bs";
import { BiExport, BiRefresh } from "react-icons/bi";
import CircularProgress from '@material-ui/core/CircularProgress';
import Offcanvas from 'react-bootstrap/Offcanvas';
import CurrencyTextField from '../../CurrencyTextField/index'


import Badge from 'react-bootstrap/Badge';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Table from 'react-bootstrap/Table';
import Empty from '../../Image/empty.jpeg';



export default function NewMember() {

    const [member, setMember] = useState([])
    const [isSet, setIsSet] = useState(false)
    const [key, setKey] = useState("all")
    const [search, setSearch] = useState(null)
    const [searchText, setSearchText] = useState(null)
    const [modalSendMail, setModalSendMail] = useState({Id: null, visible: false})
    const [price, setPrice] = useState(0)
    const [loadingSendMail, setLoadingSendMail] = useState(false)

    const [loadingRefresh, setLoadingRefresh] = useState(false)
    const [loadingRefreshStatus, setLoadingRefreshStatus] = useState(false)

    const [loadingBilling, setLoadingBilling] = useState(false)

    const [error, setError]  = useState(true)

    const [loadingIndices, setLoadingIndices] = useState(new Set());
    const [loadingIndicesCreditNote, setLoadingIndicesCreditNote] = useState(new Set());

    const [show, setShow] = useState(false);
    const [showAlreadyPay, setShowAlreadyPay] = useState(false);
    const [showSuccesSendBiling, setShowSuccesSendBiling] = useState(false);
    const [showSuccesDelete, setShowSuccesDelete] = useState(false);
    const [showSuccesApprove, setShowSuccesApprove] = useState(false);

    const handleClose = () => setShow(false);
    const handleClosePay = () => setShowAlreadyPay(false);
    const handleCloseSuccesSendBilling = () => setShowSuccesSendBiling(false);
    const handleCloseSuccesDelete = () => setShowSuccesDelete(false);
    const handleCloseSuccesApprove = () => setShowSuccesApprove(false);
    const handleCloseModalSendMail = () => {setModalSendMail({Id: null, visible: false}); setPrice(0)}

    const _renderErrorAlert = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#F8D7D9'}} show={show} onHide={handleClose} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#831F29'}}>Une erreur est survenue !</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#831F29'}}>
                Veuillez vérifier votre connexion internet ou réessayer plus tard.
                <br/>
                Si le problème persiste, veuillez contacter le service technique.
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _renderSuccesSendBilling = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={showSuccesSendBiling} onHide={handleCloseSuccesSendBilling} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#0F5132'}}>
                La facture s'est bien envoyée.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }

    const _sendMailEndBusinessCustom = async () => {
      if (loadingSendMail === false){
        setLoadingSendMail(true)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          IdOwner: modalSendMail.Id,
          Price: price
       }
        var promise = await Requestdynamic('_sendMailEndBusinessCustom', Params);
        console.log(promise)
        if (promise.error === false && promise.data.error === false){
          setModalSendMail({Id: null, visible: false})
          setPrice(0)
          setLoadingSendMail(false)
          setShowSuccesSendBiling(true)
          _getEndBusinessCustom()
        }
        else {
            if (promise.error === true){
              setModalSendMail({Id: null, visible: false})
              setPrice(0)
              setLoadingSendMail(false)
              setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
              setModalSendMail({Id: null, visible: false})
              setPrice(0)
              setLoadingSendMail(false)

                setShow(true)
            }
        }
      }


    }

    const _renderModalVisible = () => {
      return (
          <>
          <Offcanvas style={{backgroundColor: 'white', height: 'auto'}} show={modalSendMail.visible} onHide={handleCloseModalSendMail} placement={'bottom'}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{color: 'black'}}>Envoyer la facture</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{}}>
            <Form>
              <Row>
                <Col>
                <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>Veuillez vérifier le prix du business pack avant d'envoyer la facture</p>
                </Col>
              </Row>
              <Row style={{marginTop: 10}}>
                <Col xl={3} lg={3} md={12} sm={12}>
                  <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
                  <CurrencyTextField
                     label="Montant"
                     variant="standard"
                     value={price}
                     currencySymbol="€"
                     minimumValue="0"
                     outputFormat="string"
                     decimalCharacter="."
                     digitGroupSeparator=""
                     onChange={(event) => setPrice(event.target.value)}
                     />
                  </div>
                </Col>
              </Row>
              <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20}}>

              <Button
              as={Col}
              xl={5}
              lg={5}
              sm={5}
              md={5}
              onClick={() => _sendMailEndBusinessCustom()}
              style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', marginTop: 10, adding: 8,  border: "none"}}
              >
              {
                loadingSendMail === true ?
                <div style={{position: 'relative'}}>
                  <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                  <CircularProgress
                  size={25}
                  thickness={4}
                  color={"white"}
                  />
                  </div>
                  <div>
                    <p style={{margin: 0, color: 'transparent'}}>Envoyer</p>
                  </div>
                </div>
                :
                <p style={{margin: 0}}>Envoyer</p>
              }
              </Button>

              </Row>
            </Form>

            </Offcanvas.Body>
          </Offcanvas>
        </>
        );
    }

    const _renderSuccesApprove = () => {
        return (
            <>
            <Offcanvas style={{backgroundColor: '#D1E6DD'}} show={showSuccesApprove} onHide={handleCloseSuccesApprove} placement={'top'}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title style={{color: '#0F5132'}}>Réussi ! </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{color: '#0F5132'}}>
                Ce membre à bien été accepté.
                <br/>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          );
    }


    useEffect(() => {
    if (!isSet)
        _getEndBusinessCustom()
  }, [isSet])


    const _search = (info) => {
        setSearchText(info.toLowerCase())
        if(info.length === 0)
            setSearch(null)
        var find = member.filter(item => item.UserInformation.Name.First.includes(info.toLowerCase()) || item.UserInformation.Name.Last.includes(info.toLowerCase()) || item.UserInformation.Email.Privates.includes(info.toLowerCase()))
        setSearch(find)
        setKey("all")
    }


    const _approve = async (info) => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: info
       }
        var promise = await Requestdynamic('_acceptEndMember', Params);
        console.log(promise)
        if (promise.error === false && promise.data.error === false){
            setShowSuccesApprove(true)
            _getEndBusinessCustom()
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }

    const _sendBilling = async (info) => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: info
       }
        var promise = await Requestdynamic('_sendMailRemindNewMember', Params);
        console.log("promise", promise)
        if (promise.error === false && promise.data.error === false){
            if(promise.data.InvoiceIsPaid === true)
                setShowAlreadyPay(true)
            else{
                setShowSuccesSendBiling(true)
            }
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }

    const _checkMail = (info) => {
        try {
        if (info && info.FacturationDelcaEndSubscription && info.FacturationDelcaEndSubscription.Mail)
            return(info.FacturationDelcaEndSubscription.Mail[info.FacturationDelcaEndSubscription.Mail.length - 1].split(" ")[0] + " (" +(info.FacturationDelcaEndSubscription.Mail.length) + ")")
        }catch{
            return(" ")
        }
    }

    const _refreshNewMember = async () => {
      setLoadingRefresh(true)
      await _getEndBusinessCustom()
      setLoadingRefresh(false)
    }

    const _getEndBusinessCustom = async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Delca: true,
       }
        var promise = await Requestdynamic('_getEndBusinessCustomDelca', Params);
        if (promise.error === false && promise.data.error === false){
            setIsSet(true)
            setMember(promise.data.data)
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
    }

    const _createCreditNote = async (item, i) => {
        item.facture = true
        setLoadingIndicesCreditNote((prev) => new Set([...prev, i]));
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          IdFacturation: item.FacturationDelcaEndSubscription?.CreditNote?.Id,
          Id: item.Id,
          Jwt: Jwt,
          Target: "credit_notes"
       }
        var promise = await Requestdynamic('_getFacturationPdfDelca', Params);
        console.log(promise)

        if (promise.error === false && promise.data.error === false){
            const linkSource = `data:application/pdf;base64,${promise.data.data}`;
            const link = document.createElement('a');
            link.href = linkSource;
            link.setAttribute('download', Params.IdFacturation + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
        setLoadingIndicesCreditNote((prev) => {
            const updated = new Set(prev);
            updated.delete(i);
            return updated;
          });

    }

    const _createBilling = async (item, i) => {
        item.facture = true
        setLoadingIndices((prev) => new Set([...prev, i]));
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          IdFacturation: item.FacturationDelcaEndSubscription.Id,
          Id: item.Id,
          Jwt: Jwt,
       }
       console.log(Params)
        var promise = await Requestdynamic('_getFacturationPdfDelca', Params);
        console.log(promise)
        if (promise.error === false && promise.data.error === false){
            const linkSource = `data:application/pdf;base64,${promise.data.data}`;
            const link = document.createElement('a');
            link.href = linkSource;
            link.setAttribute('download', Params.IdFacturation + '.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        else {
            if (promise.error === true){
                setShow(true)
            }
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
            }
            else {
                setShow(true)
            }
        }
        setLoadingIndices((prev) => {
            const updated = new Set(prev);
            updated.delete(i);
            return updated;
          });

    }

    const _setMaj = (string) => {
        try{
          const newString = string.split("-")
          for (var i = 0; i < newString.length; i++) {
            newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
          }
          const newStringJoin = newString.join("-")
          return (newStringJoin)
        }catch(e){
          return(" ")
        }
      }

    const GetColorFacturationIsPaid = (item) => {
      if (!item.FacturationDelcaEndSubscription || !item.FacturationDelcaEndSubscription.Id)
        return ('')
      if (item.FacturationDelcaEndSubscription.IsPaid)
        return ({color: 'white', backgroundColor: 'green', borderRadius: 2, boxShadow: "6px 4px 19px -3px #000000"})
      else if (item.FacturationDelcaEndSubscription.IsPaid === false)
        return ('')
      else
      return ({color: 'white', backgroundColor: 'orange', borderRadius: 2, boxShadow: "6px 4px 19px -3px #000000"})
    }

    const _renderAll = () => {

        if (member.length != 0){
            var display = [...member]
            if(search){
                display = [...search]
            }
            console.log(display)
          return(
            display.map((item, i) => {
                item.facture = false
              return(
                <tr style={{}}>
                    <td class="align-middle" style={{fontWeight: 'bold'}}>{i + 1} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.Last)} </td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Name.First)}</td>
                    <td class="align-middle">{_setMaj(item.UserInformation.Email.Privates)}</td>
                    <td class="align-middle">{item.UserInformation.Subscription.Begin}</td>
                    <td class="align-middle">{item.Price} </td>


                    <td>
                    <DropdownButton size="sm" id="dropdown-basic-button" variant="secondary" title="" style={{margin: 0, padding: 0}}>
                    <Dropdown.Item>
                    <Buttonne component={Link}
                    to={{
                    pathname: `/details_member/${item.Id}`,
                    state: {oldPage: "/renewBusinessPackCustomDelca/", verify: true}
                    }}>
                        Détails du membre
                    </Buttonne>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item>
                        <Button onClick={() => {setModalSendMail({Id: item.Id, visible: true}); setPrice(item.Price)}} style={{backgroundColor: 'transparent', color: 'black', border:'none', width: '100%', textAlign: 'left'}}>
                            ENVOYER FACTURE
                        </Button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Button onClick={() => _approve(item.Id)} style={{backgroundColor: 'transparent', color: 'green', border:'none', width: '100%', textAlign: 'left'}}>
                            VALIDER
                        </Button>
                    </Dropdown.Item>
                    </DropdownButton>
                    </td>
                </tr>
              )
            })
          )
        }
      }

      const _renderTitle = () => {
          return (
              <>
              <th>#</th>
            <th>NOM</th>
            <th>PRENOM</th>
            <th>EMAIL</th>
            <th>FIN</th>
            <th>PRIX</th>
            <th>ACTION</th>
              </>
          )
      }

      const _textAll = () => {
        return(
          <p style={{color: 'black'}}>
          ALL <span style={{fontWeight: 'bold'}}>({member.length})</span>
        </p>
        )
    }


  return (
    <div  style={{ overflowX: 'scroll'}}>

    {
        error === true ?
        <>
        {_renderErrorAlert()}

        </>
        :
        <>
        </>
  }

  {
      modalSendMail.visible === true ?
      <>
      {_renderModalVisible()}

      </>
      :
      <>
      </>
}

  {
      showSuccesApprove === true ?
      <>
      {_renderSuccesApprove()}
      </>
      :
      <>
      </>
  }

  {
      showSuccesSendBiling === true ?
      <>
      {_renderSuccesSendBilling()}
      </>
      :
      <>
      </>
  }

   <div style={{ paddingTop: '10px'}}>
       <h1>Renouvellements <span style={{color: "#FC8016"}}>Business Custom</span> </h1>
       <p style={{color: '#bfbfbf'}}>Delca / <span style={{fontWeight: 'bold'}}>Renouvellements Business Custom</span></p>
   </div>

    <Form style={{marginTop: '25px', marginBottom: '25px'}}>
        <Row>
            <Col style={{}} xl={4} lg={4} sm={12} md={12}>
            <Form.Group  md="4">
                <InputGroup hasValidation>
                <InputGroup.Text><AiOutlineSearch/></InputGroup.Text>
                <Form.Control
                    type="text"
                    placeholder="Rechercher"
                    value={searchText}
                    onChange={(event) => _search(event.target.value)}
                />
                </InputGroup>
            </Form.Group>
            </Col>
            <Col xl={8} lg={8} sm={12} md={12}>
            <div style={{justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex'}}>
                <div style={{paddingRight: 5, position: 'relative'}}>
                <Button variant='text' style={{backgroundColor: '#FC8016', color: 'white'}} onClick={() => _refreshNewMember()}>
                    <span style={{fontWeight: 'bold', color: loadingRefresh === true ? "transparent" : "white" }}>
                        ACTUALISER
                    </span>
                    <div style={{position: 'absolute', left: '0', right: '0', bottom: '0'}}>
                        {
                        loadingRefresh === true ?
                            <CircularProgress
                            size={25}
                            thickness={4}
                            color={"red"}
                            />
                            :
                            <>
                            </>
                        }
                    </div>
                    <BiRefresh size= {'24'} style={{marginLeft: '10px', color: loadingRefresh === true ? "transparent" : "white" }} />

                </Button>
                </div>
            </div>
            </Col>
        </Row>
    </Form>

    <Tabs
      defaultActiveKey="all"
      activeKey={key}
      id="fill-tab-example"
      className="mb-3"
      fill
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="all" title={_textAll()}>
        <Table striped hover>
            <thead>
                <tr>
                {_renderTitle()}
                </tr>
            </thead>
            <tbody>
                {_renderAll()}
            </tbody>
        </Table>
        </Tab>
    </Tabs>

    </div>
  )
}
