import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../ButtonStyle";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    _ButtonClick = () => {
      if (this.props.redirect === true)
      {
        this.props.refParent.goBack()
        return ;
      }
      var Params = {openModal: !this.props.openModal, title: null, message: null}

      this.props.refParent.setState(Params)
    }

 render() {

   return (
       <Popup showPopup={this.props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.5rem', alignItems: 'center'}}>{this.props.title}</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.3rem', alignItems: 'center'}}>{this.props.message}</p>
          </Col>
        </Row>
        <Row style={{margin: 10}}>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
            <ButtonTestCss onClick={() => this._ButtonClick()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>OK</ButtonTestCss>
          </Col>
        </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUp;
