import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import Header from './Header'
import PopUp from './PopUp'
import Colleague from './Colleague'
import PopUpDelete from './PopUpDeleteColleague'
import PopUpAdd from './PopUpAdd'
import Owner from './Owner'
import PopUpOwnerChange from './PopUpOwnerChange'
import TypeOfBusinessPack from './TypeOfBusinessPack'
import PopUpModifyType from './PopUpModifyType'
import PriceBusiness from './Price'
import PopUpModifyPrice from './PopUpPrice'
import PopUpVariablePrice from './ModalVariablePrice'

class EditColleague extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        redirect: false,
        IdMember: null,
        Colleague: [],
        delete: false,
        statusDelete: null,
        IdDelete: null,
        NewFirst: null,
        add: false,
        NewEmail: "",
        NewLast: null,
        adding: false,
        addResult: null,
        verifyEmailLoading: false,
        messageEmailOwner: null,
        IdOwner: null,
        EmailValidated: false,
        RegistrationType: null,
        openModalOwner: false,
        idOwnerChange: null,
        confirmOwnerChanger: false,
        changeOwnerResult: null,
        firstNameOwner: "",
        lastNameOwner: "",
        emailOwner: "",
        member: null,
        valueModifyType: null,
        modifyType: false,
        statusModifyType: null,
        statusModifyPrice: null,
        modifyPrice: false,
        modifyvariablePrice: false,
        statusVariablePrice: null,
      }
    }


    _handleChangeOwner = (id) => {
      this.setState({openModalOwner: true, idOwnerChange: id})
    }

    _modifyOwner = async () => {
      this.setState({confirmOwnerChanger: true}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {Jwt: Jwt, IdOwner: this.state.IdMember, IdMember: this.state.idOwnerChange}
        var promise = await Requestdynamic('_ownerChanger', Params)
        console.log(promise)
        if (promise.error === false && promise.data.error === false){
          var copy = [...this.state.Colleague]
          var objectNewOwner = copy.find(e => e.Id === this.state.idOwnerChange)
          if(objectNewOwner === undefined){
            this.setState({confirmOwnerChanger: false, changeOwnerResult: 'Error'})
            return ;
          }
          var paramsSetState = {
            firstNameOwner : objectNewOwner.firstName,
            lastNameOwner : objectNewOwner.lastName,
            emailOwner : objectNewOwner.email,
            IdMember : this.state.idOwnerChange,
            openModalOwner: false,
            idOwnerChange: null,
            confirmOwnerChanger: false,
            changeOwnerResult: null
          }
          copy.push({Id: this.state.IdMember, firstName: this.state.firstNameOwner, lastName: this.state.lastNameOwner, email: this.state.emailOwner})
          paramsSetState.Colleague = copy.filter(e => e.Id != this.state.idOwnerChange)
          this.setState(paramsSetState)
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({confirmOwnerChanger: false, changeOwnerResult: 'Error'})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
          this.setState({confirmOwnerChanger: false, changeOwnerResult: 'Error'})
        }
      })
    }

    _checkEmailColleague = async (event) => {
      event.preventDefault()
      event.stopPropagation()
      this.setState({verifyEmailLoading: true, messageEmailOwner: null}, async () => {
        if(this.state.NewEmail.length === 0){
          this.setState({messageEmailOwner: "Veuillez remplir ce champs", verifyEmailLoading: false})
          return ;
        }
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Email: this.state.NewEmail
        }
        var promise = await Requestdynamic('_searchMemberAttach', Params);
        if (promise.error === false && promise.data.error === false){
          if (promise.data.data != null){
            var InfoOwner = promise.data.data;
            if (InfoOwner.RegistrationType != 'SingleA' && InfoOwner.RegistrationType != 'SingleM'){
              let RegistrationType = InfoOwner.RegistrationType != null ? InfoOwner.RegistrationType : InfoOwner.MemberStatus
              let messageEmailOwner = "Cette adresse mail est déjà associé à un compte de type "+ RegistrationType +""
              var paramsSetState = {
                EmailValidated: true,
                verifyEmailLoading: false,
                messageEmailOwner: messageEmailOwner,
                IdOwner: InfoOwner.Id
              }
              this.setState(paramsSetState)
              return ;
            }
            var Info = cookies.get('infoAdmin')
            if (Info && Info.Region){
              var indexRegion = Info.Region.findIndex(res => res === InfoOwner.Region)
              if (indexRegion === -1)
              {
                let messageEmailOwner = "Cette adresse mail est associé à une Région B19 où vous n'avez pas d'accès"
                var paramsSetState = {
                  EmailValidated: true,
                  verifyEmailLoading: false,
                  messageEmailOwner: messageEmailOwner,
                  IdOwner: InfoOwner.Id
                }
                this.setState(paramsSetState)
                return ;
              }
            }
            var paramsSetState = {
              EmailValidated: true,
              verifyEmailLoading: false,
              NewFirst: InfoOwner.UserInformation.Name.First,
              NewLast: InfoOwner.UserInformation.Name.Last,
              IdOwner: InfoOwner.Id
            }
            this.setState(paramsSetState)
            return ;
          }
        }
        var paramsSetState = {
          EmailValidated: true,
          verifyEmailLoading: false,
          NewFirst: "",
          NewLast: "",
          IdOwner: null
        }
        this.setState(paramsSetState)
      })
    }


    _CheckEmail = () => {
      try {
        var Error = null
        if (!this.state.NewEmail || this.state.NewEmail.length === 0)
          Error = "Veuillez remplir ce champs"
        return (Error)
      } catch (e) {
        return ("Veuillez remplir ce champs")
      }
    }

    _CheckLast = (Error) => {
      try {
        if (!this.state.NewLast || this.state.NewLast.length === 0)
          Error.NewLast = true
      } catch (e) {
        Error.NewLast = true
      }
    }

    _CheckFirst = (Error) => {
      try {
        if (!this.state.NewFirst || this.state.NewFirst.length === 0)
          Error.NewFirst = true
      } catch (e) {
        Error.NewFirst = true
      }
    }


    _AddColleague = async () => {
      if (this.state.add === true){
        this.setState({Error: null, adding: true}, async () => {
          var Error = {};
          var messageEmailOwner = null;
          this._CheckLast(Error)
          messageEmailOwner = this._CheckEmail()
          this._CheckFirst(Error)
          var KeysErrorLength = Object.keys(Error).length
          if (KeysErrorLength != 0 || messageEmailOwner != null){
            this.setState({Error: Error, adding: false, messageEmailOwner: messageEmailOwner})
            return ;
          }
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, IdOwner: this.state.IdMember, Email: this.state.NewEmail, FirstName: this.state.NewFirst, LastName: this.state.NewLast, IdMember: this.state.IdOwner, Method: this.state.IdOwner ? "update" : "add"}
          var promise = await Requestdynamic('_addColleague', Params)
          if (promise.error === false && promise.data.error === false){
            var Copy = [...this.state.Colleague]
            var CopyMember = [...this.state.member]
            CopyMember.push(promise.data.NewColleagueInfo)
            Copy.push(promise.data.NewColleagueInfo)
            this.setState({addResult: null, add: false, NewEmail: "", NewFirst: "", NewLast: "", EmailValidated: false, IdOwner: false, Colleague: Copy, adding: false, member: CopyMember})
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({adding: false, addResult: 'Error'})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({adding: false, addResult: 'Error'})
          }
        })
      } else {
        this.setState({add: true})
        return ;
      }
    }

    _ModifyPrice = async (value) => {
      if (this.state.modifyPrice === true){
        this.setState({statusModifyPrice: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, IdOwner: this.state.IdMember, Price: this.state.Price}
          var promise = await Requestdynamic('_changePriceOfBusiness', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusModifyPrice: 'Success'})
            await this.sleep(500)
            this.setState({modifyPrice: false})
            return;
          }
          else {
            if (promise.error === true)
              this.setState({statusModifyPrice: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusModifyPrice: "Error"})
          }
        })
      } else {
        this.setState({modifyPrice: true, statusModifyPrice: "Ask"})
      }
    }

    _ModifyPack = async (value) => {
      if (this.state.modifyType === true){
        this.setState({statusModifyType: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember, Type: this.state.valueModifyType}
          var promise = await Requestdynamic('_changeTypeOfBusiness', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusModifyType: 'Success', RegistrationType: this.state.valueModifyType, valueModifyType: null, modifyType: false})
            return;
          }
          else {
            if (promise.error === true)
              this.setState({statusModifyType: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusModifyType: "Error"})
          }
        })
      } else {
        this.setState({modifyType: true, statusModifyType: "Ask", valueModifyType: value})
      }
    }

    _VariablePriceGestion = async () => {
      if (this.state.modifyvariablePrice === true)
      {
        var Status;
        this.setState({statusVariablePrice: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdMember}
          Params.VariablePrice = this.state.VariablePrice === true ? "false" : "true"
          var promise = await Requestdynamic('_variablePrice', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusVariablePrice: 'Success'})
            await this.sleep(500)
            this.setState({modifyvariablePrice: false, VariablePrice: !this.state.VariablePrice})
            return;
          }
          else {
            if (promise.error === true)
              this.setState({statusVariablePrice: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusVariablePrice: "Error"})
          }
        })
      }
      else {
        this.setState({modifyvariablePrice: true, statusVariablePrice: "Ask"})
        return ;
      }

    }



    _DeleteColleague = async (memberId) => {
      if (this.state.delete === true)
      {
        this.setState({statusDelete: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.IdDelete}
          var promise = await Requestdynamic('_removeColleague', Params)
          if (promise.error === false && promise.data.error === false){
            this.setState({statusDelete: "Success"}, async () => {
              await this.sleep(500)
              var Copy = [...this.state.Colleague]
              var index = Copy.findIndex(res => res.Id === this.state.IdDelete)
              if (index === -1)
              {
                this.setState({delete: false, statusDelete: null, IdDelete: null})
                return;
              }
              Copy.splice(index, 1)
              var CopyMember = [...this.state.member].filter(e => e.Id != this.state.IdDelete)
              this.setState({delete: false, statusDelete: null, IdDelete: null, Colleague: Copy, member: CopyMember})
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusDelete: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusDelete: "Error"})
          }
        })
      }
      else {
        this.setState({delete: true, statusDelete: "Ask", IdDelete: memberId})
        return ;
      }

    }


    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.Colleague)
            this.setState({
              IdMember: this.props.navigation.match.params.memberid,
              Colleague: this.props.navigation.location.state.Colleague,
              RegistrationType: this.props.navigation.location.state.RegistrationType,
              Price: this.props.navigation.location.state.Price,
              VariablePrice: this.props.navigation.location.state.VariablePrice,
              firstNameOwner: this.props.navigation.location.state.firstNameOwner,
              lastNameOwner: this.props.navigation.location.state.lastNameOwner,
              emailOwner: this.props.navigation.location.state.emailOwner,
              member: [...this.props.navigation.location.state.Colleague, {Id: this.props.navigation.match.params.memberid, firstName: this.props.navigation.location.state.firstNameOwner, lastName: this.props.navigation.location.state.lastNameOwner, email: this.props.navigation.location.state.emailOwner}],
              loading: false,
              IdMemberGoBack: this.props.navigation.match.params.memberid,
            })
            else{
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Vous ne pouvez pas accéder à cette page'})
              return ;
            }
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      var Id = this.state.IdMemberGoBack ? this.state.IdMemberGoBack : this.props.navigation.match.params.memberid
      var paramsNavigate = {pathname: `/details_member/${Id}`}
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
        paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
        this.props.navigation.history.push(paramsNavigate);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <PopUp refParent={this} redirect={this.state.redirect} message={this.state.message} title={this.state.title} openModal={this.state.openModal}/>
      <PopUpModifyType refParent={this}/>
      <PopUpModifyPrice statusModifyPrice={this.state.statusModifyPrice} refParent={this}/>
      <Header refParent={this}/>

      <TypeOfBusinessPack refParent={this}/>
      {
        this.state.RegistrationType &&   this.state.RegistrationType === "BusinessPackCustomize" ?
        <PriceBusiness refParent={this}/>
        :
        <>
        </>
      }
      <Colleague refParent={this} loading={this.state.loading} Colleague={this.state.Colleague}/>
      <Owner refParent={this} loading={this.state.loading} Member={this.state.member}/>

      <PopUpAdd addResult={this.state.addResult} adding={this.state.adding} Error={this.state.Error} NewFirst={this.state.NewFirst} NewLast={this.state.NewLast} NewEmail={this.state.NewEmail} refParent={this} add={this.state.add} />
      <PopUpDelete refParent={this} delete={this.state.delete} statusDelete={this.state.statusDelete} />
      <PopUpOwnerChange refParent={this}/>
      <PopUpVariablePrice refParent={this}/>

    </div>
   )
 }
}


export default EditColleague;
