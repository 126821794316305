import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import InfoGallery from './InfoGallery'

class ParselInfoGallery extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={InfoGallery} {...props}/>
   )
 }
}



export default ParselInfoGallery;
