import React from 'react';
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

import ProfilMember from './ProfilMember'

class SpecificDeletedMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        loading: true,
        IdMember: null,
        currentPage: 'info',
        NotAcces: true,
        verifyAccess: false,
        currentHistory: 'subscribe'
      }
    }

    _DownloadFileInvoice = async (keyFile, type) => {
      try {
        var url;
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var promise = await Requestdynamic('_DownloadInvoiceFile', {Jwt: Jwt, IdMember: this.state.IdMember, KeyFile: keyFile, TypeInvoice: type})
        if (promise.error === false && promise.data.error === false){
          url = promise.data.data
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        else {
          if (promise.error === true)
            return ;
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            return ;
        }
      } catch(e)
      {
        return ;
      }
    }


    GetSpecificDeletedMember = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        Id: this.state.IdMember,
      }
      var promise = await Requestdynamic('_getSpecificDeletedMember', Params);
      if (promise.error === false && promise.data.error === false)
      {
        var Data = promise.data.data
        if (!Data.MemberStatus || Data.MemberStatus !== 'Member'){
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Information incorrect'})
          return ;
        }
        try {
          var NotAcces = false
          if (this.state.verifyAccess === true)
          {
            var InfoMember = cookies.get('infoAdmin')
            var Region = InfoMember.Region;
            var RegionUsers = Data.Complement.Region;
            var index = Region.findIndex(res => res === RegionUsers)
            if (index === -1)
              NotAcces = true
          }
          var Keys = Object.keys(Data);
          var paramsSetState = {NotAcces: NotAcces, loading: false, accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null}
          for (var i = 0; i < Keys.length; i++){
            paramsSetState[Keys[i]] = Data[Keys[i]]
          }
          this.setState(paramsSetState)
        } catch (e) {
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
        }
      }
      else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard', accept_refuse: false, statusAccept_Refuse: null, actionAccept_Refuse: null, blocked: false, statusBlocked: null})
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            var verifyAccess = false;
            if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.verify === true)
              verifyAccess = true
            this.setState({
              verifyAccess: verifyAccess,
              IdMember: this.props.navigation.match.params.memberid,
            }, async () => this.GetSpecificDeletedMember())
        })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage){

        var paramsNavigate = {pathname: this.props.navigation.location.state.oldPage}
        delete this.props.navigation.location.state.oldPage
        paramsNavigate.state = this.props.navigation.location.state
        this.props.navigation.history.push(paramsNavigate);
        return ;
      }
        this.props.navigation.history.push(`/deletedmembers/`);
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }



 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <ProfilMember currentHistory={this.state.currentHistory} currentPage={this.state.currentPage} refParent={this} intl={this.props.intl} />
    </div>
   )
 }
}


export default SpecificDeletedMember;
