import React from 'react';

const Footer = () => {
  return (
      <footer>
        <small>
          © 2022 made by -{' '}
          <a target="_blank" rel="noopener noreferrer" href="http://nyugo.be" style={{fontFamily: 'Mitr, sans-serif', color: 'black'}}>
            Nyugo
          </a>
        </small>
      </footer>
  );
};

export default Footer;
