import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Cookies from 'universal-cookie'

import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import Photo from './Photo'
import Header from './Header'
import PopUp from './PopUp'
import Info from './Info'
import Description from './Description'
import {ButtonTestCss} from "../../ButtonStyle";
import {Requestdynamic} from '../../Network/Fetch';

class AddAmbassadord extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        success: false,
        title: null,
        message: null,
        openModal: false,
        Editor: EditorState.createEmpty(),
        updateAmbassador: false,
        Region: [],
        FirstName: null,
        LastName: null,
        RegionId: 'PlaceHolder',
        Error: null,
        Function: null,
        Picture: null,
        Linkedin: null,
        verifyArgument: true,
        redirect: false,
        OldPhoto: null,
        Society: null,
      }

    }

    _VerifySource = async () => {
      this.setState({verifyArgument: true}, async () => {

            if (!this.props.navigation.location || !this.props.navigation.location.state || !this.props.navigation.match.params.memberid){
              this.setState({verifyArgument: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              verifyArgument: false,
              OldPhoto: this.props.navigation.location.state.Picture,
              FirstName: this.props.navigation.location.state.FirstName,
              LastName: this.props.navigation.location.state.LastName,
              Region: this.props.navigation.location.state.Region,
              Function: this.props.navigation.location.state.Function,
              Linkedin: this.props.navigation.location.state.Linkedin,
              Society: this.props.navigation.location.state.Society,
              Editor: EditorState.createWithContent(convertFromRaw(this.props.navigation.location.state.Description)),
            })
        })
    }


    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.navigation.history.push(`/all_ambassador/`);
    }

    _CheckName = (Error) => {
      if (!this.state.FirstName || this.state.FirstName.length === 0)
        Error.FirstName = true
      if (!this.state.LastName || this.state.LastName.length === 0)
        Error.LastName = true

    }

    _CheckRegion = (Error) => {
      if (this.state.Region.length  === 0)
        Error.Region = true
    }

    _ConvertToRaw = (Error) => {
      try {
        if (!this.state.Editor)
          return (null)
        var RawEditor = convertToRaw(this.state.Editor.getCurrentContent())
        return (RawEditor)
      } catch (e) {
        return ("erreur")
      }

    }

    _CheckPicture = (Error) => {
      if (!this.state.Picture)
        Error.Picture = true
    }

    _CheckFunction = (Error) => {
      if (!this.state.Function || this.state.Function.length === 0)
        Error.Function = true
    }

    _CheckSociety = (Error) => {
      if (!this.state.Society || this.state.Function.length === 0)
        Error.Society = true
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _updateAmbassador = async () => {
    this.setState({updateAmbassador: true, Error: null}, async() => {
    var Error = {};

    this._CheckName(Error)
    this._CheckRegion(Error)
    this._CheckFunction(Error)
    this._CheckSociety(Error)

    var Description = this._ConvertToRaw()
    var Picture = this.state.Picture

    var KeysErrorLength = Object.keys(Error).length
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, updateAmbassador: false})
      return ;
    }
    var Region = [...this.state.Region]
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var Params = {

      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      Linkedin: this.state.Linkedin,
      Fonction: this.state.Function,
      Region: Region,
      IdAmbassador: this.props.navigation.match.params.memberid,
      Jwt: Jwt,
      Picture: Picture,
      Description: Description,
      Society: this.state.Society
    }
    var promise = await Requestdynamic('_updateAmbassador', Params)
    if(promise.error === false && promise.data.error === false)
    {
      this.setState({updateAmbassador: false, openModal: true, title: 'Success', message: "L'ambassadeur à bien été mis à jour", redirect: true})
    }
    else
    {
      if (promise.error === true)
        this.setState({updateAmbassador: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
      {
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else
        this.setState({updateAmbassador: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      return ;
    }
  })

}

 render() {

   return (
     <>
      <div style={{marginLeft: 10, overflowY: "auto"}}>
       <Container fluid>
         <PopUp redirect={this.state.redirect} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
        <Header refParent={this} intl={this.props.intl}/>
        <Photo OldPhoto={this.state.OldPhoto} Error={this.state.Error} success={this.state.success} intl={this.props.intl} refParent={this}/>
        <Info
        FirstName={this.state.FirstName}
        refParent={this}
        LastName={this.state.LastName}
        Linkedin={this.state.Linkedin}
        Function={this.state.Function}
        Region={this.state.Region}
        Error={this.state.Error}
        Society={this.state.Society}
        intl={this.props.intl}/>
        <Description intl={this.props.intl} Editor={this.state.Editor} refParent={this}/>


        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ButtonTestCss disabled={this.state.updateAmbassador} color={"orange"} style={{margin: 10}} onClick={() => this._updateAmbassador()}>
              {
                this.state.updateAmbassador ?
                <CircularProgress
                  size={"1.5rem"}
                  thickness={5}
                  color={"inherit"}
                />
                :
                <p style={{margin: 0, padding: 0}}>Sauvegarder</p>
              }
            </ButtonTestCss>

          </Col>
        </Row>

       </Container>
     </div>

     </>
   )
 }
}


export default AddAmbassadord;
