import React, { useState } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";
import FileUpload from '../../Member/WaitingMember/FileUpload/FileUpload'
import {OrangeCheckbox} from '../../Member/WaitingMember/Style'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;


const PopUp = (props) => {

  const [files, setFiles] = useState({});


    const updateUploadedFiles = async (files) => {
     var file = files[0];
     props.setInvoiceFile(file)
    }

    const _renderModal = () => {
      return (
        <>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.5rem', alignItems: 'center', color: '#082430'}}>Facture</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontStyle: 'italic', fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>Veuillez joindre une facture. Cette facture sera rajouter automatiquement dans son historique de membre.</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '100%'}}>
              <FileUpload
              label={props.intl.formatMessage({ id: 'Invoice' })}
              updateFilesCb={updateUploadedFiles}
              setFiles={setFiles}
              files={files}
              />
            </div>
          </Col>
        </Row>
        {
          props.errorInvoice && props.errorInvoice.File === true ?
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
              <p style={{fontStyle: 'italic', fontSize: '0.85rem', alignItems: 'center', color: 'red'}}>Veuillez joindre un fichier PDF.</p>
            </Col>
          </Row>
          :
          <>
          </>
        }
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <FormControlLabel

              value="send"
              control={<OrangeCheckbox  name="english" />}
              checked={props.send ? true : false}
              label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>Envoyé</Typography>}
              onChange={(event) => props.setSend(!props.send)}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <p style={{fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>En cochant la case, la facture sera envoyé au client</p>
          </Col>
        </Row>
        </>
      )


    }


    const _renderStatus = () => {
      if (!props.statusInvoice || props.statusInvoice === "Ask")
        return (<></>)
      if (props.statusInvoice === "Waiting"){
        return (
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={35}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>)
      }
      if (props.statusInvoice === "Success"){
        return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
        )
      }
      return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            </Col>
          </Row>
      )

    }

    const _ButtonClickNo = () => {
        props.setInvoiceFile(null)
        props.setInvoice(false)
        props.setSend(false)
        props.setStatusInvoice(null)
        props.setErrorInvoice(null)
        props.setMemberInvoice(null)
        setFiles({})
    }

    const _ButtonClickYes = () => {
      props.UploadInvoice()
    }



   return (
       <Popup showPopup={props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
       {_renderModal()}
       {_renderStatus()}
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
         {
           props.statusInvoice === "Success" ?
           <ButtonTestCss  onClick={() => _ButtonClickNo()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{"Fermer"}</ButtonTestCss>

           :
           <>
           <ButtonTestCss  disabled={props.statusInvoice && props.statusInvoice === 'Waiting'} onClick={() => _ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{props.statusInvoice && props.statusInvoice === "Error" ? "Ré-essayer" : "Oui"}</ButtonTestCss>
           <ButtonTestCss  disabled={props.statusInvoice && props.statusInvoice === 'Waiting'} onClick={() => _ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
           </>
         }

         </Col>
       </Row>
       </div>
       </Popup>
   )
}


export default PopUp;
