import React from 'react';
import Row from 'react-bootstrap/Row';

import Name from './Name'
import Link from './Link'
import Category from './Category'

class Info extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (

       <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>

       <Name Error={this.props.Error} Name={this.props.Name} refParent={this.props.refParent}/>
       <Link Error={this.props.Error} Link={this.props.Link} refParent={this.props.refParent}/>
       <Category Error={this.props.Error} Category={this.props.Category} refParent={this.props.refParent}/>
     </Row>
   )
 }
}


export default Info;
