import React from 'react';
import Table from './Table'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import PopUpDelete from './PopUpDelete'
import Loading from './Loading'
import Cookies from 'universal-cookie'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class AllAdmin extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        location: [],
        loadingLocation: true,
        openModal: false,
        title: null,
        message: null,
        openModalDelete: false,
        deleting: null,
        LocationDelete: null,
        sortFilter: {
          Name: "up",
          City: "up",
          PostalCode: 'up',
          Street: "up",
          Number: "up",
          RegionB19: 'up'
        }
      }
    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.location];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        if (!a[Name])
          a[Name] = ""
        if (!b[Name])
          b[Name] = ""

        var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
         if (nameA < nameB) //sort string ascending
          return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({location: SortedResult, sortFilter: sortOrder})
    }

    _getAllLocation = async () => {
      this.setState({loadingLocation: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');

        var Params = {
            Jwt: Jwt,
        }
        var promise = await Requestdynamic('_getAllLocation', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({loadingLocation: false, location: promise.data.data})
        }
        else {
          if (promise.error === true){
            this.setState({loadingLocation: false, title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
            return ;
          }
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
        else {
          this.setState({loadingLocation: false, title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard"})
        }
        }
      })

    }

    componentDidMount(){
     this._getAllLocation()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    _deleteLocation = () => {
      this.setState({deleting: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');

        var Params = {
            Jwt: Jwt,
            IdLocation: this.state.LocationDelete.Id,
        }
        await this.sleep(2000)
        var promise = await Requestdynamic('_deleteLocation', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({deleting: 'Success'}, async () => {
            await this.sleep(1500)
            var Copy = [...this.state.location];
            var index = Copy.findIndex(res => res.Id === this.state.LocationDelete.Id)
            if (index === -1){
              this.setState({deleting: null, LocationDelete: null, openModalDelete: null})
              return ;
            }
            Copy.splice(index, 1)
            this.setState({location: Copy})
            await this.sleep(650)
            this.setState({deleting: null, LocationDelete: null, openModalDelete: null})
          })
        }
        else {
          if (promise.error === true)
           this.setState({deleting: "Error"})
         else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
           cookies.remove('acces', { path: '/' })
           cookies.remove('infoAdmin', { path: '/' })
           await this.sleep(900)
           var url = '/login'
           const link = document.createElement('a');
           link.href = url;
           document.body.appendChild(link);
           link.click();
           link.parentNode.removeChild(link);
           return ;
         }
         else{
           this.setState({deleting: "Error"})
         }
        }
      })

    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <PopUpDelete LocationDelete={this.state.LocationDelete} deleting={this.state.deleting} openModalDelete={this.state.openModalDelete} refParent={this}/>
     <Container fluid>
       <Row>
         <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
           <h2 style={{textAlign: 'left', marginBottom: 25}}>Adresses</h2>
         </Col>
       </Row>
     </Container>
     <Table refParent={this} deleting={this.state.deleting} location={this.state.location} loadingLocation={this.state.loadingLocation} intl={this.props.intl}/>
     <Loading loadingMore={this.state.loadingLocation}/>

    </div>
   )
 }
}


export default AllAdmin;
