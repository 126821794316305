import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: "white",
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: "green",
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "#082430",
  },
  checked: {},
}))(Switch);

export const AnimationRotate = (theme) => ({
      refresh: {
        margin: "auto",
        animation: "$spinNormal 0.5s 1",
        transform: "rotate(270deg)",

      },
      spin: {
        margin: "auto",
        animation: "$spin 0.5s 1",
        transform: "rotate(90deg)",
      },
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(90)",
        },
      },
      "@keyframes spinNormal": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(270deg)",
        },
      }
    })

export const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#082430',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#082430',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'orange',
      },
      '&:hover fieldset': {
        borderColor: '#FF8C00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#082430',
      },
    },
  },
})(TextField);


export const CssTextFieldSearch = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#082430',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#082430',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,
      '& fieldset': {
        borderColor: '#082430',
      },
      '&:hover fieldset': {
        borderColor: '#082430',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#082430',
      },
    },
  },
})(TextField);
