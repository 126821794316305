import React from 'react';
import { RiMapPin2Fill } from "react-icons/ri";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import { IoMail } from "react-icons/io5";
import Col from 'react-bootstrap/Col';

const ICONSIZE = 20;
const TEXTSIZE = "0.85em";


class Pro extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object = "Pro") => {
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE

    })
  }

  EmptyStreet = () => {
    if (!this.props.Pro || this.ConstructStringWithComma([this.props.Pro.StreetPro, this.props.Pro.ZipCodePro, this.props.Pro.CityPro], ',') === 'Vide')
      return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE
    })
    else
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
  }

  EmptyStatus = () => {
    if (!this.props.SocietyInfo || this.ConstructStringWithComma([this.props.SocietyInfo.Name, this.props.SocietyInfo.Status], '-') === 'Vide')
        return ({
        color: 'red',
        fontWeight: 'bold',
        fontSize: TEXTSIZE
      })
    else
      return ({color: 'black', fontSize: TEXTSIZE})
  }

renderSector = (data) => {
  if(data === '1')
    return('Immobilier')
  if(data === '2')
    return('Banques, assurances, finances')
  if(data === '3')
    return('Industriel')
  if(data === '4')
    return('IT')
  if(data === '5')
    return('Biens et services divers aux entreprises(BtoB)')
  if(data === '6')
    return('Commerces (BtoC)')
  if(data === '7')
    return('Avocats et autres professions libérales')
  if(data === '8')
    return('Consultance/formation')
}

  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={4} lg={4} md={12}>

            <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS PROFESSIONNELLES</p>
          <div style={{}}>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <BsFillBriefcaseFill size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <p style={this.EmptyStatus()}>{this.props.SocietyInfo ? this.ConstructStringWithComma([this.props.SocietyInfo.Name, this.props.SocietyInfo.Status], ' -') :  "Vide"}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <BsFillBriefcaseFill size={ICONSIZE}/>

            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Sector", "SocietyInfo")}>{this.props.SocietyInfo && this.props.SocietyInfo.Sector ? this.renderSector(this.props.SocietyInfo.Sector) : 'Vide'}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <RiMapPin2Fill size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.EmptyStreet()}>{this.props.Pro ? this.ConstructStringWithComma([this.props.Pro.StreetPro, this.props.Pro.ZipCodePro, this.props.Pro.CityPro], ',') :  ""}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <ImPhone size={ICONSIZE}/>

            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("TelPro")}>{this.props.Pro && this.props.Pro.TelPro ? this.props.Pro.TelPro : "Vide"}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
              <IoMail size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("EmailPro")}>{this.props.Pro && this.props.Pro.EmailPro ? this.props.Pro.EmailPro : "Vide"}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Tva:</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Tva", 'SocietyInfo')}>{this.props.SocietyInfo && this.props.SocietyInfo.Tva ? this.props.SocietyInfo.Tva : 'Vide'}</p>
            </div>
          </div>

          </div>
          </Col>
    )
  }
}

export default Pro;
