import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class PureAmbassador extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  ConstructArray = (Array) => {
  try {
    return Array.map(res => this.props.intl.formatMessage({ id: res })).join(', ')
  } catch (e) {
    return ("")
  }
}

 ConstructRegion = (Region) => {
  try {

      return (<p style={{color: 'black', fontSize: '0.8rem'}}>{this.ConstructArray(Region)}</p>)
  } catch (e) {
    return (<></>)
  }
}

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left">
        {
          this.props.ambassador.Image ?
            <img  alt={"ambassador"} key={this.props.ambassador.Id} src={this.props.ambassador.Image} style={{objectFit: 'cover', width: 50, borderRadius: 360, height: 50}}/>
          :
            <div style={{width: 50, borderRadius: 360, height: 50, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.ambassador.FirstName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.ambassador.LastName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.ambassador.Function}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.ambassador.Society}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.ambassador.Linkedin}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.ConstructRegion(this.props.ambassador.Region)}</TableCell>
        <TableCell align="center" style={{}}>






        <Dropdown style={{textAlign: 'left'}}>
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary"/>
            <Dropdown.Menu variant="primary">
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                <Button disabled={this.props.deleting ? true : false} variant="text" style={{color: 'orange', fontWeight: 'bold',  fontSize: "0.65em", width: '100%'}} component={Link}
                to={{
                pathname: `/ambassador_edit/${this.props.ambassador.Id}`,
                state: { Society: this.props.ambassador.Society, Linkedin: this.props.ambassador.Linkedin, FirstName: this.props.ambassador.FirstName, LastName: this.props.ambassador.LastName, Picture: this.props.ambassador.Image, Description:  this.props.ambassador.Description, Region: this.props.ambassador.Region, Function: this.props.ambassador.Function}
              }}
                >
                  Editer
                </Button>
              </Dropdown.Item>
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>
                <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, AmbassadorDelete: this.props.ambassador})}} disabled={this.props.deleting ? true : false} variant="text" style={{color: 'red', fontWeight: 'bold',  fontSize: "0.65em", width: '100%'}}>
                  Supprimer
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </TableCell>
      </TableRow>)
  }

}

export default PureAmbassador;
