import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddAdress from './AddAdress'

class ParselAddAdress extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddAdress} {...props}/>
   )
 }
}



export default ParselAddAdress;
