import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import Cookies from 'universal-cookie'
import PopUpDelete from './PopUpDelete'
import Header from './Header'

class AllPassedEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        LastKey: null,
        event: [],
        eventDisplay: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        search: null,
        searchEvent: [],
        deleting: null,
        EventDelete: null,
        openModalDelete: false,
        ErrorSearch: false,
        loadingSearch: false
      }
    }

    _updateSearchValue = (value) => {
      if (value.length === 0)
      {
        var Copy = [...this.state.event]
        this.setState({search: null, eventDisplay: Copy})
        return ;
      }
      this.setState({search: value})
    }

    _deleteEvent = () => {
      this.setState({deleting: "Waiting"}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
            IdEvent: this.state.EventDelete.Id,
        }
        await this.sleep(2000)
        var promise = await Requestdynamic('_deleteEvent', Params)
        if(promise.error === false && promise.data.error === false)
        {
          this.setState({deleting: 'Success'}, async () => {
            var Copy = [...this.state.event]
            var IndexCopy = Copy.findIndex(res => res.Id === this.state.EventDelete.Id)
            if (IndexCopy !== -1)
              Copy.splice(IndexCopy, 1)
            var CopyDisplay = [...this.state.eventDisplay]
            var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.EventDelete.Id)
            if (IndexCopyDisplay !== -1)
            CopyDisplay.splice(IndexCopyDisplay, 1)
            await this.sleep(500)
            this.setState({openModalDelete: false, event: Copy, eventDisplay: CopyDisplay, deleting: null, EventDelete: null})
          })
        }
        else {
          if (promise.error === true)
            this.setState({deleting: "Error"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else{
            if (promise.data && promise.data.NotAcces === true){
              this.setState({deleting: "NotAcces"})
              return ;
            }
            this.setState({deleting: "Error"})
          }
        }
      })

    }


    _getAllPassedEvent = async (LastKey) => {

      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        if (LastKey === false)
          return ;
        var Params = {
          Body: {
            Jwt: Jwt,
            NumberOfResult: "50",
            SizeImage: "Medium",
          },
        }
        if (LastKey)
          Params.Body.LastKey = LastKey
        var promise = await Requestdynamic('_getAllPassedEvent', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var EventCopy = [...this.state.event];
          var newListEvent = EventCopy.concat(promise.data.data)
          newListEvent = [...new Set(newListEvent)]
          var paramsSetState = {event: newListEvent}
          var newLastKey = promise.data.LastKey ? promise.data.LastKey : false;
          paramsSetState.LastKey = newLastKey;
          var Display = newListEvent
          paramsSetState.eventDisplay = Display
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
        }
        this.setState({loadingMore: false})
      })

    }

    search = () => {
      this.setState({loadingSearch: true, ErrorSearch: false}, async () => {
        var Display;

        if (!this.state.search || this.state.search.length === 0 || this.state.search.length < 3)
        {
          Display = [...this.state.event]
          this.setState({search: null, eventDisplay: Display, loadingSearch: false, ErrorSearch: true})
          return ;
        }
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {Jwt: Jwt, Search: this.state.search, SizeImage: "Small"};
        var promise = await Requestdynamic('_searchPassedEvent', Params);
        if (promise.error === false && promise.data.error === false){
          Display = promise.data.data;
          this.setState({eventDisplay: Display, loadingSearch: false})
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({eventDisplay: [], loadingSearch: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({eventDisplay: [], loadingSearch: false})
            return ;
        }
      })

    }

    componentDidMount(){
      this._getAllPassedEvent(null)
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;

      if (this.state.search === null)
      {
        if (bottom && this.state.LastKey && this.state.loadingMore === false) {
          this._getAllPassedEvent(this.state.LastKey)
        }
      }
      return ;

    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <PopUp Editor={this.state.Editor} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <PopUpDelete EventDelete={this.state.EventDelete} deleting={this.state.deleting} openModalDelete={this.state.openModalDelete} refParent={this}/>
     <Header  ErrorSearch={this.state.ErrorSearch} loadingSearch={this.state.loadingSearch} search={this.state.search} intl={this.props.intl} refParent={this}/>
     <Table refParent={this} loadingMore={this.state.loadingMore} LastKey={this.state.LastKey} eventDisplay={this.state.eventDisplay}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllPassedEvent;
