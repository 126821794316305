import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'universal-cookie'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import {OrangeCheckbox} from './Style'


  class Region extends React.Component {
  constructor(props){
      super(props);
      this.state = {

        }
    }

    _ConstructRegionArray = (event) => {
      var CurrentRegionArray = [...this.props.RegionB19]
      var index = CurrentRegionArray.findIndex(res => res === event.target.value)
      if (index === -1)
        CurrentRegionArray.push(event.target.value)
      else
        CurrentRegionArray.splice(index, 1)
      this.props.refParent.setState({RegionB19: CurrentRegionArray})
    }

    verifyChecked = (region) => {
      var CurrentRegionArray = [...this.props.RegionB19]
      var index = CurrentRegionArray.findIndex(res => res === region)
      if (index === -1)
        return false
      else
        return true
    }

    renderTradRegionB19 = (Region) => {
      try {
        var trad = this.props.intl.formatMessage({id: Region})
        return (trad)
      } catch(e) {
        if (Region)
          return (Region)
        return ("")
      }
    }

CreatePermissionRegion = () => {
  try {
    var Region = ["bxl", "luik", 'fl', 'anv', 'lux']

    return Region.map((region) => {
    return (
      <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: "100%", display: 'flex'}}>
        <FormControlLabel
          value={region}
          control={<OrangeCheckbox  name={region} />}
          checked={this.verifyChecked(region)}

          label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>{this.renderTradRegionB19(region)}</Typography>}
          onChange={(region) => this._ConstructRegionArray(region)}
        />
        </div>
      </Col>
    )})
  } catch (e) {
    return (<></>)
  }

}


 render() {

   return (
     <>
     <Row style={{}}>
       <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'column', marginTop: 10}}>
          <div style={{borderBottom: '2px solid orange'}}>
           <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Région B19</h3>
          </div>
          <p style={{fontSize: '0.8rem', fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>Choissisez une ou plusieurs région</p>
       </Col>
     </Row>
      <Row style={{margin: 10, marginTop: 20}}>
      {
        this.CreatePermissionRegion()
      }
      </Row>
      {
        this.props.Error && this.props.Error.RegionB19 ?
        <Row style={{marginTop: 5}}>
          <Col sm={12} xl={12} lg={12} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <p style={{fontSize: 12, color: "red", marginleft: 5}}>Veuillez selectionner au moin une Région</p>
          </Col>
        </Row>
        :
        <>
        </>

      }

      </>
   )
 }
}


export default Region;
