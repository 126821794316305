import React from 'react';
import {ButtonCssNav} from './Style'

class ActionMember extends React.Component {


  render (){
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap'}}>
        <ButtonCssNav disabled={this.props.NotAcces} style={{borderTopLeftRadius: 5, fontSize: '0.84em'}} onClick={() => this.props.refParent.setState({currentPage: 'info'})}>INFORMATIONS</ButtonCssNav>
        <ButtonCssNav disabled={this.props.NotAcces} style={{borderLeftWidth: 0, borderTopRightRadius: 5, fontSize: '0.84em'}} onClick={() => this.props.refParent.setState({currentPage: 'history'})}>HISTORIQUE</ButtonCssNav>
      </div>
    )
  }
}

export default ActionMember;
