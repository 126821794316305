import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";


class Private extends React.Component {

  ConstructStringWithComma = (ArrayString) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(', '))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object) => {
    if (value){
      if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
        return ({
          color: 'black',
          fontSize: TEXTSIZE,

        })
      }
    }
    else {
      if (this.props[object] && this.props[object].length !== 0){
        return ({
          color: 'black',
          fontSize: TEXTSIZE,

        })
      }
    }

    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }
  EmptyStreet = () => {
    if (this.props.Location && this.props.Location.Street && this.props.Location.City && this.props.Location.PostalCode && this.props.Location.Number
    && this.props.Location.Street.length !== 0 && this.props.Location.City.length && this.props.Location.PostalCode.length && this.props.Location.Number.length)
      return ({
        color: 'black',
        fontSize: TEXTSIZE
      })
    else
      return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE
    })

  }

  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={12} lg={12} md={12}>

          <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS PRINCIPAL</p>

          <div style={{}}>
          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Adresse:</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.EmptyStreet()}>{this.props.Location ? this.ConstructStringWithComma([this.props.Location.Street, this.props.Location.Number, this.props.Location.PostalCode, this.props.Location.City]) :  "Vide"}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Nom du lieux:</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Name", "Location")}>{this.props.Location && this.props.Location.Name ? this.props.Location.Name :  "Vide"}</p>
            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7}}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Nom de l'évènement:</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply(null, "Name")}>{this.props.Name && this.props.Name ? this.props.Name :  "Vide"}</p>
            </div>
          </div>



          </div>
          </Col>
    )
  }
}

export default Private;
