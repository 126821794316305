import React from 'react';
import Header from './Header'
import {Requestdynamic, ContructUrlParams} from '../../Network/Fetch'
import PopUp from './PopUp'
import Loading from './Loading'
import  {IP_BACKEND_DNS} from '../../Network/Constants'
import Cookies from 'universal-cookie'
import Table from './Table'
import ModalPdfPrint from './ModalPrintPdf'
import ModalValidatePayment  from './ModalValidatePayment'
import ModalDeleteUser from './ModalDeleteMember'

class SubscribeMemberEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        Name: null,
        Date: null,
        redirect: false,
        member: [],
        memberDisplay: [],
        searchMember: [],
        search: null,
        PdfPrint: false,
        MemberDelete: null,
        statusDelete: null,
        delete: false,
        IdEvent: null,
        isFree: null,
        statusValidatePayment: null,
        validatePayment: false,
        MemberValidatePayment: null,
        reload: false,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: 'up',
          Society: "up",
          Date: "up",
          MemberStatus: 'up',
          StatusSubscribe: 'up',
          Source: 'up'
        }
      }


    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.member];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
          if (!a[Name])
            a[Name] = ""
          if (!b[Name])
            b[Name] = ""
          var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({memberDisplay: Display, member: SortedResult, sortFilter: sortOrder})
    }

    GetInvoiceEvent = async () => {
      let path = `/invoice_event/${this.props.navigation.match.params.eventid}`;
      this.props.navigation.history.push({
        pathname: path,
        state: this.props.navigation.location.state
        });
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.eventid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            let Name = this.props.navigation.location.state && this.props.navigation.location.state.Name ?  this.props.navigation.location.state.Name : null;
            let Date = this.props.navigation.location.state && this.props.navigation.location.state.Date ?  this.props.navigation.location.state.Date : null;
            let isFree = this.props.navigation.location.state && this.props.navigation.location.state.hasOwnProperty("isFree") ?  this.props.navigation.location.state.isFree : "empty";
            if (!Name || !Date || isFree === 'empty'){
              this.setState({openModal: true, title: 'Error', message: "Une erreur est survenue", redirect: true})
              return ;
            }
            this.setState({
              Compta: this.props.navigation.location.state.Compta,
              IdEvent: this.props.navigation.match.params.eventid,
              Name: Name,
              Date: Date,
              isFree: isFree,
            }, async () => this._getAllParticipant())
        })
    }

    _getAllParticipant = async () => {

      this.setState({loadingMore: true}, async() => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            IdEvent: this.state.IdEvent,
            SizeImage: "Small",
          },
        }
        var promise = await Requestdynamic('_getAllParticipant', Params)
        if(promise.error === false && promise.data.error === false)
        {

          var newListMember = [...promise.data.data];
          for (var i = 0; i < newListMember.length; i++){
            newListMember[i].order = i
          }
          var MemberDisplay = newListMember.slice(0, 20)
          var paramsSetState = {memberDisplay: MemberDisplay, member: [...newListMember], loadingMore: false}
          this.setState(paramsSetState)
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
        }
      })

    }

    _addAllNextMember = async () => {
      this.setState({loadingMore: true}, async() => {
          var Copy;
        if (this.state.search != null)
           Copy = [...this.state.searchMember]
        else
           Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        Copy = Copy.sort((a, b) => a.order - b.order)
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
        this._VerifySource()
        this.goBack = this.goBack.bind(this);
        this.routeChange = this.routeChange.bind(this);
    }

    search = (value) => {
      var Copy, Display
      if (value.length === 0)
      {
         Copy = [...this.state.member];
         Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
        return ;
      }
       Copy = [...this.state.member]
      var FilterResult = Copy.filter(res => res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
       Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }


    routeChange = () => {
      let path = `/add_member_event/${this.props.navigation.match.params.eventid}`;
      this.props.navigation.history.push({
        pathname: path,
        state: this.props.navigation.location.state
        });
      }

      goBack(){
        var paramsNavigate = {pathname: `/details_event/${this.state.IdEvent}`}
        if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.returnTo)
          paramsNavigate.pathname = this.props.navigation.location.state.returnTo

        if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
          paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
          this.props.navigation.history.push(paramsNavigate);
      }


    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

   handleScroll = (e) => {
     var verify;
     if (this.state.search === null)
        verify = 'member'
     else
        verify = 'searchMember'

     const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
     if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
       this._addAllNextMember()
     }
   }

   ValidatePayment = async (member) => {
     if (this.state.validatePayment === true)
     {
       this.setState({statusValidatePayment: "Waiting"}, async () => {
         var cookies = new Cookies();
         var Jwt = cookies.get('acces');
         var Params = {Jwt: Jwt, IdMember: this.state.MemberValidatePayment.Id, IdEvent: this.state.IdEvent}
         var promise = await Requestdynamic('_validatePaymentEvent', Params)
         if (promise.error === false && promise.data.error === false){
           this.setState({statusValidatePayment: "Success"}, async () => {
             var Copy = [...this.state.member]
             var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberValidatePayment.Id)
             Copy[IndexCopy].StatusSubscribe = 'subscribe'
             var CopyDisplay = [...this.state.memberDisplay]
             var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberValidatePayment.Id)
             CopyDisplay[IndexCopyDisplay].StatusSubscribe = 'subscribe'
             await this.sleep(500)
             this.setState({statusValidatePayment: null, member: Copy, memberDisplay: CopyDisplay, validatePayment: false, MemberValidatePayment: null, reload: !this.state.reload})
           })
           return ;
         }
         else {
           if (promise.error === true)
             this.setState({statusValidatePayment: "Error"})
           else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
           {
             cookies = new Cookies();
             cookies.remove('acces', { path: '/' })
             cookies.remove('infoAdmin', { path: '/' })
             await this.sleep(900)
             var url = '/login'
             const link = document.createElement('a');
             link.href = url;
             document.body.appendChild(link);
             link.click();
             link.parentNode.removeChild(link);
             return ;
           }
           else
             this.setState({statusValidatePayment: "Error"})
         }
       })
     }
     else {
       this.setState({validatePayment: true, statusValidatePayment: "Ask", MemberValidatePayment: member})
       return ;
     }

   }

   DeleteParticipantEvent = async (member) => {
     if (this.state.delete === true){
       this.setState({statusDelete: "Waiting"}, async () => {
         var cookies = new Cookies();
         var Jwt = cookies.get('acces');
         var Params = {Jwt: Jwt, IdMember: this.state.MemberDelete.Id, IdEvent: this.props.navigation.match.params.eventid,}

         var promise = await Requestdynamic('_deleteParticipantEvent', Params)
         if (promise.error === false && promise.data.error === false){
           this.setState({statusDelete: "Success"}, async () => {
             var Copy = [...this.state.member]
             var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberDelete.Id)
             Copy.splice(IndexCopy, 1)
             var CopyDisplay = [...this.state.memberDisplay]
             var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberDelete.Id)
             CopyDisplay.splice(IndexCopyDisplay, 1)
             await this.sleep(500)
             this.setState({statusDelete: null, member: Copy, memberDisplay: CopyDisplay, delete: false, MemberDelete: null})
           })
           return ;
         }
         else {
           if (promise.error === true)
             this.setState({statusDelete: "Error"})
           else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
           {
             cookies = new Cookies();
             cookies.remove('acces', { path: '/' })
             cookies.remove('infoAdmin', { path: '/' })
             await this.sleep(900)
             var url = '/login'
             const link = document.createElement('a');
             link.href = url;
             document.body.appendChild(link);
             link.click();
             link.parentNode.removeChild(link);
             return ;
           }
           else{
             console.log(promise)
             if (promise.data.NotAccess === true)
             {
              this.setState({statusDelete: "Error", title: 'Erreur', message: "Vous n'avez pas les droits nécèssaires pour éditer cette évènement"})
               return ;
             }
             this.setState({statusDelete: "Error"})
           }
         }
       })
     } else {
       this.setState({delete: true, statusDelete: "Ask", MemberDelete: member})
       return ;
     }
   }

   _exportExcelFile = async () => {
     var cookies = new Cookies();
     var Jwt = cookies.get('acces');
     var Params = {
         Jwt: Jwt,
         IdEvent: this.props.navigation.match.params.eventid,
         All: "no"
     }
    var url = `${IP_BACKEND_DNS}/ExportParticipant/`
    url = url + ContructUrlParams(Params)
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);

   }

   _printPdfFile = async () => {
    /* var cookies = new Cookies();
     var Jwt = cookies.get('acces');
     var Params = {
         Jwt: Jwt,
         IdEvent: this.props.navigation.match.params.eventid,
     }
    var url = `${IP_BACKEND_DNS}/PrintParticipantEvent/`
    url = url + ContructUrlParams(Params)
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank'
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);*/
    this.setState({PdfPrint: true})
   }

 render() {
   return (

      <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
        <PopUp redirect={this.state.redirect} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
        <ModalPdfPrint Date={this.state.Date} Name={this.state.Name} openModal={this.state.PdfPrint} member={this.state.member} refParent={this}/>
        <ModalValidatePayment refParent={this} openModal={this.state.validatePayment} statusValidatePayment={this.state.statusValidatePayment} MemberValidatePayment={this.state.MemberValidatePayment}/>
        <ModalDeleteUser refParent={this} openModal={this.state.delete} statusDelete={this.state.statusDelete} MemberDelete={this.state.MemberDelete}/>

       <Header Member={this.state.member} refParent={this} search={this.props.search} PrintPdfFile={this._printPdfFile} exportExcelFile={this._exportExcelFile} Name={this.state.Name} Date={this.state.Date} goBack={this.goBack} routeChange={this.routeChange}/>
       <Table reload={this.state.reload} isFree={this.state.isFree} IdEvent={this.state.IdEvent} refParent={this} loadingMore={this.state.loadingMore} memberDisplay={this.state.memberDisplay} intl={this.props.intl}/>
       <Loading loadingMore={this.state.loadingMore}/>
      </div>

   )
 }
}


export default SubscribeMemberEvent;
