import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import NewMemberStripe from './NewMemberStripe'

class ParselNewMemberStripe extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={NewMemberStripe} {...props}/>
   )
 }
}



export default ParselNewMemberStripe;
