import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Name from './Name'
import Region from './Region'

class Info extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
     <>
       <Row style={{}}>
         <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Coordonnées :</h3>
            </div>
         </Col>
       </Row>
       <Name Society={this.props.Society} Linkedin={this.props.Linkedin} Error={this.props.Error} FirstName={this.props.FirstName} LastName={this.props.LastName} refParent={this.props.refParent} Function={this.props.Function}/>
       <Region intl={this.props.intl} Error={this.props.Error} Region={this.props.Region} refParent={this.props.refParent}/>
     </>
   )
 }
}


export default Info;
