import React from 'react';
import { ImPhone } from "react-icons/im";
import { IoMail } from "react-icons/io5";
import Col from 'react-bootstrap/Col';

const ICONSIZE = 20;
const TEXTSIZE = "0.85em";


class Private extends React.Component {


  HowStyleToApply = (value) => {
    if (this.props.Private && this.props.Private[value] && this.props.Private[value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE

    })
  }

  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={4} lg={4} md={12}>

          <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS PERSONNELLES</p>

          <div style={{}}>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <IoMail size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("EmailPrivate")}>{this.props.Private && this.props.Private.EmailPrivate ? this.props.Private.EmailPrivate : "Vide"}</p>

            </div>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 15 }}>
            <ImPhone size={ICONSIZE}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("TelPrivate")}>{this.props.Private && this.props.Private.TelPrivate ? this.props.Private.TelPrivate : "Vide"}</p>
            </div>
          </div>


          </div>
          </Col>
    )
  }
}

export default Private;
