import React from 'react';

import { Row, Col} from 'react-bootstrap';
import Logob19 from "../../../assets/ThumbLogo.png"
import { PDFExport } from  '@progress/kendo-react-pdf';
import "./VisualizePdf.css"
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

function VisualizePdf(props) {


  const renderMemberRow = () => {
    return props.data.map((res, index) => {
      return (
        <TableRow style={{backgroundColor: index % 2 ? "#ededed" : "", borderBottom:"none"}}>
        <TableCell   align="left" style={{fontSize: "0.55rem",}}>{res.Name}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.55rem",}}>{res.DateEvent}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.55rem",}}>{res.BeginHours}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.55rem",}}>{res.EndHours}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.55rem",}}>{res.Location}</TableCell>
        </TableRow>
      )
    })
  }


  return (
      <div  className="page-container hidden-on-narrow">
        <PDFExport paperSize={"A4"} ref={props.pdfExport}>
        <div  className={ `pdf-page size-a4` } style={{overflowY: 'scroll'}}>

          <div style={{textAlign: 'center', marginBottom: 50}}>
            <img alt={"logo b19"} src={Logob19} style={{width: "80px"}}/>
          </div>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 40}}>
          <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%', marginBottom: 20}}/>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell   align="left" style={{fontSize: "0.65rem"}}>Nom</TableCell>
                <TableCell   align="left" style={{fontSize: "0.65rem"}}>Date</TableCell>
                <TableCell  align="left" style={{fontSize: "0.65rem"}}>Début</TableCell>
                <TableCell   align="left" style={{fontSize: "0.65rem"}}>Fin</TableCell>
                <TableCell   align="left" style={{fontSize: "0.65rem"}}>Lieux</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderMemberRow()}
            </TableBody>
          </Table>


        </div>
        </PDFExport>
        </div>

  );
}

export default VisualizePdf;
