import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SeparateLine from './SeparateLine'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {OrangeCheckbox} from './Style'
import Typography from '@material-ui/core/Typography';

class Language extends React.Component {


    _ConstructLanguageArray = (event) => {
      var CurrentLanguageArray = [...this.props.Language]
      var index = CurrentLanguageArray.findIndex(res => res === event.target.value)
      if (index === -1)
        CurrentLanguageArray.push(event.target.value)
      else
        CurrentLanguageArray.splice(index, 1)
      this.props.refParent.setState({Language: CurrentLanguageArray})
    }

    verifyChecked = (Language) => {
      var CurrentLanguageArray = [...this.props.Language]
      var index = CurrentLanguageArray.findIndex(res => res === Language)
      if (index === -1)
        return false
      else
        return true
    }


 render() {

   return (
     <>
       <Row style={{}}>
         <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Langue</h3>
            </div>
         </Col>
       </Row>
     <Row style={{display: 'flex', justifyContent: 'flex-start'}}>
       <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
         <div style={{width: "100%", display: 'flex'}}>
         <FormControlLabel
            value='en'
           control={<OrangeCheckbox  name="english" />}
           checked={this.verifyChecked('en')}

           label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>Anglais</Typography>}
           onChange={(event) => this._ConstructLanguageArray(event)}
         />
         </div>
       </Col>
       <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
         <div style={{width: "100%", display: 'flex'}}>
         <FormControlLabel
            value='fr'
           control={<OrangeCheckbox  name="French" />}
           checked={this.verifyChecked('fr')}

           label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>Français</Typography>}
           onChange={(event) => this._ConstructLanguageArray(event)}
         />
         </div>
       </Col>
       <Col sm={12} lg={3} xl={3} md={12} style={{display: 'flex', flexDirection: 'column'}}>
         <div style={{width: "100%", display: 'flex'}}>
         <FormControlLabel
           control={<OrangeCheckbox  name="neederlands" />}
           value='nl'
           checked={this.verifyChecked('nl')}
           label={<Typography style={{fontWeight: 'bold', fontSize: '1.1rem'}}>Neerlandais</Typography>}
           onChange={(event) => this._ConstructLanguageArray(event)}
         />
         </div>
       </Col>
     </Row>
     <Row>
      <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Language ? 'block' : 'none', marginTop: 5}}>Veuillez choisir au minimum une langue</p>
     </Row>

     <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

     </>
   )
 }
}


export default Language;
