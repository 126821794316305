import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SeparateLine from './SeparateLine'
import {CssTextField} from './Style'

class City extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{this.props.intl.formatMessage({ id: 'CityGallery' })}</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField value={this.props.City ? this.props.City : ''} label={this.props.intl.formatMessage({ id: 'CityGallery' })} variant="outlined" onChange={(event) => this.props.refParent.setState({City: event.target.value})}/>
            </div>
          </Col>
         </Row>
         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

     </>
   )
 }
}


export default City;
