import React, { useState }from 'react';
import { useIntl } from 'react-intl';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaHandsHelping, FaFileInvoiceDollar } from 'react-icons/fa';
import { IoIosArrowForward, IoIosArrowBack, IoIosCalendar, IoIosConstruct, IoIosPerson } from "react-icons/io";
import { Link } from 'react-router-dom';
import { GiPublicSpeaker } from "react-icons/gi";
import { BiPhotoAlbum } from "react-icons/bi";
import { RiDashboardFill } from "react-icons/ri";

import Cookies from 'universal-cookie'
import ButtonLogout from './LogoutButton'
import ChooseLanguage from './ChooseLanguage'


const Aside = ({ toggled, handleToggleSidebar, setLocale }) => {
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const renderAside = () => {
    return (
      <ProSidebar
        collapsed={collapsed}
        toggled={toggled}
        breakPoint="md"
        onToggle={handleToggleSidebar}
        collapsedWidth={100}
      >
        <SidebarHeader>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div
            style={{
              padding: '24px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 14,
              letterSpacing: '1px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {intl.formatMessage({ id: 'sidebarTitle' })}
          </div>
          {
            toggled ?
              <></>
              :
              <button
                  onClick={() => handleCollapsedChange(!collapsed)}
                  style={{backgroundColor: 'transparent', borderWidth: 0}}
                  >
                  {collapsed ? <IoIosArrowForward color={'white'} size={20}/> : <IoIosArrowBack color={'white'} size={20}/>}
                </button>
          }

          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {verifyAccessDashBoard()}
            <SubMenu
              title={intl.formatMessage({ id: 'Members' })}
              icon={<IoIosPerson />}>
              {verifyAccessBusinessCustomize()}
              <MenuItem>
                {intl.formatMessage({ id: 'AllMembers' })}
                <Link to="/allmembers/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'WaitingMembers' })}
                <Link to="/waiting_member/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'EndMembership' })}
                <Link to="/end_member/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'NewMemberWhitoutInvoice' })}
                <Link to="/new_member_invoice/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'NoneMembers' })}
                <Link to="/all_none_member/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'DeletedMember' })}
                <Link to="/deletedmembers/" />
              </MenuItem>
            </SubMenu>


              {verifyAccessDelca()}
              {verifyAccessLuik()}



            <SubMenu
              title={intl.formatMessage({ id: 'Event' })}
              icon={<IoIosCalendar />}>
              <MenuItem>
                {intl.formatMessage({ id: 'AddEvent' })}
                <Link to="/add_event/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'NextEvent' })}
                <Link to="/all_event/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'PassedEvent' })}
                <Link to="/all_event_passed/" />

              </MenuItem>
            </SubMenu>

            <SubMenu
              title={intl.formatMessage({ id: 'Ambassadeur' })}
              icon={<GiPublicSpeaker />}>
              <MenuItem>
                {intl.formatMessage({ id: 'AddAmbassador' })}
                <Link to="/add_ambassador/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'AllAmbassador' })}
                <Link to="/all_ambassador/" />
              </MenuItem>
            </SubMenu>



          </Menu>
          <Menu iconShape="circle">
            <SubMenu
              title={intl.formatMessage({ id: 'Sponsor' })}
              icon={<FaHandsHelping />}>
              <MenuItem>
                {intl.formatMessage({ id: 'AddSponsor' })}
                <Link to="/add_sponsor/" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: 'AllSponsor' })}
                <Link to="/all_sponsor/" />
              </MenuItem>
            </SubMenu>



            <SubMenu

              title={intl.formatMessage({ id: 'Gallery' })}
              icon={<BiPhotoAlbum />}
            >
              <MenuItem>{intl.formatMessage({ id: 'AddGallery' })}
                <Link to="/add_gallery/" />
              </MenuItem>
              <MenuItem>{intl.formatMessage({ id: 'AllGallery' })}
                <Link to="/all_gallery/" />
              </MenuItem>
            </SubMenu>
            <SubMenu title={intl.formatMessage({ id: 'Outils' })} icon={<IoIosConstruct />}>
              <SubMenu title={intl.formatMessage({ id: 'AdresseB19' })}>
                <MenuItem>{intl.formatMessage({ id: 'AllAdresseB19' })}<Link to="/all_place/" /></MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'AddAdresseB19' })}<Link to="/add_place/" /></MenuItem>
              </SubMenu>
              <SubMenu title={intl.formatMessage({ id: 'Admin' })}>
                <MenuItem>{intl.formatMessage({ id: 'AllAdmin' })}<Link to="/all_admin/" /></MenuItem>
                <MenuItem>{intl.formatMessage({ id: 'AddAdmin' })}<Link to="/add_admin/" /></MenuItem>
              </SubMenu>
              {verifyAccessPromotion()}
            </SubMenu>
          </Menu>
        </SidebarContent>
        <SidebarFooter style={{ textAlign: 'center' }}>
                <div
                  className="sidebar-btn-wrapper"
                  style={{
                    padding: '20px 24px',
                  }}
                >
                {ConstructName()}
                </div>
                <div   style={{
                  padding: '20px 24px',
                }} className="sidebar-btn-wrapper"
                  >
                {ConstructRegion(collapsed)}
                </div>

                <ButtonLogout collapsed={collapsed}/>
              </SidebarFooter>
      </ProSidebar>
    )
  }


  const verifyAccessBusinessCustomize = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    var Access = false;

    if (Info && Info.businessCustomize === true)
      Access = true
    if (Access === false)
      return <></>
    return (
      <MenuItem>
        {intl.formatMessage({ id: 'BusinessCustomize' })}
        <Link to="/addbusinesscustom/" />
      </MenuItem>
    )
  }


  const verifyAccessDelca = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    var Access = false;

    if (Info && Info.delca === true)
      Access = true
    if (Access === false)
      return <></>
    return (
      <SubMenu
      title={"Delca"}
      icon={<FaFileInvoiceDollar />}>
      <MenuItem>
        {intl.formatMessage({ id: 'NewMemberDelca' })}
        <Link to="/newMemberDelca/" />
      </MenuItem>

     <MenuItem>
        {intl.formatMessage({ id: 'EndMemberDelca' })}
        <Link to="/renewMemberDelca/" />
      </MenuItem>
      <MenuItem>
         {intl.formatMessage({ id: 'EndMemberBusinessPackCustomDelca' })}
         <Link to="/renewBusinessPackCustomDelca/" />
       </MenuItem>

    </SubMenu>
    )
  }

  const verifyAccessLuik = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    var Access = false;

    if (Info && Info.luikMember === true)
      Access = true
    if (Access === false)
      return <></>
    return (
      <SubMenu
      title={"Membres Liège"}
      icon={<FaFileInvoiceDollar />}>
      <MenuItem>
        {intl.formatMessage({ id: 'NewMemberDelca' })}
        <Link to="/newMemberLuik/" />
      </MenuItem>
     {/*
     <MenuItem>
        {intl.formatMessage({ id: 'EndMemberDelca' })}
        <Link to="/renewMemberDelca/" />
      </MenuItem>
     */}
    </SubMenu>
    )
  }

  const verifyAccessPromotion = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    var Access = false;

    if (Info && Info.promotion === true)
      Access = true
    if (Access === false && Info && Info.hasOwnProperty('status') && Info.status === 'SuperSuperAdmin')
      Access = true
    if (Access === false)
      return <></>
    return (
        <MenuItem>{intl.formatMessage({ id: 'AllPromotion' })}<Link to="/all_promotion/" /></MenuItem>
  )
  }

  const verifyAccessDashBoard = () => {
    var cookies = new Cookies()
    var Info = cookies.get('infoAdmin')
    var Access = false;

    if (Info && Info.dashboard === true)
      Access = true
    if (Access === false && Info && Info.hasOwnProperty('status') && Info.status === 'SuperSuperAdmin')
      Access = true
    if (Access === false)
      return <></>
    return (
      <MenuItem icon={<RiDashboardFill />}>
      {intl.formatMessage({ id: 'DashBoard' })}
      <Link to="/dashboard/" />
    </MenuItem>
  )
  }
//<ChooseLanguage setLocale={setLocale}/>

  const   capitalizeFirstLetter = (string) =>  {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const  ConstructArray = (Array, collapsed) => {
    try {
      return Array.map(res => {
        if (collapsed)
          res = res + "diminutif"
        return intl.formatMessage({ id: res })
      }).join(' ')
    } catch (e) {
      return ("")
    }
  }

  const ConstructRegion = (collapsed) => {
    try {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      if (Info && Info.Region)
        return (<p style={{color: 'white', fontSize: collapsed ? '0.85rem' : 15, fontWeight: 'bold', fontFamily: 'Mitr'}}>{ConstructArray(Info.Region, collapsed)}</p>)
      return (<></>)
    } catch (e) {
      return (<></>)
    }
  }

  const ConstructName = () => {
    try {
      var cookies = new Cookies()
      var Info = cookies.get('infoAdmin')
      if (Info && Info.Name)
        return (<p style={{color: 'white', fontSize: 15, fontWeight: 'bold', fontFamily: 'Mitr'}}>{capitalizeFirstLetter(Info.Name.First) + " " + capitalizeFirstLetter(Info.Name.Last)}</p>)
      return (<></>)
    } catch (e) {
      return (<></>)
    }
  }

  const checkCookies = () => {
    const cookies = new Cookies();
    var acces = cookies.get('acces')
    if (!acces || acces === 'undefined')
      return (<></>)
    else
      return (renderAside())
  }

  return (
    <>
    {
      checkCookies()
    }
    </>

  );
};

export default Aside;
