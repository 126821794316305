import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AddAmbassador from './AddAmbassador'

class ParselAddAmbassador extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AddAmbassador} {...props}/>
   )
 }
}



export default ParselAddAmbassador;
