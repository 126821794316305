import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AllMember from './AllMember'

class ParselAllMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AllMember} {...props}/>
   )
 }
}



export default ParselAllMember;
