import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loading from './Loading'
import Private from './PrivateInfo'
import Pro from './ProInfo'
import Invoice from './InvoiceInfo'
import Complement from './ComplementInfo'
import Subscribe from './SubscribeInfo'
import Colleague from './Colleague'

class InfoMember extends React.Component {


  render (){
    return (
        <Container  className='shadow' style={{backgroundColor: 'white', padding: 15, borderRadius: 5, marginTop: 30, marginBottom: 10}}>


          <Row >
          {
            this.props.loading ?
            <Loading size={50} loadingMore={this.props.loading}/>
            :
            <>
            <Private Private={this.props.Private}/>
            <Pro SocietyInfo={this.props.SocietyInfo} Pro={this.props.Pro} />
            <Invoice  refParent={this.props.refParent} InvoiceInfo={this.props.InvoiceInfo}/>
            <Complement  Complement={this.props.Complement} SocietyInfo={this.props.SocietyInfo}/>
            <Subscribe  Private={this.props.Private} intl={this.props.intl} Complement={this.props.Complement}/>
            {
              this.props.Complement && (this.props.Complement.RegistrationType === "Business" || this.props.Complement.RegistrationType === "Couple" || this.props.Complement.RegistrationType === "BusinessPackCustomize") && this.props.Complement.BusinessInfo === true ?
              <Colleague  Accept={this.props.Accept} intl={this.props.intl} Complement={this.props.Complement}/>
              :
              <>
              </>
            }




            </>

          }


          </Row>

        </Container>

    )
  }
}

export default InfoMember;
