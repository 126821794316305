import React from 'react';
import Cookies from 'universal-cookie'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'react-bootstrap/Button'
import { IoLogOutOutline } from "react-icons/io5";

class LogoutButton extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        logout: false
        }
    }


    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
    }

     Logout = async () => {
      this.setState({logout: true}, async () => {
        const cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(500)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
    }

    renderLougoutContent = (color) => {
      if (this.props.collapsed)
        return <IoLogOutOutline color={color} size={15} />
      return (
        <p style={{margin: 0, padding: 0, color: color, fontSize: 15, fontWeight: 'bold', fontFamily: 'Mitr'}}>Déconnexion</p>
      )
   }

 render() {

   return (
     <div style={{padding: '20px 24px',}} className="sidebar-btn-wrapper">

     <Button
     disabled={this.state.logout}
     onClick={() => this.Logout()}

     style={{ margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
    >
    {
      this.state.logout ?
      <div style={{display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <div style={{width: '100%'}}>
       {this.renderLougoutContent('transparent')}
      </div>
      <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress
          size={"0.85rem"}
          thickness={5}
          color={"inherit"}
        />
      </div>
      </div>
      :
      this.renderLougoutContent("white")
    }

     </Button>
     </div>


   )
 }
}


export default LogoutButton;
