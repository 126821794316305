import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {AntSwitch} from './Style'



class Close extends React.Component {


 render() {

   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Fermer les inscriptions? (Complet)</h3>
              </div>
           </Col>
         </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
            <p style={{fontSize: 15, color: '#808080'}}>Cette conférence est-elle complète ? </p>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <p style={{fontSize: 15, color: '#808080', margin: 10}}>Non</p>
                <AntSwitch checked={this.props.isClosed} onChange={() => this.props.refParent.setState({isClosed: !this.props.isClosed})} style={{marginRight: 5}}/>
                <p style={{fontSize: 15, color: '#808080', margin: 10}}>Oui</p>
              </div>
           </div>
         </Col>
         </Row>

     </>
   )
 }
}


export default Close;
