import React from 'react';
import { useIntl } from 'react-intl';

const NotFound = () => {
  const intl = useIntl();
  return (
      <div style={{height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <h1 style={{color: 'black', fontSize: 35, fontWeight: 'bold'}}>Ooops...</h1>
        <p style={{fontFamily: 'Mitr, sans-serif', fontSize: 20, color:"#A9A9A9",}}>{intl.formatMessage({ id: 'PageNotFound' })}</p>
      </div>
  );
};

export default NotFound;
