import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { Dropdown } from 'react-bootstrap';

class PureParticipant extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {

        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

backgroundColorGet = () => {
  if (this.props.index % 2 === 0)
    return ('')
  return ('#ECECEC')
}

renderFile = () => {
  if (this.props.member.File){
    return (
      <Button onClick={() => this.props.refParent._DownloadFileInvoice(this.props.member.File, this.props.member.Id)} style={{fontSize: '0.85em'}} variant='text'>
        Télécharger
      </Button>
    )
  }
  return ('')
}

  render() {
    return (
      <TableRow key={this.props.member.Id} style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.FirstName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.LastName}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.Email}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.Society}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.Function}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.DateFacturation}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.member.File ? this.renderFile() : ""}</TableCell>

        <TableCell align="center" style={{}}>


          <Dropdown style={{textAlign: 'left'}}>
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

            </Dropdown.Toggle>

            <Dropdown.Menu variant="primary">

              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
              to={{
              pathname: this.props.member.MemberStatus === 'Member' ? `/details_member/${this.props.member.Id}` : `/details_none_member/${this.props.member.Id}`,
              state: {oldPage: `/invoice_event/${this.props.refParent.state.IdEvent}`, verify: true, Name: this.props.refParent.props.navigation.location.state.Name, Date: this.props.refParent.props.navigation.location.state.Date, isFree: this.props.refParent.props.navigation.location.state.isFree, Compta: this.props.refParent.props.navigation.location.state.Compta}
              }}>
                  Détails du membre
              </Button>

              </Dropdown.Item>
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button onClick={() => {this.props.refParent.UploadInvoice(this.props.member)}} variant="text" style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                Envoyer la facture
              </Button>


              </Dropdown.Item>


            </Dropdown.Menu>
          </Dropdown>

        </TableCell>
      </TableRow>)
  }

}


export default PureParticipant;
