import React from 'react';
import IsConnectedAndAccess from '../../IsConnected/IsConnectedAndAccess'
import BusinessCustomize from './BusinessCustomize'

class ParselBusinessCustomize extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={false} Access={"businessCustomize"} Component={BusinessCustomize} {...props}/>
   )
 }
}



export default ParselBusinessCustomize;
