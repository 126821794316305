import React from 'react';
import Table from './Table'
import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import {TransformDateToUnixTime} from '../../Moment/Moment';
import PopUp from './PopUp'
import Cookies from 'universal-cookie'
import Header from './Header'
import ModalDeleteUser from './ModalDeleteMember'
import ModalFreeUser from './ModalFreeMember'
import ExcelFile from './ExportExcelFile'
import ModalDetails from './ModalDetails'

class AllMember extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        member: [],
        free: false,
        statusFree: null,
        FreeMember: null,
        memberDisplay: [],
        searchMember: [],
        searchMemberRegistration: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
        search: null,
        Filter: [],
        FilterRegistration: [],
        statusDelete: null,
        delete: false,
        searchRegistration: false,
        MemberDelete: null,
        modalShow: false,
        detailsMemberShow: null,
        loadingDetailsMemberShow: false,
        errorDetailsMemberShow: false,
        detailsMemberShowBackend: null,
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: 'up',
          Society: "up",
          AccountCreatedAt: "up",
          SubscriptionBegin: "up",
          SubscriptionEnd: "up",
          Cotisation: "up",
          RegistrationType: "up",
          Region: "up",
        }
      }
    }

    GetSpecificMemberDetails = async (Member) => {
      this.setState({loadingDetailsMemberShow: true, modalShow: true, detailsMemberShow: Member}, async() => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          Id: Member.Id,
          SizeImage: "Large"
        }
        var promise = await Requestdynamic('_getSpecificMember', Params);
        if (promise.error === false && promise.data.error === false)
        {
          var Data = promise.data.data
          this.setState({loadingDetailsMemberShow: false, detailsMemberShowBackend: Data})
        }
        else {
          if (promise.error === true)
            this.setState({loadingDetailsMemberShow: false, errorDetailsMemberShow: true})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({loadingDetailsMemberShow: false, errorDetailsMemberShow: true})
        }
      })
    }

    _resetFilter = () => {
      this.setState({
        FirstName: "up",
        LastName: "up",
        Email: 'up',
        Society: "up",
        AccountCreatedAt: "up",
        SubscriptionBegin: "up",
        SubscriptionEnd: "up",
        Cotisation: "up",
        RegistrationType: "up",
        Region: "up",
      })
    }


    _sortByAlphabet = (Name) => {
      var Verify = ""
      if (this.state.searchRegistration === true)
        Verify = "searchMemberRegistration"
      else
        Verify = "member"

      var Copy = [...this.state[Verify]];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
        var nameA, nameB
        if (Name === "Cotisation")
        {
           nameA = a.Cotisation === true ? 'oui' : 'non'
           nameB = b.Cotisation === true ? 'oui' : 'non'
           if (!nameA)
            nameA = ""
           if (!nameB)
            nameB = ""
        }
        else if (Name ===  "SubscriptionEnd")
        {
           nameA = TransformDateToUnixTime(a.SubscriptionEnd, 'DD/MM/YYYY')
           nameB = TransformDateToUnixTime(b.SubscriptionEnd, 'DD/MM/YYYY')
           if (!nameA)
            nameA = ""
           if (!nameB)
            nameB = ""
        }
        else {
          if (!a[Name])
            a[Name] = ""
          if (!b[Name])
            b[Name] = ""
           nameA = a[Name].toLowerCase();
           nameB = b[Name].toLowerCase();
        }
           if (nameA < nameB) //sort string ascending
            return -1;
            if (nameB < nameA)
              return 1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      if (this.state.searchRegistration === true)
        this.setState({memberDisplay: Display, searchMemberRegistration: SortedResult, sortFilter: sortOrder})
      else
        this.setState({memberDisplay: Display, member: SortedResult, sortFilter: sortOrder})
    }

    _getAllNextMember = async () => {
      this.setState({loadingMore: true, searchMemberRegistration: [], FilterRegistration: [], searchRegistration: false}, async() => {
        await this.sleep(150)
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
            Jwt: Jwt,
            SizeImage: "Medium",
        }
        if (this.state.Filter.length !== 0)
          Params.RegionFrontend = this.state.Filter
        var promise = await Requestdynamic('_getAllNextMember', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var MemberCopy = [...this.state.member];
          var newListMember = MemberCopy.concat(promise.data.data)
          newListMember = [...new Set(newListMember)]
          var MemberDisplay = newListMember.slice(0, 20)
          var paramsSetState = {memberDisplay: MemberDisplay, member: newListMember, MemberDelete: null, statusDelete: null, delete: false}
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false, MemberDelete: null, statusDelete: null, delete: false})
        }
        this.setState({loadingMore: false})
      })

    }

    _addAllNextMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy;
        if (this.state.search != null)
           Copy = [...this.state.searchMember]
        else if (this.state.searchRegistration === true)
          Copy = [...this.state.searchMemberRegistration]
        else
           Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    componentDidMount(){
      this._getAllNextMember()
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    search = (value) => {
      var Copy, Display;
      var Verify;
      if (this.state.searchRegistration === true)
        Verify = "searchMemberRegistration"
      else
        Verify = "member"

      if (value.length === 0)
      {
         Copy = [...this.state[Verify]];
         Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
        return ;
      }
       Copy = [...this.state[Verify]]
      var FilterResult = Copy.filter(res => {
        if (res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
          return true
        else if (res.Society && res.Society.toLowerCase().includes(value.toLowerCase()))
          return true
        return false
      })
       Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }

    MemberDisplayRegistration = () => {
      var Copy, Display
      if (this.state.FilterRegistration.length === 0)
      {
         Copy = [...this.state.member];
         Display = Copy.slice(0, 20)
         this._resetFilter()
        this.setState({memberDisplay: Display, searchMemberRegistration: [], searchRegistration: false})
        return ;
      }
       Copy = [...this.state.member]
      var FilterResult = Copy.filter(res => this.state.FilterRegistration.find(registration => {
        if (registration === "Business")
          if (res.RegistrationType === "BusinessPackCustomize")
            return true
        if (registration === res.RegistrationType)
          return true
      }))
       Display = FilterResult.slice(0, 20)
      this.setState({searchRegistration: true, memberDisplay: Display, searchMemberRegistration: FilterResult})
    }

    handleScroll = (e) => {
      var verify;
      if (this.state.search === null && this.state.searchRegistration === false)
         verify = 'member'
      else if (this.state.search != null)
         verify = 'searchMember'
      else
        verify = 'searchMemberRegistration'

      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
        this._addAllNextMember()
      }
    }

    _FreeGestion = async (FreeMember) => {
      if (this.state.free === true)
      {
        var Status;
        this.setState({statusFree: "Waiting"}, async () => {
          var cookies = new Cookies();
          var Jwt = cookies.get('acces');
          var Params = {Jwt: Jwt, Id: this.state.FreeMember.Id}
          Params.Status = this.state.FreeMember.FreeStatus === true ? "false" : "true"
          var promise = await Requestdynamic('_FreeMember', Params)
          console.log(promise)

          if (promise.error === false && promise.data.error === false){
            this.setState({statusFree: "Success"}, async () => {
              var Copy = [...this.state.member]
              var CopyDisplay = [...this.state.memberDisplay]
              if (this.state.FreeMember && (this.state.FreeMember.RegistrationType === 'Business' || this.state.FreeMember.RegistrationType === 'Couple' || this.state.FreeMember.RegistrationType === 'BusinessPackCustomize')){
                var Colleague = []
                if (this.state.FreeMember.BusinessInfo === true)
                  Colleague = this.state.FreeMember.Colleague
                else{
                  Colleague = this.state.FreeMember.BusinessInfo.Colleague
                  Colleague.push({Id: this.state.FreeMember.BusinessInfo.Id})
                }
                for (var i = 0; i < Colleague.length ; i++){
                  var IndexCopy = Copy.findIndex(res => res.Id === Colleague[i].Id)
                  var ObjectCopy = Object.assign({}, Copy[IndexCopy])
                  ObjectCopy.FreeStatus = !this.state.FreeMember.FreeStatus
                  ObjectCopy.SubscriptionEnd = promise.data.SubscriptionEnd
                  Copy[IndexCopy] = ObjectCopy

                  var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === Colleague[i].Id)
                  if (IndexCopyDisplay != -1){
                    ObjectCopy = Object.assign({}, CopyDisplay[IndexCopyDisplay])
                    ObjectCopy.FreeStatus = !this.state.FreeMember.FreeStatus
                    ObjectCopy.SubscriptionEnd = promise.data.SubscriptionEnd
                    CopyDisplay[IndexCopyDisplay] = ObjectCopy
                  }
                }
              }
              var IndexCopy = Copy.findIndex(res => res.Id === this.state.FreeMember.Id)
              var ObjectCopy = Object.assign({}, Copy[IndexCopy])
              ObjectCopy.FreeStatus = !this.state.FreeMember.FreeStatus
              ObjectCopy.SubscriptionEnd = promise.data.SubscriptionEnd


              Copy[IndexCopy] = ObjectCopy

              var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.FreeMember.Id)
              if (IndexCopyDisplay != -1){
                ObjectCopy = Object.assign({}, CopyDisplay[IndexCopyDisplay])
                ObjectCopy.FreeStatus = !this.state.FreeMember.FreeStatus
                ObjectCopy.SubscriptionEnd = promise.data.SubscriptionEnd
                CopyDisplay[IndexCopyDisplay] = ObjectCopy
              }

              this.setState({statusFree: false, member: Copy, memberDisplay: CopyDisplay, free: false, FreeMember: null})
            })
            return ;
          }
          else {
            if (promise.error === true)
              this.setState({statusFree: "Error"})
            else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
            {
              cookies = new Cookies();
              cookies.remove('acces', { path: '/' })
              cookies.remove('infoAdmin', { path: '/' })
              await this.sleep(900)
              var url = '/login'
              const link = document.createElement('a');
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              return ;
            }
            else
              this.setState({statusFree: "Error"})
          }
        })
      }
      else {
        this.setState({free: true, statusFree: "Ask", FreeMember: FreeMember})
        return ;
      }

    }

    _DeleteUser = async (member) => {
      try {
        if (this.state.delete === true)
        {
          this.setState({statusDelete: "Waiting"}, async () => {
            var cookies = new Cookies();
            var Jwt = cookies.get('acces');
            var Params = {Jwt: Jwt, Id: this.state.MemberDelete.Id}
            if (this.state.MemberDelete && (this.state.MemberDelete.RegistrationType === 'Business' || this.state.MemberDelete.RegistrationType === 'Couple' || this.state.MemberDelete.RegistrationType === 'BusinessPackCustomize') && this.state.MemberDelete.BusinessInfo !== true){
              var promise = await Requestdynamic('_removeColleague', Params)
            }
            else
              var promise = await Requestdynamic('_deleteMember', Params)
            if (promise.error === false && promise.data.error === false){
              this.setState({statusDelete: "Success"}, async () => {
                var Copy = [...this.state.member]
                var CopyDisplay = [...this.state.memberDisplay]
                if (this.state.MemberDelete && (this.state.MemberDelete.RegistrationType === 'Business' || this.state.MemberDelete.RegistrationType === 'Couple' || this.state.MemberDelete.RegistrationType === 'BusinessPackCustomize') && this.state.MemberDelete.BusinessInfo === true){
                  for(var i = 0; i < this.state.MemberDelete.Colleague.length ; i++){
                    var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberDelete.Colleague[i].Id)
                    Copy.splice(IndexCopy, 1)
                    var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberDelete.Colleague[i].Id)
                    if (IndexCopyDisplay != -1)
                      CopyDisplay.splice(IndexCopyDisplay, 1)
                  }
                }
                var IndexCopy = Copy.findIndex(res => res.Id === this.state.MemberDelete.Id)
                Copy.splice(IndexCopy, 1)
                var IndexCopyDisplay = CopyDisplay.findIndex(res => res.Id === this.state.MemberDelete.Id)
                if (IndexCopyDisplay != -1)
                  CopyDisplay.splice(IndexCopyDisplay, 1)
                await this.sleep(500)
                this.setState({statusDelete: null, member: Copy, memberDisplay: CopyDisplay, delete: false, MemberDelete: null})
              })
              return ;
            }
            else {
              if (promise.error === true)
                this.setState({statusDelete: "Error"})
              else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
              {
                cookies = new Cookies();
                cookies.remove('acces', { path: '/' })
                cookies.remove('infoAdmin', { path: '/' })
                await this.sleep(900)
                var url = '/login'
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                return ;
              }
              else
                this.setState({statusDelete: "Error"})
            }
          })
        }
        else {
          this.setState({delete: true, statusDelete: "Ask", MemberDelete: member})
          return ;
        }
      } catch (e) {
        this.setState({statusDelete: "Error"})
      }
    }


 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <ModalDetails
            show={this.state.modalShow}
            loadingDetailsMemberShow={this.state.loadingDetailsMemberShow}
            detailsMemberShowBackend={this.state.detailsMemberShowBackend}
            detailsMember={this.state.detailsMemberShow}
            errorDetailsMemberShow={this.state.errorDetailsMemberShow}
            onHide={() => this.setState({modalShow: false, detailsMemberShow: null})}
            intl={this.props.intl}
          />
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <ModalDeleteUser refParent={this} openModal={this.state.delete} statusDelete={this.state.statusDelete} MemberDelete={this.state.MemberDelete}/>
     <ModalFreeUser refParent={this} openModal={this.state.free} statusDelete={this.state.statusFree}/>
     <Header FilterRegistration={this.state.FilterRegistration} Member={this.state.member} searchRegistration={this.state.searchRegistration} searchMemberRegistration={this.state.searchMemberRegistration}  Filter={this.state.Filter} intl={this.props.intl} refParent={this}/>
     <ExcelFile data={this.state.searchRegistration ? this.state.searchMemberRegistration : this.state.member}/>
     <Table loadingMore={this.state.loadingMore} intl={this.props.intl} refParent={this} memberDisplay={this.state.memberDisplay} deleting={this.state.deleting} member={this.state.member}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllMember;
