import React from 'react';
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { Fade } from "react-awesome-reveal";
import ColleagueInfo from './ColleagueInfo'
import { IoIosAlert } from "react-icons/io";


class ColleagueSection extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    componentDidMount(){

    }

    ColleagueInfo = () => {
      try {
        return this.props.refParent.state.Colleague.map((Colleague, index) => {
          return (
            <ColleagueInfo refParent={this.props.refParent} Colleague={Colleague} index={index} key={Colleague.target} />
          )
        })
      } catch (e){
        return <></>
      }
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

 render() {
   return (
     <Container ref={this.props.refParent.colleagueSection} className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 10, padding: 50, margin: 10, marginTop: 25}}>
       {
         this.props.refParent.state.Error &&  this.props.refParent.state.Error.hasOwnProperty("Colleague") && this.props.refParent.state.Error.Colleague.colleague ?
         <Container className="shadow" style={{backgroundColor: '#ff7878', borderRadius: 10, paddingBottom: 0,  width: '100%', marginBottom: 10}}>
           <Row style={{}}>
             <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', height: '100%', flexDirection: 'row', padding: 10, alignItems: 'center'}}>
                 <div style={{width: '10%', justifyContent: 'flex-start', alignItems: 'center'}}>
                   <IoIosAlert size={30} color={"white"}/>
                 </div>
                 <div style={{width: '90%', justifyContent: 'flex-start', alignItems: 'center'}}>
                   <p style={{color: "white", fontSize: 15, padding: 0, margin: 0, fontWeight: 'bold'}}>{this.props.refParent.state.Error.Colleague.colleague}</p>
                 </div>
             </Col>
           </Row>
         </Container>
         :
         <>
         </>
       }
       <Row>
         <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations des collègues</h6>
         </Col>
       </Row>
       {this.ColleagueInfo()}
       <Button

       onClick={() => this.props.refParent.AddColleague()}
       style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', marginTop: 10, adding: 8,  border: "none"}}
       >
         <p style={{margin: 0}}>AJOUTER UN COLLEGUE</p>
       </Button>

     </Container>
   )
 }
}


export default ColleagueSection;
