import React from 'react';
import Col from 'react-bootstrap/Col';
const TEXTSIZE = "0.85em";

class Complement extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object, date = false) => {
    if (date === true)
    {
      if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0 && this.props[object][value] !== "null/null/null"){
        return ({
          color: 'black',
          fontSize: TEXTSIZE,

        })
      }
      return ({
        color: 'red',
        fontWeight: 'bold',
        fontSize: TEXTSIZE,

      })
    }
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,

    })
  }

  render (){
    return (
      <Col sm={12} xl={12} lg={12} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS COMPLEMENTAIRE</p>


        <div style={{}}>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Langue de l'évènement:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Language", 'Complement')}>{this.props.Complement && this.props.Complement.Language && this.props.Complement.Language.length !== 0 ? this.ConstructStringWithComma(this.props.Complement.Language, ' ,') : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Complet:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Closed", 'Complement')}>{this.props.Complement && this.props.Complement.Closed ? this.props.Complement.Closed : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Url Public:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("PublicUrlSubscribe", 'Complement')}>{this.props.Complement && this.props.Complement.PublicUrlSubscribe ? this.props.Complement.PublicUrlSubscribe : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Nombre d'inscription:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("NumberSubscribe", 'Complement')}>{this.props.Complement && this.props.Complement.hasOwnProperty('NumberSubscribe') ? this.props.Complement.NumberSubscribe : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Limit de place:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("LimitPlace", 'Complement')}>{this.props.Complement && this.props.Complement.LimitPlace ? this.props.Complement.LimitPlace : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Tête d'affiche:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("HeadLiner", 'Complement')}>{this.props.Complement && this.props.Complement.HeadLiner ? this.props.Complement.HeadLiner : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Prix:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Free", 'Complement')}>{this.props.Complement && this.props.Complement.Free ? this.props.Complement.Free : 'Vide'}</p>
          </div>
        </div>



        </div>
      </Col>
    )
  }
}

export default Complement;
