import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';
import {AnimationRotate} from './Style'
import { IoIosArrowForward} from "react-icons/io";

class TableHeadAdmin extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _renderCell = (Name, Trad, classes, none) => {
      if (none)
      {
        return (
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
            <p>{Trad}</p>
            <div style={{marginLeft: 5}}>
              <IoIosArrowForward  color={'rgba(0,0,0,0)'} size={15} />
            </div>
          </div>
        )
      }
      return (
        <div onClick={() => this.props.refParent._sortByAlphabet(Name)} style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
          <p>{Trad}</p>
          <div style={{marginLeft: 5}}>
            <IoIosArrowForward className={this.props.refParent.state.sortFilter[Name] === 'down' ? classes.spin : classes.refresh} color={'black'} size={15} />
          </div>
        </div>
      )
    }

 render() {
   const { classes } = this.props
   return (

      <TableHead>
        <TableRow>
          <TableCell align="left">{this._renderCell("Name", 'NOM', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("City", 'VILLE', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("PostalCode", 'CODE POSTAL', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("Street", 'RUE', classes)}</TableCell>
          <TableCell align="left">{this._renderCell("Number", 'NUMERO', classes)}</TableCell>
          <TableCell align="center">{this._renderCell("RegionB19", 'REGION', classes)}</TableCell>
          <TableCell align="center">{this._renderCell("Action", 'ACTION', classes)}</TableCell>
        </TableRow>
      </TableHead>

   )
 }
}


export default withStyles(AnimationRotate)(TableHeadAdmin);
