import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container  from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'

class ErrorNotFound extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (

     <Container className="shadow" style={{width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50, marginBottom: 50}}>
     <Row >
      <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h2>Page not found...</h2>
      </Col>
     </Row>

     <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} sm={10} md={10} xl={8} lg={8}>
        <Button
        onClick={() => this.props.next()}
        style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
       >
        <p style={{margin: 0, padding: 0}}>Retourner à la page de connexion</p>
        </Button>
      </Col>
     </Row>
     </Container>

   )
 }
}


export default ErrorNotFound;
