import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

class PureSponsor extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  TradRegionB19 = (Region) => {
    try {
      var trad = this.props.intl.formatMessage({id: Region})
      return (trad)
    } catch (e) {
      if (Region)
        return Region
      return ('')
    }
  }

  renderRegionB19 = (RegionB19) => {
    if (RegionB19)
      return RegionB19.map(res => this.TradRegionB19(res)).join(', ')
    return ('')
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left">
        {
          this.props.sponsor.Logo ?
          <img  alt={"Logo"} key={this.props.sponsor.Id} src={this.props.sponsor.Logo} style={{padding: 0, margin: 0, objectFit: 'contain', width: 90}}/>
          :
          <div style={{padding: 0, margin: 0, backgroundColor: 'red', objectFit: 'contain', width: 90, backgroundColor: this.state.color, height: '2em'}}></div>
        }
        </TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.sponsor.Name}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}><a rel="noreferrer noopener" target="_blank" href={this.props.sponsor.Link}>{this.props.sponsor.Link}</a></TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.sponsor.Category}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.renderRegionB19(this.props.sponsor.RegionB19)}</TableCell>
        <TableCell align="center" style={{}}>






        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button disabled={this.props.deleting ? true : false} variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
              to={{
              pathname: `/sponsor_edit/${this.props.sponsor.Id}`,
              state: {
                Name: this.props.sponsor.Name,
                Link: this.props.sponsor.Link,
                Logo: this.props.sponsor.Logo,
                Category:  this.props.sponsor.Category,
                RegionB19: this.props.sponsor.RegionB19
              }
            }}
              >
                Editer
              </Button>

            </Dropdown.Item>
            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button onClick={() => {this.props.refParent.setState({openModalDelete: true, SponsorDelete: this.props.sponsor})}} disabled={this.props.deleting ? true : false} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
              Supprimer
            </Button>

            </Dropdown.Item>


          </Dropdown.Menu>
        </Dropdown>

        </TableCell>
      </TableRow>)
  }

}

export default PureSponsor;
