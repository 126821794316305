import React from 'react';
import TableSubscribe from './HistoryTable/Subscribe/Table'
import TableEvent from './HistoryTable/Event/Table'
import TableCarpool from './HistoryTable/Carpool/Table'
import TableStripe from './HistoryTable/Stripe/Table'

class SubscribeHistory extends React.Component {

  GetHistory = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = [...History.Payment]
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  GetHistoryEvent = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = [...History.Event]
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  GetHistoryStripe = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = [...History.Stripe]
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  GetHistoryCarpool = () => {
    try {
      var History = this.props.refParent.state.History;
      if (!History)
        return ([])
      History = [...History.Carpool]
      if (!History  || History.length === 0)
        return ([])
      return (History)
    } catch (e) {
      return ([])
    }

  }

  _render = () => {
    if (this.props.History === "subscribe")
      return (<TableSubscribe refParent={this.props.refParent} History={this.GetHistory()} />)
    else if (this.props.History === "event")
      return (<TableEvent refParent={this.props.refParent} History={this.GetHistoryEvent()} />)
  /*  else if (this.props.History === "stripe")
      return (<TableStripe refParent={this.props.refParent} History={this.GetHistoryStripe()} />)*/
    else
      return (<TableCarpool refParent={this.props.refParent} History={this.GetHistoryCarpool()} />)
  }

  render (){
    return (
      <>
      {
        this._render()
      }
      </>
    )
  }
}

export default SubscribeHistory;
