import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class LoadingInfo extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {

   return (
       <Popup showPopup={this.props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: 'orange'}}>
         <CircularProgress
           size={50}
           thickness={4}
           color={"inherit"}
         />
         </div>
       </Popup>
   )
 }
}


export default LoadingInfo;
