import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Loading from './Loading'
import Button from '@material-ui/core/Button';

import { TiDelete } from "react-icons/ti";
import { IoAddCircleSharp } from "react-icons/io5";
class Header extends React.Component {

  _setMaj = (string) => {
    try {
      if(string !== null){
        const newString = string.split("-")
        for (var i = 0; i < newString.length; i++) {
          newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
        }
        const newStringJoin = newString.join("-")
        return (newStringJoin)
      }
      return ('')
    }catch (e){
      return (string)
    }
}

  _renderColleague = () => {
    try {
      return this.props.Colleague.map((colleague, index) => {
        return (
          <Row style={{margin: 15}}>
            <Col sm={9} xl={9} lg={9} md={9} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                <p style={{margin: 0, padding: 0, fontWeight: 'bold', fontSize: '0.85rem'}}>{this._setMaj(colleague.firstName) + " " + this._setMaj(colleague.lastName)} </p>
                <p style={{margin: 10, padding: 0, fontSize: '0.80rem', fontStyle: 'italic'}}>{colleague.email} </p>
              </div>
            </Col>
            <Col sm={3} xl={3} lg={3} md={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Button variant='text' onClick={() => this.props.refParent._DeleteColleague(colleague.Id)} style={{margin: 10}}>
                <TiDelete size={30} color={'black'} />
              </Button>
            </Col>
          </Row>
        )
      })
    } catch (e) {
      return (<></>)
    }
  }

  _renderAddColleague = () => {
    try {
      if (this.props.refParent.state.RegistrationType === "BusinessPackCustomize" || this.props.Colleague.length != 3)
      {
        return (
          <Row style={{margin: 15}}>
            <Col sm={9} xl={9} lg={9} md={9} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
              <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: 'column'}}>
                <p style={{margin: 0, padding: 0, fontWeight: 'bold', fontSize: '0.85rem'}}>Ajouter un collègue </p>
                <p style={{margin: 10, padding: 0, fontSize: '0.80rem', fontStyle: 'italic'}}>example@example.com </p>
              </div>
            </Col>
            <Col sm={3} xl={3} lg={3} md={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Button variant='text' onClick={() => this.props.refParent._AddColleague()} style={{margin: 10}}>
                <IoAddCircleSharp size={30} color={'orange'} />
              </Button>
            </Col>
          </Row>
        )
      }
      else
        return(<></>)
    } catch (e) {
      return (<></>)
    }
  }


  render (){
    return (


      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20,  whidth: '100%', marginBottom: 10}}>
        {this.props.loading ?
          <Loading />
          :
          <>
          <h5 style={{marginBottom: '25px'}}>Ajouter et/ou Supprimer un collègue</h5>
          {this._renderColleague()}
          {this._renderAddColleague()}
          </>
        }
      </Container>

    )
  }
}

export default Header;
