import React from 'react';
import Header from './Header'
import {Requestdynamic} from '../../Network/Fetch'
import PopUp from './PopUp'
import Loading from './Loading'
import Table from './Table'
import Cookies from 'universal-cookie'


class AddParticipantEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        loadingMore: false,
        openModal: false,
        memberDisplay: [],
        searchMember: [],
        member: [],
        title: null,
        message: null,
        Name: null,
        Date: null,
        redirect: false,
        TypeMember: null,
        IdEvent: null,
        search: null,
        AddParticipant: [],
        sortFilter: {
          FirstName: "up",
          LastName: "up",
          Email: 'up',
          Society: "up",
          AccountCreatedAt: "up",
          SubscriptionBegin: "up",
          SubscriptionEnd: "up",
          Cotisation: "up",
          RegistrationType: "up",
          Region: "up",
          NumberSubscribeEvent: 'up'
        }
      }
    }

    _sortByAlphabet = (Name) => {

      var Copy = [...this.state.member];
      var NameSortFilter = this.state.sortFilter[Name]
      var SortedResult = Copy.sort(function(a, b){
          if (!a[Name])
            a[Name] = ""
          if (!b[Name])
            b[Name] = ""
          var nameA = a[Name].toLowerCase(), nameB = b[Name].toLowerCase();
           if (nameA < nameB) //sort string ascending
            return -1;
         return 0; //default return value (no sorting)
        });
      if (NameSortFilter === 'down')
        SortedResult = SortedResult.reverse()
      var Display = SortedResult.slice(0, 20);
      var sortOrder = Object.assign({}, this.state.sortFilter)
      sortOrder[Name] = sortOrder[Name] === 'up' ? "down" : 'up'
      this.setState({memberDisplay: Display, member: SortedResult, sortFilter: sortOrder})
    }

    _getAllNotParticipant = async () => {
      if (!this.state.TypeMember){
        this.setState({loadingMore: false})
        return ;
      }
      this.setState({loadingMore: true}, async() => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            IdEvent: this.state.IdEvent,
            SizeImage: "Small",
            MemberStatus: this.state.TypeMember
          },
        }
        var promise = await Requestdynamic('_getAllNotParticipant', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var MemberCopy = [...this.state.member];
          var newListMember = MemberCopy.concat(promise.data.data)
          newListMember = [...new Set(newListMember)]
          var MemberDisplay = newListMember.slice(0, 10)
          var paramsSetState = {memberDisplay: MemberDisplay, member: newListMember, loadingMore: false}
          this.setState(paramsSetState)
          return ;
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", loadingMore: false})
        }
      })

    }

    _addAllNextMember = async () => {
      this.setState({loadingMore: true}, async() => {
        var Copy;
        if (this.state.search != null)
           Copy = [...this.state.searchMember]
        else
           Copy = [...this.state.member]

        var CopyDisplay = [...this.state.memberDisplay];

        var Length = Copy.length
        var LengthDisplay = CopyDisplay.length

        if (LengthDisplay >= Length)
        {
          this.setState({loadingMore: false})
          return ;
        }
        var CopySplice = Copy.slice(LengthDisplay, LengthDisplay + 20)
        var Display = CopyDisplay.concat(CopySplice)
        Display = [...new Set(Display)]
        this.setState({loadingMore: false, memberDisplay: Display})
        return ;
      })
    }

    _VerifySource = async () => {
      this.setState({loadingMore: true}, async () => {

            if (!this.props.navigation.match.params.eventid){
              this.setState({loadingMore: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            let Name = this.props.navigation.location.state && this.props.navigation.location.state.Name ?  this.props.navigation.location.state.Name : null;
            let Date = this.props.navigation.location.state && this.props.navigation.location.state.Date ?  this.props.navigation.location.state.Date : null;
            if (!Name || !Date){
              this.setState({openModal: true, title: 'Error', message: "Une erreur est survenue", redirect: true, loadingMore: false})
              return ;
            }
            this.setState({
              IdEvent: this.props.navigation.match.params.eventid,
              Name: Name,
              Date: Date
            }, async () => this._getAllNotParticipant())
        })
    }

    componentDidMount(){
        this.goBack = this.goBack.bind(this);
        this._VerifySource()
    }

    search = (value) => {
      var Copy, Display;
      if (value.length === 0)
      {
         Copy = [...this.state.member];
         Display = Copy.slice(0, 20)
        this.setState({search: null, memberDisplay: Display, searchMember: []})
        return ;
      }
       Copy = [...this.state.member]
      var FilterResult = Copy.filter(res => res.Email.toLowerCase().includes(value.toLowerCase()) || res.FirstName.toLowerCase().includes(value.toLowerCase()) || res.LastName.toLowerCase().includes(value.toLowerCase()))
       Display = FilterResult.slice(0, 20)
      this.setState({search: value, memberDisplay: Display, searchMember: FilterResult})
    }


    goBack(){
      var paramsNavigate = {pathname: `/subscribe_member_event/${this.state.IdEvent}`, state: this.props.navigation.location.state}
      this.props.navigation.history.push(paramsNavigate);
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

   handleScroll = (e) => {
     var verify;
     if (this.state.search === null)
        verify = 'member'
     else
        verify = 'searchMember'

     const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
     if (bottom && this.state.memberDisplay.length !== this.state[verify].length && this.state.loadingMore === false) {
       this._addAllNextMember()
     }
   }

   AddParticipantEvent = async (Id) => {
     var Copy = [...this.state.AddParticipant];
     var index = Copy.findIndex(res => res.Id === Id)
     if (index !== -1)
      Copy[index].status = 'Waiting'
    else
      Copy.push({Id: Id, status: "Waiting"})
     this.setState({AddParticipant: Copy}, async () => {
       var cookies = new Cookies();
       var Jwt = cookies.get('acces');
       var Params = {
           Jwt: Jwt,
           IdEvent: this.props.navigation.match.params.eventid,
           IdMember: Id
       }
       await this.sleep(350)
       var promise = await Requestdynamic('_putParticipantEvent', Params)
       if(promise.error === false && promise.data.error === false)
       {
         Copy = [...this.state.AddParticipant]
        index = Copy.findIndex(res => res.Id === Id)
         if (index === -1)
          return ;
          Copy[index].status = 'Success';
          this.setState({AddParticipant: Copy})

       }
       else {
         if (promise.error === true){
           Copy = [...this.state.AddParticipant]
          index = Copy.findIndex(res => res.Id === Id)
           if (index === -1)
            return ;
            Copy[index].status = 'Error';
            this.setState({AddParticipant: Copy})
         }
         else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
           cookies = new Cookies();
           cookies.remove('acces', { path: '/' })
           cookies.remove('infoAdmin', { path: '/' })
           await this.sleep(900)
           var url = '/login'
           const link = document.createElement('a');
           link.href = url;
           document.body.appendChild(link);
           link.click();
           link.parentNode.removeChild(link);
           return ;
         }
         else {
           if (promise.data.NotAccess === true)
           {
             Copy = [...this.state.AddParticipant]
            index = Copy.findIndex(res => res.Id === Id)
             if (index === -1)
              return ;
              Copy[index].status = 'Error';
              this.setState({AddParticipant: Copy, title: 'Erreur', message: "Vous n'avez pas les droits nécèssaires pour éditer cette évènement", openModal: true})
             return ;
           }
           Copy = [...this.state.AddParticipant]
          index = Copy.findIndex(res => res.Id === Id)
           if (index === -1)
            return ;
            Copy[index].status = 'Error';
            this.setState({AddParticipant: Copy})
         }

       }
     })
   }


 render() {
   return (

      <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
        <PopUp redirect={this.state.redirect} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
       <Header search={this.state.search} refParent={this} TypeMember={this.state.TypeMember} Name={this.state.Name} Date={this.state.Date} goBack={this.goBack}/>
       <Table IdEvent={this.state.IdEvent} refParent={this} AddParticipant={this.state.AddParticipant} loadingMore={this.state.loadingMore} memberDisplay={this.state.memberDisplay} intl={this.props.intl}/>
       <Loading loadingMore={this.state.loadingMore}/>
      </div>

   )
 }
}


export default AddParticipantEvent;
