import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import InvoiceEvent from './InvoiceEvent'

class ParselInvoiceEvent extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={InvoiceEvent} {...props}/>
   )
 }
}



export default ParselInvoiceEvent;
