import React from 'react';
import Image from 'react-bootstrap/Image'
class ProfilImage extends React.Component {


  render (){
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {
          this.props.Image ?
          <Image  src={this.props.Image} thumbnail style={{borderRadius: 5, width: '50%'}}/>
            :
          <img alt={"member ressource"} src={this.props.Image} style={{width: '10.5em', height: '10.5em'}}/>

        }


      </div>
    )
  }
}

export default ProfilImage;
