import React from 'react';
import {Requestdynamic} from '../Network/Fetch';
import Cookies from 'universal-cookie'

import Email from './Email'
import RecoveryToken from './RecoveryToken'
import ErrorNotFound from './ErrorNotFound'
import NewPassword from './NewPassword'
import NewRecoveryToken from './NewRecoveryToken'

class ForgetPassword extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        Error: null,
        Email: null,
        RecoveryToken: null,
        UniqueKey: null,
        successValidateAccount: false,
        successPassword: false,
        verifyEmail: false,
        Password: null,
        VerifyPassWord: null,
        createPassword: false,
        verifyRecoveryToken: false,
        successRecoveryToken: false
      }
    }

    componentDidMount(){
      var cookies = new Cookies();
      var acces = cookies.get('acces')
      if (acces !== 'undefined' && acces){
        this.props.navigation.history.push({
        pathname: `/login`,
        state: {}
        })
      }


    }

_CheckRecoveryToken = (Error) => {
  if (!this.state.RecoveryToken || this.state.RecoveryToken.length === 0)
    Error.RecoveryToken = true
}


_CheckEmail = (Error) => {
  if (!this.state.Email || this.state.Email.length === 0){
      Error.Email = true
  }
}

sleep = (ms) => {
 return new Promise(resolve => setTimeout(resolve, ms));
}

  _verifyEmail = () => {
  this.setState({verifyEmail: true, Error: null}, async () => {

  var Error = {};
  this._CheckEmail(Error);
  var KeysErrorLength = Object.keys(Error).length
  if (KeysErrorLength !== 0)
  {
    this.setState({Error: Error, verifyEmail: false})
    return ;
  }
  var Params = {
    Email: this.state.Email,
    OAuth: false,
  }
  var promise = await Requestdynamic('_validateEmail_ForgetPassword', Params)
  if (promise.error === false && promise.data.error === false)
  {
    this.setState({verifyEmail: false, Error: null, successValidateAccount: true})
  }
  else {
    if (promise.error === true)
      this.setState({verifyEmail: false, Error: {Connexion: "Un problème est survenue veuillez réesayer plus tard"}})
    else
      this.setState({verifyEmail: false, Error: {Connexion: 'Information incorrect'}})
  }

  })


}

_next = () => {
  this.props.navigation.history.push({
  pathname: `/login`,
  state: {}
  })
}

_CheckPassword = (Error) => {
  if (!this.state.Password || this.state.Password.length === 0)
    Error.Password = true
}

_CheckVerifyPassword = (Error) => {
  if (!this.state.VerifyPassWord || this.state.Password.VerifyPassWord === 0)
    Error.VerifyPassWord = true
}



_verifyRecoveryToken = () => {
  this.setState({verifyRecoveryToken: true, Error: null}, async () => {

    var Error = {};
    this._CheckRecoveryToken(Error);
    if (Error.hasOwnProperty("RecoveryToken"))
    {
      this.setState({Error: Error, verifyRecoveryToken: false})
      return ;
    }
    var Params = {
      Email: this.state.Email,
      RecoveryToken: this.state.RecoveryToken,
    }
    var promise = await Requestdynamic('_recoveryToken_ForgetPassword', Params)
    if (promise.error === false && promise.data.error === false)
    {
      this.setState({verifyRecoveryToken: false, Error: null, successRecoveryToken: true})
    }
    else {
      if (promise.error === true)
        this.setState({verifyRecoveryToken: false, Error: {Connexion: "Un problème est survenue veuillez réesayer plus tard"}})
      else
        this.setState({verifyRecoveryToken: false, Error: {Connexion: 'Information incorrect'}})
    }

  })
}

_createPassword = () => {
  this.setState({createPassword: true, Error: null}, async () => {

    var Error = {};
    this._CheckPassword(Error);
    if (Error.hasOwnProperty("Password"))
    {
      this.setState({Error: Error, createPassword: false})
      return ;
    }
    this._CheckVerifyPassword(Error);
    if (Error.hasOwnProperty("VerifyPassWord")){
      this.setState({Error: Error, createPassword: false})
      return ;
    }
    if (this.state.Password !== this.state.VerifyPassWord)
    {
      this.setState({Error: {NotMatchPassword: true}, createPassword: false})
      return ;
    }
    var Params = {
      Password: this.state.Password,
      Email: this.state.Email,
      RecoveryToken: this.state.RecoveryToken,
      VerifyPassword: this.state.VerifyPassWord,
    }
    var promise = await Requestdynamic('_newPassword_ForgetPassword', Params)
    if (promise.error === false && promise.data.error === false)
    {
      this.setState({createPassword: false, Error: null, successPassword: true, UniqueKey: promise.data.data})
    }
    else {
        this.setState({createPassword: false, Error: {Connexion: 'Un problème est survenue veuillez réesayer plus tard'}})
    }

  })
}

_renderComponent = () => {
  if (this.state.successValidateAccount === false)
    return (<Email Token={this.state.Token} Email={this.state.Email} verifyEmail={this.state.verifyEmail} refParent={this} intl={this.props.intl} Error={this.state.Error} />)
  else if (this.state.successValidateAccount === true && this.state.successRecoveryToken === false)
    return (<RecoveryToken Error={this.state.Error} refParent={this} RecoveryToken={this.state.RecoveryToken} verifyRecoveryToken={this.state.verifyRecoveryToken} />)
  else if (this.state.successValidateAccount === true && this.state.successRecoveryToken === true && this.state.successPassword === false)
    return (<NewPassword Error={this.state.Error} refParent={this} Password={this.state.Password} VerifyPassWord={this.state.VerifyPassWord} createPassword={this.state.createPassword}/>)
  else if (this.state.successValidateAccount === true && this.state.successRecoveryToken === true && this.state.successPassword === true && this.state.UniqueKey)
    return (<NewRecoveryToken refParent={this} UniqueKey={this.state.UniqueKey} />)
  else
    return (<ErrorNotFound next={this._next}/>)
}

 render() {
   return (
     <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',  overflowY: "auto"}}>
     {
       this._renderComponent()
     }
     </div>
   )
 }
}


export default ForgetPassword;
