import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { IoIosArrowBack } from "react-icons/io";
import Button from '@material-ui/core/Button';


class Header extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }




 render() {

   return (
     <>
     <Row>
       <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
         <Button onClick={() => this.props.refParent.goBack()} variant='text' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
           <IoIosArrowBack  color={"orange"} size={20} />
           <p style={{color: 'black', margin: 10}}>Retour</p>
         </Button>
       </Col>

     </Row>
     <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
       <Col xl={5} lg={5} md={5} sm={5} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3, flexDirection: 'column'}}>

           <h2 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Modifier la conférence</h2>
           <div style={{height: 2, width: "50%", backgroundColor: 'orange'}} />
       </Col>
     </Row>
     </>
   )
 }
}


export default Header;
