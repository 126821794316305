import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    _renderModal = () => {
      if (this.props.refParent.state.FreeMember && (this.props.refParent.state.FreeMember.RegistrationType === 'Business' || this.props.refParent.state.FreeMember.RegistrationType === 'Couple' || this.props.refParent.state.FreeMember.RegistrationType === "BusinessPackCustomize"))
        return (
          <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.5rem', alignItems: 'center', color: 'red'}}>Attention !</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            {
              this.props.refParent.state.FreeMember.hasOwnProperty("FreeStatus") && this.props.refParent.state.FreeMember.FreeStatus === false ?
              <p style={{fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>{'Vous êtes sur le point de rendre gratuit un business pack.'}<br />{"Cela aura comme conséquence de supprimer la date de fin d'adhésion."}</p>
              :
              <p style={{fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>{"Vous êtes sur le point de rendre payant un business pack."}<br />{"Cela aura comme conséquence d'attribué une date de fin d'adhésion avec comme référence la date d'aujourd'hui."}</p>
            }
            </Col>
          </Row>
          </>
        )
      else{
        return (
          <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.5rem', alignItems: 'center', color: 'red'}}>Attention !</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            {
              this.props.refParent.state.FreeMember && this.props.refParent.state.FreeMember.hasOwnProperty("FreeStatus") && this.props.refParent.state.FreeMember.FreeStatus === false ?
              <p style={{fontSize: '1.3rem', alignItems: 'center'}}>{"Vous êtes sur le point de rendre gratuit membre ?"}<br />{"Cela aura comme conséquence de supprimer la date de fin d'adhésion."}</p>
              :
              <p style={{fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>{"Vous êtes sur le point de rendre ce membre payant."}<br />{"Cela aura comme conséquence d'attribué une date de fin d'adhésion avec comme référence la date d'aujourd'hui."}</p>
            }            </Col>
          </Row>
          </>
        )
      }

    }


    _renderStatus = () => {
      if (!this.props.statusFree || this.props.statusFree === "Ask")
        return (<></>)
      if (this.props.statusFree === "Waiting"){
        return (
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={35}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>)
      }
      if (this.props.statusFree === "Success"){
        return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
        )
      }
      return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            </Col>
          </Row>
      )

    }

    _ButtonClickNo = () => {
      this.props.refParent.setState({free: false, statusFree: null, FreeMember: null})
    }

    _ButtonClickYes = () => {
      this.props.refParent._FreeGestion()
    }


 render() {

   return (
       <Popup showPopup={this.props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
       {this._renderModal()}
       {this._renderStatus()}
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
           <ButtonTestCss  disabled={this.props.statusFree && this.props.statusFree !== 'Ask' && this.props.statusFree !== 'Error'} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{this.props.statusFree && this.props.statusFree === "Error" ? "Ré-essayer" : "Oui"}</ButtonTestCss>
           <ButtonTestCss  disabled={this.props.statusFree && this.props.statusFree !== 'Ask' && this.props.statusFree !== 'Error'} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
         </Col>
       </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUp;
