import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class LoadingInfo extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {

   return (

         <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: 'orange'}}>
         <CircularProgress
           size={50}
           thickness={4}
           color={"inherit"}
         />
         </div>

   )
 }
}


export default LoadingInfo;
