import React from 'react';
import Button from '@material-ui/core/Button';
import { IoIosArrowBack } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Name from './Name'
import SeparateLine from './SeparateLine'
import Action from './Action'

class Header extends React.Component {
  render (){
    return (
      <Container fluid>
        <Row>
          <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Button variant='text' onClick={() => this.props.goBack()} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <IoIosArrowBack color={"orange"} size={20} />
              <p style={{color: 'black', margin: 10}}>Retour</p>
            </Button>
          </Col>
        </Row>
        <Row style={{margin: 10}}>
          <Col sm={12} lg={12} xl={12} md={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Gallerie</h3>
            </div>
          </Col>
        </Row>
        <Name Name={this.props.Name} City={this.props.City} Date={this.props.DateDisplay}/>
        <Action action={this.props.action} loading={this.props.loading} refParent={this.props.refParent} edit={this.props.edit}/>
        <SeparateLine marginTop={20} width={"50%"} height={1} color={"greyTrans"}/>
      </Container>
    )
  }
}

export default Header;
