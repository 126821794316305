import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


    _ButtonClick = () => {
      if (this.props.statusRequest === "Success"){
        this.props.refParent.goBack()
        return ;
      }
      var Params = {StatusUpdate: null, updateProfil: false, message: null}

      this.props.refParent.setState(Params)
    }

    _renderWaiting = () => {
      return (
        <>
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={20}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>
        </>
      )
    }

    _renderError = () => {
      return (
        <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <p style={{fontSize: '1.5rem', alignItems: 'center', color: 'red'}}>Erreur</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.3rem', alignItems: 'center'}}>{this.props.message}</p>
            </Col>
          </Row>
          <Row style={{margin: 10}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
              <ButtonTestCss onClick={() => this._ButtonClick()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>OK</ButtonTestCss>
            </Col>
          </Row>
        </>
      )
    }

    _renderSuccess = () => {
      return (
        <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.3rem', alignItems: 'center'}}>{this.props.message}</p>
            </Col>
          </Row>
          <Row style={{margin: 10}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
              <ButtonTestCss onClick={() => this._ButtonClick()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>OK</ButtonTestCss>
            </Col>
          </Row>
        </>
      )

    }

    renderStatus = () => {
      if (this.props.statusRequest === "Waiting")
        return (this._renderWaiting())
      else if (this.props.statusRequest === "Success")
        return (this._renderSuccess())
      else if (this.props.statusRequest === "Error")
        return (this._renderError())
      else
        return (<></>)
    }

 render() {

   return (
       <Popup showPopup={this.props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
        {this.renderStatus()}
       </div>
       </Popup>
   )
 }
}


export default PopUp;
