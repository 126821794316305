import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {ButtonTestCss} from "../../ButtonStyle";
import Cookies from 'universal-cookie'


class ActionHeader extends React.Component {

    _checkInvoiceRender = () => {
      try {
        var cookies = new Cookies();
        var InfoAdmin = cookies.get('infoAdmin');
        var Region = InfoAdmin.Region;
        var index = Region.findIndex(res => res === this.props.refParent.state.Compta)
        if (index !== -1 && this.props.refParent.state.isFree === false)
          return (
            <Row style={{justifyContent: 'center', alignItems: 'center'}}>
             <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>
               <ButtonTestCss onClick={() => this.props.refParent.GetInvoiceEvent()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
                 Facturation
               </ButtonTestCss>
             </Col>
            </Row>
          )
        else
          return (<></>)
      } catch (e) {
        return (<></>)
      }

    }


 render() {

   return (
     <>
     <Row style={{marginTop: 30, display: "flex", justifyContent: 'center'}}>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} onClick={() => this.props.routeChange()}>
           Ajouter un participant
         </ButtonTestCss>
       </Col>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss onClick={() => this.props.exportExcelFile()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
           Exporter le fichier d'inscription
         </ButtonTestCss>

       </Col>
       <Col xl={4} lg={4} md={12} sm={12} style={{marginTop: 10, marginBottom: 10}}>

         <ButtonTestCss onClick={() => this.props.PrintPdfFile()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"}>
           Imprimer la liste des participant
         </ButtonTestCss>
       </Col>
     </Row>
     {
       this._checkInvoiceRender()
     }
     </>
   )
 }
}


export default ActionHeader;
