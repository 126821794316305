import React from 'react';
import Col from 'react-bootstrap/Col';

const TEXTSIZE = "0.85em";

class Complement extends React.Component {

  ConstructStringWithComma = (ArrayString, separator) => {
    try {
      if (ArrayString.length === 0)
        return ("Vide")
      var ArrayClean = ArrayString.filter(res => res !== null && res !== 'undefined' && res.length !== 0)
      if (ArrayClean.length === 0)
        return ("Vide")
      return (ArrayClean.map(res => res).join(""+ separator +" "))
    } catch (e) {
      return ("Vide")
    }
  }

  HowStyleToApply = (value, object, date = false) => {
    if (date === true)
    {
      if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0 && this.props[object][value] !== "null/null/null"){
        return ({
          color: 'black',
          fontSize: TEXTSIZE,
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          textOverflow: 'clip'

        })
      }
      return ({
        color: 'red',
        fontWeight: 'bold',
        fontSize: TEXTSIZE,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textOverflow: 'clip'

      })
    }
    if (this.props[object] && this.props[object][value] && this.props[object][value].length !== 0){
      return ({
        color: 'black',
        fontSize: TEXTSIZE,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textOverflow: 'clip'

      })
    }
    return ({
      color: 'red',
      fontWeight: 'bold',
      fontSize: TEXTSIZE,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      textOverflow: 'clip'

    })
  }

  renderSizeOfSociety = (size) => {
    if (size === "-10")
      return ('Moins de 10 travailleurs')
    if (size === "10/50")
      return ('Entre 10 et 50 travailleurs')
    if (size === "50/200")
      return ("De 50 à 200 travailleurs")
    if (size === "200")
      return ("Plus de 200 travailleurs")
  }

  render (){
    return (
      <Col sm={12} xl={4} lg={4} md={12} style={{marginTop: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}}>
      <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>INFORMATIONS COMPLEMENTAIRE</p>


        <div style={{}}>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Date de création de l'entreprise:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Date", 'SocietyInfo', true)}>{this.props.SocietyInfo && this.props.SocietyInfo.Date && this.props.SocietyInfo.Date !== "null/null/null" ? this.props.SocietyInfo.Date : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Localisation:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Localisation", 'SocietyInfo')}>{this.props.SocietyInfo && this.props.SocietyInfo.Localisation ? this.props.SocietyInfo.Localisation : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Taille de l'entreprise:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("SizeOfSociety", 'SocietyInfo')}>{this.props.SocietyInfo && this.props.SocietyInfo.SizeOfSociety ? this.renderSizeOfSociety(this.props.SocietyInfo.SizeOfSociety) : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Parrain:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("Sponsor", 'Complement')}>{this.props.Complement && this.props.Complement.Sponsor ? this.props.Complement.Sponsor : 'Vide'}</p>
          </div>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Nombre de participation:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("NumberSubscribeEvent", 'Complement')}>{this.props.Complement  ? this.props.Complement.NumberSubscribeEvent : 0}</p>
          </div>
        </div>

        {
          this.props.Complement && this.props.Complement.RegistrationType && this.props.Complement.RegistrationType === "Young" ?
          <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
            <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
            <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Motivation:</p>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <p style={this.HowStyleToApply("Motivation", 'Complement')}>{this.props.Complement && this.props.Complement.Motivation ? `${this.props.Complement.Motivation}` : 'Vide'}</p>
            </div>
          </div>
          :
          <div>
          </div>
        }

        <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
          <div style={{display: 'flex', alignItems: 'flex-start', marginRight: 7 }}>
          <p style={{fontWeight: 'bold', fontSize: '0.87em'}}>Commentaire Administrateur:</p>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
          <p style={this.HowStyleToApply("CommentaryAdmin", 'Complement')}>{this.props.Complement && this.props.Complement.CommentaryAdmin ? `${this.props.Complement.CommentaryAdmin}` : 'Vide'}</p>
          </div>
        </div>

        </div>
      </Col>
    )
  }
}

export default Complement;
