import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead'
import PureAmbassador from './PureAmbassador'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TableAmbassador extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderAmbassador = () => {
      return (
        this.props.ambassadorDisplay.map((ambassador, index) => (
        <PureAmbassador intl={this.props.intl} refParent={this.props.refParent} deleting={this.props.deleting} ambassador={ambassador} key={ambassador.Id} index={index}/>
      ))
      )
    }

 render() {
   return (
     <Table>
      <TableHead />
      {
        this.props.ambassadorDisplay.length === 0 && this.props.loadingMore === false  ?
        <TableBody>
          <TableRow>
            <TableCell  colSpan={"11"}>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3}}>
                    <p style={{color: 'black', fontSize: '1.2rem', fontWeight: 'bold'}}>Pas de résultats</p>
                  </Col>
                </Row>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
        :
        <TableBody>
          {this.renderAmbassador()}
        </TableBody>
      }

    </Table>
   )
 }
}


export default TableAmbassador;
