import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AllAddress from './AllAddress'

class ParselAllAddress extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AllAddress} {...props}/>
   )
 }
}



export default ParselAllAddress;
