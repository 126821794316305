export default {
  fr: {
    NewMemberDelca: "Nouveaux membres", //TRAD A FAIRE
    EndMemberDelca: "Renouvellements membres", // TRAD A FAIRE
    EndMemberBusinessPackCustomDelca: "Renouvellements businessCustomize", // TRAD A FAIRE
    NextEvent: "Next event",
    CityGallery: "Ville",
    Allevent: "All event",
    AddEvent: "Add event",
    AddEventTitle: "Ajouter une conférence",
    AllAmbassador: "Ambassadeurs",
    AddAmbassador: "Ajouter un ambassadeur",
    AddAmbassadorTitle: "Ajouter un ambassadeur",
    GoBack: "Retour",
    ImageEvent: "Image de la conférence",
    NameEvent: "Nom de la conférence",
    Photo: "Photo",
    DescriptionEvent: "Texte de la conférence",
    NotAccess: "Il semblerait que vous n'avez pas les droits pour accéder à cette page...",
    title: 'B19 Admin',
    sidebarTitle: 'B19 Admin',
    Event: "Évènements",
    AmbassadeurImage: "Ambassadeur Image",
    AddSponsor: "Ajouter un partenaire",
    AllSponsor: "Sponsor",
    AllGallery: "Gallerie",
    AddGallery: 'Créer une gallerie',
    NameGallery: 'Nom de la gallerie',
    DescriptionGallery: 'Description de la Gallery (facultatif)',
    lux: "Luxembourg",
    bxl: "Bruxelles/Wallonie",
    anv: "Anvers/Limburg",
    luik: "Liège",
    fl: "Flandre orientale/occidentale",
    luxdiminutif: "Lux",
    bxldiminutif: "Bxl/Wa",
    anvdiminutif: "Anv",
    luikdiminutif: "Lg",
    fldiminutif: "VWV",
    NotOrderCotisation: "NotOrderCotisation",
    PendingStatusMember: "PendingStatusMember",
    BlockStatusMember: "BlockStatusMember",
    PageNotFound: "Cette page est introuvable",
    NewMemberWhitoutInvoice: "Nouveaux Membres Stripe",
    Outils: 'Outils',
    AllAdresseB19: 'Adresse B19',
    AddAdresseB19: 'Ajouter une adresse B19',
    AllPromotion: 'Promotion B19',
    Promotion: 'Promotion',
    AddPromotion: 'Ajouter une promotion',
    AllAdmin: 'Administrateurs',
    Admin: 'Administrateur',
    AddAdmin: 'Ajouter un Admin',
    Members: 'Membres B19',
    DeletedMember: "Membres supprimés",
    AllMembers: 'Liste des membres',
    WaitingMembers: "Demande d'adhésion",
    EndMembership: "Fin d'adhésion",
    NoneMembers: "Non Membres",
    PassedEvent: "Événements passés",
    Ambassadeur: 'Ambassadeur',
    Sponsor: 'Sponsor',
    Gallery: 'Galerie',
    AdresseB19: "Adresse B19",
    DashBoard: "Dashboard",
    BusinessCustomize: "Business Custom",
    AddBusinessCutomize: "Ajouter un Business Pack Custom"
  },
  en: {
    NextEvent: "Next event",
    Allevent: "All event",
    AddEvent: "Add event",
    AddEventTitle: "Ajouter une conférence",
    AllAmbassador: "Ambassadeurs",
    AddAmbassador: "Ajouter un ambassadeur",
    AddAmbassadorTitle: "Ajouter un ambassadeur",
    GoBack: "Retour",
    ImageEvent: "Image de la conférence",
    NameEvent: "Nom de la conférence",
    Photo: "Photo",
    DescriptionEvent: "Texte de la conférence",
    NotAccess: "Il semblerait que vous n'avez pas les droits pour accéder à cette page...",
    title: 'B19 Admin',
    sidebarTitle: 'B19 Admin',
    Event: "Évènements",
    AmbassadeurImage: "Ambassadeur Image",
    AddSponsor: "Ajouter un partenaire",
    AllSponsor: "Sponsor",
    AllGallery: "Gallerie",
    AddGallery: 'Crée une gallerie',
    NameGallery: 'Nom de la gallerie',
    DescriptionGallery: 'Description de la Gallery (facultatif)',
    lux: "Luxembourg",
    bxl: "Bruxelles/Wallonie",
    anv: "Anvers/Limburg",
    luik: "Liège",
    fl: "Flandre orientale/occidentale",
    luxdiminutif: "Lux",
    bxldiminutif: "Bxl/Wa",
    anvdiminutif: "Anv",
    luikdiminutif: "Lg",
    fldiminutif: "VWV",
    NotOrderCotisation: "NotOrderCotisation",
    PendingStatusMember: "PendingStatusMember",
    BlockStatusMember: "BlockStatusMember",
    PageNotFound: "Cette page est introuvable",
    NewMemberWhitoutInvoice: "Nouveaux Membres Stripe",
    Outils: 'Outils',
    AllAdresseB19: 'Adresse B19',
    AddAdresseB19: 'Ajouter une adresse B19',
    AllAdmin: 'Administrateurs',
    Admin: 'Administrateur',
    AddAdmin: 'Ajouter un Admin',
    Members: 'Membres B19',
    DeletedMember: "Membres supprimés",
    AllMembers: 'Liste des membres',
    WaitingMembers: "Demande d'adhésion",
    EndMembership: "Fin d'adhésion",
    NoneMembers: "Non Membres",
    PassedEvent: "Événements passés",
    Ambassadeur: 'Ambassadeur',
    Sponsor: 'Sponsor',
    Gallery: 'Galerie',
    AdresseB19: "Adresse B19",
    DashBoard: "Dashboard",
    BusinessCustomize: "Business Custom",
    AddBusinessCutomize: "Ajouter un Business Pack Custom"


  },
};
