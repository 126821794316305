const _getAllLocation = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/GetLocation/",
    body : Params
  }
  return params
}

const _getAllNextEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetAllNextEvent/",
    body : Params.Body
  }
  return params
}

const _getAllPassedEvent = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetAllPassedEvent/",
    body : Params.Body
  }
  return params
}

const _getAllParticipant = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetParticipantEvent/",
    body : Params.Body
  }
  return params
}

const _getAllNotParticipant = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetNotParticipantEvent/",
    body : Params.Body
  }
  return params
}

const _getSpecificEvent = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/GetSpecificEvent/",
    body : Params
  }
  return params
}

const _exportExcelFile = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/ExportParticipant/",
    File: true,
    body : Params
  }
  return params
}

const _getRegion = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/GetRegion/",
    File: true,
    body : Params
  }
  return params
}

const _getAllAmbassador = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetAmbassador/",
    body : Params.Body
  }
  return params
}

const _getAllAdmin = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/GetAdmin/",
    body : Params
  }
  return params
}

const _getAllSponsor = (Params) => {
  var params;
  params = {
    method : "POST",
    Route : "/GetSponsor/",
    body : Params.Body
  }
  return params
}

const _getSpecificGallery = (Params) => {
  var params;
  params = {
    method : "GET",
    Route : "/GetSpecificGallery/",
    body : Params
  }
  return params
}



module.exports = {
  _getAllLocation,
  _getAllNextEvent,
  _getSpecificEvent,
  _getAllParticipant,
  _getAllNotParticipant,
  _exportExcelFile,
  _getRegion,
  _getAllAmbassador,
  _getAllSponsor,
  _getSpecificGallery,
  _getAllAdmin,
  _getAllPassedEvent,
}
