import React from 'react';
import {ButtonCss} from './Style'

class ActionMember extends React.Component {

  TitleBlocked = () => {
    var Status = this.props.refParent.state.hasOwnProperty("Blocked") ? this.props.refParent.state.Blocked : "false";

    if (Status === "false")
      return 'BLOQUER'
    return 'DÉBLOQUER'

  }

  renderChangeInfoColleague = () => {
    if (this.props.refParent.state.hasOwnProperty('Complement') && (this.props.refParent.state.Complement.RegistrationType === 'Business' || this.props.refParent.state.Complement.RegistrationType === 'Couple' || this.props.refParent.state.Complement.RegistrationType === "BusinessPackCustomize") && this.props.refParent.state.Complement.BusinessInfo === true){
      return(<ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ChangeInformationColleague()}>MODIFIER LE BUSINESS PACK</ButtonCss>)
    }
    return(<></>)
  }

  renderAcceptStatus = () => {
    if (this.props.refParent.state.hasOwnProperty('Accept') && this.props.refParent.state.Accept === true){
      return (
        <>
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyActionClick()}>MODIFIER</ButtonCss>
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyEmail()}>MODIFIER E-MAIL</ButtonCss>
        {
          this.props.refParent.state.hasOwnProperty('Complement') && this.props.refParent.state.Complement.RegistrationType === 'Young' ?
          <>
          </>
          :
          <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._BlockedGestion()}>{this.TitleBlocked()}</ButtonCss>
        }
        {this.renderChangeInfoColleague()}
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._DeleteUser()}>SUPPRIMER</ButtonCss>
        </>
      )
    }
    return (
      <>
      <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyActionClick()}>MODIFIER</ButtonCss>
      <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyEmail()}>MODIFIER E-MAIL</ButtonCss>
      <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._AcceptRefuseGestion(true)}>ACCEPTER</ButtonCss>
      <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._AcceptRefuseGestion(false)}>REFUSER</ButtonCss>
      </>
    )

  }

  render (){
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      {this.renderAcceptStatus()}
      </div>
    )
  }
}

export default ActionMember;
