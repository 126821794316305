import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import AllSponsor from './AllSponsor'

class ParselAllSponsor extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={AllSponsor} {...props}/>
   )
 }
}



export default ParselAllSponsor;
