import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Header from './Header'
import Photo from './Photo'
import Name from './Name'
import Date from './Date'
import Description from './Description'
import Free from './Free'
import LimitPlace from './LimitPlace'
import HeaderLiner from './HeaderLiner'
import Place from './Place'
import Close from './Close'
import PopUp from './PopUp'
import Language from './Language'
import Sponsor from './Sponsor'
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import {CheckFormatDate, CheckIfDateIsAfter, GetCurrentDateEuropeParis} from '../../Moment/Moment'
import {Requestdynamic} from '../../Network/Fetch';
import { EditorState, convertToRaw } from "draft-js";
import Cookies from 'universal-cookie'
import Region from './Region'
import ModalPreviewEvent from './ModalPreviewEvent'
import Compta from './Compta'

class AddEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        isFree: true,
        PreviewEvent: false,
        Price: null,
        limitPlace: false,
        limitPlaceNumber: '0',
        addEvent: false,
        Year: null,
        Day: null,
        Month: null,
        Error: null,
        EndHours: "06",
        EndMinutes: "00",
        BeginHours: "06",
        BeginMinutes: "00",
        Name: null,
        FileUrl: null,
        FileAppUrl: null,
        Editor: EditorState.createEmpty(),
        isHeadLiner: false,
        isClosed: false,
        Language: [],
        Sponsor: [],
        Region: [],
        SponsorSelect: [],
        Picture: null,
        PictureApp: null,
        openModal: false,
        title: null,
        message: null,
        success: false,
        Location: [],
        Compta: null,
        LocationId: "PlaceHolder",
        LocationSelect: null,
      }
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _GetAllLocation = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var promise = await Requestdynamic('_getAllLocation', {Jwt: Jwt})
      if(promise.error === false && promise.data.error === false)
      {
        this.setState({Location: promise.data.data})
        this._GetAllSponsor()
      }
      else {
        if (promise.error === true){
          this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des lieux, veuillez ré-essayer plus tard"})
          return ;
        }
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
      else {
        this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des lieux, veuillez ré-essayer plus tard"})
      }
      }
    }

    _GetAllSponsor = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var promise = await Requestdynamic('_getAllSponsor', {Body: {Jwt: Jwt, Size: "Large"}})
      if(promise.error === false && promise.data.error === false)
      {
        this.setState({Sponsor: promise.data.data})
      }
      else {
        if (promise.error === true){
          this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des sponsors, veuillez ré-essayer plus tard"})
          return ;
        }
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
      else {
        this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des sponsor, veuillez ré-essayer plus tard"})
      }
      }
    }


    componentDidMount(){
      this._GetAllLocation()
    }

    _ConvertToRaw = (Error) => {
      try {
        if (!this.state.Editor)
          return (null)
        var RawEditor = convertToRaw(this.state.Editor.getCurrentContent())
        return (RawEditor)
      } catch (e) {
        return ("erreur")
      }

    }

    _CheckDate = (Error) => {
      if (!this.state.Year || !this.state.Month || !this.state.Day)
      {
        Error.Date = true
        return ;
      }
      var Date = this.state.Year + '/' + this.state.Month + '/' + this.state.Day
      if (!CheckFormatDate(Date, "YYYY/MM/DD"))
        Error.Date = true
      return ;
    }

    _CheckHours = (Error) => {

      var Date = this.state.Year + '/' + this.state.Month + '/' + this.state.Day
      var DateEnd = Date + " " + this.state.EndHours + ':' + this.state.EndMinutes;
      var DateStart = Date + " " + this.state.BeginHours + ':' + this.state.BeginMinutes;
      if (!CheckFormatDate(DateEnd, "YYYY/MM/DD HH:mm"))
        Error.Hours = true;
      if (!CheckFormatDate(DateStart, "YYYY/MM/DD HH:mm"))
        Error.Hours = true;
      if (!CheckIfDateIsAfter(DateStart, DateEnd))
        Error.Hours = true;
      var Now = GetCurrentDateEuropeParis();
      if (!CheckIfDateIsAfter(Now, DateStart))
        Error.Hours = true;
    }

    _CheckName = (Error) => {
      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = true
    }


    _CheckPrice = () => {
        if (!this.state.Price)
          return (null)
        if (this.state.isFree)
          return (null)
        return (this.state.Price)
    }

    _CheckLanguage = (Error) => {
      if (this.state.Language.length === 0)
      {
        Error.Language = true;
        return ;
      }
    }

    _CheckLocation = (Error) => {
      if (this.state.LocationId  === "PlaceHolder")
        Error.Location = true
    }

    _CheckRegion = (Error) => {
      if (this.state.Region.length === 0)
        Error.Region = true
    }

    _CheckCompta = (Error) => {
      if (this.state.Compta === null)
        Error.Compta = true
    }

    _CheckFreeLux = () => {
      if (this.state.Compta === 'lux' && this.state.isFree === false)
        return (0)
      return (1)
    }

    _addEvent = async () => {
    this.setState({addEvent: true, Error: null}, async() => {
    var Error = {};

    if (!this._CheckFreeLux())
    {
      alert('Les paiements en ligne pour la comptabilité du Luxembourg sont désactivé')
      return ;
    }
    this._CheckDate(Error)
    this._CheckHours(Error)
    this._CheckName(Error)
    this._CheckLanguage(Error)
    this._CheckLocation(Error)
    this._CheckRegion(Error)
    this._CheckCompta(Error)


    var Description = this._ConvertToRaw()
    var Price = this._CheckPrice()
    var LimitPlace = this.state.limitPlace ? this.state.limitPlaceNumber : null
    var HeaderLinerVariable = this.state.isHeadLiner
    var Closed = this.state.isClosed
    var Picture = this.state.Picture
    var PictureApp = this.state.PictureApp

    var KeysErrorLength = Object.keys(Error).length
    console.log("KeysErrorLength", KeysErrorLength)
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, addEvent: false})
      return ;
    }
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var Params = {
      Sponsor: this.state.SponsorSelect,
      Language: [...this.state.Language],
      Closed: Closed,
      Compta: this.state.Compta,
      Name: this.state.Name,
      Date: this.state.Year + '/' + this.state.Month + '/' + this.state.Day,
      StartHours: this.state.BeginHours + ':' + this.state.BeginMinutes,
      EndHours: this.state.EndHours + ':' + this.state.EndMinutes,
      Location: this.state.LocationSelect,
      Jwt: Jwt,
      RegionFrontend: this.state.Region,
      Price: Price,
      Description: Description,
      HeadLiner: HeaderLinerVariable,
      LimitPlace: LimitPlace,
      Picture: Picture,
      PictureApp: PictureApp
    }
    console.log("Params", Params)
    var promise = await Requestdynamic('_addEvent', Params)
    console.log("promise", promise)

    if(promise.error === false && promise.data.error === false)
    {
      this.setState({success: true, addEvent: false, openModal: true, title: 'Success', message: "L'évènement à bien été ajouté"})
    }
    else
    {
      if (promise.error === true)
        this.setState({addEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else
        this.setState({addEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      return ;
    }
  })

}

PreviewEvent = () => {
  this.setState({PreviewEvent: true});
}


 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <Container fluid>
         <PopUp Editor={this.state.Editor} success={this.state.success} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
         <ModalPreviewEvent openModal={this.state.PreviewEvent} refParent={this}/>
        <Header intl={this.props.intl} />
        <Photo success={this.state.success} intl={this.props.intl} refParent={this}/>
        <Date
       Error={this.state.Error}
       intl={this.props.intl}
       Year={this.state.Year}
       Day={this.state.Day}
       Month={this.state.Month}
       EndHours={this.state.EndHours}
       EndMinutes={this.state.EndMinutes}
       BeginMinutes={this.state.BeginMinutes}
       BeginHours={this.state.BeginHours}
       refParent={this}/>
        <Name Error={this.state.Error} intl={this.props.intl} Name={this.state.Name} refParent={this}/>
        <Description intl={this.props.intl} Editor={this.state.Editor} refParent={this}/>
        <Language Error={this.state.Error} intl={this.props.intl} Language={this.state.Language} refParent={this}/>
        <Free intl={this.props.intl} isFree={this.state.isFree} Price={this.state.Price} refParent={this}/>
        <LimitPlace intl={this.props.intl} limitPlaceNumber={this.state.limitPlaceNumber} limitPlace={this.state.limitPlace} refParent={this}/>
        <HeaderLiner intl={this.props.intl} isHeadLiner={this.state.isHeadLiner} refParent={this}/>
        <Place Error={this.state.Error} intl={this.props.intl} refParent={this} Location={this.state.Location} LocationId={this.state.LocationId}/>
        <Close intl={this.props.intl} isClosed={this.state.isClosed} refParent={this}/>
        <Region Region={this.state.Region} intl={this.props.intl} refParent={this} Error={this.state.Error}/>
        <Compta Compta={this.state.Compta} intl={this.props.intl} refParent={this} Error={this.state.Error}/>
        <Sponsor Sponsor={this.state.Sponsor} SponsorSelect={this.state.SponsorSelect} refParent={this}/>


        <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ButtonTestCss disabled={this.state.addEvent} color={"orange"} style={{margin: 10}} onClick={() => this.PreviewEvent()}>
              {
                this.state.addEvent ?
                <CircularProgress
                  size={"1.5rem"}
                  thickness={5}
                  color={"inherit"}
                />
                :
                <p style={{margin: 0, padding: 0}}>Visualisation</p>
              }
            </ButtonTestCss>

          </Col>
        </Row>

      </Container>
     </div>

   )
 }
}



export default AddEvent;
