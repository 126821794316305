import React from 'react';

import Loading from './Loading'
import {Requestdynamic} from '../../Network/Fetch';
import PopUp from './PopUp'
import ListOfGallery from './ListOfGallery'
import Cookies from 'universal-cookie'

class AllGallery extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        LastKey: null,
        gallery: [],
        loadingMore: false,
        openModal: false,
        title: null,
        message: null,
      }
    }

    ChangeRoute = (gallery) => {
      var url = `/info_gallery/${gallery.Id}`
      this.props.navigation.history.push(url)
    }

    _getAllNextGallery = async (LastKey) => {

      this.setState({loadingMore: true}, async() => {
        await this.sleep(150)
        if (LastKey === false)
          return ;
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Body: {
            Jwt: Jwt,
            NumberOfResult: "50",
          },
        }
        if (LastKey)
          Params.Body.LastKey = LastKey
        var promise = await Requestdynamic('_getAllNextGallery', Params)
        if(promise.error === false && promise.data.error === false)
        {
          var GalleryCopy = [...this.state.gallery];
          var newListGallery = GalleryCopy.concat(promise.data.data)
          newListGallery = [...new Set(newListGallery)]
          var paramsSetState = {gallery: newListGallery}
          var newLastKey = promise.data.LastKey ? promise.data.LastKey : false;
          paramsSetState.LastKey = newLastKey;
          this.setState(paramsSetState)
        }
        else {
          if (promise.error === true)
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({title: 'Error', openModal: true, message: "Un problème est survenue veuillez réesayer plus tard", LastKey: false})
        }
        this.setState({loadingMore: false})
      })

    }

    componentDidMount(){
      this._getAllNextGallery(null)
    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleScroll = (e) => {

      const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
      if (bottom  && this.state.LastKey && this.state.loadingMore === false) {
        this._getAllNextGallery(this.state.LastKey)
      }
    }



 render() {

   return (
     <div style={{marginLeft: 10, overflowY: "auto"}} onScroll={this.handleScroll}>
     <PopUp openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
     <ListOfGallery refParent={this} gallery={this.state.gallery}/>
     <Loading loadingMore={this.state.loadingMore}/>
    </div>
   )
 }
}


export default AllGallery;
