import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {OrangeCheckbox} from './Style'
import FormControlLabel from '@material-ui/core/FormControlLabel';

class PureSponsor extends React.PureComponent {
  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  _ConstructRegionArray = () => {
    var CurrentRegionArray = [...this.props.sponsorSelect]
    var index = CurrentRegionArray.findIndex(res => res.Id === this.props.sponsor.Id)
    if (index === -1)
      CurrentRegionArray.push(this.props.sponsor)
    else
      CurrentRegionArray.splice(index, 1)
    this.props.refParent.setState({SponsorSelect: CurrentRegionArray})
  }

  verifyChecked = () => {

    if (!this.props.sponsorSelect.find(res => res.Id === this.props.sponsor.Id))
      return (false)
    return (true)
  }



  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="center">
        {
          this.props.sponsor.Logo ?
            <img  alt="sponsor" key={this.props.sponsor.Id} src={this.props.sponsor.Logo} style={{objectFit: 'contain', width: 90, height: 90}}/>
          :
            <div style={{width: 35, borderRadius: 360, height: 35, backgroundColor: this.state.color}}></div>
        }
        </TableCell>
        <TableCell align="center" style={{fontSize: '0.8rem'}}>{this.props.sponsor.Name}</TableCell>
        <TableCell align="center" style={{fontSize: '0.8rem'}}>{this.props.sponsor.Category}</TableCell>
        <TableCell align="center" style={{}}>

        <FormControlLabel
          value={this.props.sponsor}
          control={<OrangeCheckbox  name={this.props.sponsor.Name}/>}
          checked={this.verifyChecked()}
          onChange={() => this._ConstructRegionArray()}
        />

        </TableCell>
      </TableRow>)
  }

}

export default PureSponsor;
