import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Form} from 'react-bootstrap';

import Photo from './ProfilImage'
import Name from './Name'
import SocietyInfo from './SocietyInfo'
import ActionMember from './ActionMember'
import NavBarMember from './NavMember'
import Switch from "react-switch";

import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const OrangeCheckbox = withStyles({
  root: {
    color: 'orange',
    '&$checked': {
      color: 'orange',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Header extends React.Component {


  render (){
    return (


      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20, paddingBottom: 0,  whidth: '100%', marginBottom: 10}}>
        <Row>
          <Col sm={12} md={12} lg={4} xl={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Photo Image={this.props.Image} />
          </Col>
          <Col  lg={5} xl={5} style={{padding: 0, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column'}}>
            <Name FirstName={this.props.FirstName} LastName={this.props.LastName}/>
            <SocietyInfo SocietyInfo={this.props.SocietyInfo}/>
            {
              this.props.refParent.state.hasOwnProperty('Complement') && this.props.refParent.state.Complement.RegistrationType === 'Young' ?
              <>
              </>
              :
              <>
              <div style={{display: 'flex', width: '100%'}}>
                <p style={{fontSize: '1em', fontWeight: 'bold', color: 'black', marginRight: 10}}>{"Gratuit"}</p>
                <Switch uncheckedIcon={false} checkedIcon={false} onChange={() => this.props.refParent._FreeGestion()} checked={this.props.refParent.state.hasOwnProperty("FreeStatus") ? this.props.refParent.state.FreeStatus : false}  width={42} height={22} />
              </div>
              <div style={{display: 'flex', width: '100%'}}>
              <Form.Group >
                <FormControlLabel disabled={this.props.refParent.state.TypeContactFacturation === "society"} control={<OrangeCheckbox onChange={() => this.props.refParent._TypeContactGestion("society")} checked={this.props.refParent.state.TypeContactFacturation === "society"} name="society"/>} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Société</Typography>} />
                <FormControlLabel disabled={this.props.refParent.state.TypeContactFacturation === "private"} control={<OrangeCheckbox onChange={() => this.props.refParent._TypeContactGestion("private")} checked={this.props.refParent.state.TypeContactFacturation === "private"} name="private" />} label={<Typography style={{fontWeight: 'bold', fontSize: '0.85rem'}}>Privé</Typography>} />
              </Form.Group>
              </div>
              </>
            }
          </Col>
          <Col sm={12} md={12} lg={3} xl={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <ActionMember NotAcces={this.props.NotAcces} refParent={this.props.refParent}/>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 0}}>
            <NavBarMember NotAcces={this.props.NotAcces} refParent={this.props.refParent}/>
          </Col>
        </Row>
      </Container>

    )
  }
}

export default Header;
