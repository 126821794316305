const Model_Put = require ('./Put/model')
const Model_Get = require ('./Get/model')
const Model_Post = require ('./Post/Post')

var _updateEvent = Model_Post._updateEvent;
var _putParticipantEvent = Model_Post._putParticipantEvent;
var _deleteParticipantEvent = Model_Post._deleteParticipantEvent;
var _deleteEvent = Model_Post._deleteEvent;
var _deleteAmbassador = Model_Post._deleteAmbassador;
var _updateAmbassador = Model_Post._updateAmbassador
var _deleteSponsor = Model_Post._deleteSponsor
var _updateSponsor = Model_Post._updateSponsor
var _getAllNextGallery = Model_Post._getAllNextGallery
var _endUploadIdImage = Model_Post._endUploadIdImage;
var _createUploadIdImage = Model_Post._createUploadIdImage;
var _deleteGallery = Model_Post._deleteGallery;
var _updateImagesInfo = Model_Post._updateImagesInfo;
var _editGallery = Model_Post._editGallery;
var _login = Model_Post._login;
var _validateAccount_Token = Model_Post._validateAccount_Token;
var _validateAccount_password = Model_Post._validateAccount_password;
var _newPassword_ForgetPassword = Model_Post._newPassword_ForgetPassword;
var _validateEmail_ForgetPassword = Model_Post._validateEmail_ForgetPassword;
var _recoveryToken_ForgetPassword = Model_Post._recoveryToken_ForgetPassword;
var _deleteAdmin = Model_Post._deleteAdmin;
var _updateAdmin = Model_Post._updateAdmin;
var _deleteLocation = Model_Post._deleteLocation;
var _updateLocation = Model_Post._updateLocation;
var _getAllNextMember = Model_Post._getAllNextMember;
var _getAllNextDeletedMember = Model_Post._getAllNextDeletedMember;
var _getSpecificMember = Model_Post._getSpecificMember;
var _getSpecificDeletedMember = Model_Post._getSpecificDeletedMember;
var _updateProfilMember = Model_Post._updateProfilMember;
var _blockedMember = Model_Post._blockedMember
var _deleteMember = Model_Post._deleteMember;
var _deleteNonMember = Model_Post._deleteNonMember;
var _getAllNextWaitingMember = Model_Post._getAllNextWaitingMember;
var _acceptRefuseMember = Model_Post._acceptRefuseMember;
var _getAllNextEndMember = Model_Post._getAllNextEndMember;
var _getAllNextNewMemberStripe = Model_Post._getAllNextNewMemberStripe;
var _UploadInvoiceNewStripeMember = Model_Post._UploadInvoiceNewStripeMember;
var _getAllNextNoneMember = Model_Post._getAllNextNoneMember;
var _updateNoneMember = Model_Post._updateNoneMember;
var _updateEmailMember = Model_Post._updateEmailMember;
var _DownloadInvoiceFile = Model_Post._DownloadInvoiceFile;
var _searchPassedEvent = Model_Post._searchPassedEvent;
var _getAllParticipantSubscribeInvoice = Model_Post._getAllParticipantSubscribeInvoice;
var _sendInvoiceEvent = Model_Post._sendInvoiceEvent;
var _validatePaymentEvent = Model_Post._validatePaymentEvent;
var _removeColleague = Model_Post._removeColleague;
var _addColleague = Model_Post._addColleague;
var _transfertDeleteToMember = Model_Post._transfertDeleteToMember;
var _getSubscriptionStat = Model_Post._getSubscriptionStat;
var _searchMemberAttach = Model_Post._searchMemberAttach
var _searchTva = Model_Post._searchTva;
var _createBusinessPackCustomize = Model_Post._createBusinessPackCustomize;
var _ownerChanger = Model_Post._ownerChanger;
var _FreeMember = Model_Post._FreeMember;
var _changeTypeOfBusiness = Model_Post._changeTypeOfBusiness;
var _changePriceOfBusiness = Model_Post._changePriceOfBusiness;
var _variablePrice = Model_Post._variablePrice
var _changeTypeContactFacturation = Model_Post._changeTypeContactFacturation;
var _getNewMemberDelca = Model_Post._getNewMemberDelca;
var _getEndBusinessCustomDelca = Model_Post._getEndBusinessCustomDelca;
var _getEndMemberDelca = Model_Post._getEndMemberDelca;
var _getFacturationPdfDelca = Model_Post._getFacturationPdfDelca;
var _verifyPayment = Model_Post._verifyPayment;
var _verifyEndPayment = Model_Post._verifyEndPayment;
var _acceptNewMember = Model_Post._acceptNewMember;
var _acceptEndMember = Model_Post._acceptEndMember;
var _refuseNewMember = Model_Post._refuseNewMember;
var _sendMailRemindNewMember = Model_Post._sendMailRemindNewMember;
var _getAllPromotion = Model_Post._getAllPromotion
var _putNewPromotion = Model_Post._putNewPromotion;
var _updateStatusOnlinePromotion = Model_Post._updateStatusOnlinePromotion;
var _deletePromotion = Model_Post._deletePromotion;
var _updatePromotion = Model_Post._updatePromotion;
var _sendMailEndBusinessCustom = Model_Post._sendMailEndBusinessCustom;

var _addEvent = Model_Put._addEvent;
var _addAmbassador = Model_Put._addAmbassador;
var _addSponsor = Model_Put._addSponsor;
var _addGallery = Model_Put._addGallery;
var _addAdmin = Model_Put._addAdmin;
var _addLocation = Model_Put._addLocation;
var _addNoneMember = Model_Put._addNoneMember;

var _getAllLocation = Model_Get._getAllLocation;
var _getAllNextEvent = Model_Get._getAllNextEvent;
var _getSpecificEvent = Model_Get._getSpecificEvent;
var _getAllParticipant = Model_Get._getAllParticipant;
var _getAllNotParticipant = Model_Get._getAllNotParticipant;
var _exportExcelFile = Model_Get._exportExcelFile;
var _getRegion = Model_Get._getRegion;
var _getAllAmbassador = Model_Get._getAllAmbassador;
var _getAllSponsor = Model_Get._getAllSponsor;
var _getSpecificGallery = Model_Get._getSpecificGallery;
var _getAllAdmin = Model_Get._getAllAdmin;
var _getAllPassedEvent = Model_Get._getAllPassedEvent


module.exports = {
  _addEvent,
  _getAllLocation,
  _getAllNextEvent,
  _getSpecificEvent,
  _updateEvent,
  _getAllParticipant,
  _getAllNotParticipant,
  _putParticipantEvent,
  _deleteParticipantEvent,
  _exportExcelFile,
  _deleteEvent,
  _getRegion,
  _addAmbassador,
  _getAllAmbassador,
  _deleteAmbassador,
  _updateAmbassador,
  _addSponsor,
  _getAllSponsor,
  _deleteSponsor,
  _updateSponsor,
  _addGallery,
  _getAllNextGallery,
  _getSpecificGallery,
  _endUploadIdImage,
  _createUploadIdImage,
  _deleteGallery,
  _updateImagesInfo,
  _editGallery,
  _login,
  _addAdmin,
  _validateAccount_Token,
  _validateAccount_password,
  _newPassword_ForgetPassword,
  _validateEmail_ForgetPassword,
  _recoveryToken_ForgetPassword,
  _getAllAdmin,
  _deleteAdmin,
  _updateAdmin,
  _addLocation,
  _deleteLocation,
  _updateLocation,
  _getAllNextMember,
  _getAllNextDeletedMember,
  _getSpecificMember,
  _getSpecificDeletedMember,
  _updateProfilMember,
  _blockedMember,
  _deleteMember,
  _getAllNextWaitingMember,
  _acceptRefuseMember,
  _getAllNextEndMember,
  _getAllNextNewMemberStripe,
  _UploadInvoiceNewStripeMember,
  _getAllNextNoneMember,
  _addNoneMember,
  _updateNoneMember,
  _deleteNonMember,
  _updateEmailMember,
  _DownloadInvoiceFile,
  _getAllPassedEvent,
  _searchPassedEvent,
  _getAllParticipantSubscribeInvoice,
  _sendInvoiceEvent,
  _validatePaymentEvent,
  _removeColleague,
  _addColleague,
  _transfertDeleteToMember,
  _getSubscriptionStat,
  _searchMemberAttach,
  _searchTva,
  _createBusinessPackCustomize,
  _ownerChanger,
  _FreeMember,
  _changeTypeOfBusiness,
  _changePriceOfBusiness,
  _variablePrice,
  _changeTypeContactFacturation,
  _getNewMemberDelca,
  _getEndMemberDelca,
  _getEndBusinessCustomDelca,
  _getFacturationPdfDelca,
  _verifyPayment,
  _verifyEndPayment,
  _acceptNewMember,
  _refuseNewMember,
  _sendMailRemindNewMember,
  _getAllPromotion,
  _putNewPromotion,
  _updateStatusOnlinePromotion,
  _deletePromotion,
  _updatePromotion,
  _acceptEndMember,
  _sendMailEndBusinessCustom
}
