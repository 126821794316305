import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SeparateLine from './SeparateLine'
import {CssTextField} from './Style'

class RegionB19 extends React.Component {

  _ChangeRegionB19 = (event) => {
    this.props.refParent.setState({RegionB19: event.target.value})
  }

  renderRegionTrad = (Region) => {
    try {
      var trad = this.props.intl.formatMessage({id: Region})
      return (trad)
    } catch (e) {
      if (Region)
        return (Region)
      return ("")
    }
  }

  _createRegionB19 = () => {
    var RegionB19 = ['bxl', 'luik', 'anv', 'lux', 'fl']
    return RegionB19.map((region, key) => {
      return (
        <option key={region} value={region}>{this.renderRegionTrad(region)}</option>

      )
    })
  }

 render() {
   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'column', marginTop: 10}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Région</h3>
              </div>
              <p style={{fontSize: '0.8rem', fontStyle: 'italic', fontWeight: 'bold', color: 'grey'}}>Choissisez une Région</p>
           </Col>
         </Row>
         <Row style={{marginTop: 3, display: 'flex', justifyContent: 'space-between'}}>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <CssTextField  onChange={(event) => this._ChangeRegionB19(event)} value={this.props.RegionB19 ? this.props.RegionB19 : "PlaceHolder"} select label="Région B19" variant="outlined" SelectProps={{native: true}}>
                <option key={"PlaceHolder"} value={"PlaceHolder"} disabled>{"Région B19"}</option>
                {this._createRegionB19()}
              </CssTextField>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.RegionB19 ? 'block' : 'none', marginTop: 5}}>Veuillez choisir parmis l'une des options ci-dessus</p>
            </div>
          </Col>
         </Row>
         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />

     </>
   )
 }
}


export default RegionB19;
