import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Loading from './Loading'
import InfoEvent from './PrivateInfo'
import Description from './InvoiceInfo'
import Complement from './ComplementInfo'
import Date from './SubscribeInfo'
import Sponsor from './Colleague'

class InfoMember extends React.Component {


  render (){
    return (
        <Container  className='shadow' style={{backgroundColor: 'white', padding: 15, borderRadius: 5, marginTop: 30, marginBottom: 10}}>


          <Row >
          {
            this.props.loading ?
            <Loading size={50} loadingMore={this.props.loading}/>
            :
            <>
            <InfoEvent Name={this.props.refParent.state.Name} Location={this.props.refParent.state.Location}/>
            <Complement  Complement={this.props.refParent.state.Complement}/>
            <Date Date={this.props.refParent.state.Date} intl={this.props.intl}/>
            <Sponsor Sponsor={this.props.refParent.state.Sponsor} intl={this.props.intl}/>
            <Description Description={this.props.refParent.state.DescriptionHTML}/>
            </>

          }


          </Row>

        </Container>

    )
  }
}

export default InfoMember;
