import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from './TableHead'
import PureEvent from './PureEvent'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class TableEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    renderEvent = () => {
      return (
        this.props.eventDisplay.map((event, index) => (
        <PureEvent refParent={this.props.refParent} deleting={this.props.deleting} event={event} key={event.Id} index={index}/>
      ))
      )
    }

 render() {
   return (
     <Table>
      <TableHead refParent={this.props.refParent}/>
      {
        this.props.eventDisplay.length === 0 && this.props.loadingMore === false  ?
        <TableBody>
          <TableRow>
            <TableCell  colSpan={"9"}>
              <Container fluid>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 3}}>
                    <p style={{color: 'black', fontSize: '1.2rem', fontWeight: 'bold'}}>Pas d'évènement</p>
                  </Col>
                </Row>
              </Container>
            </TableCell>
          </TableRow>
        </TableBody>
        :
        <TableBody>
          {this.renderEvent()}
        </TableBody>
      }

    </Table>
   )
 }
}


export default TableEvent;
