import React from 'react';
import {ButtonCss} from './Style'

class ActionMember extends React.Component {


  renderAcceptStatus = () => {
      return (
        <>
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyActionClick()}>MODIFIER</ButtonCss>
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._DeleteUser()}>SUPPRIMER</ButtonCss>
        <ButtonCss disabled={this.props.NotAcces} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent._ModifyEmail()}>MODIFIER E-MAIL</ButtonCss>
        </>
      )

  }

  render (){
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      {this.renderAcceptStatus()}
      </div>
    )
  }
}

export default ActionMember;
