import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Form} from 'react-bootstrap';
import SubscribeHistory from './SubscribeHistory'
/*
<option value="stripe">
  Stripe
</option>
*/
class HistoryMember extends React.Component {


  render (){
    return (
        <Container  className='shadow' style={{backgroundColor: 'white', padding: 15, borderRadius: 5, marginTop: 30, marginBottom: 10}}>


          <Row >
            <Form.Group as={Col} controlId="formGridHistory">
               <Form.Control
               onChange={(e) => this.props.refParent.setState({currentHistory: e.target.value})}
               value={this.props.currentHistory}
               style={{fontSize: '1.2em', textAlign: 'center'}}
                as="select">
                 <option value="subscribe">
                   Historique du compte
                 </option>
                 <option value="event">
                   Historique des évènements
                 </option>
                 <option value="carpool">
                   Historique du Carpool
                 </option>

            </Form.Control>
            </Form.Group>


          </Row>

            <SubscribeHistory History={this.props.currentHistory} refParent={this.props.refParent}/>


        </Container>

    )
  }
}

export default HistoryMember;
