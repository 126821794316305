import React from 'react';
import Header from './Header';
import PopUp from './PopUp'
import PopUpDelete from './PopUpDelete'
import ListPhoto from './ListPhoto';
import AddFiles from './UploadPhoto'
import {Requestdynamic} from '../../Network/Fetch';
import Cookies from 'universal-cookie'

class InfoGallery extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        openModal: false,
        title: null,
        message: null,
        Name: null,
        City: null,
        Target: null,
        Date: null,
        redirect: false,
        LastKey: null,
        images: [],
        imagesLazy: [],
        searchResult: null,
        verifyArgument: true,
        edit: false,
        loading: false,
        currentId: null,
        addFiles: false,
        deleting: null,
        Files: [],
        action: false,
        IdGallery: null,
        openModalDelete: false,
        Description: null,
      }


    }

    _ResetGallery = async (waiting) => {
      this.setState({loading: true}, async () => {
        this._GetSpecificGallery(waiting)
      })
    }

    _GetSpecificGallery = async (waiting = null) => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Jwt: Jwt,
        IdGallery: this.state.IdGallery
      }
      var promise = await Requestdynamic('_getSpecificGallery', Params);
      if (promise.error === false && promise.data.error === false){
        var paramsSetState = {
          Date: promise.data.data.Date,
          City: promise.data.data.City,
          Target: promise.data.data.Target,
          DateDisplay: promise.data.data.DateDisplay,
          Name: promise.data.data.Name,
          Public: promise.data.data.Public,
          images: promise.data.data.Image,
          Description: promise.data.data.Description,
          loading: false,
        }
        if (waiting)
          delete paramsSetState.loading
        this.setState(paramsSetState)
        await this.sleep(waiting)
        this.setState({loading: false})
      } else {
        if (promise.error === true)
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
        {
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
          this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
      }
    }

    _VerifySource = async () => {
      this.setState({verifyArgument: true}, async () => {
            if (!this.props.navigation.match.params.galleryid){
              this.setState({verifyArgument: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              verifyArgument: false,
              IdGallery: this.props.navigation.match.params.galleryid,
              loading: true,
            }, async () => this._GetSpecificGallery())
        })
    }

    componentDidMount(){
      this._VerifySource()
    }

    routeChange = () => {
      let path = `/all_gallery/`;
      this.props.navigation.history.push({
        pathname: path,
        });
      }

       handleDrag = (ev) => {
    this.setState({currentId: ev.currentTarget.id});
  };

   handleDrop = (ev) => {
    const dragBox = this.state.images.find((box) => box.Key === this.state.currentId);
    const dropBox = this.state.images.find((box) => box.Key === ev.currentTarget.id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = this.state.images.map((box) => {
      if (box.Key === this.state.currentId) {
        box.order = dropBoxOrder;
      }
      if (box.Key === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    this.setState({images: newBoxState})
  };


    goBack = () => {
        this.props.navigation.history.push(`/all_gallery/`);
    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

  Edit = () => {
    if (this.state.edit)
    {
      this.setState({loading: true, action: false}, async () => {
        var CopyImages = [...this.state.images];
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          ImagesInfo: CopyImages,
          Jwt: Jwt,
          IdGallery: this.state.IdGallery
        }
        var promise = await Requestdynamic('_updateImagesInfo', Params);
        await this.sleep(350)
        if (promise.error === true || promise.data.error === true)
        {
          if (promise.error === true)
            this.setState({openModal: true, edit: false, loading: false, message: "Une erreur est survenue veuillez ré-essayer plus tard", title: 'error', action: false})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
          {
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({openModal: true, edit: false, loading: false, message: "Une erreur est survenue veuillez ré-essayer plus tard", title: 'error', action: false})
          return ;
        }
        this.setState({loading: false, edit: false})
      })
    }
    else {
      this.setState({edit: true, action: true})
    }
  }

  RedirectModify = () => {
    this.props.navigation.history.push({
      pathname: `/edit_gallery/${this.state.IdGallery}`,
      state: {Date: this.state.Date, Name: this.state.Name, Public: this.state.Public, Description: this.state.Description, City: this.state.City, Target: this.state.Target}
    })
  }

  DeleteGallery = () => {
    this.setState({action: true, openModalDelete: true})
  }

  _deleteGallery = async () => {
    this.setState({deleting: "Waiting"}, async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
          Jwt: Jwt,
          IdGallery: this.state.IdGallery,
      }
      await this.sleep(2000)
      var promise = await Requestdynamic('_deleteGallery', Params)
      if(promise.error === false && promise.data.error === false)
      {
        this.setState({deleting: 'Success'}, async () => {
          await this.sleep(1500)
          this.setState({deleting: null, openModalDelete: null})
          this.props.navigation.history.push(`/all_gallery/`)
        })
      }
      else {
        if (promise.error === true)
         this.setState({deleting: "Error"})
       else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true)
       {
         cookies = new Cookies();
         cookies.remove('acces', { path: '/' })
         cookies.remove('infoAdmin', { path: '/' })
         await this.sleep(900)
         var url = '/login'
         const link = document.createElement('a');
         link.href = url;
         document.body.appendChild(link);
         link.click();
         link.parentNode.removeChild(link);
         return ;
       }
       else
        this.setState({deleting: "Error"})
      }
    })
  }

  DeleteImage = async (Images) => {
    var Copy = [...this.state.images];
    var index = Copy.findIndex(res => res.Key === Images.Key)
    if (index === -1)
      return ;
    Copy[index].delete = true
    this.setState({images: Copy})
  }

 render() {
   return (

      <div style={{marginLeft: 10, overflowY: "auto"}}>
        <AddFiles IdGallery={this.state.IdGallery} Files={this.state.Files} refParent={this} addFiles={this.state.addFiles} />
        <PopUp refParent={this} redirect={this.state.redirect} openModal={this.state.openModal} title={this.state.title} message={this.state.message}/>
        <PopUpDelete deleting={this.state.deleting} Name={this.state.Name} refParent={this} openModalDelete={this.state.openModalDelete} />
        <Header action={this.state.action} loading={this.state.loading} refParent={this} edit={this.state.edit} Name={this.state.Name} City={this.state.City} DateDisplay={this.state.DateDisplay} goBack={this.goBack}/>
        <ListPhoto  refParent={this} handleDrop={this.handleDrop} handleDrag={this.handleDrag} images={this.state.images} edit={this.state.edit} verifyArgument={this.state.verifyArgument} loading={this.state.loading}/>
      </div>

   )
 }
}


export default InfoGallery;
