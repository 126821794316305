import React from 'react';
import Button from '@material-ui/core/Button';
import { IoIosArrowBack } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Name from './Name'
import SeparateLine from './SeparateLine'
import ActionHeader from './ActionHeader'
import {CssTextFieldSearch} from './Style'
import Typography from '@material-ui/core/Typography';



class Header extends React.Component {

  renderTotal = () => {
    var stringTot = "Total ("
    if (this.props.Member)
      return (stringTot + this.props.Member.length + ')')
    return ("Total (0)")
  }

  render (){
    return (
      <>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <Button variant='text' onClick={() => this.props.goBack()} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <IoIosArrowBack color={"orange"} size={20} />
              <p style={{color: 'black', margin: 10}}>Retour</p>
            </Button>
          </Col>
        </Row>
        <Row style={{margin: 10}}>
          <Col sm={12} lg={12} xl={12} md={12} style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Conférence</h3>
            </div>
          </Col>
        </Row>
        <Name Name={this.props.Name} Date={this.props.Date}/>
        <ActionHeader refParent={this.props.refParent} PrintPdfFile={this.props.PrintPdfFile} exportExcelFile={this.props.exportExcelFile} routeChange={this.props.routeChange}/>
        <SeparateLine marginTop={20} width={"50%"} height={1} color={"greyTrans"}/>
      </Container>
      <Container fluid>
      <Row style={{margin: 10}}>
        <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center',}}>
            <CssTextFieldSearch value={this.props.search ? this.props.search : null} label="rechercher un membre" variant="outlined" onChange={(event) => this.props.refParent.search(event.target.value)} style={{width: '25%', marginRight: 5}}/>
          </div>
        </Col>
      </Row>
      <Col sm={3} md={3} lg={3} xl={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: "100%", display: 'flex', justifyContent: 'flex-start', alignItems: 'center', }}>

            <Typography style={{fontWeight: 'bold', fontSize: '0.98rem', color: '#082430', marginBottom: 50, marginTop: 10}}>{this.renderTotal()}</Typography>

        </div>
      </Col>
      </Container>
      </>
    )
  }
}

export default Header;
