import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import DeletedMember from './DeletedMember'

class ParselDeletedMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={DeletedMember} {...props}/>
   )
 }
}



export default ParselDeletedMember;
