import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SeparateLine from './SeparateLine'
import {CssTextField} from './Style'



class Date extends React.Component {
 render() {

   return (
     <>
       <Row style={{}}>
         <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
            <div style={{borderBottom: '2px solid orange'}}>
             <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Date</h3>
            </div>
         </Col>
       </Row>
         <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
         <Col xl={3} lg={3} md={12} sm={12}>
           <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
              <p style={{fontSize: 15, color: this.props.Error  && this.props.Error.Date ? "red" : '#808080'}}>Date (jj/mm/aaaa)</p>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <CssTextField value={this.props.Day ? this.props.Day : ''} label="jj" variant="outlined" onChange={(event) => this.props.refParent.setState({Day: event.target.value})} style={{marginRight: 5}}/>
                <CssTextField value={this.props.Month ? this.props.Month : ''} label="mm" variant="outlined" onChange={(event) => this.props.refParent.setState({Month: event.target.value})} style={{marginRight: 5}}/>
                <CssTextField value={this.props.Year ? this.props.Year : ''} label="aaa" variant="outlined" onChange={(event) => this.props.refParent.setState({Year: event.target.value})}/>
              </div>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Date ? 'block' : 'none', marginTop: 5}}>Vérifier le format de la date (jours / mois / années)</p>
           </div>
         </Col>
         </Row>

         <SeparateLine marginTop={'1.5rem'} width={"50%"} height={1} color="greyTrans" />


     </>
   )
 }
}


export default Date;
