import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

class PureMember extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  renderStatus = () => {
    if (this.props.history.Method === 'Stripe'){
      if (!this.props.history.Status)
        return ("Ouverture du formulaire Stripe")
      if (this.props.history.Status === "false")
        return ("Annulé/Raté")
      if (this.props.history.Status === "true")
        return ("Payé")
      return ("Ouverture du formulaire Stripe")
    }
    if (this.props.history.Method === "Billing"){
      if (this.props.history.Status === "pending")
        return ("En attente")
      if (this.props.history.Status === "true")
        return ("Payé")
      if (this.props.history.Status === "true")
        return ("Pas payé")
    }
    if (this.props.history.Method === "AcceptButton"){
      if (this.props.history.Status === "true")
        return ("Accepter")
      return ("Refuser")
    }
    if (this.props.history.Method === "BlockButton"){
      if (this.props.history.Status === "false")
        return ("Bloquer")
      if (this.props.history.Status === "true")
        return ("Débloquer")
    }
    if (this.props.history.Method === "FacturationButton"){
      if (this.props.history.Status === "false")
        return ("Facture Upload")
      return ("Facture envoyé")
    }
    return ('')
  }

  renderFile = () => {
    if (this.props.history.File){
      return (
        <Button onClick={() => this.props.refParent._DownloadFileInvoice(this.props.history.File, 'Members')} style={{fontSize: '0.85em'}} variant='text'>
          Télécharger
        </Button>
      )
    }
    return ('')
  }

  render() {
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.From}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Date}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.RegistrationType}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Method}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.renderStatus()}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.File ? this.renderFile() : ""}</TableCell>
      </TableRow>)
  }

}

export default PureMember;
