import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container  from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button'
import { IoMdDownload,  } from "react-icons/io";
import { IoClipboard } from "react-icons/io5";

class RetrieveKey extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.props.UniqueKey], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "B19_recovery.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.parentNode.removeChild(element);
  }

  _copy = () => {
    navigator.clipboard.writeText(this.props.UniqueKey)
  }

 render() {
   return (

     <Container className="shadow" style={{width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50, marginBottom: 50}}>
     <Row >
      <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <h2>Code de récupération</h2>
      </Col>
     </Row>

       <Row>
        <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <p style={{color:'black', fontWeight:'bold', marginTop: 25}}>Notez ce code de récupération d'urgence et conservez-le en lieu sûr. Si vous perdez votre mot de passe vous pourrez utiliser ce code pour créer un nouveau mot de passe</p>
        </Col>
       </Row>

       <Row>
            <Col>
              <div style={{display: 'flex', flex: 1, border:'1px solid black', borderRadius: 5}}>
                <div style={{ whiteSpace: 'nowrap',  overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', justifyContent:'flex-start', alignItems: 'center', flex: 1}}>
                  <h4  style={{color:'black', numberOfLine: 1, fontSize: '1.2rem', marginLeft: 5}}>{this.props.UniqueKey}</h4>
                </div>
                <div style={{display: 'flex', justifyContent:'flex-end', flex: 1/8}}>
                  <Button onClick={() => this._downloadTxtFile()} style={{ backgroundColor: 'white',  border: 'none', height: '100%', borderLeft: '1px solid black',borderRight: '1px solid black',borderRadius: 0}}><IoMdDownload color={'orange'} size={25}/></Button>
                  <Button onClick={() => this._copy()} style={{backgroundColor: 'white', border: 'none', height: '100%', marginRight: 0}}><IoClipboard color={'orange'} size={25}/></Button>
                </div>
              </div>
            </Col>
          </Row>

     <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} sm={10} md={10} xl={8} lg={8}>
        <Button
        onClick={() => this.props.refParent._next()}
        style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
       >
        <p style={{margin: 0, padding: 0}}>Ok j'ai compris !</p>
        </Button>
      </Col>
     </Row>
     </Container>

   )
 }
}


export default RetrieveKey;
