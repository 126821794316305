import React from 'react';
import IsConnectedAndAccess from '../../IsConnected/IsConnectedAndAccess'
import AllPromotion from './AllPromotion'

class ParselAllPromotion extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={true} Access={"promotion"} Component={AllPromotion} {...props}/>
   )
 }
}



export default ParselAllPromotion;
