import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container  from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {Requestdynamic} from '../Network/Fetch';
import Cookies from 'universal-cookie'
import CircularProgress from '@material-ui/core/CircularProgress';

class Login extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        login: false,
        Error: null,
        Email: null,
        Password: null,
      }
    }

    componentDidMount(){
      var cookies = new Cookies();
      var acces = cookies.get('acces')
      if (acces !== 'undefined' && acces){
        var Info = cookies.get('infoAdmin')

        if (!Info || !Info.hasOwnProperty('status') || Info.status != 'SuperSuperAdmin'){
          this.props.navigation.history.push({
          pathname: `/allmembers`,
          state: {}
          })
        }
        else{
          this.props.navigation.history.push({
          pathname: `/dashboard`,
          state: {}
          })
        }
      }


    }

_CheckPassword = (Error) => {
  if (!this.state.Password || this.state.Password.length === 0)
    Error.Password = true
}


_CheckEmail = (Error) => {
  if (!this.state.Email || this.state.Email.length === 0){
      Error.Email = true
  }
}

sleep = (ms) => {
 return new Promise(resolve => setTimeout(resolve, ms));
}

_connect = () => {
  this.setState({login: true, Error: null}, async () => {

  var Error = {};
  this._CheckPassword(Error);
  this._CheckEmail(Error);
  var KeysErrorLength = Object.keys(Error).length
  if (KeysErrorLength !== 0)
  {
    this.setState({Error: Error, login: false})
    return ;
  }
  var Params = {
    email: this.state.Email,
    password: this.state.Password,
    OAuth: true,
  }
  var promise = await Requestdynamic('_login', Params)
  if (promise.error === false && promise.data.error === false)
  {
    var cookies = new Cookies();
    cookies.set('acces', promise.data.data.accesToken, {sameSite: 'strict', path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000)})

    cookies.set('infoAdmin', {Name: promise.data.data.name, Region: promise.data.data.region, status: promise.data.data.status, dashboard: promise.data.data.dashboard, businessCustomize: promise.data.data.businessCustomize, delca: promise.data.data.delca, luikMember: promise.data.data.luikMember, promotion: promise.data.data.promotion}, {sameSite: 'strict', path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000)})
    this.props.refreshLayout(promise.data.data);

    this.setState({login: false, Error: null})
  }
  else {
    if (promise.error === true)
      this.setState({login: false, Error: {Connexion: "Un problème est survenu veuillez réesayer plus tard"}})
    else
      this.setState({login: false, Error: {Connexion: 'Information incorrect'}})
  }

  })


}

  pressEnter = (event) => {
    if(event.key === "Enter")
      this._connect()
  }

 render() {
   return (
     <div style={{backgroundColor: "#F6F7FB", paddingTop: 180, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',  overflowY: "auto"}}>
         <Container className="shadow" style={{width: '100%', backgroundColor: '#FFFFFF', borderRadius: 5, padding: 50, marginBottom: 50}}>
         <Row >
          <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <h2>Connexion</h2>
          </Col>
         </Row>
         {
           this.state.Error && this.state.Error.Connexion ?
           <Row >
            <Col sm={12} md={12} xl={12} lg={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <p style={{color:'red', fontWeight:'bold', marginTop: 25}}>{this.state.Error.Connexion}</p>
            </Col>
           </Row>
           : <>
           </>
         }

         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5}}>
           <Form.Group as={Col} sm={10} md={10} xl={8} lg={8} controlId="formGridEmail" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
             <Form.Control
             type="email"
             placeholder="Login (adresse email)"
             value={this.state.Email ? this.state.Email : ""}
             onChange={(event) => this.setState({Email: event.target.value})}

             />
           </Form.Group>
           {
             this.state.Error && this.state.Error.Email ?
             <Col sm={10} md={10} xl={8} lg={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
               <p style={{color:'red', fontWeight:'bold'}}>Veuillez saire une adresse email</p>
             </Col>
             :
             <>
             </>
           }
         </Row>
         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
           <Form.Group as={Col} sm={10} md={10} xl={8} lg={8} controlId="formGridEmail" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
           <Form.Control
           type="password"
           placeholder="Mot de passe"
           onKeyPress={(event) => this.pressEnter(event)}
           value={this.state.Password ? this.state.Password : ""}
           onChange={(event) => this.setState({Password: event.target.value})}
           />
           </Form.Group>
           {
             this.state.Error && this.state.Error.Password ?
             <Col sm={10} md={10} xl={8} lg={8} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
               <p style={{color:'red', fontWeight:'bold'}}>Veuillez saisir un mot de passe</p>
             </Col>
             :
             <>
             </>
           }
         </Row>
         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} sm={10} md={10} xl={8} lg={8}>
            <Button
            disabled={this.state.login}
            onClick={() => this._connect()}
            style={{margin: 20, marginTop: 50, fontWeight: 'bold',  backgroundColor: '#FC8016', borderWidth: 0}}
           >
           {
             this.state.login ?
             <div style={{display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
             <div style={{width: '100%'}}>
              <p style={{color: 'transparent', margin: 0, padding: 0}}>SE CONNECTER</p>
             </div>
             <div style={{position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
               <CircularProgress
                 size={"1.5rem"}
                 thickness={5}
                 color={"inherit"}
               />
             </div>
             </div>
             :
            <p style={{margin: 0, padding: 0}}>SE CONNECTER</p>
           }

            </Button>
          </Col>
         </Row>



         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 25, padding: 5}}>
           <Col style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} sm={12} md={12} xl={12} lg={12}>
            <Button href="/forget_password" variant="link" style={{color:'black'}}>Mot de passe oublié ?</Button>
           </Col>
         </Row>

         </Container>
         </div>
   )
 }
}


export default Login;
