import React from 'react';
import IsConnectedAndAccess from '../IsConnected/IsConnectedAndAccess'
import DashBoard from './DashBoard'

class ParselDashBoard extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnectedAndAccess SuperAdmin={true} Access={"dashboard"} Component={DashBoard} {...props}/>
   )
 }
}



export default ParselDashBoard;
