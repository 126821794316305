import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import DetailsMember from './SpecificMember'

class ParselDetailsMember extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={DetailsMember} {...props}/>
   )
 }
}



export default ParselDetailsMember;
