import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import { IoIosCheckmark } from "react-icons/io";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUp extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _renderModal = () => {
      if (this.props.MemberDelete && (this.props.MemberDelete.RegistrationType === 'Business' || this.props.MemberDelete.RegistrationType === 'Couple'))
        return (
          <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.5rem', alignItems: 'center', color: 'red'}}>Attention !</p>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '0.85rem', alignItems: 'center', textAlign: 'center'}}>Vous êtes sur le point de supprimer un membre qui fait parti d'un business pack.<br />Si vous supprimez ce membre vous supprimerais l'entièreté des membres compris dans ce business pack</p>
            </Col>
          </Row>
          </>
        )
      else{
        return (
          <>
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
              <p style={{fontSize: '1.3rem', alignItems: 'center'}}>Voulez vous supprimer ce membre ?</p>
            </Col>
          </Row>
          </>
        )
      }

    }


    _renderStatus = () => {
      if (!this.props.statusDelete || this.props.statusDelete === "Ask")
        return (<></>)
      if (this.props.statusDelete === "Waiting"){
        return (
          <Row style={{height: '100%'}}>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <CircularProgress
                size={35}
                thickness={4}
                color={"inherit"}
              />
            </Col>
          </Row>)
      }
      if (this.props.statusDelete === "Success"){
        return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
              <IoIosCheckmark color={"green"} size={50} />
            </Col>
          </Row>
        )
      }
      return (
          <Row>
            <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            </Col>
          </Row>
      )

    }

    _ButtonClickNo = () => {
      this.props.refParent.setState({delete: false, statusDelete: null, MemberDelete: null})
    }

    _ButtonClickYes = () => {
      this.props.refParent._DeleteUser()
    }


 render() {

   return (
       <Popup showPopup={this.props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
       {this._renderModal()}
       {this._renderStatus()}
       <Row style={{margin: 10}}>
         <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
           <ButtonTestCss  disabled={this.props.statusDelete && this.props.statusDelete === 'Waiting'} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{this.props.statusDelete && this.props.statusDelete === "Error" ? "Ré-essayer" : "Oui"}</ButtonTestCss>
           <ButtonTestCss  disabled={this.props.statusDelete && this.props.statusDelete === 'Waiting'} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
         </Col>
       </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUp;
