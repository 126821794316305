import React from 'react';
import {Button} from  'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Form} from 'react-bootstrap';
import CurrencyTextField from '../../CurrencyTextField/index'
import Switch from "react-switch";

class PriceBusiness extends React.Component {


  render (){
    return (
      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20,  whidth: '100%', marginBottom: 10}}>
        <Row>
        <Form.Group as={Col} xl={5} lg={5} sm={12} md={12} controlId="formGridCity">
        <h5 style={{marginBottom: '25px'}}>Prix du Business</h5>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
           <CurrencyTextField
              label="Montant"
              variant="standard"
              value={this.props.refParent.state.Price ? this.props.refParent.state.Price : '0'}
              currencySymbol="€"
              minimumValue="0"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator=""
              onChange={(event) => this.props.refParent.setState({Price: event.target.value})}
              />
            </div>
            <Button
            onClick={() => this.props.refParent._ModifyPrice()}
            style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
            >
              <p style={{margin: 0}}>Changer</p>
            </Button>
        </Form.Group>
        </Row>
        <Row>
          <Col xl={3} lg={3} md={12} sm={12}>
            <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 5}}>
              <p style={{fontSize: '1em', color: 'black'}}>{"Prix variable ?"}</p>
              <Switch onChange={() => this.props.refParent._VariablePriceGestion()} checked={this.props.refParent.state.VariablePrice}  width={42} height={22} />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default PriceBusiness;
