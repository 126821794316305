import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import Loading from './Loading'
import { IoIosCheckmark } from "react-icons/io";

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

class PopUpDelete extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

    _ButtonClickYes = () => {
      this.props.refParent._deleteEvent()
    }

    _ButtonClickNo = () => {
      var Params = {openModalDelete: false, EventDelete: null, deleting: null}
      this.props.refParent.setState(Params)
    }

    _statusDeleting = () => {
      if (this.props.deleting === 'Waiting')
        return (<Loading loadingMore={this.props.deleting} size={15} />)
      else if (this.props.deleting === 'NotAcces')
        return (
          <div style={{textAlign: 'center', margin: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
            <p style={{color: 'grey', fontWeight: 'bold', size: '0.8em'}}>Vous n'avez pas les droits nécèssaires pour supprimer cette évènement</p>
          </div>
        )
      else if (this.props.deleting === 'Error')
        return (
          <div style={{textAlign: 'center', margin: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <p style={{color: 'red', fontWeight: 'bold', size: '1.2rem'}}>Erreur</p>
          </div>
        )
      else
        return (
          <div style={{textAlign: 'center', margin: 25}}>
            <IoIosCheckmark color={"green"} size={20} />
          </div>
        )
    }

 render() {

   return (
       <Popup showPopup={this.props.openModalDelete} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: this.props.openModalDelete ? 10000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.5rem', alignItems: 'center', fontWeight: 'bold', color: 'red'}}>Suppression</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '0.9rem', alignItems: 'center'}}>Etes-vous sûr de vouloir supprimer cet évènement?</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center'}}>
            <p style={{fontSize: '1.3rem', alignItems: 'center', fontStyle: 'italic'}}>"{this.props.EventDelete ? this.props.EventDelete.Name : ' '}"</p>
          </Col>

        </Row>
        {
          this.props.deleting ?
          this._statusDeleting()
          :
          <>
          </>
        }
        <Row style={{margin: 10}}>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
            <ButtonTestCss disabled={this.props.deleting && (this.props.deleting !== "Error" && this.props.deleting !== "NotAcces")} onClick={() => this._ButtonClickYes()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>{this.props.deleting && (this.props.deleting === "Error" || this.props.deleting === "NotAcces") ? "Ré-essayer" : "Oui"}</ButtonTestCss>
            <ButtonTestCss disabled={this.props.deleting && (this.props.deleting !== "Error" && this.props.deleting !== "NotAcces")} onClick={() => this._ButtonClickNo()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Non</ButtonTestCss>
          </Col>
        </Row>
       </div>
       </Popup>
   )
 }
}


export default PopUpDelete;
