import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IoIosArrowForward} from "react-icons/io";

class TableHeadEvent extends React.Component {

    _renderCell = (Name, Trad) => {
        return (
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.85em', color: 'black', fontWeight: 'bold'}}>
            <p>{Trad}</p>
            <div style={{marginLeft: 5}}>
              <IoIosArrowForward  color={'rgba(0,0,0,0)'} size={15} />
            </div>
          </div>
        )
    }

 render() {

   return (

      <TableHead>
        <TableRow>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Photo", 'PHOTO')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Name", 'NOM')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("FirstName", 'PRENOM')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Fonction", 'FONCTION')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Society", 'SOCIETE')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Linkedin", 'LINKEDIN')}</div></TableCell>
          <TableCell align="left"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Region", 'REGION')}</div></TableCell>
          <TableCell align="center"><div style={{fontSize: 15, fontFamily: 'Mitr, sans-serif', color: 'black', fontWeight: 'bold'}}>{this._renderCell("Action", 'ACTION')}</div></TableCell>
        </TableRow>
      </TableHead>

   )
 }
}


export default TableHeadEvent;
