import React from 'react';
import Col from 'react-bootstrap/Col';
import {CssTextField} from './Style'


class Category extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   return (
        <Col xl={5} lg={5} md={12} sm={12}>
        <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
          <CssTextField  onChange={(event) => this.props.refParent.setState({Category: event.target.value})} value={this.props.Category} select label="Category" variant="outlined" SelectProps={{native: true}}>
            <option key={"PlaceHolder"} value={"PlaceHolder"} disabled>{"Veuillez indiquer la categorie"}</option>
            <option key={"Platinium"} value={"Platinium"}>Platinium</option>
            <option key={"Silver"} value={"Silver"}>Silver</option>

          </CssTextField>
          <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Category ? 'block' : 'none', marginTop: 5}}>Veuillez indiquer la categorie</p>
        </div>
        </Col>

   )
 }
}


export default Category;
