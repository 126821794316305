import React from 'react';
import Button from '@material-ui/core/Button';
import { IoIosArrowBack } from "react-icons/io";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Form} from 'react-bootstrap';


class TypeOfBusinessPack extends React.Component {


  render (){
    return (
      <Container className="shadow" style={{backgroundColor: "white", borderRadius: 10, padding: 20,  whidth: '100%', marginBottom: 10}}>
        <Row>
        <Form.Group as={Col} xl={5} lg={5} sm={12} md={12} controlId="formGridCity">
        <h5 style={{marginBottom: '25px'}}>Type du BusinessPack</h5>
         <Form.Control value={this.props.refParent.state.RegistrationType ? this.props.refParent.state.RegistrationType : ""} required as="select" onChange={(event) => this.props.refParent._ModifyPack(event.target.value)}>
           <option value={"BusinessPackCustomize"}>
            {"BusinessCustomize"}
           </option>
           <option value={"Business"}>
            {"Business"}
           </option>
          </Form.Control>
        </Form.Group>
        </Row>
      </Container>
    )
  }
}

export default TypeOfBusinessPack;
