import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Header from './Header'
import Photo from './Photo'
import Name from './Name'
import Date from './Date'
import Description from './Description'
import Free from './Free'
import LimitPlace from './LimitPlace'
import HeaderLiner from './HeaderLiner'
import Place from './Place'
import Close from './Close'
import PopUp from './PopUp'
import Language from './Language'
import {ButtonTestCss} from "../../ButtonStyle";
import CircularProgress from '@material-ui/core/CircularProgress';
import {CheckFormatDate, CheckIfDateIsAfter, GetCurrentDateEuropeParis} from '../../Moment/Moment'
import {Requestdynamic} from '../../Network/Fetch';
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import Cookies from 'universal-cookie'
import Region from './Regions'
import Sponsor from './Sponsor'
import ModalPreviewEvent from './ModalPreviewEvent'
import Compta from './Compta'

class EditEvent extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        isFree: true,
        Price: null,
        limitPlace: false,
        limitPlaceNumber: '0',
        addEvent: false,
        Year: null,
        Day: null,
        Month: null,
        Compta: null,
        Error: null,
        EndHours: "06",
        EndMinutes: "00",
        BeginHours: "06",
        BeginMinutes: "00",
        FileUrl: null,
        FileAppUrl: null,
        Name: null,
        Editor: EditorState.createEmpty(),
        isHeadLiner: false,
        isClosed: false,
        Language: [],
        Picture: null,
        PictureApp: null,
        openModal: false,
        title: null,
        message: null,
        Location: [],
        OldPhoto: null,
        LocationId: "PlaceHolder",
        loadingSpecificEvent: true,
        redirect: false,
        Region: [],
        LocationSelect: [],
        Sponsor: [],
        SponsorSelect: []
      }

    }

    sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
   }

    _GetAllSponsor = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var promise = await Requestdynamic('_getAllSponsor', {Body: {Jwt: Jwt, Size: "Large"}})
      if(promise.error === false && promise.data.error === false)
      {
        this.setState({Sponsor: promise.data.data})
        this._GetSpecificEvent()
      }
      else {
        if (promise.error === true){
          this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des sponsors, veuillez ré-essayer plus tard"})
          return ;
        }
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
      else {
        this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des sponsor, veuillez ré-essayer plus tard"})
      }
      }
    }

    _GetAllLocation = async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var promise = await Requestdynamic('_getAllLocation', {Jwt: Jwt})
      if(promise.error === false && promise.data.error === false)
      {
        this.setState({Location: promise.data.data})
        this._GetAllSponsor()

      }
      else {
        if (promise.error === true){
          this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des lieux, veuillez ré-essayer plus tard"})
          return ;
        }
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
      else {
        this.setState({openModal: true, title: 'Error', message: "Un problème est survenue lors du chargement des lieux, veuillez ré-essayer plus tard"})
      }
      }
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.eventid){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un problème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({
              IdEvent: this.props.navigation.match.params.eventid,
            }, async () => this._GetAllLocation())
        })
    }

    VerifySponsorExist = (Sponsor) => {
      var FinalSponsor = [];
      Sponsor.forEach(res => {
        if (this.state.Sponsor.find(sponsor => sponsor.Id === res.Id))
          FinalSponsor.push(res)
      })
      return (FinalSponsor)
    }

    _GetSpecificEvent = async () => {
      this.setState({loadingSpecificEvent: true}, async () => {
        var cookies = new Cookies();
        var Jwt = cookies.get('acces');
        var Params = {
          Jwt: Jwt,
          SizeImage: 'Medium',
          IdEvent: this.props.navigation.match.params.eventid
        }
        var promise = await Requestdynamic('_getSpecificEvent', Params)
        if(promise.error === false && promise.data.error === false)
        {
          try {
            var SetParams = {
              Compta: promise.data.data.Compta,
              isHeadLiner: promise.data.data.HeadLiner,
              OldPhoto: promise.data.data.Image,
              OldPhotoApp: promise.data.data.ImageApp,
              FileUrl: promise.data.data.Image,
              FileAppUrl: promise.data.data.ImageApp,
              loadingSpecificEvent: false,
              isFree: promise.data.data.Free.isFree,
              Price: promise.data.data.Free.Price ? promise.data.data.Free.Price.toString() : null,
              limitPlace: promise.data.data.LimitPlace.isLimited,
              limitPlaceNumber: promise.data.data.LimitPlace.isLimited === true ? promise.data.data.LimitPlace.LimitPlaceNumber.toString() : '0',
              Language: promise.data.data.Language,
              LocationId: promise.data.data.Location ? promise.data.data.Location.Id : "PlaceHolder",
              LocationSelect: promise.data.data.Location,
              SponsorSelect: this.VerifySponsorExist(promise.data.data.Sponsor),
              Name: promise.data.data.Name,
              isClosed: promise.data.data.Closed,
              Region: promise.data.data.Region,
              Editor: EditorState.createWithContent(convertFromRaw(promise.data.data.Description)),
              Year: promise.data.data.EventDate.Date.split('/')[0],
              Month: promise.data.data.EventDate.Date.split('/')[1],
              Day: promise.data.data.EventDate.Date.split('/')[2],
              BeginHours: promise.data.data.EventDate.Start.split(':')[0],
              EndHours: promise.data.data.EventDate.End.split(':')[0],
              BeginMinutes: promise.data.data.EventDate.Start.split(':')[1],
              EndMinutes: promise.data.data.EventDate.End.split(':')[1],
            }
            this.setState(SetParams)
          } catch (e) {
            this.setState({redirect: true, loadingSpecificEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})

          }
        }
        else
        {
          if (promise.error === true)
            this.setState({redirect: true, loadingSpecificEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
          else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
            cookies = new Cookies();
            cookies.remove('acces', { path: '/' })
            cookies.remove('infoAdmin', { path: '/' })
            await this.sleep(900)
            var url = '/login'
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return ;
          }
          else
            this.setState({redirect: true, loadingSpecificEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
          return ;
        }
      })
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);

    }

    _ConvertToRaw = (Error) => {
      try {
        if (!this.state.Editor)
          return (null)
        var RawEditor = convertToRaw(this.state.Editor.getCurrentContent())
        return (RawEditor)
      } catch (e) {
        return ("erreur")
      }

    }

    _CheckDate = (Error) => {
      if (!this.state.Year || !this.state.Month || !this.state.Day)
      {
        Error.Date = true
        return ;
      }
      var Date = this.state.Year + '/' + this.state.Month + '/' + this.state.Day
      if (!CheckFormatDate(Date, "YYYY/MM/DD"))
        Error.Date = true
      return ;
    }

    _CheckHours = (Error) => {

      var Date = this.state.Year + '/' + this.state.Month + '/' + this.state.Day
      var DateEnd = Date + " " + this.state.EndHours + ':' + this.state.EndMinutes;
      var DateStart = Date + " " + this.state.BeginHours + ':' + this.state.BeginMinutes;
      if (!CheckFormatDate(DateEnd, "YYYY/MM/DD HH:mm"))
        Error.Hours = true;
      if (!CheckFormatDate(DateStart, "YYYY/MM/DD HH:mm"))
        Error.Hours = true;
      if (!CheckIfDateIsAfter(DateStart, DateEnd))
        Error.Hours = true;
      var Now = GetCurrentDateEuropeParis();
      if (!CheckIfDateIsAfter(Now, DateStart))
        Error.Hours = true;
    }

    _CheckName = (Error) => {
      if (!this.state.Name || this.state.Name.length === 0)
        Error.Name = true
    }


    _CheckPrice = () => {
        if (!this.state.Price)
          return (null)
        if (this.state.isFree)
          return (null)
        return (this.state.Price)
    }

    _CheckLanguage = (Error) => {
      if (this.state.Language.length === 0)
      {
        Error.Language = true;
        return ;
      }
    }

    _CheckLocation = (Error) => {
      if (this.state.LocationId  === "PlaceHolder")
        Error.Location = true
    }

    _CheckRegion = (Error) => {
      if (this.state.Region.length === 0)
        Error.Region = true
    }

    _CheckCompta = (Error) => {
      if (this.state.Compta === null)
        Error.Compta = true
    }

    _CheckFreeLux = () => {
      if (this.state.Compta === 'lux' && this.state.isFree === false)
        return (0)
      return (1)
    }

    _updateEvent = async () => {
    this.setState({_updateEvent: true, Error: null}, async() => {
    var Error = {};

    if (!this._CheckFreeLux())
    {
      alert('Les paiements en ligne pour la comptabilité du Luxembourg sont désactivé')
      return ;
    }
    this._CheckDate(Error)
    this._CheckHours(Error)
    this._CheckName(Error)
    this._CheckLanguage(Error)
    this._CheckLocation(Error)
    this._CheckRegion(Error)
    this._CheckCompta(Error)


    var Description = this._ConvertToRaw()
    var Price = this._CheckPrice()
    var LimitPlace = this.state.limitPlace ? this.state.limitPlaceNumber : null
    var HeaderLinerVariable = this.state.isHeadLiner
    var Closed = this.state.isClosed
    var Picture = this.state.Picture
    var PictureApp = this.state.PictureApp
    var KeysErrorLength = Object.keys(Error).length
    if (KeysErrorLength !== 0)
    {
      this.setState({Error: Error, addEvent: false})
      return ;
    }
    var cookies = new Cookies();
    var Jwt = cookies.get('acces');
    var Params = {
      Sponsor: this.state.SponsorSelect,
      Compta: this.state.Compta,
      Language: [...this.state.Language],
      Closed: Closed,
      Name: this.state.Name,
      Date: this.state.Year + '/' + this.state.Month + '/' + this.state.Day,
      StartHours: this.state.BeginHours + ':' + this.state.BeginMinutes,
      EndHours: this.state.EndHours + ':' + this.state.EndMinutes,
      Location: this.state.LocationSelect,
      IdEvent: this.state.IdEvent,
      Jwt: Jwt,
      RegionFrontend: this.state.Region,
      Price: Price,
      Description: Description,
      HeadLiner: HeaderLinerVariable,
      LimitPlace: LimitPlace,
      Picture: Picture,
      PictureApp: PictureApp
    }
    var promise = await Requestdynamic('_updateEvent', Params)
    if(promise.error === false && promise.data.error === false)
    {
      this.setState({_updateEvent: false, openModal: true, title: 'Success', message: "L'évènement à bien été mis à jour", redirect: true})
    }
    else
    {
      if (promise.error === true)
        this.setState({_updateEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
        cookies = new Cookies();
        cookies.remove('acces', { path: '/' })
        cookies.remove('infoAdmin', { path: '/' })
        await this.sleep(900)
        var url = '/login'
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        return ;
      }
      else {
        if (promise.data && promise.data.NotAcces === true)
          return ({_updateEvent: false, openModal: true, title: 'Error', message: "Vous n'avez pas les droits nécéssaires pour modifier cette évènements"})
        this.setState({_updateEvent: false, openModal: true, title: 'Error', message: "Un problème est survenue veuillez réesayer plus tard"})
      }
      return ;
    }
  })

}

goBack(){

  var paramsNavigate = {pathname: `/details_event/${this.state.IdEvent}`}
  if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.oldPage)
    paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
    this.props.navigation.history.push(paramsNavigate);

}

PreviewEvent = () => {
  this.setState({PreviewEvent: true});
}

 render() {
   return (
      <div style={{marginLeft: 10, overflowY: "auto"}}>
       <Container fluid>
        <ModalPreviewEvent openModal={this.state.PreviewEvent} refParent={this}/>
          <PopUp redirect={this.state.redirect} openModal={this.state.openModal} message={this.state.message} title={this.state.title} refParent={this}/>
         <Header intl={this.props.intl} refParent={this}/>
         <Photo loadingSpecificEvent={this.state.loadingSpecificEvent} success={this.state.success} OldPhoto={this.state.OldPhoto} OldPhotoApp={this.state.OldPhotoApp} intl={this.props.intl} refParent={this}/>
         <Date
         loadingSpecificEvent={this.state.loadingSpecificEvent}
        Error={this.state.Error}
        intl={this.props.intl}
        Year={this.state.Year}
        Day={this.state.Day}
        Month={this.state.Month}
        EndHours={this.state.EndHours}
        EndMinutes={this.state.EndMinutes}
        BeginMinutes={this.state.BeginMinutes}
        BeginHours={this.state.BeginHours}
        refParent={this}/>
         <Name Error={this.state.Error} intl={this.props.intl} Name={this.state.Name} refParent={this}/>
         <Description intl={this.props.intl} Editor={this.state.Editor} refParent={this}/>
         <Language Error={this.state.Error} intl={this.props.intl} Language={this.state.Language} refParent={this}/>
         <Free intl={this.props.intl} isFree={this.state.isFree} Price={this.state.Price} refParent={this}/>
         <LimitPlace intl={this.props.intl} limitPlaceNumber={this.state.limitPlaceNumber} limitPlace={this.state.limitPlace} refParent={this}/>
         <HeaderLiner intl={this.props.intl} isHeadLiner={this.state.isHeadLiner} refParent={this}/>
         <Place Error={this.state.Error} intl={this.props.intl} refParent={this} Location={this.state.Location} LocationId={this.state.LocationId}/>
         <Close intl={this.props.intl} isClosed={this.state.isClosed} refParent={this}/>
         <Region Region={this.state.Region} intl={this.props.intl} refParent={this} Error={this.state.Error}/>
         <Compta Compta={this.state.Compta} intl={this.props.intl} refParent={this} Error={this.state.Error}/>
         <Sponsor Sponsor={this.state.Sponsor} SponsorSelect={this.state.SponsorSelect} refParent={this}/>

         <Row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
             <ButtonTestCss disabled={this.state._updateEvent} color={"orange"} style={{margin: 10}} onClick={() => this.PreviewEvent()}>
               {
                 this.state._updateEvent ?
                 <CircularProgress
                   size={"1.5rem"}
                   thickness={5}
                   color={"inherit"}
                 />
                 :
                 <p style={{margin: 0, padding: 0}}>Visualiser</p>
               }
             </ButtonTestCss>

           </Col>
         </Row>

       </Container>
     </div>
   )
 }
}


export default EditEvent;
