import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TitleContact extends React.Component {
  constructor(props){
      super(props);
      this.state = {

        }
    }

 render() {
   return (

        <Row style={{margin: 10, marginTop: 20}}>
          <Col sm={12} lg={6} xl={6} md={12} style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column'}}>
            <p style={{fontSize: 16, fontWeight: 'bold', fontFamily: 'Mitr, sans-serif', color: 'black'}}>Information du nouvel Admin :</p>
          </Col>
        </Row>
   )
 }
}


export default TitleContact;
