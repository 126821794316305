import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loading extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }


 render() {
   if (this.props.loadingMore)
   return (
      <div style={{textAlign: 'center', margin: 25, color: "orange"}}>
        <CircularProgress
          size={this.props.size ? this.props.size : 40}
          thickness={4}
          color={"inherit"}
        />
      </div>
   )
   else
    return (
      <>
      </>
    )
 }
}


export default Loading;
