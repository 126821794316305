import React from 'react';

import { Row, Col} from 'react-bootstrap';
import Logob19 from "../../../assets/ThumbLogo.png"
import { PDFExport } from  '@progress/kendo-react-pdf';
import "./VisualizePdf.css"
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

function VisualizePdf(props) {

  const CheckBackSlashN = (string, max) => {
    let StringTemp = [];
    let count = 0;
    let start = 0;

    for (var i = 0; i <= string.length; i++){
      if (count % max === 0){
        StringTemp.push(string.slice(start, count))
        start = count;
      }
      if (i === string.length){
        StringTemp.push(string.slice(start))
      }
        count++
    }
    var length = StringTemp.length;

    if (StringTemp[length - 1].length < max){
      var lengthString = StringTemp[length - 1].length;
      var AddString = max - lengthString;
      var NewAddString = new Array(AddString).join(" ");
      StringTemp[length - 1] = StringTemp[length - 1].concat(NewAddString)
    }
    var StringToReturn = StringTemp.map(res => res).join("\n")
    /*if (StringToReturn.length === 1 && StringToReturn === "\n"){
      StringToReturn = new Array(max + 1).join(" ")
    }*/
    return StringToReturn;
  }

const sortOnFirstAndLast = (a,b) => {
  a.FirstName = a.FirstName.charAt(0).toUpperCase() + a.FirstName.slice(1)
  a.LastName = a.LastName.charAt(0).toUpperCase() + a.LastName.slice(1)
  b.FirstName = b.FirstName.charAt(0).toUpperCase() + b.FirstName.slice(1)
  b.LastName = b.LastName.charAt(0).toUpperCase() + b.LastName.slice(1)

  var aa = a.LastName + ", " + a.FirstName,
    bb = b.LastName + ", " + b.FirstName;
  if (aa > bb)
    return 1;
  else if (aa < bb)
    return -1;
  return 0;
}


  const renderMemberRow = () => {
    return props.data.sort(sortOnFirstAndLast).map((res, index) => {
      return (
        <TableRow style={{backgroundColor: index % 2 ? "#ededed" : "", borderBottom:"none", padding: 0, margin: 0}}>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.LastName}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.FirstName}</TableCell>
        <TableCell   colSpan={2} align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.Society}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.StatusSubscribe}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.MemberStatus}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0}}>{res.RegistrationType}</TableCell>
        <TableCell   align="left" style={{fontSize: "0.44rem", padding: 2, margin: 0, color: res.Cotisation === true ? 'green' : 'red'}}>{res.Cotisation === true ? 'Oui' : 'Non'}</TableCell>
        </TableRow>
      )
    })
  }


  return (
      <div  className="page-container hidden-on-narrow">
        <PDFExport paperSize={"A4"} ref={props.pdfExport}>
        <div  className={ `pdf-page size-a4` } style={{overflowY: 'scroll'}}>

        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{textAlign: 'left', marginBottom: 10}}>
            <img alt={"logo b19"} src={Logob19} style={{width: "40px"}}/>
          </div>

          <div style={{textAlign: 'right', width: '100%'}}>
            <p style={{fontSize: '10px', fontWeight: 'bold'}}>{props.Date}</p>
          </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <p style={{fontSize: '13px', fontWeight: 'bold'}}>{props.Name}</p>
          </div>

          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 10}}>
          <div style={{backgroundColor: '#ff5f00', height: 2, width: '90%', marginBottom: 20}}/>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Nom</TableCell>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Prénom</TableCell>
                <TableCell  align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Société</TableCell>
                <TableCell  align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}></TableCell>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Status</TableCell>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Type</TableCell>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Abonnement</TableCell>
                <TableCell   align="left" style={{fontSize: "0.50rem", padding: 0, margin: 0}}>Cotisation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderMemberRow()}
            </TableBody>
          </Table>


        </div>
        </PDFExport>
        </div>

  );
}

export default VisualizePdf;
