const _addEvent = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutEvent",
    body : Params
  }
  return params
}

const _addAmbassador = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutAmbassador",
    body : Params
  }
  return params
}

const _addSponsor = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutSponsor",
    body : Params
  }
  return params
}

const _addAdmin = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutAdmin",
    body : Params
  }
  return params
}

const _addGallery = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutGallery",
    body : Params
  }
  return params
}

const _addLocation = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutLocation",
    body : Params
  }
  return params
}

const _addNoneMember = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/PutNonMember",
    body : Params
  }
  return params
}

const _updateEmailMember = (Params) => {
  var params;
  params = {
    method : "PUT",
    Route : "/UpdateEmail",
    body : Params
  }
  return params
}

module.exports = {
  _addEvent,
  _addAmbassador,
  _addSponsor,
  _addGallery,
  _addAdmin,
  _addLocation,
  _addNoneMember,
  _updateEmailMember

}
