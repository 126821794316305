import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { Dropdown } from 'react-bootstrap';
 import { Link } from 'react-router-dom';

class PureParticipant extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {

        color: this.getRandomColor()
      }
    }

  getRandomColor = () => {
   var letters = '0123456789ABCDEF';
   var color = '#';
   for (var i = 0; i < 6; i++) {
     color += letters[Math.floor(Math.random() * 16)];
   }
   return color;
 }

backgroundColorGet = () => {
  if (this.props.index % 2 === 0)
    return ('')
  return ('#ECECEC')
}

_getStyleCotisation = (Cotisation) => {
  if (!Cotisation)
    return ({fontWeight: 'bold'})
  if (Cotisation === "false")
    return ({color: 'red', fontWeight: 'bold'})
  else if (Cotisation === 'true')
    return ({color: 'green'})
  else
    return ({color: 'orange', fontWeight: 'italic'})

}

_setMaj = (string) => {
  try {
    if(string !== null){
      const newString = string.split("-")
      for (var i = 0; i < newString.length; i++) {
        newString[i] = newString[i].charAt(0).toUpperCase() + newString[i].slice(1);
      }
      const newStringJoin = newString.join("-")
      return (newStringJoin)
    }
    return ('')
  }catch (e){
    return (string)
  }
}

IsDeletedParticipant = () => {
  var index = this.props.DeleteParticipant.findIndex(res => res.Id === this.props.member.Id)
  if (index === -1)
    return (false)
  return (true)
}


  render() {
    return (
      <TableRow key={this.props.member.Id} style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this._setMaj(this.props.member.FirstName)}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this._setMaj(this.props.member.LastName)}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.Email}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this._setMaj(this.props.member.Society)}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.Function}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.Date}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.Source}</TableCell>
        <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.MemberStatus}</TableCell>
        {
          this.props.isFree ?
          <>
          </>
          :
          <>
          <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.UrlPayment}</TableCell>
          <TableCell align="left" style={{fontSize: "0.85em",}}>{this.props.member.StatusSubscribe}</TableCell>
          </>
        }
        <TableCell align="left" style={{fontSize: "0.85em", color: this.props.member.Cotisation === true ? 'green' : 'red'}}>{this.props.member.Cotisation === true ? 'Oui' : 'Non'}</TableCell>

        <TableCell align="center" style={{}}>








        <Dropdown style={{textAlign: 'left'}}>
          <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">

          </Dropdown.Toggle>

          <Dropdown.Menu variant="primary">

            <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

            <Button variant="text" style={{color: 'orange', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}} component={Link}
            to={{
            pathname: this.props.member.MemberStatus === 'Member' ? `/details_member/${this.props.member.Id}` : `/details_none_member/${this.props.member.Id}`,
            state: {oldPage: `/subscribe_member_event/${this.props.refParent.state.IdEvent}`, verify: true, Compta: this.props.refParent.state.Compta, Name: this.props.refParent.state.Name, Date: this.props.refParent.state.Date, isFree: this.props.isFree}
            }}>
                Détails du membre
            </Button>

            </Dropdown.Item>

              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button onClick={() => this.props.refParent.DeleteParticipantEvent(this.props.member)} variant="text" style={{color: 'red', fontWeight: 'bold', fontSize: "0.65em", width: '100%'}}>
                Retirer
              </Button>
              </Dropdown.Item>
            {
              !this.props.isFree && this.props.member.StatusSubscribe === 'pending' ?
              <Dropdown.Item id="nav-dropdown" style={{textAlign: 'center', display: 'flex', padding: 0}}>

              <Button variant="text" onClick={() => this.props.refParent.ValidatePayment(this.props.member)} style={{color: '#082430', fontWeight: 'bold', fontSize: "0.65em", width: '100%' }}>
                  Valider l'inscription
              </Button>
              </Dropdown.Item>

              :
              <>
              </>
            }




          </Dropdown.Menu>
        </Dropdown>

        </TableCell>
      </TableRow>)
  }

}


export default PureParticipant;
