import React from 'react';
import IsConnected from '../../IsConnected/IsConnected'
import EditAdress from './EditAdress'

class ParselEditAdress extends React.Component {

 render() {
   const {...props} = this.props
   return (
     <IsConnected Component={EditAdress} {...props}/>
   )
 }
}



export default ParselEditAdress;
