import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {CssTextField, ButtonCss} from './Style'
class Header extends React.Component {

checkDisabled = () => {
  if (!this.props.search)
    return (true)
  if (this.props.loadingSearch === true)
    return (true)
  if (this.props.search.length < 3)
    return (true)
  return (false)
}

pressEnter = (event) => {
  if(event.key === "Enter")
    this.props.refParent.search()
}

  render (){
    return (
      <>
      <Container fluid>
        <Row>
          <Col sm={12} xl={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
            <h2 style={{textAlign: 'left', marginBottom: 25}}>Evènements passé</h2>
          </Col>
        </Row>
      </Container>
        <Container fluid>
        <Row style={{margin: 10}}>
          <Col sm={12} md={12} lg={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
              <div style={{width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                <CssTextField onKeyPress={(event) => this.pressEnter(event)} value={this.props.search ? this.props.search : ''} label="rechercher un évènement (minimum 3 character)" variant="outlined" onChange={(event) => this.props.refParent._updateSearchValue(event.target.value)} style={{marginRight: 5, width: '50%'}}/>
              </div>
              <div >
                <ButtonCss disabled={this.checkDisabled()} style={{width: '90%', fontSize: '0.90em'}} onClick={() => this.props.refParent.search()}>RECHERCHER</ButtonCss>
              </div>
            </div>
          </Col>
        </Row>
        </Container>
        </>
    )
  }
}

export default Header;
