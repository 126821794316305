import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

class PureMember extends React.PureComponent {
  constructor(props){
      super(props);
      this.state = {
      }
    }

  backgroundColorGet = () => {
    if (this.props.index % 2 === 0)
      return ('')
    return ('#ECECEC')
  }

  renderStatus = () => {
    if (this.props.history.Method === 'Stripe'){
      if (!this.props.history.Status)
        return ("Ouverture du formulaire Stripe")
      if (this.props.history.Status === "false")
        return ("Annulé/Raté")
      if (this.props.history.Status === "true")
        return ("Payé")
    }
    if (this.props.history.Method === "Billing"){
      if (this.props.history.Status === "pending")
        return ("En attente")
      if (this.props.history.Status === "true")
        return ("Payé")
      if (this.props.history.Status === "true")
        return ("Pas payé")
    }
    if (this.props.history.Method === "AcceptButton"){
      if (this.props.history.Status === "true")
        return ("Accepter")
      return ("Refuser")
    }
    if (this.props.history.Method === "BlockButton"){
      if (this.props.history.Status === "true")
        return ("Bloquer")
      if (this.props.history.Status === "false")
        return ("Débloquer")
    }
    if (this.props.history.Method === "FacturationButton"){
      if (this.props.history.Status === "false")
        return ("Facture Upload")
      return ("Facture envoyé")
    }
    return ('')
  }

  renderFile = () => {
    if (this.props.history.File){
      return (
        <Button onClick={() => this.props.refParent._DownloadFileInvoice(this.props.history.File, 'Members')} style={{fontSize: '0.85em'}} variant='text'>
          Télécharger
        </Button>
      )
    }
    return ('')
  }

  _renderAdress = () => {
    var AdresseFrom = ''
    var AdresseTo = ''
    if (this.props.history.FromAddress)
      AdresseFrom = this.props.history.FromAddress.Address
    if (this.props.history.To)
      AdresseTo = this.props.history.To
    if (AdresseFrom.length === 0 && AdresseTo.length === 0)
      return ('')
    return (AdresseFrom + ' || ' + AdresseTo)
  }

  _renderStatus = () => {
    if (this.props.history.Status === 'Post')
      return (<p style={{fontWeight: 'bold'}}>Post</p>)
    return (
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div style={{width: '100%'}}>
          <p style={{fontWeight: 'bold'}}>{this.props.history.Status}</p>
        </div>
        <div style={{width: '100%'}}>
          <p style={{fontStyle: 'italic'}}><a style={{color: 'black'}} href={`/details_member/${this.props.history.RequestTo.Id}`}>{this.props.history.RequestTo.LastName + " " + this.props.history.RequestTo.FirstName}</a></p>
        </div>
      </div>
    )
  }

  renderFrom = () => {
    return (<p><a style={{color: 'black'}} href={`/details_member/${this.props.history.From.Id}`}>{this.props.history.From.LastName + " " + this.props.history.From.FirstName}</a></p>)
  }

  render() {
    console.log(this.props)
    return (
      <TableRow  style={{backgroundColor: this.backgroundColorGet(), borderBottom:"none"}}>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.renderFrom()}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Date}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Produit}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this._renderStatus()}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.DateCarpool ? this.props.history.DateCarpool.Date : ""}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.DateCarpool ? this.props.history.DateCarpool.StartDepart : ""}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.DateCarpool ? this.props.history.DateCarpool.StartEvent : ""}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.NumberOfPlace ? this.props.history.NumberOfPlace : ""}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this._renderAdress()}</TableCell>
        <TableCell align="left" style={{fontSize: '0.8rem'}}>{this.props.history.Commentary ? this.props.history.Commentary : ""}</TableCell>
      </TableRow>)

  }

}

export default PureMember;
