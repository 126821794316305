import React from 'react';
import { Button, Row, Container, Col, Form} from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import Switch from "react-switch";


class OwnerSection extends React.Component {
  constructor(props){
      super(props);
      this.state = {

      }
    }


    componentDidMount(){

    }

     sleep = (ms) => {
      return new Promise(resolve => setTimeout(resolve, ms));
    }



 render() {
   return (
     <Container ref={this.props.refParent.SocietySection} className="shadow" style={{backgroundColor: '#FFFFFF', borderRadius: 10, padding: 50, margin: 10, marginTop: 25}}>
       <Row>
         <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
          <Switch onChange={() => this.props.refParent.setState({SocietyInfoSection: !this.props.refParent.state.SocietyInfoSection})} uncheckedIcon={true} checked={this.props.refParent.state.SocietyInfoSection}  width={42} height={22} />
         </Col>
       </Row>
       <Row>
         <Col sm={12} lg={12} md={12} xl={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
         <h6 style={{textAlign: 'left', color:'#FC8016', marginTop: 25}}>Informations de la société</h6>
         </Col>
       </Row>
       {
         this.props.refParent.state.SocietyInfoSection === true ?
         <>
         <Form noValidate validated={this.props.refParent.state.TvaSearchFormValidated} onSubmit={this.props.refParent.SearchTva} style={{marginTop: 25, marginBottom: 20}}>
             <Row>
               <Col>
               <p style={{textAlign: 'left', marginTop: 10, fontWeight: 'bold'}}>{"Recherchez un numéro de Tva"}</p>
               </Col>
             </Row>
              <Row>
                <Col xl={2} lg={3} sm={3} md={3} style={{margin: 2}}>
                  <Form.Control
                  required
                  value={this.props.refParent.state.CountryTva}
                  placeholder="BE"
                  onChange={(event) => this.props.refParent.setState({CountryTva: event.target.value})}
                  />
                </Col>
                <Col xl={5} lg={5} sm={6} md={4} style={{margin: 2}}>
                  <Form.Control
                  required
                  type="number"
                  value={this.props.refParent.state.TvaSearch}
                  placeholder="0123"
                  onChange={(event) => this.props.refParent.setState({TvaSearch: event.target.value})}
                  />
                  {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Society") ?
                     <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Society.tva}</p>
                     :
                     <></>
                  }
                  <Form.Control.Feedback type="invalid" style={{textAlign: 'left', color: 'red'}}>
                    {this.props.refParent.state.messageTvaSearch}
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" style={{textAlign: 'left', color: 'red'}}>
                    {this.props.refParent.state.messageTvaSearch}
                  </Form.Control.Feedback>
                </Col>
                <Col xl={3} lg={3} sm={6} md={6} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 2}}>
                <Button
                type="submit"
                style={{backgroundColor: '#082331', fontSize: "0.90rem", color: "white", fontWeight: 'bold', padding: 8,  border: "none"}}
                >
                {
                  this.props.refParent.state.loadingTvaSearch === true ?
                  <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}>
                      <CircularProgress color="white" size={20}/>
                    </div>
                    <div>
                      <p style={{margin: 0, color: 'transparent'}}>Chercher</p>
                    </div>
                  </div>
                  :
                  <p style={{margin: 0}}>Chercher</p>
                }
                </Button>
                </Col>
              </Row>
              </Form>
               <Row>
               <Form.Group as={Col} xl={4} lg={5} sm={12} md={12}>
                 <p style={{textAlign: 'left', marginTop: 10}}>Nom<span style={{color: 'red'}}> *</span></p>
                 <Form.Control
                 required
                 type="text"
                 placeholder={"Entrez le nom de la société"}
                 value={this.props.refParent.state.SocietyName}
                 onChange={(event) => this.props.refParent.setState({SocietyName: event.target.value})}/>
                 {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Society") ?
                    <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Society.society}</p>
                    :
                    <></>
                 }
               </Form.Group>
               <Form.Group as={Col} xl={7} lg={6} sm={12} md={12}>
                 <p style={{textAlign: 'left', marginTop: 10}}>Adresse<span style={{color: 'red'}}> *</span></p>
                 <Form.Control
                 required
                 type="text"
                 placeholder={"Numéro, Rue, Avenue"}
                 value={this.props.refParent.state.StreetCity}
                 onChange={(event) => this.props.refParent.setState({StreetCity: event.target.value})}/>
                   {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Society") ?
                      <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Society.streetPrivate}</p>
                      :
                      <></>
                   }
                   {this.props.refParent.state.messageStreet ? <p style={{fontWeight: 'bold', color: 'orange', marginTop: 5}}>{this.props.refParent.state.messageStreet}</p> : <></>}

               </Form.Group>
               </Row>
                <Row>
                  <Form.Group as={Col} xl={4} lg={3} sm={12} md={12}>
                    <p style={{textAlign: 'left', marginTop: 10}}>Code Postal<span style={{color: 'red'}}> *</span></p>
                    <Form.Control
                    required
                    type="number"
                    placeholder={"Entrez le code postal"}
                    value={this.props.refParent.state.PostalCode}
                    onChange={(event) => this.props.refParent.setState({PostalCode: event.target.value})}/>
                    {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Society") ?
                       <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Society.zipCodePrivate}</p>
                       :
                       <></>
                    }
                  </Form.Group>
                  <Form.Group as={Col} xl={7} lg={8} sm={12} md={12}>
                    <p style={{textAlign: 'left', marginTop: 10}}>Ville<span style={{color: 'red'}}> *</span></p>
                    <Form.Control
                    required
                    type="text"
                    placeholder={"Entrez la ville"}
                    value={this.props.refParent.state.City}
                    onChange={(event) => this.props.refParent.setState({City: event.target.value})}/>
                    {this.props.refParent.state.Error && this.props.refParent.state.Error.hasOwnProperty("Society") ?
                       <p style={{fontWeight: 'bold', color: 'red', marginTop: 5, fontSize: 12}}>{this.props.refParent.state.Error.Society.cityPrivate}</p>
                       :
                       <></>
                    }
                  </Form.Group>
                  </Row>
         </>
         :
         <>
         </>
       }
     </Container>
   )
 }
}


export default OwnerSection;
