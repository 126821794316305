import React from 'react';
import {Requestdynamic} from '../Network/Fetch';
import Cookies from 'universal-cookie'

import LoadingInfo from './LoadingInfo'
import PopUp from './PopUp'
import HeaderGoBack from './HeaderGoBack'
import UpdateModal from './UpdateModal'
import EmailForm from './EmailForm'

class UpdateEmail extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        Error: null,
        Email: null,
        VerifyEmail: null,
        IdMember: null,
        StatusUpdate: null,
        message: null,
        openModal: false,
        title: null,
        redirect: false,
        loading: false,
        updateProfil: false
      }
    }

    componentDidMount(){
      this._VerifySource()
      this.goBack = this.goBack.bind(this);
    }

    goBack(){
      if (this.props.navigation.location && this.props.navigation.location.state && this.props.navigation.location.state.back){
        var pathname = this.props.navigation.location.state.back
        var paramsNavigate = {pathname: pathname}
        if (this.props.navigation.location.state.oldPage)
          paramsNavigate.state = {oldPage: this.props.navigation.location.state.oldPage}
          this.props.navigation.history.push(paramsNavigate);
        return ;
      }
      this.props.navigation.history.push('/allmembers/');
    }

    _VerifySource = async () => {
      this.setState({loading: true}, async () => {

            if (!this.props.navigation.match.params.memberid || !this.props.navigation.location || !this.props.navigation.location.state || !this.props.navigation.location.state.back){
              this.setState({loading: false, openModal: true, title: 'Error', redirect: true, message: 'Un probleeeème est survenue veuillez réesayer plus tard'})
              return ;
            }
            this.setState({IdMember: this.props.navigation.match.params.memberid, loading: false})
        })
    }

_CheckEmail = (Error) => {
  if (!this.state.Email || this.state.Email.length === 0){
      Error.Email = true
  }
}

_CheckVerifyEmail = (Error) => {
  if (!this.state.VerifyEmail || this.state.VerifyEmail === 0)
    Error.VerifyEmail = true
}

_CheckMacthEmail = (Error) => {
  if (this.state.Email !== this.state.VerifyEmail)
    Error.VerifyEmail = true
}

sleep = (ms) => {
 return new Promise(resolve => setTimeout(resolve, ms));
}

_ChangeEmail = () => {
  this.setState({Error: null}, async () => {

    var Error = {};
    this._CheckEmail(Error);
    this._CheckVerifyEmail(Error);
    this._CheckMacthEmail(Error);

    var KeyError = Object.keys(Error)
    if (KeyError.length !== 0){
      this.setState({Error: Error})
      return ;
    }
    this.setState({updateProfil: true, StatusUpdate: 'Waiting'}, async () => {
      var cookies = new Cookies();
      var Jwt = cookies.get('acces');
      var Params = {
        Email: this.state.Email,
        Id: this.state.IdMember,
        Jwt: Jwt,
      }
      var promise = await Requestdynamic('_updateEmailMember', Params);
      if (promise.error === false && promise.data.error === false)
      {
        this.setState({StatusUpdate: "Success", message: "L'e-mail à bien été mis à jour"})
        return ;
      }
      else {
        if (promise.error === true)
          this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})
        else if (promise.data && promise.data.hasOwnProperty('jwt') && promise.data.jwt === true){
          cookies = new Cookies();
          cookies.remove('acces', { path: '/' })
          cookies.remove('infoAdmin', { path: '/' })
          await this.sleep(900)
          var url = '/login'
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          return ;
        }
        else
        {
          if (promise.data.message && (promise.data.message.email === "E-mail déjà existant" || promise.data.message === "E-mail déjà existant")){
            this.setState({StatusUpdate: "Error", message: "E-mail déjà existant"})
            return ;
          }
          this.setState({StatusUpdate: "Error", message: "Un problème est survenue veuillez ré-essayer plus tard"})

        }
        return ;
      }
    })

  })
}

 render() {
   return (
     <div style={{marginLeft: 10, overflowY: "auto"}}>
      <UpdateModal refParent={this} openModal={this.state.updateProfil} statusRequest={this.state.StatusUpdate} message={this.state.message}/>
      <PopUp redirect={this.state.redirect} refParent={this} openModal={this.state.openModal} message={this.state.message} title={this.state.title} />
      <LoadingInfo openModal={this.state.loading} />
      <HeaderGoBack refParent={this}/>
      <EmailForm Error={this.state.Error} refParent={this} intl={this.props.intl}/>
    </div>
   )
 }
}


export default UpdateEmail;
