import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AntSwitch} from './Style'
import Cookies from 'universal-cookie'

class HeaderLiner extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }

renderHeaderLiner = () => {
  var cookies = new Cookies();
  var InfoAdmin = cookies.get('infoAdmin');
  if (InfoAdmin && InfoAdmin !== undefined)
    var status = InfoAdmin.status;
  return (
    <>
        <Row style={{}}>
          <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'row', marginTop: 10}}>
             <div style={{borderBottom: '2px solid orange'}}>
              <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>Tête d'affiche</h3>
             </div>
          </Col>
        </Row>
        <Row style={{marginTop: 10, display: 'flex', justifyContent: 'space-between'}}>
        <Col xl={3} lg={3} md={12} sm={12}>
          <div style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'flex-start', margin: 5}}>
           <p style={{fontSize: 15, color: '#808080'}}>Cette conférence est-elle en tête d'affiche ? </p>
             <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
               <p style={{fontSize: 15, color: '#808080', margin: 10}}>Non</p>
               <AntSwitch checked={this.props.isHeadLiner} onChange={() => this.props.refParent.setState({isHeadLiner: !this.props.isHeadLiner})} style={{marginRight: 5}}/>
               <p style={{fontSize: 15, color: '#808080', margin: 10}}>Oui</p>
             </div>
          </div>
        </Col>
        </Row>
    </>
  )

}

 render() {

   return (
     <>
     {this.renderHeaderLiner()}
     </>
   )
 }
}


export default HeaderLiner;
