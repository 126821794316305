import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FileUpload from "../../FileUpload/FileUpload";
import FileUploadReset from "../../FileUpload/FileUploadReset";
import SeparateLine from "./SeparateLine"

class Photo extends React.Component {
  constructor(props){
      super(props);
      this.state = {
      }
    }



     updateUploadedFiles = async (files) => {
      var file = files[0];
      var base64 = await this._convertBase64(file);
      this.props.refParent.setState({Picture: base64})
     }

    _convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

 render() {

   return (
     <>
         <Row style={{}}>
           <Col xl={12} lg={12} md={12} sm={12} style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', padding: 3, flexDirection: 'column'}}>
              <div style={{borderBottom: '2px solid orange'}}>
               <h3 style={{color: 'black', fontSize: 20, fontFamily:'Mitr, sans-sérif', }}>{this.props.intl.formatMessage({ id: 'Photo' })}</h3>
              </div>
              <p style={{fontSize: 12, color: "red", display: this.props.Error  && this.props.Error.Picture ? 'block' : 'none', marginTop: 5}}>Veuillez upload une photo</p>

           </Col>
         </Row>
         <Row>
           <Col xl={12} lg={12} md={12} sm={12} style={{color: this.props.success ? 'orange' : '', textAlign: 'center'}}>
           {
             this.props.success ?
             <FileUploadReset
             />
             :
             <FileUpload
             label={this.props.intl.formatMessage({ id: 'AmbassadeurImage' })}
             updateFilesCb={this.updateUploadedFiles}
             />
           }

           </Col>
         </Row>
         <SeparateLine width={"50%"} height={1} color="greyTrans" />
     </>
   )
 }
}


export default Photo;
