import React, { useRef, useState } from "react";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  InputLabel,
  FileUploading
} from "./FileUploadStyle";
import { MdDeleteForever } from "react-icons/md";
import UploadService from "./FileUploadService";
import {Requestdynamic} from '../../../Network/Fetch'
import {ButtonTestCss} from "../../../ButtonStyle";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000000000000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  updateFilesCb,
  closeModalCallBack,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  IdGallery,
  Reset,
  ...otherProps
}) => {

  const fileInputField = useRef(null);
  const [files, setFiles] = useState({});

  const [message, setMessage] = useState([]);
  const progressInfosRef = useRef(null)
  const [progressInfos, setProgressInfos] = useState({ val: [] });


    const CreateUploadId = async () => {
      var promise = await Requestdynamic('_createUploadIdImage', {Jwt: "djqskl", IdGallery: IdGallery})
      var UploadId;
      if (promise.error === false && promise.data.error === false)
         UploadId = promise.data.data
      else
         UploadId = null
      uploadFiles(UploadId)
    }

    const CloseUploadId = async (UploadId) => {
      await Requestdynamic('_endUploadIdImage', {Jwt: 'jlk', IdGallery: IdGallery, UploadId: UploadId})
      Reset(1000)
    }

    const uploadFiles = (UploadId) => {
      const filess = convertNestedObjectToArray(files);
      let _progressInfos = filess.map(file => ({ percentage: 0, fileName: file.name }));


      progressInfosRef.current = {
        val: _progressInfos,
      }
      const uploadPromises = filess.map((file, i) => upload(i, file, IdGallery, UploadId));
      Promise.all(uploadPromises)
        .then(() => CloseUploadId(UploadId))


      setMessage([]);
    };

    const upload = (idx, file, IdGallery, UploadId) => {
        let _progressInfos = [...progressInfosRef.current.val];
        return UploadService.upload({file: file, IdGallery: IdGallery, UploadId: UploadId}, (event) => {

          _progressInfos[idx].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then(() => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              {error: false, name: file.name},
            ]));
          })
          .catch((e) => {
            console.log("Erreur file upload ", file.name ,e)
            _progressInfos[idx].percentage = 0;
            setProgressInfos({ val: _progressInfos });

            setMessage((prevMessage) => ([
              ...prevMessage,
              {error: true, name: file.name}
            ]));
          });
      };

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      let isImageFile = file.type.split('/')[0] === 'image'
      if (!isImageFile)
        continue ;
      if (file.size < maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload =  (e) => {

    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
    if (progressInfos && progressInfos.val.length > 0){
      setMessage([])
      setProgressInfos({ val: [] })
      setFiles({});

    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });
  };

  const reset = () => {
    setMessage([])
    setProgressInfos({ val: [] })
    setFiles({});

    closeModalCallBack()
  }

  return (
    <>
      <FileUploadContainer style={{
        backgroundColor: otherProps.backgroundColor ? otherProps.backgroundColor : '',
        borderStyle: otherProps.borderStyle ? otherProps.borderStyle : "",
        borderColor: otherProps.borderColor ? otherProps.borderColor : ""}}>
        <InputLabel>{label}</InputLabel>
        <DragDropText>Drag and drop your file anywhere or</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Upload {otherProps.multiple ? "files" : "a file"}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <span>To Upload</span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <MdDeleteForever
                        size={30}
                        color={"white"}
                        className="fas fa-trash-alt"
                        onClick={() => removeFile(fileName)}
                      />
                    </aside>
                  </FileMetaData>
                  {
                    progressInfos && progressInfos.val.length > 0
                    ?
                    <FileUploading isUpload={progressInfos && progressInfos.val.length > 0} color={message.find(res => res.error === true && res.name === fileName)}>

                          <CircularProgressbar  circleRatio={1} styles={buildStyles({
                            strokeLinecap: 'round',
                            textSize: '0.8rem',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(255, 255, 255, ${60 / 100})`,
                            textColor: 'white',
                            trailColor: 'rgba(5, 5, 5, 0.55)',
                            backgroundColor: 'white'})}
                            value={progressInfos.val.find(res => res.fileName === fileName).percentage}
                            text={progressInfos.val.find(res => res.fileName === fileName).percentage + "%"}/>

                    </FileUploading>
                    :
                    <>
                    </>
                  }



                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
      <div style={{width: '100%', padding: 20, flexDirection: "row", display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>

      <ButtonTestCss onClick={CreateUploadId} color={'rgb(8, 36, 48)'} disabled={Object.keys(files).length !== 0 && progressInfos.val.length === 0 ? false : true}>
        Upload
      </ButtonTestCss>



      <ButtonTestCss onClick={reset} color={'orange'}>
        Fermer
      </ButtonTestCss>
      </div>
    </>
  );
};

export default FileUpload;
