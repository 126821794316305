import React from 'react';
import Col from 'react-bootstrap/Col';
const HtmlToReactParser = require('html-to-react').Parser;

class Invoice extends React.Component {

_renderDescription = () => {
  let htmlInput = this.props.Description;
  let htmlToReactParser = new HtmlToReactParser();
  let reactElement = htmlToReactParser.parse(htmlInput);
  return(reactElement)
}

  componentDidMount(){
    this._renderDescription()
  }

  render (){
    return (
          <Col style={{marginTop: 10, display: 'flex',
          justifyContent: 'flex-start', alignItems: 'flex-start', flexDirection: 'column'}} sm={12} xl={12} lg={12} md={12}>

          <p style={{fontWeight: 'bold', color:'#FC8016', fontSize: '0.97em'}}>DESCRIPTION</p>



                  {this._renderDescription()}

          </Col>
    )
  }
}

export default Invoice;
