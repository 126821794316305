import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import {ButtonTestCss} from "../../ButtonStyle";
import VisualizePdf from './Pdf/VisualizePdf'

const Popup = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
`;

function PopUp(props) {
  const pdfExport = useRef();


    const _ButtonClick = () => {
      props.refParent.setState({PdfPrint: false})
    }

    const exportPdf = () => {
      try {
        pdfExport.current.save()
      } catch(e){
        return ;
      }
    }


   return (
       <Popup showPopup={props.openModal} style={{right: 0, left: 0, bottom: 0, top: 0, zIndex: props.openModal ? 100000 : -5, position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div style={{backgroundColor: 'white', padding: 20, borderRadius: 5}}>
        <VisualizePdf data={props.event} pdfExport={pdfExport}/>
        <Row style={{margin: 10}}>
          <Col xl={12} lg={12} sm={12} md={12} style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
            <ButtonTestCss onClick={() => exportPdf()} size={"0.8rem"} color={"orange"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Exporter</ButtonTestCss>

            <ButtonTestCss onClick={() => _ButtonClick()} size={"0.8rem"} color={"#082430"} radius={"10px"} width={"100%"} style={{margin: 10, padding: 10}}>Fermer</ButtonTestCss>
          </Col>
        </Row>
       </div>
       </Popup>
   )
}


export default PopUp;
